import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DealTypes, users } from 'api';
import DEALS from 'constants/deals';
import { arrayToOptions } from 'helpers/common';
import { FormCard, FormInput } from 'components';

interface Props {
  control: Control<DealTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
}

const PlatformBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const startDate = watch('start_date');
  const endDate = watch('end_date');
  const reminderDate = watch('reminder_date');
  const strategy = watch('strategy');
  const channel = watch('channel');

  const handleStrategyChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const value = event.target.value as DealTypes.StrategiesEnum;
      const channelOptions = DEALS.CHANNEL_OPTIONS[value];

      setValue('channel', channelOptions[0]);

      onChange(value);
    },
    [setValue],
  );

  const isReminderPostDateActive = useMemo(
    () =>
      strategy === DealTypes.StrategiesEnum.influencers &&
      channel === DealTypes.ChannelInfluencerEnum.InstagramStories,
    [channel, strategy],
  );

  return (
    <FormCard title="Platform details" avatar={<InfoOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="autocompleteSearch"
            name="lead"
            label="Lead"
            queryFn={users.autocompleteSearch}
            queryKey={users.endpoints.autocompleteSearch}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="strategy"
            inputType="select"
            label="Channel"
            options={DEALS.STRATEGY_OPTIONS}
            onChange={handleStrategyChange}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="channel"
            inputType="select"
            label="Sub channel"
            options={arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="status"
            label="Status"
            options={DEALS.STATUS_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="type"
            label="Type"
            options={DEALS.TYPE_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="focus"
            label="Focus"
            options={DEALS.FOCUS_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="start_date"
            label="Start Date"
            rules={{
              required: true,
              onChange: (event) => {
                const date = dayjs.utc(event.target.value);
                if (date.isAfter(reminderDate)) {
                  setValue('reminder_date', null);
                }
                if (date.isAfter(endDate)) {
                  const start = date.format('DD/MM/YYYY');
                  const end = dayjs(endDate).format('DD/MM/YYYY');
                  if (!dayjs(start).isSame(end)) {
                    setValue('end_date', null);
                  }
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="end_date"
            label="End Date"
            minDate={
              reminderDate
                ? dayjs.utc(reminderDate).add(1, 'days')
                : dayjs.utc(startDate)
            }
          />
        </Grid>
        {isReminderPostDateActive && (
          <Grid item xs={6}>
            <FormInput
              control={control}
              inputType="datepicker"
              name="reminder_date"
              label="Reminder Post Date"
              maxDate={dayjs.utc(endDate).subtract(1, 'days')}
              minDate={dayjs.utc(startDate).add(1, 'days')}
            />
          </Grid>
        )}
      </Grid>
    </FormCard>
  );
};

export default PlatformBlock;
