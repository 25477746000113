import { SvgIcon, SvgIconProps } from '@mui/material';

const InstagramIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.37474 3.5C4.78706 3.5 3.5 4.78706 3.5 6.37474V13.6242C3.5 15.2119 4.78706 16.499 6.37474 16.499H13.6242C15.2119 16.499 16.499 15.2119 16.499 13.6242V6.37474C16.499 4.78706 15.2119 3.5 13.6242 3.5H6.37474ZM2 6.37474C2 3.95864 3.95864 2 6.37474 2H13.6242C16.0403 2 17.999 3.95864 17.999 6.37474V13.6242C17.999 16.0403 16.0403 17.999 13.6242 17.999H6.37474C3.95864 17.999 2 16.0403 2 13.6242V6.37474ZM10.3472 7.84271C9.89996 7.77638 9.44314 7.85278 9.04178 8.06104C8.64041 8.2693 8.31494 8.59882 8.11164 9.00272C7.90834 9.40662 7.83758 9.86434 7.90942 10.3108C7.98126 10.7572 8.19204 11.1696 8.51178 11.4894C8.83152 11.8091 9.24394 12.0199 9.69037 12.0917C10.1368 12.1636 10.5945 12.0928 10.9984 11.8895C11.4023 11.6862 11.7319 11.3607 11.9401 10.9594C12.1484 10.558 12.2248 10.1012 12.1584 9.6539C12.0908 9.19765 11.8782 8.77526 11.552 8.44911C11.2259 8.12297 10.8035 7.91036 10.3472 7.84271ZM8.35092 6.7296C9.03234 6.37603 9.80789 6.24632 10.5673 6.35893C11.3419 6.47379 12.059 6.83474 12.6127 7.38845C13.1664 7.94216 13.5274 8.65928 13.6422 9.43388C13.7548 10.1933 13.6251 10.9688 13.2715 11.6502C12.918 12.3316 12.3585 12.8842 11.6728 13.2294C10.9871 13.5745 10.21 13.6946 9.45207 13.5727C8.69413 13.4507 7.99395 13.0929 7.45112 12.55C6.90828 12.0072 6.55043 11.307 6.42847 10.5491C6.30651 9.79115 6.42664 9.01406 6.77179 8.32833C7.11693 7.64261 7.66951 7.08318 8.35092 6.7296ZM13.988 5.2628C13.5738 5.2628 13.238 5.59859 13.238 6.0128C13.238 6.42702 13.5738 6.7628 13.988 6.7628H13.9946C14.4088 6.7628 14.7446 6.42702 14.7446 6.0128C14.7446 5.59859 14.4088 5.2628 13.9946 5.2628H13.988Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default InstagramIcon;
