import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ListReportData } from 'api/types/common';
import DataTableProps from '../DataTableProps';
import ReportsBlock from './ReportsBlock';
import Search from './Search';
import ViewSelectFilter from './ViewSelectFilter';
import SplitViewToggleButton from './SplitViewToggleButton';

interface Props {
  createButton: DataTableProps.CreateButton | undefined;
  children: ReactNode;
  viewOptions: DataTableProps.ViewOptions | undefined;
  SearchProps: DataTableProps.AutocompleteSearch | undefined;
  withReportsBlock: boolean | undefined;
  reports: ListReportData | undefined;
  title: string | undefined;
  itemsCount: number | undefined;
  inline: boolean;
  withSplitView?: boolean;
}

const TableHeader = (props: Props): JSX.Element | null => {
  const {
    createButton,
    children,
    viewOptions,
    SearchProps,
    withReportsBlock,
    reports,
    title,
    itemsCount,
    inline,
    withSplitView,
  } = props;

  if (inline) {
    return (
      <Box id="table-head">
        <Box
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            p: 1.5,
          })}
        >
          <Grid
            container
            columnSpacing={1.25}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item flexGrow={1}>
              <Typography
                variant="h6"
                color="text.primary"
                noWrap
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {title}
                <Tooltip placement="right" title={`${itemsCount} items found`}>
                  <Chip
                    color="default"
                    size="small"
                    label={itemsCount}
                    sx={{ ml: 1 }}
                  />
                </Tooltip>
              </Typography>
            </Grid>

            {createButton && (
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<Add fontSize="large" />}
                  component={NavLink}
                  to={createButton.link}
                >
                  {createButton.name}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        {children}
      </Box>
    );
  }

  if (!SearchProps && !viewOptions && !createButton && !reports) {
    return (
      <Box>
        <Box id="table-head">{children}</Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box id="table-head">
        <Box
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            p: 1.5,
          })}
        >
          <Grid
            container
            columnSpacing={1.25}
            alignItems="center"
            wrap="nowrap"
          >
            <Search SearchProps={SearchProps} inline={inline} />
            {withSplitView && (
              <Grid item>
                <SplitViewToggleButton />
              </Grid>
            )}
            {!!viewOptions && (
              <Grid item xs={2}>
                <ViewSelectFilter viewOptions={viewOptions} inline={inline} />
              </Grid>
            )}
            {createButton && (
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<Add fontSize="large" />}
                  component={NavLink}
                  to={createButton.link}
                >
                  {createButton.name}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        {children}
      </Box>
      {withReportsBlock && <ReportsBlock data={reports} />}
    </Box>
  );
};

export default TableHeader;
