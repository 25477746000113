import { useMemo } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DownloadRounded } from '@mui/icons-material';
import { useApiRequest } from 'hooks';
import { deals, DealTypes } from 'api';

interface Props {
  deal: DealTypes.ItemData;
}

const DownloadSequences = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const { requestFn: getStoriesFn, isLoading: isStoriesLoading } =
    useApiRequest(() => deals.getStories(deal._id.$oid));

  const {
    requestFn: getReminderStoriesFn,
    isLoading: isReminderStoriesLoading,
  } = useApiRequest(() => deals.getStories(deal._id.$oid));

  const handleDownloadStories = async () => {
    const promisesArray = [
      getStoriesFn().then((response) => {
        if (response) {
          const blob = new Blob([response], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `sequences_download_${deal.deal_id}.zip`;
          link.click();
        }
      }),
    ];
    if (deal.reminder_post_scraped_at) {
      promisesArray.push(
        getReminderStoriesFn().then((response) => {
          if (response) {
            const blob = new Blob([response], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `sequences_reminder_download_${deal.deal_id}.zip`;
            link.click();
          }
        }),
      );
    }
    await Promise.all(promisesArray);
  };

  const isLoading = useMemo(
    () => isStoriesLoading || isReminderStoriesLoading,
    [isStoriesLoading, isReminderStoriesLoading],
  );

  return (
    <Tooltip title="Download stories">
      <span>
        <IconButton
          onClick={handleDownloadStories}
          disabled={isLoading}
          size="small"
        >
          {isLoading ? <CircularProgress size={20} /> : <DownloadRounded />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DownloadSequences;
