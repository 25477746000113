import { CardContent, CardHeader, Skeleton } from '@mui/material';
import { ColorCard, ErrorBoundary } from 'components';
import { colors } from 'theme/constants';
import { useQuery } from '@tanstack/react-query';
import { campaigns, CampaignTypes } from 'api';
import Chart from './Chart';

interface Props {
  params: CampaignTypes.PerformanceParams;
}

const MetricsChart = (props: Props): JSX.Element | null => {
  const { params } = props;

  const { data, error } = useQuery(
    [campaigns.endpoints.performanceInsightsChart, 'marketing', params],
    () => campaigns.getPerformanceMarketingChart(params),
  );

  if (error) {
    return (
      <ErrorBoundary
        height="60vh"
        variant="error"
        message="Failed to load performance data"
      />
    );
  }

  if (!data) {
    return (
      <Skeleton
        variant="rectangular"
        height={100}
        sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
      />
    );
  }

  return (
    <ColorCard color={colors.darkBlue}>
      <CardHeader title="Marketing spend" />
      <CardContent>
        <Chart data={data} />
      </CardContent>
    </ColorCard>
  );
};

export default MetricsChart;
