import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { campaigns } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';

const acceptedFiles = 'image/jpeg, image/png';

interface Props {
  id: string;
  value: string;
  onUploaded: () => Promise<QueryObserverResult>;
}

const AvatarUpload = (props: Props): JSX.Element => {
  const { value, id, onUploaded } = props;

  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const { requestFn: uploadFn, isLoading: isUploading } = useApiRequest(
    (data) =>
      campaigns.addAttachment(id, data, (progressEvent) => {
        setUploadProgress(
          Math.round(progressEvent.percent / progressEvent.totalBytes),
        );
      }),
  );

  const { requestFn: removeFn, isLoading: isRemoving } = useApiRequest(
    campaigns.removeAttachment,
  );

  const handleUpload = useCallback(
    async (file: File) => {
      await uploadFn({
        args: file,
        successMessage: 'Image uploaded',
      });

      await onUploaded();
      setInputValue('');
      setUploadProgress(0);
    },
    [onUploaded, uploadFn],
  );

  const handleRemove = useCallback(async () => {
    await removeFn({
      args: id,
      successMessage: 'Image removed',
    });
    await onUploaded();
  }, [id, onUploaded, removeFn]);

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      await handleUpload(file);
    }
  };

  const isLoading = useMemo(
    () => isRemoving || isUploading,
    [isUploading, isRemoving],
  );

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Campaign image
      </Typography>
      <Box>
        <Grid container alignItems="center">
          <Grid
            item
            component="label"
            htmlFor="file-upload"
            sx={{
              position: 'relative',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {isLoading && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
                color="primary"
                size={80}
                {...(uploadProgress !== 0 && {
                  variant: 'determinate',
                  value: uploadProgress,
                })}
              />
            )}
            <Avatar
              src={value}
              sx={{
                width: 80,
                height: 80,
              }}
            />
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button
              color="primary"
              component="label"
              htmlFor="file-upload"
              disabled={isLoading}
            >
              Upload
            </Button>
          </Grid>
          {!!value && (
            <Grid item sx={{ ml: 2 }}>
              <Button color="error" onClick={handleRemove} disabled={isLoading}>
                Remove
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        component="input"
        type="file"
        disabled={isLoading}
        sx={{
          visibility: 'hidden',
          width: 0,
          height: 0,
        }}
        onChange={handleFileSelect}
        id="file-upload"
        name="file-upload"
        value={inputValue}
        accept={acceptedFiles}
      />
    </Box>
  );
};

export default AvatarUpload;
