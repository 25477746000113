import {
  SvgIconComponent,
  TodayOutlined,
  GroupOutlined,
  GridViewRounded,
  BarChartRounded,
  AssignmentIndOutlined,
  TagOutlined,
  HandshakeOutlined,
  BookmarkBorderOutlined,
  CampaignOutlined,
  WorkOutlineOutlined,
  PercentOutlined,
  FunctionsOutlined,
  CalendarMonthOutlined,
} from '@mui/icons-material';

const mainLinks = {
  signIn: {
    name: 'Sign in',
    link: '/sign-in',
  },
  index: {
    name: 'Home',
    link: '/',
  },
  dashboard: {
    name: 'Dashboard',
    link: '/dashboard',
  },
  calendar: {
    name: 'Calendar',
    link: '/calendar',
  },
  sourcing: {
    name: 'Sourcing',
    link: '',
  },
  planning: {
    name: 'Planning',
    link: '',
  },
  analytics: {
    name: 'Analytics',
    link: '',
  },
};

const sourcingLinks = {
  influencers: {
    name: 'Influencers',
    link: `${mainLinks.sourcing.link}/influencers`,
    create: {
      name: 'Add influencer',
      link: `${mainLinks.sourcing.link}/influencers/create`,
    },
    details: {
      name: 'Influencer Details',
      link: `${mainLinks.sourcing.link}/influencers/:id`,
      as: (id: string) => `${mainLinks.sourcing.link}/influencers/${id}`,
    },
    edit: {
      name: 'Edit Influencer',
      link: `${mainLinks.sourcing.link}/influencers/:id/edit`,
      as: (id: string) => `${mainLinks.sourcing.link}/influencers/${id}/edit`,
    },
  },
  bookmarks: {
    name: 'Bookmarks',
    link: `${mainLinks.sourcing.link}/bookmarks`,
  },
  mentions: {
    name: 'Mentions',
    link: `${mainLinks.sourcing.link}/mentions`,
  },
  negotiations: {
    name: 'Negotiations',
    link: `${mainLinks.sourcing.link}/negotiations`,
    create: {
      name: 'Add negotiation',
      link: `${mainLinks.sourcing.link}/negotiations/create`,
      as: (influencerId: string) =>
        `${mainLinks.sourcing.link}/negotiations/create?influencerId=${influencerId}`,
    },
    details: {
      name: 'Negotiation Details',
      link: `${mainLinks.sourcing.link}/negotiations/:id`,
      as: (id: string) => `${mainLinks.sourcing.link}/negotiations/${id}`,
    },
    edit: {
      name: 'Edit Negotiation',
      link: `${mainLinks.sourcing.link}/negotiations/:id/edit`,
      as: (id: string) => `${mainLinks.sourcing.link}/negotiations/${id}/edit`,
    },
  },
};

const planningLinks = {
  sales: {
    name: 'Sales planning',
    link: `${mainLinks.planning.link}/sales`,
    create: {
      name: 'Add sales planning',
      link: `${mainLinks.planning.link}/sales/create`,
    },
    details: {
      name: 'Sales planning details',
      link: `${mainLinks.planning.link}/sales/:id`,
      as: (id: string) => `${mainLinks.planning.link}/sales/${id}`,
    },
    edit: {
      name: 'Edit sales planning',
      link: `${mainLinks.planning.link}/sales/:id/edit`,
      as: (id: string) => `${mainLinks.planning.link}/sales/${id}/edit`,
    },
  },
  campaigns: {
    name: 'Campaigns',
    link: `${mainLinks.planning.link}/campaigns`,
    create: {
      name: 'Add campaign',
      link: `${mainLinks.planning.link}/campaigns/create`,
    },
    details: {
      name: 'Campaign Details',
      link: `${mainLinks.planning.link}/campaigns/:id`,
      as: (id: string) => `${mainLinks.planning.link}/campaigns/${id}`,
    },
    edit: {
      name: 'Edit campaign',
      link: `${mainLinks.planning.link}/campaigns/:id/edit`,
      as: (id: string) => `${mainLinks.planning.link}/campaigns/${id}/edit`,
    },
  },
  deals: {
    name: 'Deals',
    link: `${mainLinks.planning.link}/deals`,
    create: {
      name: 'Add deal',
      link: `${mainLinks.planning.link}/deals/create`,
      as: (influencerId: string) =>
        `${mainLinks.planning.link}/deals/create?influencerId=${influencerId}`,
      clone: (dealId: string) =>
        `${mainLinks.planning.link}/deals/create?cloneDealId=${dealId}`,
    },
    details: {
      name: 'Deal Details',
      link: `${mainLinks.planning.link}/deals/:id`,
      as: (id: string) => `${mainLinks.planning.link}/deals/${id}`,
    },
    edit: {
      name: 'Edit Deal',
      link: `${mainLinks.planning.link}/deals/:id/edit`,
      as: (id: string) => `${mainLinks.planning.link}/deals/${id}/edit`,
    },
  },
  markingPlan: {
    name: 'Marketing plan',
    link: `${mainLinks.planning.link}/marketing-plan`,
    create: {
      name: 'Add marketing plan',
      link: `${mainLinks.planning.link}/marketing-plan/create`,
    },
    details: {
      name: 'Marketing plan details',
      link: `${mainLinks.planning.link}/marketing-plan/:id`,
      as: (id: string) => `${mainLinks.planning.link}/marketing-plan/${id}`,
    },
    edit: {
      name: 'Edit marketing plan',
      link: `${mainLinks.planning.link}/marketing-plan/:id/edit`,
      as: (id: string) =>
        `${mainLinks.planning.link}/marketing-plan/${id}/edit`,
    },
  },
  discounts: {
    name: 'Discounts',
    link: `${mainLinks.planning.link}/discounts`,
    create: {
      name: 'Add discount',
      link: `${mainLinks.planning.link}/discounts/create`,
    },
    details: {
      name: 'Discount Details',
      link: `${mainLinks.planning.link}/discounts/:id`,
      as: (id: string) => `${mainLinks.planning.link}/discounts/${id}`,
    },
    edit: {
      name: 'Edit discount',
      link: `${mainLinks.planning.link}/discounts/:id/edit`,
      as: (id: string) => `${mainLinks.planning.link}/discounts/${id}/edit`,
    },
  },
};

const analyticsLinks = {
  budgetDistribution: {
    name: 'Budget',
    link: `${mainLinks.analytics.link}/budget`,
  },
  pipelinePreparation: {
    name: 'Pipeline Preparation',
    link: `${mainLinks.analytics.link}/pipeline-preparation`,
  },
};

const adminLinks = {
  team: {
    name: 'Team',
    link: '/team',
    create: {
      name: 'Add teammate',
      link: '/team/create',
    },
    details: {
      name: 'Teammate Details',
      link: '/team/:id',
      as: (id: string) => `/team/${id}`,
    },
    edit: {
      name: 'Edit Teammate',
      link: '/team/:id/edit',
      as: (id: string) => `/team/${id}/edit`,
    },
  },
};

const appLinks = {
  ...mainLinks,
  ...sourcingLinks,
  ...planningLinks,
  ...analyticsLinks,
  ...adminLinks,
};

export interface MenuLink {
  name: string;
  link: string;
}

export interface MenuNavLink extends MenuLink {
  name: string;
  link: string;
  Icon: SvgIconComponent;
  children?: MenuLink[];
}

const adminMenuLinks: MenuNavLink[] = [
  {
    ...sourcingLinks.bookmarks,
    Icon: BookmarkBorderOutlined,
  },
  {
    ...adminLinks.team,
    Icon: GroupOutlined,
  },
];

const mainMenuLinks: MenuNavLink[] = [
  {
    ...mainLinks.calendar,
    Icon: TodayOutlined,
  },
  {
    ...planningLinks.markingPlan,
    Icon: CalendarMonthOutlined,
  },
  {
    ...sourcingLinks.mentions,
    Icon: TagOutlined,
  },
  {
    ...sourcingLinks.influencers,
    Icon: AssignmentIndOutlined,
  },
  {
    ...sourcingLinks.negotiations,
    Icon: HandshakeOutlined,
  },
  {
    ...planningLinks.campaigns,
    Icon: CampaignOutlined,
  },
  {
    ...planningLinks.discounts,
    Icon: PercentOutlined,
  },
  {
    ...planningLinks.deals,
    Icon: WorkOutlineOutlined,
  },
  {
    ...planningLinks.sales,
    Icon: FunctionsOutlined,
  },
  {
    name: 'Pipeline Preparation',
    link: `${mainLinks.analytics.link}/pipeline-preparation`,
    Icon: BarChartRounded,
  },
];

const menuLinks = {
  dashboard: {
    ...mainLinks.dashboard,
    Icon: GridViewRounded,
  },
  mainMenuLinks,
  adminMenuLinks,
};

const getNestedLinks = (links: Record<string, string | MenuLink>) =>
  Object.values(links)
    .map((obj) =>
      Object.entries(obj).reduce((previousValue, [, value]) => {
        if (typeof value !== 'string') {
          return [...previousValue, value];
        }
        return previousValue;
      }, [] as MenuLink[]),
    )
    .flat() as MenuLink[];

const appLinksFlat: any[] = [
  ...Object.values(appLinks),
  ...getNestedLinks(sourcingLinks),
  ...getNestedLinks(planningLinks),
  ...getNestedLinks(analyticsLinks),
  ...getNestedLinks(adminLinks),
];

export { appLinks, mainMenuLinks, menuLinks, appLinksFlat };
