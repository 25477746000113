import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Stack,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/links';
import { getInfluencerName } from 'helpers/common';
import { BrandsStatus, InfluencerName } from 'components';
import { formatCompactNumber, formatPercent } from 'helpers/formatters';
import { InfluencerTypes } from 'api';
import MetricBlock from '../MetricBlock';

interface Props {
  data: InfluencerTypes.ItemData;
}

const SimilarInfluencerCard = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Card style={{ width: '275px' }} sx={{ mr: 1 }}>
      <CardActionArea
        component={NavLink}
        to={appLinks.influencers.details.as(data.influencer_id || '')}
      >
        <CardContent>
          <Stack
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={1}
          >
            <Avatar
              src={data.profile_picture_url}
              alt={getInfluencerName(data)}
              sx={{
                width: 100,
                height: 100,
              }}
            />
            <InfluencerName
              data={data}
              BoxProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
            <BrandsStatus brands={data.brand_infos} />
            <Stack
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <MetricBlock
                value={formatCompactNumber(data.instagram?.followers_nb)}
                percentage={data.instagram?.followers_growth}
                title="Followers"
              />
              <MetricBlock
                value={formatPercent(data.instagram_post?.engagement_rate)}
                percentage={0}
                title="Eng. Rate"
              />
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SimilarInfluencerCard;
