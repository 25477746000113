import * as React from 'react';
import {
  Avatar,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { InfluencerTypes } from 'api';
import INFLUENCERS from 'constants/influencers';
import { useMemo } from 'react';
import { CloseOutlined, RestoreOutlined, Verified } from '@mui/icons-material';
import { AutocompleteSearchItem } from 'api/types/common';
import { getUserInitials } from 'helpers/common';

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  option: AutocompleteSearchItem;
  onRemove: (id: string) => void;
}

const AutocompleteOption = (props: Props): JSX.Element => {
  const { option, onRemove, ...rest } = props;

  const platforms = useMemo(() => {
    if (option.platforms) {
      return Object.keys(option.platforms).map((key) => {
        const platform =
          INFLUENCERS.PLATFORM_LOWERCASE[
            key as Lowercase<InfluencerTypes.PlatformEnum>
          ];
        return { platform, Icon: INFLUENCERS.PLATFORM_ICONS[platform] };
      });
    }

    return [];
  }, [option.platforms]);

  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();

    onRemove(option._id.$oid);
  };

  return (
    <li {...rest}>
      <ListItemAvatar>
        <Avatar
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.light,
          })}
        >
          {getUserInitials(option.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        {option.is_verified ? (
          <Stack direction="row" alignItems="center" columnGap={1}>
            <Typography>{option.name || '-'}</Typography>
            {option.is_verified && (
              <Verified color="primary" fontSize="small" />
            )}
          </Stack>
        ) : (
          <ListItemText primary={option.name} />
        )}
        {platforms.length > 0 && (
          <Stack direction="row" columnGap={1} alignItems="center" mt={0.75}>
            {platforms.map(({ platform, Icon }) => (
              <Icon
                key={platform}
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  fontSize: '16px',
                })}
              />
            ))}
          </Stack>
        )}
      </ListItemText>
      {option.historyItem && (
        <ListItemIcon
          onClick={handleRemove}
          sx={{
            minWidth: 'unset',
            '& .restore': {
              display: 'block',
            },
            '& .remove': {
              display: 'none',
            },
            '&:hover': {
              cursor: 'pointer',
              '& .restore': {
                display: 'none',
              },
              '& .remove': {
                display: 'block',
              },
            },
          }}
        >
          <RestoreOutlined className="restore" />
          <CloseOutlined className="remove" />
        </ListItemIcon>
      )}
    </li>
  );
};

export default AutocompleteOption;
