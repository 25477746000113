import { DealTypes, InfluencerTypes } from 'api';

const getSocialNetworkFromChannel = (
  channel: DealTypes.ChannelEnum,
): InfluencerTypes.PlatformEnum => {
  switch (channel) {
    case DealTypes.ChannelInfluencerEnum.TiktokPost:
    case DealTypes.ChannelInternalEnum.TiktokPost:
      return InfluencerTypes.PlatformEnum.Tiktok;
    case DealTypes.ChannelInfluencerEnum.YoutubePost:
    case DealTypes.ChannelInternalEnum.YoutubePost:
      return InfluencerTypes.PlatformEnum.Youtube;
    case DealTypes.ChannelInfluencerEnum.SnapchatPost:
    case DealTypes.ChannelInternalEnum.SnapchatPost:
      return InfluencerTypes.PlatformEnum.Snapchat;
    case DealTypes.ChannelInfluencerEnum.PinterestPost:
    case DealTypes.ChannelInternalEnum.PinterestPost:
      return InfluencerTypes.PlatformEnum.Pinterest;
    default:
      return InfluencerTypes.PlatformEnum.Instagram;
  }
};

export default getSocialNetworkFromChannel;
