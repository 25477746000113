import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { useLayoutEffect } from 'react';
import SideNav from './SideNav';
import Header from './Header';
import { useStoreContext } from '../../store';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../theme/constants';
import DetailsDrawerRoutes from '../DetailsDrawer/DetailsDrawerRoutes';

const Layout = (): JSX.Element => {
  const {
    state: { isSideNavOpen },
  } = useStoreContext();

  useLayoutEffect(() => {
    document.addEventListener('wheel', () => {
      const el = document.activeElement as HTMLInputElement;
      if (el && el.type === 'number') {
        el.blur();
      }
    });
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <DetailsDrawerRoutes />
      <SideNav />
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          width: isSideNavOpen
            ? `calc(100% - ${DRAWER_WIDTH}px)`
            : `calc(100% - ${theme.spacing(8)} + 1px)`,
        })}
      >
        <Box sx={{ height: HEADER_HEIGHT }} />
        <Header />
        <Outlet />
      </Box>
    </Box>
  );
};
export default Layout;
