import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PaletteMode,
  Box,
  Button,
} from '@mui/material';
import {
  DarkModeOutlined,
  LightModeOutlined,
  SettingsBrightnessOutlined,
} from '@mui/icons-material';
import { useStoreContext } from '../../../store';
import { LocalStorage, LocalStorageEnum } from '../../../helpers/localStorage';
import { setStoreState } from '../../../store/actions';

const AppearanceMenu: React.FC = () => {
  const {
    state: { appearance },
    dispatch,
  } = useStoreContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: PaletteMode | undefined) => {
    if (value) {
      LocalStorage.set(LocalStorageEnum.APPEARANCE, value);
    } else {
      LocalStorage.remove(LocalStorageEnum.APPEARANCE);
    }
    dispatch(setStoreState({ appearance: value }));
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <Button
        variant="text"
        sx={(theme) => ({
          padding: theme.spacing(1, 1.5),
          backgroundColor:
            theme.palette.mode === 'dark'
              ? undefined
              : theme.palette.primary.light,
          color: theme.palette.text.primary,
          minHeight: '48px',
          minWidth: 'unset',
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? undefined
                : theme.palette.primary.light,
          },
        })}
      >
        {!appearance && <SettingsBrightnessOutlined />}
        {appearance &&
          (appearance === 'dark' ? (
            <DarkModeOutlined />
          ) : (
            <LightModeOutlined />
          ))}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          position: 'inherit',
        }}
        PaperProps={{
          sx: (theme) => ({
            position: 'fixed',
            maxHeight: theme.spacing(45),
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }),
        }}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => handleChange(undefined)}
          selected={!appearance}
          sx={{ minHeight: '48px' }}
        >
          <ListItemIcon>
            <SettingsBrightnessOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="System" />
        </MenuItem>
        <MenuItem
          onClick={() => handleChange('light')}
          selected={appearance === 'light'}
          sx={{ minHeight: '48px' }}
        >
          <ListItemIcon>
            <LightModeOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Light" />
        </MenuItem>
        <MenuItem
          onClick={() => handleChange('dark')}
          selected={appearance === 'dark'}
          sx={{ minHeight: '48px' }}
        >
          <ListItemIcon>
            <DarkModeOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Dark" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AppearanceMenu;
