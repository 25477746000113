import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FieldArrayBlock, FormContainer } from 'components';
import { influencers, InfluencerTypes } from 'api';
import { getFieldsWithValues, getDiffFields } from 'helpers/getFormFields';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import { brandsInfo, eventsInfo } from './fields';
import SocialNetworksBlock from './components/SocialNetworksBlock';
import PersonalBlock from './components/PersonalBlock';
import AgencyBlock from './components/AgencyBlock';
import ProfileBlock from './components/ProfileBlock';
import AudienceBlock from './components/AudienceBlock';

interface Props {
  id: string | undefined;
  initialData: InfluencerTypes.ItemCreateParams;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    trigger,
    setValue,
  } = useForm<InfluencerTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { requestFn } = useApiRequest(
    id ? (data) => influencers.update(id, data) : influencers.create,
  );

  const onSubmit = useCallback(
    async (data: InfluencerTypes.ItemCreateParams) => {
      const values = id
        ? getDiffFields(initialData, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `Influencer ${id ? 'updated' : 'created'}`,
      });

      if (res) {
        if (drawer) {
          updateFiltersSearchParams({
            influencerId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.influencers.details.as(res.influencer_id), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      id,
      initialData,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.influencers.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
    >
      <Stack rowGap={4}>
        <Card sx={{ p: 2 }}>
          <Typography variant="h5" paragraph>
            General information
          </Typography>
          <Stack spacing={2}>
            <PersonalBlock control={control} watch={watch} />
            <AgencyBlock control={control} />
            <ProfileBlock control={control} watch={watch} />
            <AudienceBlock watch={watch} setValue={setValue} inline={drawer} />
          </Stack>
        </Card>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <FieldArrayBlock
              control={control}
              name={brandsInfo.key}
              title={brandsInfo.label}
              itemTitle={brandsInfo.itemLabel}
              itemFields={brandsInfo.itemFields}
              appendValue={brandsInfo.appendValue}
            />
          </Stack>
        </Card>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <FieldArrayBlock
              control={control}
              name={eventsInfo.key}
              title={eventsInfo.label}
              itemTitle={eventsInfo.itemLabel}
              itemFields={eventsInfo.itemFields}
              appendValue={eventsInfo.appendValue}
            />
          </Stack>
        </Card>
        <SocialNetworksBlock
          control={control}
          watch={watch}
          trigger={trigger}
        />
      </Stack>
    </FormContainer>
  );
};

export default Form;
