import { ThemeOptions } from '@mui/material';

const typography: ThemeOptions['typography'] = {
  fontSize: 14,
  fontFamily: '"Poppins", sans-serif',
  h1: {
    fontSize: '56px',
    fontWeight: 500,
    lineHeight: '84px',
  },
  h2: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '72px',
  },
  h3: {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '54px',
  },
  h4: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '42px',
  },
  h5: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  h6: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
  },
  subtitle1: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  subtitle2: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
};

export default typography;
