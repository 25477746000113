import { useCallback } from 'react';
import {
  Box,
  StackProps as MuiStackProps,
  Stack,
  Card,
  CardContent,
} from '@mui/material';
import { HandleChangeValue } from 'hooks';
import PopoverFilter from './PopoverFilter';
import { Filter } from '../Filters';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';

interface InlineProps {
  inline: true;
  onClose: (key: string) => void;
  onApply: () => void;
  onClearFilter: (key: string) => void;
}

interface DrawerProps {
  inline?: never;
  onClose?: never;
  onApply?: never;
  onClearFilter?: never;
}

type Props = (InlineProps | DrawerProps) & {
  filters: DataTableProps.Filters<Record<string, unknown>>;
  filtersState: Record<string, any>;
  onChange: (key: string, value: HandleChangeValue<unknown>) => void;
  StackProps?: MuiStackProps;
};

const Filters = (props: Props): JSX.Element => {
  const {
    filtersState,
    filters,
    inline,
    onChange,
    onClose,
    StackProps,
    onClearFilter,
    onApply,
  } = props;
  const {
    state: { filtersState: rootFiltersState, activeFilters },
  } = useDataTableContext();

  const getOptions = useCallback(
    (filter: DataTableProps.Filter<Record<string, unknown>>) => {
      if ('options' in filter) {
        return typeof filter.options === 'function'
          ? filter.options(filtersState)
          : filter.options;
      }

      return [];
    },
    [filtersState],
  );

  if (inline && onClose && onApply) {
    return (
      <Stack
        spacing={1}
        direction="row"
        sx={{ overflow: 'auto' }}
        {...StackProps}
      >
        {filters.map((filter) =>
          filter.quickFilter === false &&
          !activeFilters?.includes(filter.key) ? null : (
            <Box key={filter.key}>
              <PopoverFilter
                label={filter.name}
                labelFormatter={filter.nameFormatter}
                value={rootFiltersState[filter.key]}
                options={getOptions(filter)}
                disableClearable={filter.required}
                onClose={() => onClose(filter.key)}
                onClear={() => onClearFilter(filter.key)}
                onApply={onApply}
              >
                <Filter
                  inline
                  filtersState={filtersState}
                  filter={filter}
                  value={filtersState[filter.key]}
                  onChange={onChange}
                  options={getOptions(filter)}
                />
              </PopoverFilter>
            </Box>
          ),
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={1} {...StackProps}>
      {filters.map((filter) => (
        <Box key={filter.key}>
          <Card variant="outlined">
            <CardContent>
              <Filter
                filtersState={filtersState}
                filter={filter}
                value={filtersState[filter.key]}
                onChange={onChange}
                options={getOptions(filter)}
              />
            </CardContent>
          </Card>
        </Box>
      ))}
    </Stack>
  );
};

export default Filters;
