import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { InfluencerTypes } from 'api';
import { InfoCard, InfoCardItem } from 'components';
import { colors } from 'theme/constants';

interface Props {
  inline?: boolean;
  data: InfluencerTypes.ItemData;
}

const AboutTab = (props: Props): JSX.Element | null => {
  const { data, inline } = props;

  const personalInfo: InfoCardItem<InfluencerTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'personal_info.first_name',
        label: 'First name',
        value: data.personal_info?.first_name,
      },
      {
        key: 'personal_info.middle_name',
        label: 'Middle name',
        value: data.personal_info?.middle_name,
      },
      {
        key: 'personal_info.last_name',
        label: 'Last name',
        value: data.personal_info?.last_name,
      },
      {
        key: 'personal_info.age_group',
        label: 'Age group',
        value: data.personal_info?.age_group,
      },
      {
        key: 'personal_info.gender',
        label: 'Gender',
        value: data.personal_info?.gender,
      },
      {
        key: 'personal_info.birth_dat',
        label: 'Birth date',
        value: data.personal_info?.birth_date,
      },
      {
        key: 'vertical_primary',
        label: 'Primary vertical',
        value: data.vertical_primary,
      },
      {
        key: 'vertical_secondary',
        label: 'Secondary verticals',
        value: data.verticals_secondary?.join(', '),
      },
      {
        key: 'tags',
        label: 'Tags',
        value: data.tags?.join(', '),
      },
    ],
    [data],
  );

  const contactInfo: InfoCardItem<InfluencerTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'personal_info.email',
        label: 'Email',
        value: data.personal_info?.email,
      },
      {
        key: 'biography_email',
        label: 'Bio Email',
        value: data.biography_email,
      },
      {
        key: 'personal_info.phone',
        label: 'Phone',
        value: data.personal_info?.phone,
      },
      {
        key: 'personal_info.private_address',
        label: 'Private address',
        value: data.personal_info?.private_address,
      },
      {
        key: 'personal_info.shipping_address',
        label: 'Shipping address',
        value: data.personal_info?.shipping_address,
      },
      {
        key: 'agency_contact_info.agency_name',
        label: 'Agency name',
        value: data.agency_contact_info?.agency_name,
      },
      {
        key: 'agency_contact_info.contact_name',
        label: 'Agency contact person',
        value: data.agency_contact_info?.contact_name,
      },
      {
        key: 'agency_contact_info.email',
        label: 'Agency email',
        value: data.agency_contact_info?.email,
      },
      {
        key: 'agency_contact_info.phone',
        label: 'Agency phone',
        value: data.agency_contact_info?.phone,
      },
    ],
    [data],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard title="Bio" color={colors.green2}>
            <Typography>{data.biography}</Typography>
          </InfoCard>
          <InfoCard
            title="Personal information"
            data={personalInfo}
            color={colors.darkGreen}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard
            title="Contact information"
            data={contactInfo}
            color={colors.darkOrange}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AboutTab;
