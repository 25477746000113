import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';
import { ErrorBoundary } from 'components';
import { campaigns, CampaignTypes, DealTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import PerformanceLoader from './PerformanceLoader';
import MetricsCards from './MetricsCards';
import MarketingSpentTable from './MarketingSpentTable';
import PerformanceFilters from './PerformanceFilters';
import PerformanceCharts from './PerformanceCharts';
import ChannelPerformance from './ChannelPerformance';
import TopInfluencers from './TopInfluencers';
import DealsPerformance from './DealsPerformance';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

const PerformanceTab = (props: Props): JSX.Element | null => {
  const { campaign, inline } = props;

  const [type, setType] = useState<DealTypes.TypeEnum>();
  const [strategy, setStrategy] = useState<DealTypes.StrategiesEnum>();
  const [channel, setChannel] = useState<DealTypes.ChannelEnum>();
  const [brands, setBrands] = useState<BrandsEnum[]>([]);
  const [countries, setCountries] = useState<CountriesEnum[]>([]);

  const params: CampaignTypes.PerformanceParams = useMemo(
    () => ({
      filters: {
        campaign_ids: [campaign._id.$oid],
        type,
        strategy: strategy ? [strategy] : undefined,
        channel: channel ? [channel] : undefined,
        brand: brands.length > 0 ? brands : undefined,
        country_team: countries.length > 0 ? countries : undefined,
      },
      period: {
        start_date: campaign.start_date,
        end_date: campaign.end_date,
      },
    }),
    [
      campaign._id.$oid,
      campaign.start_date,
      campaign.end_date,
      type,
      strategy,
      channel,
      brands,
      countries,
    ],
  );

  const { data, error } = useQuery(
    [campaigns.endpoints.performanceReport, params],
    () => campaigns.getPerformanceReport(params),
  );

  if (error) {
    return (
      <ErrorBoundary
        height="60vh"
        variant="error"
        message="Failed to load performance data"
      />
    );
  }

  if (!data) {
    return <PerformanceLoader />;
  }

  return (
    <Stack rowGap={3}>
      <PerformanceFilters
        type={type}
        setType={setType}
        strategy={strategy}
        setStrategy={setStrategy}
        channel={channel}
        setChannel={setChannel}
        brands={brands}
        setBrands={setBrands}
        countries={countries}
        setCountries={setCountries}
      />
      <MetricsCards performance={data} inline={inline} />
      <PerformanceCharts params={params} />
      <MarketingSpentTable performance={data} />
      <ChannelPerformance params={params} />
      <TopInfluencers params={params} />
      <DealsPerformance params={params} />
    </Stack>
  );
};

export default PerformanceTab;
