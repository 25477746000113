import React from 'react';
import {
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { InfoCard } from 'components';
import { SalesPlanningTypes } from 'api';
import { formatCurrency } from 'helpers/formatters';
import { colors } from 'theme/constants';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { getDealStatusColor } from 'helpers/getStatusColor';

interface Props {
  performance: SalesPlanningTypes.PerformanceData;
}

const config: {
  key: keyof SalesPlanningTypes.PerformanceDetailsItem;
  label: string;
}[] = [
  { key: 'key', label: 'Date' },
  { key: 'status', label: 'Status' },
  { key: 'budget_running_forecast', label: 'Runn. forecast' },
  { key: 'budget_actual', label: 'Actual' },
  { key: 'budget_potential', label: 'Potential' },
  { key: 'revenue_running_forecast', label: 'Runn. forecast' },
  { key: 'revenue_actual', label: 'Actual' },
  { key: 'revenue_potential', label: 'Potential' },
];

const TrafficLightTable: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    performance: { details },
  } = props;

  return (
    <InfoCard title="Marketing spent info" color={colors.darkGreen}>
      <Card variant="outlined" sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead
            sx={(theme) => ({
              '& th': {
                backgroundColor: theme.palette.background.paper,
              },
            })}
          >
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell
                colSpan={3}
                align="center"
                sx={(theme) => ({
                  borderRight: `1px solid ${theme.palette.divider}`,
                  borderLeft: `1px solid ${theme.palette.divider}`,
                })}
              >
                Budget
              </TableCell>
              <TableCell colSpan={3} align="center">
                Revenue
              </TableCell>
            </TableRow>
            <TableRow>
              {config.map((c, index) => (
                <TableCell
                  key={c.key}
                  sx={(theme) => ({
                    borderRight:
                      index === 4
                        ? `1px solid ${theme.palette.divider}`
                        : undefined,
                    borderLeft:
                      index === 2
                        ? `1px solid ${theme.palette.divider}`
                        : undefined,
                  })}
                >
                  {c.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((item) => (
              <TableRow
                key={item.key + item.status}
                sx={{
                  '&:last-child td, &:last-child th': { borderBottom: 0 },
                }}
              >
                {config.map((c, index) => (
                  <TableCell
                    key={c.key}
                    sx={(theme) => ({
                      borderRight:
                        index === 4
                          ? `1px solid ${theme.palette.divider}`
                          : undefined,
                      borderLeft:
                        index === 2
                          ? `1px solid ${theme.palette.divider}`
                          : undefined,
                    })}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {c.key === 'key' ? (
                      getFormattedDate(item[c.key])
                    ) : c.key === 'status' ? (
                      <Chip
                        variant="outlined"
                        label={item[c.key]}
                        color={getDealStatusColor(item.status)}
                      />
                    ) : (
                      formatCurrency(item[c.key] as number)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </InfoCard>
  );
};

export default TrafficLightTable;
