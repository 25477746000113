import { useMemo } from 'react';
import { Area, AreaChart } from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';
import { ChartOption } from 'api/types/common';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.SparklineCell &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const SparklineCell = (props: Props): JSX.Element => {
  const { data, cell } = props;
  const { palette } = useTheme();

  const chartData = useMemo(
    () =>
      (data[cell.key] as ChartOption[]).map((c) => ({
        ...c,
        value: c.value || 0,
      })),
    [cell.key, data],
  );

  return (
    <Box>
      {chartData.length === 0 ? (
        <Typography>-</Typography>
      ) : (
        <AreaChart
          width={120}
          height={60}
          data={chartData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <Area
            type="monotone"
            dataKey="value"
            stroke={palette.primary.main}
            fill={palette.primary.light}
          />
        </AreaChart>
      )}
    </Box>
  );
};

export default SparklineCell;
