import apiClient from '../apiClient';
import BookmarksTypes from './types';

const endpoints = {
  getList: 'bookmarks',
  create: 'bookmarks',
  remove: (id: string) => `bookmarks/${id}`,
  bulkRemove: 'bookmarks/bulk_destroy',
};

const getList = (): BookmarksTypes.ListResponse =>
  apiClient.get(endpoints.getList).then((response) =>
    response.json().then((res) => {
      const data = (res as BookmarksTypes.ItemData[]).map((r) => ({
        ...r,
        _id: { $oid: r.id },
      }));

      return {
        total: (data as BookmarksTypes.ItemData[]).length,
        data: data as BookmarksTypes.ItemData[],
      };
    }),
  );

const create = (
  data: BookmarksTypes.ItemCreateParams,
): BookmarksTypes.ListResponse =>
  apiClient
    .post(endpoints.create, { json: data })
    .then((response) => response.json());

const remove = (id: string): BookmarksTypes.ListResponse =>
  apiClient.delete(endpoints.remove(id)).then((response) => response.json());

const bulkRemove = (
  params: BookmarksTypes.BulkRemovePrams,
): BookmarksTypes.ListResponse =>
  apiClient
    .delete(endpoints.bulkRemove, { json: params })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  create,
  remove,
  bulkRemove,
};
