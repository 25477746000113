import { BrandsEnum } from '../api/types/Brands';

const AVA_MAY_PRODUCTS = {
  Accessories: ['Matches', 'Trimmer', 'Snuffer'],
  'Additional Packaging': [
    'DE - Freedom Card, Annapurna',
    'DE - Love Card, Santorini',
    'DE - Protection Card, Dambulla',
    'FR - Freedom Card, Annapurna',
    'FR - Love Card, Santorini',
    'FR - Protection Card, Dambulla',
  ],
  'Body milk': ['Bahamas Body milk 200 ml'],
  'Candle - Large': [
    'Havana Candle - Large 500 gms',
    'Madagascar Candle - Large 500 gms',
    'Norway Candle - Large 500 gms',
    'Persia Candle - Large 500 gms',
    'Seychelles Candle - Large 500 gms',
    'Sweden Candle - Large 500 gms',
    'Finland Candle - Large 500 gms',
    'Tulum Candle - Large 500 gms',
    'Finland Candle - Large 500 gms',
  ],
  'Candle - Limited Edition': [
    'Christmas Candle - Limited Edition 180 gms',
    "New Year's Eve Candle - Limited Edition 180 gms",
    'Winterland Candle - Limited Edition 180 gms',
    'Wooden Cabin Candle - Limited Edition 180 gms',
    'Strasbourg Candle - Limited Edition 500 gms',
    'Zurich Candle - Limited Edition 500 gms',
    'Rose Candle - Limited Edition 180 gms',
    'Citamaass Candle - Limited Edition 180 gms',
  ],
  'Candle - Massage': [
    'Ashikaga Candle - Massage 140 gms',
    'Kerala Candle - Massage 140 gms',
  ],
  'Candle - Mini': [
    'Annapurna Candle - Mini 70 gms',
    'Ava Candle - Mini 70 gms',
    'Bahamas Candle - Mini 70 gms',
    'Bali Candle - Mini 70 gms',
    'Barcelona Candle - Mini 70 gms',
    'Dambulla Candle - Mini 70 gms',
    'Havana Candle - Mini 70 gms',
    'Honolulu Candle - Mini 70 gms',
    'Honolulu Candle - Mini 70 gms - Purelei Special Edition',
    'Kyoto Candle - Mini 70 gms',
    'Lisbon Candle - Mini 70 gms',
    'Madagascar Candle - Mini 70 gms',
    'Marrakesh Candle - Mini 70 gms',
    'May Candle - Mini 70 gms',
    'Nara Candle - Mini 70 gms',
    'Norway Candle - Mini 70 gms',
    'Paris Candle - Mini 70 gms',
    'Persia Candle - Mini 70 gms',
    'Provence Candle - Mini 70 gms',
    'Santorini Candle - Mini 70 gms',
    'Seychelles Candle - Mini 70 gms',
    'Sicily Candle - Mini 70 gms',
    'Sweden Candle - Mini 70 gms',
    'Ibiza Mini Candle',
    'Santa Monica Mini Candle',
    'Finland Candle - Mini 70 gms',
    'Tulum Candle - Mini 70 gms',
    'Finland Candle - Mini 70 gms',
    'Rio de Janeiro Candle - Mini 70 gms',
  ],
  'Candle - Standard': [
    'Annapurna Candle - Standard 180 gms',
    'Ava Candle - Standard 180 gms',
    'Ava Candle - Standard 180 gms',
    'Bahamas Candle - Standard 180 gms',
    'Bali Candle - Standard 180 gms',
    'Dambulla Candle - Standard 180 gms',
    'Flora Candle - Standard 180 gms',
    'Honolulu Candle - Standard 180 gms',
    'Kyoto Candle - Standard 180 gms',
    'London Candle - Standard 220 gms',
    'Marrakesh Candle - Standard 180 gms',
    'May Candle - Standard 180 gms',
    'May Candle - Standard 180 gms',
    'Nara Candle - Standard 180 gms',
    'New York City Candle - Standard 220 gms',
    'Nürnberg Candle - Standard 180 gms',
    'Paris Candle - Standard 180 gms',
    'Persia Candle - Standard 180 gms',
    'Provence Candle - Standard 180 gms',
    'Santorini Candle - Standard 180 gms',
    'Sicily Candle - Standard 180 gms',
    'Sydney Candle - Standard 220 gms',
    'Dresden Candle - Standard 180 gms',
    'Rio de Janeiro Candle - Standard 180 gms',
  ],
  Candles: [
    'Bahamas Candle ',
    'Himalya Candle',
    'Kyoto Candle',
    'Marrakech Candle',
    'Paris Candle',
    'Persia Candle',
    'Santorini Candle',
    'Sicily Candle ',
    'Sri Lanka Candle',
  ],
  'Collection Set': [
    'Flora Bath & Body Collection Set',
    'Flora Home Fragrances Collection Set',
  ],
  Collier: [
    'Your Journey Collier',
    'Your Mantra Collier',
    'Your Origin Collier',
  ],
  Creme: ['Persia Face Cream'],
  'Diffuser - mini': [
    'Ibiza Diffuser - mini 50 ml',
    'Santa Monica Diffuser - mini 50 ml',
  ],
  'Diffuser - Standard': [
    'Bahamas Diffuser - Standard 100 ml',
    'Barcelona Diffuser - Standard 100 ml',
    'Flora Diffuser - Standard 100 ml',
    'Ibiza Diffuser - Standard 100 ml',
    'Lisbon Diffuser - Standard 100 ml',
    'Madagascar Diffuser - Standard 100 ml',
    'Marrakesh Diffuser - Standard 100 ml',
    'Nara Diffuser - Standard 100 ml',
    'Norway Diffuser - Standard 100 ml',
    'Paris Diffuser - Standard 100 ml',
    'Persia Diffuser - Standard 100 ml',
    'Santa Monica Diffuser - Standard 100 ml',
    'Santorini Diffuser - Standard 100 ml',
    'Sweden Diffuser - Standard 100 ml',
  ],
  'Fragrance disc': ['Take your memories everywhere Fragrance disc 2 pcs'],
  'Faynt Fragrance': [
    'Beach Bliss 15ml',
    'Liquid Legend 15ml',
    'Sunburst 15ml',
    'RealiTea 10ml',
    'Dolce Vita 10ml',
    'Pink Cheeks 10ml',
  ],
  'Gift Bag': ['Gift Bag'],
  'Gift Box': [
    'Premium Gift Box 2 Candles',
    'Premium Gift Box 3 Candles',
    'Premium Gift Box Soap, Diffuser, Candle',
    'Spring Summer Seasonal Gift box SS20 + inlay  - soap + handwash lotion',
    'Spring Summer Seasonal Gift box SS20 + inlay - 3 candles',
    'Spring Summer Seasonal Gift box SS20 + inlay - candle + diffuser + room spray',
  ],
  'Gift Set': [
    'Spicebomb Gift Set 3 Candles',
    'Flowers Bouquet Gift Set 3 Candles',
    'Natural Spirit Gift Set 3 Candles',
    'Sweet Loving Gift Set 3 Candles',
    'Marrakesh Gift Set Candle, Diffuser, Room Spray',
    'Santorini Gift Set Candle, Diffuser, Room Spray',
    'Persia Gift Set Candle, Diffuser, Room Spray',
    'Bahamas Gift Set Candle, Diffuser, Room Spray',
    'Santorini Gift Set Soap, Handwash Lotion',
    'Bahamas Gift Set Soap, Handwash Lotion',
    'Marrakesh Gift Set Soap, Handwash Lotion',
    'Persia Gift Set Soap, Handwash Lotion',
    'Nara Gift Set Soap, Handwash Lotion',
    'Bahamas-Persia Gift Set Handwash Lotions (x2)',
  ],
  Halskette: [
    'Your Journey Halskette',
    'Your Mantra Halskette',
    'Your Origin Halskette',
  ],
  'Hand Wash Lotion': [
    'Bahamas Hand Wash Lotion 250 ml',
    'Flora Hand Wash Lotion 250 ml',
    'Marrakesh Hand Wash Lotion 250 ml',
    'Nara Hand Wash Lotion 250 ml',
    'Persia Hand Wash Lotion 250 ml',
    'Santorini Hand Wash Lotion 250 ml',
  ],
  Jewelry: [
    'Bar Necklace DE',
    'Bar Necklace FR',
    'Circle Necklace DE',
    'Circle Necklace FR',
    'Hand Pendant',
    'Heart Pendant',
    'Love Pendant',
    'Pearl Necklace DE',
    'Pearl Necklace FR',
  ],
  'Jewlery Candle': ['Jewlery Candle'],
  'Liquid Soap': [
    'Bahamas Liquid Soap 250 ml',
    'Flora Liquid Soap 250 ml',
    'Marrakesh Liquid Soap 250 ml',
    'Nara Liquid Soap 250 ml',
    'Persia Liquid Soap 250 ml',
    'Santorini Liquid Soap 250 ml',
  ],
  Matches: ['Light up your Memories Matches 33 pcs'],
  'Room Spray': [
    'Amalfi Coast Room Spray 100 ml',
    'Angkor Wat Room Spray 100 ml',
    'Bahamas Room Spray 100 ml',
    'Flora Room Spray 100 ml',
    'Marrakesh Room Spray 100 ml',
    'Persia Room Spray 100 ml',
    'Santorini Room Spray 100 ml',
  ],
  'Shower gel': ['Bahamas Shower gel 200 ml'],
  Snuffer: ['Snooze Your Memories Snuffer'],
  Toner: ['Persia Toner'],
  Trimmer: ['Treat Your Memories Trimmer'],
  'Wardrobe Sachet': [
    'Fiji Wardrobe Sachet 75 gms',
    'Provence Wardrobe Sachet 75 gms',
  ],
};

const FAYNT_PRODUCTS = {
  'Faynt Fragrance': [
    'Beach Bliss 15ml',
    'Liquid Legend 15ml',
    'Sunburst 15ml',
    'RealiTea 10ml',
    'Dolce Vita 10ml',
    'Pink Cheeks 10ml',
  ],
  'Free Mini': [],
};

const MILLION_FACETS_PRODUCTS = {
  'Face Cream': ['Hydro Orchid'],
  'Face Mask': ['Detox My Skin', 'Tea Treat'],
  'Face Toner': ['Pure Jasmine'],
};

const AVA_MAY_PRODUCT_CATEGORIES = [
  'Accessories',
  'Additional Packaging',
  'Body milk',
  'Candle - Large',
  'Candle - Limited Edition',
  'Candle - Massage',
  'Candle - Mini',
  'Candle - Standard',
  'Candles',
  'Collection Set',
  'Collier',
  'Creme',
  'Diffuser - mini',
  'Diffuser - Standard',
  'Fragrance disc',
  'Faynt Fragrance',
  'Gift Bag',
  'Gift Box',
  'Gift Set',
  'Halskette',
  'Hand Wash Lotion',
  'Jewlery Candle',
  'Liquid Soap',
  'Matches',
  'Room Spray',
  'Shower gel',
  'Snuffer',
  'Toner',
  'Trimmer',
  'Wardrobe Sachet',
];

const FAYNT_PRODUCT_CATEGORIES = ['Faynt Fragrance', 'Free Mini'];

const MILLION_FACETS_PRODUCT_CATEGORIES = [
  'Body Cream',
  'Face Cream',
  'Face Mask',
  'Face Toner',
];

const PRODUCT_OPTIONS = {
  [BrandsEnum.avaAndMay]: AVA_MAY_PRODUCTS,
  [BrandsEnum.faynt]: FAYNT_PRODUCTS,
  [BrandsEnum.millionFacets]: MILLION_FACETS_PRODUCTS,
  [BrandsEnum.ultravit]: [],
  [BrandsEnum.reflekt]: [],
  [BrandsEnum.clubNoe]: [],
};

const PRODUCT_CATEGORIES = {
  [BrandsEnum.avaAndMay]: AVA_MAY_PRODUCT_CATEGORIES,
  [BrandsEnum.faynt]: FAYNT_PRODUCT_CATEGORIES,
  [BrandsEnum.millionFacets]: MILLION_FACETS_PRODUCT_CATEGORIES,
  [BrandsEnum.ultravit]: [],
  [BrandsEnum.reflekt]: [],
  [BrandsEnum.clubNoe]: [],
};

const PRODUCTS = {
  AVA_MAY_PRODUCTS,
  FAYNT_PRODUCTS,
  MILLION_FACETS_PRODUCTS,
  PRODUCT_OPTIONS,
  PRODUCT_CATEGORIES,
};

export default PRODUCTS;
