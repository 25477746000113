import { Stack, Typography } from '@mui/material';
import { PercentText } from 'components';
import React from 'react';

interface Props {
  value: string | number;
  percentage: number | undefined;
  title: string;
}

const MetricBlock = (props: Props): JSX.Element | null => {
  const { percentage, value, title } = props;

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="body2" noWrap>
        {value}
        <PercentText percentage={percentage} />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Stack>
  );
};

export default MetricBlock;
