import { useCallback } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import { IconButton } from '@mui/material';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { timeline, TimelineTypes } from 'api';
import { useApiRequest } from 'hooks';
import Loader from '../Loader';

interface Props {
  id: string;
  commentId: string;
  type: TimelineTypes.ModelEnum;
  refetch: () => Promise<QueryObserverResult>;
}

const DeleteButton = (props: Props): JSX.Element | null => {
  const { type, id, refetch, commentId } = props;

  const { requestFn, isLoading } = useApiRequest(() =>
    timeline.remove(type, id, commentId),
  );

  const handleDelete = useCallback(async () => {
    await requestFn({ successMessage: 'Log deleted' });
    refetch();
  }, [refetch, requestFn]);

  return (
    <IconButton onClick={handleDelete} disabled={isLoading}>
      {isLoading ? <Loader /> : <DeleteOutlineRounded />}
    </IconButton>
  );
};

export default DeleteButton;
