import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import React, { ChangeEvent, useCallback } from 'react';
import { Grid } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DealTypes, DiscountTypes } from 'api';
import DEALS from 'constants/deals';
import { arrayToOptions } from 'helpers/common';
import { FormCard, FormInput } from 'components';

interface Props {
  control: Control<DiscountTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DiscountTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DiscountTypes.ItemCreateParams>;
}

const OtherBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const startDate = watch('start_date');
  const endDate = watch('end_date');
  const strategy = watch('strategy');

  const handleStrategyChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const value = event.target.value as DealTypes.StrategiesEnum;
      const channelOptions = DEALS.CHANNEL_OPTIONS[value];

      setValue('channel', channelOptions[0]);

      onChange(value);
    },
    [setValue],
  );

  return (
    <FormCard title="Additional information" avatar={<InfoOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="strategy"
            inputType="select"
            label="Channel"
            options={DEALS.STRATEGY_OPTIONS}
            onChange={handleStrategyChange}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="channel"
            inputType="select"
            label="Sub channel"
            options={
              strategy
                ? arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])
                : DEALS.SUB_CHANNEL_OPTIONS
            }
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datetimepicker"
            name="start_date"
            label="Start Date"
            rules={{
              required: true,
              onChange: (event) => {
                const date = dayjs.utc(event.target.value);

                if (date.isAfter(endDate)) {
                  const start = date.format('DD/MM/YYYY');
                  const end = dayjs(endDate).format('DD/MM/YYYY');
                  if (!dayjs(start).isSame(end)) {
                    setValue('end_date', null);
                  }
                }
              },
            }}
            onClose={(value) => {
              const time = dayjs.utc(value).startOf('day').toISOString();
              setValue('start_date', time);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datetimepicker"
            name="end_date"
            label="End Date"
            minDate={dayjs.utc(startDate)}
            onClose={(value) => {
              const time = dayjs.utc(value).endOf('day').toISOString();
              setValue('end_date', time);
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default OtherBlock;
