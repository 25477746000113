import { createTheme, PaletteMode } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { colors, darkModeColors } from './constants';
import typography from './typography';
import components from './components';

const createMuiTheme = (mode: PaletteMode) =>
  createTheme({
    shape: {
      borderRadius: 14,
    },
    palette: {
      mode,
      primary:
        mode === 'dark'
          ? {
              main: darkModeColors.primary,
            }
          : { main: colors.accent, light: colors.accentLight },
      secondary: { main: colors.purple },
      background:
        mode === 'dark'
          ? // ? { default: darkModeColors.paper, paper: darkModeColors.background }
            // : // { default: darkModeColors.background, paper: darkModeColors.paper }
            { default: darkModeColors.background, paper: darkModeColors.paper }
          : { default: colors.grey4, paper: colors.white },
      error: { main: colors.red },
      warning: { main: colors.orange },
      success: { main: colors.green },
      info: { main: colors.blue },
      divider: mode === 'dark' ? darkModeColors.divider : colors.grey3,
      text:
        mode === 'dark'
          ? {}
          : {
              primary: colors.dark2,
              secondary: colors.grey1,
              disabled: colors.grey1,
            },
      // grey: {
      //   '100': colors.grey4,
      // },
    },
    // shadows,
    typography,
    components: components(mode),
  });

export default createMuiTheme;
