import React, { FormEventHandler, ReactNode } from 'react';
import { ContainerProps } from '@mui/material';
import PageFormContainer from './PageFormContainer';
import DrawerFormContainer from './DrawerFormContainer';

interface Props {
  variant?: 'drawer' | 'page';
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  disabled: boolean;
  children: ReactNode;
  ContainerProps?: ContainerProps;
  drawerTitle?: string;
  pageLink?: string;
}

const FormContainer = (props: Props): JSX.Element | null => {
  const { variant, children, drawerTitle, pageLink, ...rest } = props;

  if (variant === 'drawer') {
    return (
      <DrawerFormContainer title={drawerTitle} pageLink={pageLink} {...rest}>
        {children}
      </DrawerFormContainer>
    );
  }

  return <PageFormContainer {...rest}>{children}</PageFormContainer>;
};

export default FormContainer;
