import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { FormContainer } from 'components';
import { deals, DealTypes } from 'api';
import { getDiffFields, getFieldsWithValues } from 'helpers/getFormFields';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { AutocompleteSearchItem } from 'api/types/common';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import PlatformBlock from './components/PlatformBlock';
import GeneralBlock from './components/GeneralBlock';
import DiscountBlock from './components/DiscountBlock';
import ProductsBlock from './components/ProductsBlock';
import BudgetBlock from './components/BudgetBlock';
import SuggestedBudgetInfo from './components/BudgetSuggestion';
import InfluencerInsightsBlock from './components/InfluencerInsightsBlock';

interface Props {
  id: string | undefined;
  initialData: DealTypes.ItemCreateParams;
  initialInfluencer?: AutocompleteSearchItem;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, initialInfluencer, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    setValue,
    setError,
  } = useForm<DealTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const { requestFn } = useApiRequest(
    id ? (data) => deals.update(id, data) : deals.create,
  );

  const onSubmit = useCallback(
    async (data: DealTypes.ItemCreateParams) => {
      // todo - optional use getDiffFields in other pages
      const values = id
        ? getDiffFields(initialData, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `Deal ${id ? 'updated' : 'created'}`,
      });

      if (res) {
        if (drawer) {
          updateFiltersSearchParams({
            dealId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.deals.details.as(res.deal_id), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      id,
      initialData,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.deals.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
    >
      <Stack spacing={2}>
        <GeneralBlock
          control={control}
          setValue={setValue}
          initialInfluencer={initialInfluencer}
        />
        <PlatformBlock control={control} watch={watch} setValue={setValue} />
        <SuggestedBudgetInfo id={id} watch={watch} setValue={setValue} />
        <BudgetBlock control={control} watch={watch} setValue={setValue} />
        <InfluencerInsightsBlock control={control} />
        <ProductsBlock control={control} watch={watch} setValue={setValue} />
        <DiscountBlock
          id={id}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
        />
      </Stack>
    </FormContainer>
  );
};

export default Form;
