import { useQuery } from '@tanstack/react-query';
import { deals, DealTypes } from '../../../../../api';
import LogsTableLoader from './LogsTableLoader';
import LogsTable from './LogsTable';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const LogbookTab = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const { data } = useQuery(
    [deals.endpoints.getDealLogbook(deal._id.$oid)],
    () => deals.getDealLogbook(deal._id.$oid),
  );

  if (!data) return <LogsTableLoader />;

  return <LogsTable items={data} />;
};

export default LogbookTab;
