import React, { ChangeEvent } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { HandleChangeValue } from 'hooks/useModel';
import DataTableProps from '../DataTableProps';

type Value = DataTableProps.RangeFilterValue;

interface Props {
  label: string;
  value: Value | undefined;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
}

const RangeFilter = (props: Props): JSX.Element | null => {
  const { onChange, value, label, inline } = props;

  const handleChange = (
    key: keyof Value,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange((prev) => ({
      ...prev,
      [key]: event.target.value,
    }));
  };

  const handleBlur = (
    key: keyof Value,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange((prev) => ({
      ...prev,
      [key]: +(+event.target.value).toFixed(2),
    }));
  };

  return (
    <Box p={inline ? 2 : undefined}>
      <Typography paragraph>{label}</Typography>
      <Stack direction="row" spacing={1}>
        <TextField
          type="number"
          label="Min"
          placeholder="Enter min value"
          value={value?.$gte || ''}
          onChange={(event) => {
            handleChange('$gte', event);
          }}
          onBlur={(event) => {
            handleBlur('$gte', event);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            notched: false,
            inputProps: {
              max: value?.$lte,
            },
          }}
        />
        <TextField
          type="number"
          label="Max"
          placeholder="Enter max value"
          value={value?.$lte || ''}
          onChange={(event) => {
            handleChange('$lte', event);
          }}
          onBlur={(event) => {
            handleBlur('$lte', event);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            notched: false,
            inputProps: {
              min: value?.$gte,
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default RangeFilter;
