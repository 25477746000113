import React, { ReactNode, useState } from 'react';
import { Button, ButtonProps, IconButton, Tooltip } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import Loader from '../Loader';
import LoadingButton from '../LoadingButton';
import ConfirmDialogControlled from './ConfirmDialogControlled';

interface Props {
  name: string;
  onSubmit: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  title?: string;
  message?: string;
  icon?: ReactNode;
  submitButtonText?: string;
  tooltip?: string;
  disabled?: boolean;
  disabledSubmitButton?: boolean;
  buttonType?: 'iconButton' | 'button' | 'buttonGroup';
  buttonColor?: ButtonProps['color'];
  children?: ReactNode;
}

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const {
    onSubmit,
    onClose,
    name,
    isLoading = false,
    disabled,
    children,
    title = 'Are you sure?',
    icon = <DeleteOutlined />,
    message = `Are You sure You want to delete ${name}?`,
    submitButtonText = 'Delete',
    buttonType = 'button',
    buttonColor = 'error',
    disabledSubmitButton,
    tooltip,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = () => {
    onSubmit();
    setIsOpen(false);
  };

  return (
    <>
      {buttonType === 'buttonGroup' && (
        <Tooltip title={tooltip || submitButtonText}>
          <Button onClick={handleOpenDialog}>
            {isLoading ? (
              <Loader
                variant="inline"
                circularProgressProps={{ size: 24, color: 'primary' }}
              />
            ) : (
              icon
            )}
          </Button>
        </Tooltip>
      )}
      {buttonType === 'iconButton' && (
        <Tooltip title={submitButtonText}>
          <IconButton
            onClick={handleOpenDialog}
            disabled={disabled || isLoading}
          >
            {isLoading ? <Loader /> : icon}
          </IconButton>
        </Tooltip>
      )}
      {buttonType === 'button' && (
        <LoadingButton
          fullWidth
          color={buttonColor}
          onClick={handleOpenDialog}
          disabled={disabled || isLoading}
          loading={isLoading}
          endIcon={icon}
        >
          {submitButtonText}
        </LoadingButton>
      )}
      <ConfirmDialogControlled
        name={name}
        isOpen={isOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        title={title}
        message={message}
        submitButtonText={submitButtonText}
        LoadingButtonProps={{
          disabled: disabledSubmitButton,
          color: buttonColor,
        }}
      >
        {children}
      </ConfirmDialogControlled>
    </>
  );
};

export default ConfirmDialog;
