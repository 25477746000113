import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { DealTypes } from 'api';
import { SelectInputControlled } from 'components';
import DEALS from 'constants/deals';
import { arrayToOptions } from 'helpers/common';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';

interface Props {
  type: DealTypes.TypeEnum | undefined;
  setType: (value: DealTypes.TypeEnum | undefined) => void;
  strategy: DealTypes.StrategiesEnum | undefined;
  setStrategy: (value: DealTypes.StrategiesEnum | undefined) => void;
  channel: DealTypes.ChannelEnum | undefined;
  setChannel: (value: DealTypes.ChannelEnum | undefined) => void;
  brands: BrandsEnum[];
  setBrands: (value: BrandsEnum[]) => void;
  countries: CountriesEnum[];
  setCountries: (value: CountriesEnum[]) => void;
}

const PerformanceFilters = (props: Props): JSX.Element | null => {
  const {
    setChannel,
    channel,
    setType,
    type,
    strategy,
    setStrategy,
    setCountries,
    countries,
    brands,
    setBrands,
  } = props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4} md={12 / 5}>
            <SelectInputControlled
              name="brands"
              label="Brands"
              options={BRANDS.OPTIONS}
              value={brands}
              onChange={(event) =>
                event.target.value.length > 0
                  ? setBrands(event.target.value as unknown as BrandsEnum[])
                  : false
              }
              SelectProps={{
                multiple: true,
                endAdornment:
                  brands.length === 0 ? undefined : (
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <IconButton size="small" onClick={() => setBrands([])}>
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={12 / 5}>
            <SelectInputControlled
              name="countries"
              label="Countries"
              options={COUNTRIES.OPTIONS}
              value={countries}
              onChange={(event) =>
                event.target.value.length > 0
                  ? setCountries(
                      event.target.value as unknown as CountriesEnum[],
                    )
                  : false
              }
              SelectProps={{
                multiple: true,
                endAdornment:
                  countries.length === 0 ? undefined : (
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <IconButton size="small" onClick={() => setCountries([])}>
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={12 / 5}>
            <SelectInputControlled
              name="type"
              label="Type"
              options={DEALS.TYPE_OPTIONS}
              value={type}
              onChange={(event) =>
                event.target.value.length > 0
                  ? setType(event.target.value as unknown as DealTypes.TypeEnum)
                  : false
              }
              SelectProps={{
                endAdornment: !type ? undefined : (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton size="small" onClick={() => setType(undefined)}>
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={12 / 5}>
            <SelectInputControlled
              name="strategy"
              label="Channel"
              options={DEALS.STRATEGY_OPTIONS}
              value={strategy}
              onChange={(event) => {
                const value = event.target.value as DealTypes.StrategiesEnum;
                const channelOptions = DEALS.CHANNEL_OPTIONS[value];

                setStrategy(value);
                setChannel(channelOptions[0]);
              }}
              SelectProps={{
                endAdornment: !strategy ? undefined : (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setStrategy(undefined);
                        setChannel(undefined);
                      }}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={12 / 5}>
            <SelectInputControlled
              name="channel"
              label="Sub channel"
              options={
                strategy
                  ? arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])
                  : DEALS.SUB_CHANNEL_OPTIONS
              }
              value={channel}
              onChange={(event) => {
                const value = event.target.value as DealTypes.ChannelEnum;

                setChannel(value);
              }}
              SelectProps={{
                endAdornment: !channel ? undefined : (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => setChannel(undefined)}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PerformanceFilters;
