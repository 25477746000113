import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import { alpha, Box, useTheme } from '@mui/material';
import { DealTypes } from 'api';
import { getFormattedDate } from 'helpers/dayJsFormats';

interface Props {
  data: DealTypes.Metric[];
}

const Chart = (props: Props): JSX.Element | null => {
  const { data } = props;
  const theme = useTheme();

  return (
    <Box width="100%" height="400px">
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            left: -16,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={getFormattedDate} />
          <YAxis />
          <Tooltip labelFormatter={(label) => getFormattedDate(label)} />
          <Area
            name="Actual"
            type="linear"
            dot={{
              stroke: theme.palette.primary.main,
              fill: theme.palette.common.white,
              fillOpacity: 1,
              strokeWidth: 2,
              r: 4,
            }}
            dataKey="revenue_actual"
            stackId="1"
            strokeWidth={2}
            stroke={theme.palette.primary.main}
            fill={alpha(theme.palette.primary.main, 0.1)}
          />
          <Area
            name="Potential"
            type="linear"
            dot={{
              stroke: theme.palette.secondary.main,
              fill: theme.palette.common.white,
              fillOpacity: 1,
              strokeWidth: 2,
              r: 4,
            }}
            dataKey="revenue_potential"
            stackId="2"
            strokeWidth={2}
            stroke={theme.palette.secondary.main}
            fill={alpha(theme.palette.secondary.main, 0.1)}
          />
          <Legend iconType="circle" />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Chart;
