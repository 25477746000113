import React, { useMemo, useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { DealTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import DetailsTab from './DetailsTab';
import ActivityLogTab from './ActivityLogTab';
import PerformanceTab from './PerformanceTab';
import ChecklistTab from './ChecklistTab';
import LogbookTab from './LogbookTab';

const TABS = {
  performance: 'Performance',
  details: 'Details',
  checklist: 'Checklist',
  activityLog: 'Activity log',
  logbook: 'Log book',
};

interface Props {
  inline?: boolean;
  data: DealTypes.ItemDataExtended;
  refetch: () => Promise<QueryObserverResult>;
}

const PageTabs = (props: Props): JSX.Element => {
  const { data, inline, refetch } = props;

  const isPerformanceActive = useMemo(
    () =>
      data.status === DealTypes.StatusEnum.Posted ||
      data.status === DealTypes.StatusEnum.Finished,
    [data.status],
  );

  const [activeTab, setActiveTab] = useState(
    isPerformanceActive ? TABS.performance : TABS.details,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        {isPerformanceActive && (
          <Tab label={TABS.performance} value={TABS.performance} />
        )}
        <Tab label={TABS.details} value={TABS.details} />
        {inline && <Tab label={TABS.checklist} value={TABS.checklist} />}
        <Tab label={TABS.activityLog} value={TABS.activityLog} />
        <Tab label={TABS.logbook} value={TABS.logbook} />
      </Tabs>

      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.performance && (
          <PerformanceTab deal={data} inline={inline} />
        )}
        {activeTab === TABS.details && (
          <DetailsTab deal={data} inline={inline} />
        )}
        {activeTab === TABS.activityLog && <ActivityLogTab deal={data} />}
        {activeTab === TABS.checklist && (
          <ChecklistTab deal={data} refetch={refetch} inline />
        )}
        {activeTab === TABS.logbook && <LogbookTab deal={data} />}
      </Stack>
    </>
  );
};

export default PageTabs;
