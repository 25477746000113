import React, { useCallback } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { DealTypes, influencers } from 'api';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { AutocompleteSearchItem } from 'api/types/common';
import { FormCard, FormInput, ShopInput } from 'components';

interface Props {
  control: Control<DealTypes.ItemCreateParams> | undefined;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
  initialInfluencer?: AutocompleteSearchItem;
}

const GeneralBlock = (props: Props): JSX.Element | null => {
  const { control, setValue, initialInfluencer } = props;

  const handleInfluencerChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && !Array.isArray(value) && typeof value === 'object') {
        onChange(value._id.$oid);
      }
    },
    [],
  );

  return (
    <FormCard title="General details" avatar={<PersonOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="influencer_id"
            inputType="autocompleteSearch"
            label="Influencer"
            queryFn={influencers.autocompleteSearch}
            queryKey={influencers.endpoints.autocompleteSearch}
            onChange={handleInfluencerChange}
            initialValue={initialInfluencer}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ShopInput
            control={control}
            onChange={(shop) => {
              setValue('brand', shop.brand);
              setValue('country_team', shop.country_team);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="brand"
            inputType="select"
            label="Brand"
            options={BRANDS.OPTIONS}
            rules={{
              required: true,
              onChange: () => {
                setValue('product_categories', []);
                setValue('products', []);
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="country_team"
            inputType="select"
            label="Country team"
            options={COUNTRIES.OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default GeneralBlock;
