import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20px"
    height="24px"
    viewBox="0 0 20 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-373.000000, -68.000000)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      >
        <g id="Group-2" transform="translate(373.000000, 69.000000)">
          <rect id="Rectangle" x="0.491900854" y="20" width="19" height="2" />
          <rect id="Rectangle" x="1.49190085" y="10" width="16" height="2" />
          <rect id="Rectangle" x="2.49190085" y="4" width="16" height="2" />
          <rect
            id="Rectangle"
            transform="translate(7.000000, 8.043826) rotate(-79.000000) translate(-7.000000, -8.043826) "
            x="-1"
            y="7.04382646"
            width="16"
            height="2"
          />
          <rect
            id="Rectangle"
            transform="translate(13.000000, 8.043826) rotate(-79.000000) translate(-13.000000, -8.043826) "
            x="5"
            y="7.04382646"
            width="16"
            height="2"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default LogoIcon;
