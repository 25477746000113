import { Card, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  value: string | number | undefined;
  variant?: 'rect' | 'square';
}

const StatsBlock = (props: Props): JSX.Element | null => {
  const { title, value, variant = 'rect' } = props;

  return (
    <Card
      sx={(theme) => ({
        p: 2,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.grey['900']
            : theme.palette.grey['100'],
        height: '100%',
      })}
    >
      {variant === 'square' ? (
        <Stack spacing={2}>
          <Typography component="strong" color="text.primary" variant="h5">
            {value}
          </Typography>
          <Typography
            color="text.secondary"
            textTransform="capitalize"
            variant="h6"
            fontWeight={400}
          >
            {title}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography color="text.secondary" variant="h6" fontWeight={400}>
            {title}
          </Typography>
          <Typography component="strong" color="text.primary" variant="h6">
            {value}
          </Typography>
        </Stack>
      )}
    </Card>
  );
};

export default StatsBlock;
