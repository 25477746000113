import React, { useEffect, useMemo, useState } from 'react';
import { users, UserTypes } from 'api';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary, Loader } from 'components';
import Form from './Form';
import { dataToInitialModel, initialModel } from './fields';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { itemId } = props;
  const { id: paramsId = '' } = useParams<Params>();

  const id = useMemo(() => paramsId || itemId || '', [itemId, paramsId]);

  const [initialData, setInitialData] = useState<
    UserTypes.ItemCreateParams | undefined
  >(undefined);

  const { data, error } = useQuery(
    [users.endpoints.getById(id)],
    () => users.getById(id),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!id) {
      setInitialData(initialModel);
    }

    if (id && data) {
      const initialFormData = dataToInitialModel(data);

      setInitialData(initialFormData);
    }
  }, [data, id]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant="centered" />
  ) : (
    <Form
      drawer={!!itemId}
      drawerTitle={`Edit ${data?.full_name || 'user'}`}
      id={id}
      initialData={initialData}
    />
  );
};

export default Index;
