import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListItemButton,
  TextField,
  MenuItem,
  Avatar,
  ListItemAvatar,
} from '@mui/material';
import { HandleChangeValue } from 'hooks/useModel';
import { MenuOptions } from 'types/common';

type Value = string | undefined;

interface Props {
  label: string;
  value: Value;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
  options: MenuOptions;
}

const InlineFilter = (props: Omit<Props, 'label' | 'inline'>) => {
  const { onChange, value, options } = props;

  const handleChange = (optionValue: string) => {
    onChange(optionValue);
  };

  return (
    <List>
      {options.map((option) => (
        <ListItem key={option.value} disableGutters disablePadding>
          <ListItemButton
            selected={value === option.value}
            onClick={() => handleChange(option.value)}
          >
            {option.image && (
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Box
                  component="img"
                  src={option.image}
                  sx={{ width: 'auto', height: 20 }}
                />
              </ListItemIcon>
            )}
            {option.Icon && (
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <Avatar sx={{ width: 30, height: 30 }}>
                  <option.Icon fontSize="small" />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText primary={option.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const SelectFilter = (props: Omit<Props, 'inline'>) => {
  const { onChange, value, label, options } = props;

  const handleChange = (optionValue: string) => {
    onChange(optionValue);
  };

  return (
    <TextField
      select
      label={label}
      placeholder={label}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      SelectProps={{
        displayEmpty: true,
        notched: false,
        renderValue: (value1) => {
          const option = options.find((o) => o.value === value1);

          if (!option) return `Select ${label}`;

          return (
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {option.image && (
                <ListItemIcon sx={{ minWidth: '36px' }}>
                  <Box
                    component="img"
                    src={option.image}
                    sx={{ width: 'auto', height: 20 }}
                  />
                </ListItemIcon>
              )}
              {option.Icon && (
                <ListItemIcon sx={{ minWidth: '36px' }}>
                  <option.Icon sx={{ width: 'auto', height: 20 }} />
                </ListItemIcon>
              )}
              <ListItemText primary={option.label} />
            </Box>
          );
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ display: 'flex' }}
        >
          {option.image && (
            <ListItemIcon>
              <Box
                component="img"
                src={option.image}
                sx={{ width: 'auto', height: 20 }}
              />
            </ListItemIcon>
          )}
          {option.Icon && (
            <ListItemIcon>
              <option.Icon sx={{ width: 'auto', height: 20 }} />
            </ListItemIcon>
          )}
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
};

const SingleFilter = (props: Props): JSX.Element | null => {
  const { inline, ...rest } = props;

  if (inline) {
    return <InlineFilter {...rest} />;
  }

  return <SelectFilter {...rest} />;
};

export default SingleFilter;
