import avaAndMayIcon from 'assets/brands/avaMay/avaAndMayShort.svg';
import fayntIcon from 'assets/brands/faynt/fayntShort.svg';
import clubNoeIcon from 'assets/brands/clubNoe/club noe short.svg';
import reflectIcon from 'assets/brands/reflekt/reflekt short.svg';

import {
  BrandBusinessFocusesEnum,
  BrandRelationsQualityEnum,
  BrandSalesPotentialEnum,
  BrandsEnum,
  BrandStatusEnum,
  BrandsTimeframeEnum,
} from 'api/types/Brands';
import { enumToOptions } from 'helpers/common';
import { colors } from 'theme/constants';

const ACTIVE_LIST = {
  [BrandsEnum.avaAndMay]: {
    name: BrandsEnum.avaAndMay,
    image: avaAndMayIcon,
    disabled: false,
    color: colors.accent,
  },
  [BrandsEnum.faynt]: {
    name: BrandsEnum.faynt,
    image: fayntIcon,
    disabled: false,
    color: colors.purple,
  },
  [BrandsEnum.clubNoe]: {
    name: BrandsEnum.clubNoe,
    image: clubNoeIcon,
    disabled: false,
    color: colors.blue,
  },
};

const LIST = {
  ...ACTIVE_LIST,
  [BrandsEnum.reflekt]: {
    name: BrandsEnum.reflekt,
    image: reflectIcon,
    disabled: true,
    color: colors.yellow,
  },
  [BrandsEnum.ultravit]: {
    name: BrandsEnum.ultravit,
    image: '',
    disabled: true,
    color: colors.orange,
  },
  [BrandsEnum.millionFacets]: {
    name: BrandsEnum.millionFacets,
    image: '',
    disabled: true,
    color: colors.pink1,
  },
};

const OPTIONS = Object.entries(ACTIVE_LIST).map(([key, value]) => ({
  value: key as BrandsEnum,
  label: value.name,
  image: value.image,
  disabled: value.disabled,
}));

const STATUS_OPTIONS = enumToOptions(BrandStatusEnum);

const RELATIONSHIP_QUALITY_OPTIONS = enumToOptions(BrandRelationsQualityEnum);

const SALES_POTENTIAL_OPTIONS = enumToOptions(BrandSalesPotentialEnum);

const TIMEFRAME_OPTIONS = enumToOptions(BrandsTimeframeEnum);

const BUSINESS_FOCUSES_OPTIONS = enumToOptions(BrandBusinessFocusesEnum);

const BRANDS = {
  RELATIONSHIP_QUALITY_OPTIONS,
  SALES_POTENTIAL_OPTIONS,
  TIMEFRAME_OPTIONS,
  BUSINESS_FOCUSES_OPTIONS,
  OPTIONS,
  STATUS_OPTIONS,
  ACTIVE_LIST,
  LIST,
};

export default BRANDS;
