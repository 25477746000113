import { lighten } from '@mui/material';

const DRAWER_WIDTH = 240;
const HEADER_HEIGHT = 64;

const colors = {
  lightBlue: '#eaedff',
  accent: '#304FFD',
  accentLight: lighten('#304FFD', 0.9),
  orange: '#FF965D',
  red: '#FD7972',
  yellow: '#FFD240',
  blue: '#22CCE2',
  green: '#49C96D',
  darkGreen: '#20A144',
  darkOrange: '#E56E19',
  darkYellow: '#E6B823',
  darkRed: '#F05C54',
  darkBlue: '#009EB3',
  purple: '#B656EB',
  pink1: '#F9DDC5',
  pink2: '#FD72DE',
  green2: '#AEE553',
  white: '#FFFFFF',
  dark1: '#1E1F2D',
  dark2: '#3F434A', // heading
  grey1: '#8A9099', // body light
  grey2: '#C6C9CC', // grey
  grey3: '#E8E9EB', // border
  grey4: '#F8F8F8', // bg
};

const darkModeColors = {
  // new
  background: '#0d1117',
  paper: '#010409',
  primary: '#2f81f7',
  primaryLight: lighten('#2f81f7', 0.4),
  secondary: '#a371f7',
  textPrimary: '#0d1117',
  textSecondary: '#6e7681',
  textDisabled: 'rgba(255, 255, 255, 0.5)',
  divider: '#606771',
  chipBg: '#484f58',
};

const rocketShadows = {
  // shadow1: '-4px 8px 24px rgba(44, 63, 88, 0.02)',
  shadow0: '0px 1px 4px rgba(44, 63, 88, 0.25)',
  shadow1: '0px 8px 24px rgba(44, 63, 88, 0.1)',
  shadow2: '0px 8px 24px rgba(44, 63, 88, 0.1)',
  shadow3: '0px 20px 50px rgba(44, 63, 88, 0.15)',
  shadow4: '0px 8px 16px rgba(91, 91, 91, 0.2)',
  filterShadow1: 'drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02))', // filter: drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02))
};

const joyShadows = {
  xs: '0 0 #000, 0 1px 2px 0 rgb(187, 187, 187, 12%)',
  sm: '0 0 #000, 0.3px 0.8px 1.1px rgba(187,187,187,  0.11),0.5px 1.3px 1.8px -0.6px rgba(187,187,187,  0.18),1.1px 2.7px 3.8px -1.2px rgba(187,187,187,  0.26)',
  md: '0 0 #000, 0.3px 0.8px 1.1px rgba(187,187,187,  0.12),1.1px 2.8px 3.9px -0.4px rgba(187,187,187,  0.17),2.4px 6.1px 8.6px -0.8px rgba(187,187,187,  0.23),5.3px 13.3px 18.8px -1.2px rgba(187,187,187,  0.29)',
  lg: '0 0 #000, 0.3px 0.8px 1.1px rgba(187,187,187, 0.11),1.8px 4.5px 6.4px -0.2px rgba(187,187,187,  0.13),3.2px 7.9px 11.2px -0.4px rgba(187,187,187,  0.16),4.8px 12px 17px -0.5px rgba(187,187,187,  0.19),7px 17.5px 24.7px -0.7px rgba(187,187,187,  0.21)',
  xl: '0 0 #000, 0.3px 0.8px 1.1px rgba(187,187,187,  0.11),1.8px 4.5px 6.4px -0.2px rgba(187,187,187,  0.13),3.2px 7.9px 11.2px -0.4px rgba(187,187,187,  0.16),4.8px 12px 17px -0.5px rgba(187,187,187,  0.19),7px 17.5px 24.7px -0.7px rgba(187,187,187,  0.21),10.2px 25.5px 36px -0.9px rgba(187,187,187,  0.24),14.8px 36.8px 52.1px -1.1px rgba(187,187,187,  0.27),21px 52.3px 74px -1.2px rgba(187,187,187,  0.29)',
};

export {
  DRAWER_WIDTH,
  colors,
  HEADER_HEIGHT,
  joyShadows,
  rocketShadows,
  darkModeColors,
};
