import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CardContent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { deals, DealTypes } from 'api';
import { colors } from 'theme/constants';
import { BrandsEnum } from 'api/types/Brands';
import { BrandChip, ColorCard } from 'components';
import { dummyArray } from 'helpers/common';
import { formatCurrency, formatPercent } from 'helpers/formatters';

const getBrand = (item: DealTypes.DealProductsItem) => {
  switch (item.brand) {
    case 'am':
      return BrandsEnum.avaAndMay;
    case 'cn':
      return BrandsEnum.clubNoe;
    default:
      return BrandsEnum.faynt;
  }
};

const LOADER_ARRAY = dummyArray(5);

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const ProductsTable = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const { data } = useQuery(
    [deals.endpoints.getDealProducts(deal.deal_id)],
    () => deals.getDealProducts(deal.deal_id),
  );

  const products = useMemo(() => {
    if (data) {
      return data.product_absolute_quantity.sort((a, b) =>
        a.quantity > b.quantity ? -1 : 1,
      );
    }

    return undefined;
  }, [data]);

  return (
    <Stack direction="column" rowGap={2}>
      <Typography variant="h5" flexGrow={1}>
        Deal Products
      </Typography>
      <ColorCard color={colors.accent}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Category</TableCell>
                <TableCell align="right">Brand</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Revenue</TableCell>
                <TableCell align="right">CM3</TableCell>
                <TableCell align="right">CM3%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!products &&
                LOADER_ARRAY.map((v) => (
                  <TableRow
                    key={v}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        variant="circular"
                        height={32}
                        sx={{ borderRadius: 4 }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" height={32} />
                    </TableCell>
                  </TableRow>
                ))}
              {products &&
                products.map((row) => (
                  <TableRow
                    key={row.product_title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.product_title}
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.product_category}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <BrandChip
                        brand={getBrand(row)}
                        ChipProps={{ sx: { width: '100%' } }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        color={
                          row.quantity > 1 ? 'success.main' : 'text.secondary'
                        }
                        variant="inherit"
                      >
                        {row.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {formatCurrency(row.revenue_net)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        color={row.cm3_euro < 0 ? 'error' : 'text.secondary'}
                        variant="inherit"
                      >
                        {formatCurrency(row.cm3_euro)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        color={row.cm3_pct < 0 ? 'error' : 'text.secondary'}
                        variant="inherit"
                      >
                        {formatPercent(row.cm3_pct)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </ColorCard>
    </Stack>
  );
};

export default ProductsTable;
