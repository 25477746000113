import React, { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deals, DealTypes } from 'api';
import { FormInput } from 'components';
import { ChecklistItem } from './index';

interface Props {
  id: string;
  type: 'checklist_content' | 'pre_checklist';
  value: DealTypes.ContentChecklist & DealTypes.PreChecklist;
  options: ChecklistItem<DealTypes.ContentChecklist & DealTypes.PreChecklist>[];
  disabled?: boolean;
}

const Form = memo((props: Props): JSX.Element | null => {
  const { options, id, value, type, disabled } = props;

  const queryClient = useQueryClient();

  const initialValue = useMemo(
    () =>
      options.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.key]: value[currentValue.key] || false,
        }),
        {} as DealTypes.ContentChecklist | DealTypes.PreChecklist,
      ),
    [options, value],
  );

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm<DealTypes.ContentChecklist | DealTypes.PreChecklist>({
    defaultValues: initialValue,
  });

  const mutation = useMutation<any, any, Partial<DealTypes.ItemData>, any>({
    mutationFn: (data) => deals.update(id, data),
    onSuccess: (data) => {
      queryClient.setQueryData([deals.endpoints.getById(id)], data);

      const newInitVal = options.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.key]: data[type][currentValue.key] || false,
        }),
        {} as DealTypes.ContentChecklist | DealTypes.PreChecklist,
      );

      reset(newInitVal);
    },
  });

  const onSubmit = useCallback(
    async (data: DealTypes.ContentChecklist | DealTypes.PreChecklist) => {
      if (isDirty && Object.keys(dirtyFields).length !== 0) {
        mutation.mutate({
          [type]: data,
        });
      }
    },
    [dirtyFields, isDirty, mutation, type],
  );

  return (
    <Stack component="form" onMouseLeave={handleSubmit(onSubmit)}>
      {options.map((o) => (
        <FormInput
          key={o.key}
          inputType="checkbox"
          control={control}
          name={o.key}
          disabled={disabled}
          label={
            <Typography
              variant="inherit"
              sx={{
                textDecoration:
                  getValues(o.key) === true ? 'line-through' : 'unset',
              }}
            >
              {o.label}
            </Typography>
          }
        />
      ))}
    </Stack>
  );
});

export default Form;
