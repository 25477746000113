import { Box, Typography } from '@mui/material';
import { SalesPlanningTypes } from 'api';
import PreviousSalesPlannings from './PreviousSalesPlannings';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const PrevSalesPlannings = (props: Props): JSX.Element | null => {
  const { planning, inline } = props;

  return (
    <Box>
      {!inline && (
        <Typography variant="subtitle2" paragraph>
          Previous sales plannings
        </Typography>
      )}
      <PreviousSalesPlannings planning={planning} inline={inline} />
    </Box>
  );
};

export default PrevSalesPlannings;
