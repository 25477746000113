import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import createMuiTheme from 'theme/createMuiTheme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMemo } from 'react';
import { useStoreContext } from '../store';

interface Props {
  children: JSX.Element;
}

const ThemeConfig = (props: Props): JSX.Element => {
  const { children } = props;

  const {
    state: { appearance },
  } = useStoreContext();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const themeConfig = useMemo(() => {
    if (appearance) {
      return createMuiTheme(appearance);
    }

    return createMuiTheme(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode, appearance]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
