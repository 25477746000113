import React from 'react';
import {
  Box,
  FormLabel,
  Stack,
  TextField,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { HandleChangeValue } from 'hooks/useModel';
import { DATE_OPTIONS } from 'constants/dateOptions';
import { getDateByKey } from 'helpers/dayJsFormats';
import DataTableProps from '../DataTableProps';

type Value = DataTableProps.DateFilterOption;

interface Props {
  label: string;
  value: Value | undefined;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
}

const DateFilter = (props: Props): JSX.Element | null => {
  const { onChange, value, label, inline } = props;

  const handleChange = (key: keyof Value['value'], date: Dayjs | null) => {
    onChange((prev) => {
      if (prev) {
        return {
          ...prev,
          key: 'custom',
          value: {
            ...prev.value,
            [key]: date ? date.format() : date,
          },
        };
      }

      const oppositeKey =
        key === '$lte' ? '$gte' : ('$lte' as keyof Value['value']);

      return {
        key: 'custom',
        label: 'Custom range',
        value: {
          [key]: date ? date.format() : date,
          [oppositeKey]: null,
        } as Value['value'],
      };
    });
  };

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = DATE_OPTIONS.find((o) => o.key === event.target.value);
    if (option) {
      onChange({ ...option, value: getDateByKey(option.key) });
    }
  };

  return (
    <Box p={inline ? 2 : undefined}>
      <Typography paragraph>{label}</Typography>
      <Stack direction="row" spacing={1}>
        <DatePicker
          label="From"
          value={value?.value?.$gte ? dayjs.utc(value?.value?.$gte) : null}
          onChange={(newValue) => {
            const maxDate = value?.value?.$lte
              ? dayjs.utc(value?.value?.$lte)
              : null;

            if (maxDate && maxDate.isBefore(newValue)) {
              handleChange('$lte', null);
            }
            handleChange('$gte', newValue);
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              InputProps: {
                notched: false,
              },
              InputLabelProps: {
                shrink: true,
              },
            },
          }}
        />
        <DatePicker
          label="To"
          minDate={value?.value?.$gte ? dayjs.utc(value?.value?.$gte) : null}
          value={value?.value?.$lte ? dayjs.utc(value?.value?.$lte) : null}
          onChange={(newValue) => {
            handleChange('$lte', newValue);
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              InputProps: {
                notched: false,
              },
              InputLabelProps: {
                shrink: true,
              },
            },
          }}
        />
      </Stack>
      {inline ? (
        <Box py={2} px={1}>
          <FormLabel id="date-picker-group-label">Ranges</FormLabel>
          <RadioGroup
            sx={{ maxHeight: '300px', overflow: 'scroll' }}
            aria-labelledby="date-picker-group-label"
            name="date-picker-group"
            value={value?.key || 'custom'}
            onChange={handleRangeChange}
          >
            {DATE_OPTIONS.map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={<Radio />}
                label={option.label}
              />
            ))}
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Custom range"
              disabled
            />
          </RadioGroup>
        </Box>
      ) : (
        <Box py={2}>
          <TextField
            select
            label={`${label} range`}
            placeholder={`${label} range`}
            value={value?.key || 'custom'}
            onChange={handleRangeChange}
          >
            {DATE_OPTIONS.map((option) => (
              <MenuItem value={option.key} key={option.key}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem value="custom" disabled>
              Custom range
            </MenuItem>
          </TextField>
        </Box>
      )}
    </Box>
  );
};

export default DateFilter;
