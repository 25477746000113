import React, { ReactNode, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import SidePanelDrawer from './SidePanelDrawer';

interface Props {
  children: ReactNode;
  drawer?: ReactNode;
}

const isOpenLs = LocalStorage.get(LocalStorageEnum.IS_DETAILS_SIDE_PANEL_OPEN);

const Details = (props: Props): JSX.Element | null => {
  const { children, drawer } = props;

  const isDefaultOpen = useMemo(() => {
    if (!drawer) return false;
    if (isOpenLs === 'true') return true;
    if (isOpenLs === 'false') return false;

    return true;
  }, [drawer]);

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const handleIsOpenChange = () => {
    setIsOpen((prev) => {
      LocalStorage.set(
        LocalStorageEnum.IS_DETAILS_SIDE_PANEL_OPEN,
        JSON.stringify(!prev),
      );

      return !prev;
    });
  };

  return (
    <Box>
      <Grid container wrap="nowrap">
        <Grid
          item
          sx={{
            flexBasis: isOpen ? 'calc(100% - 350px)' : '100%',
            maxWidth: isOpen ? 'calc(100% - 350px)' : '100%',
            flexGrow: 1,
          }}
        >
          {children}
        </Grid>
        {drawer && (
          <Grid
            item
            sx={{
              flexBasis: isOpen ? '350px' : 0,
              maxWidth: isOpen ? '350px' : 0,
            }}
          >
            <SidePanelDrawer open={isOpen} onClick={handleIsOpenChange}>
              {drawer}
            </SidePanelDrawer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Details;
