import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { NotificationsTypes } from 'api';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/links';
import { QueryObserverResult } from '@tanstack/react-query';
import ToggleReadStatusButton from './ToggleReadStatusButton';

interface Props {
  item: NotificationsTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
}

const NotificationItem = (props: Props) => {
  const { item, refetch } = props;

  const url = useMemo(() => {
    if (item.notificationable_type === 'Deal') {
      return appLinks.deals.details.as(item.short_notificationable_id);
    }

    return '';
  }, [item.notificationable_type, item.short_notificationable_id]);

  return (
    <ListItem
      secondaryAction={<ToggleReadStatusButton item={item} refetch={refetch} />}
      disablePadding
    >
      <ListItemButton component={NavLink} to={url}>
        <ListItemText primary={item.title} secondary={item.description} />
      </ListItemButton>
    </ListItem>
  );
};

export default NotificationItem;
