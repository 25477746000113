import React, { useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { DiscountTypes } from 'api';
import { getFormattedDate, getFormattedDateTime } from 'helpers/dayJsFormats';
import { colors } from 'theme/constants';
import { InfoCard, InfoCardItem, ShopInfo } from 'components';
import OptionsInfo, { OptionsInfoItem } from './OptionsInfo';

interface Props {
  inline?: boolean;
  discount: DiscountTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { discount, inline } = props;

  const generalInfo: InfoCardItem<DiscountTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'shop_id',
        label: 'Shop',
        value: <ShopInfo variant="text" shopId={discount.shop_id.$oid} />,
      },
      {
        key: 'coupon_code',
        label: 'Coupon code',
        value: discount.coupon_code,
      },
      {
        key: 'coupon_description',
        label: 'Coupon description',
        value: discount.coupon_description,
      },
      {
        key: 'discount_amount_type',
        label: 'Discount type',
        value: discount.discount_amount_type,
      },
      {
        key: 'discount_type',
        label: 'Discount value',
        value: discount.discount_type,
      },
      {
        key: 'discount_details',
        label: 'Discount details',
        value: discount.discount_details,
      },
      {
        key: 'discount_details',
        label: 'Value',
        value: discount.value,
      },
    ],
    [
      discount.coupon_code,
      discount.coupon_description,
      discount.discount_amount_type,
      discount.discount_details,
      discount.discount_type,
      discount.shop_id.$oid,
      discount.value,
    ],
  );

  const rulesInfo: InfoCardItem<DiscountTypes.ItemData>[] = useMemo(() => {
    const options: InfoCardItem<DiscountTypes.ItemData>[] = [
      {
        key: 'applies_once_per_order',
        label: 'Once per order',
        value: String(discount.applies_once_per_order),
      },
      {
        key: 'applies_once_per_customer',
        label: 'Once per customer',
        value: String(discount.applies_once_per_customer),
      },
      {
        key: 'usage_limit',
        label: 'Usage limit',
        value: discount.usage_limit,
      },
      {
        key: 'minimum_requirements',
        label: 'Minimum requirements',
        value: discount.minimum_requirements,
      },
      {
        key: 'applies_to',
        label: 'Applies to',
        value: discount.applies_to,
      },
    ];

    const minValueLabel =
      discount.minimum_requirements ===
      DiscountTypes.MinimumRequirementsEnum.MinimumPurchaseAmount
        ? 'purchase amount'
        : discount.minimum_requirements ===
            DiscountTypes.MinimumRequirementsEnum.MinimumQuantity && 'quantity';

    if (minValueLabel) {
      const minValue = {
        key: 'minimum_value',
        label: `Minimum ${minValueLabel}`,
        value: String(discount.minimum_value),
      };

      options.splice(4, 0, minValue);
    }

    return options;
  }, [
    discount.applies_once_per_customer,
    discount.applies_once_per_order,
    discount.applies_to,
    discount.minimum_requirements,
    discount.minimum_value,
    discount.usage_limit,
  ]);

  const otherInfo: InfoCardItem<DiscountTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'strategy',
        label: 'Channel',
        value: discount.strategy,
      },
      {
        key: 'channel',
        label: 'Sub channel',
        value: discount.channel,
      },
      {
        key: 'start_date',
        label: 'Start date',
        value: getFormattedDateTime(discount.start_date),
      },
      {
        key: 'end_date',
        label: 'End date',
        value: discount.end_date ? getFormattedDateTime(discount.end_date) : '',
      },
      {
        key: 'created_at',
        label: 'Created at',
        value: getFormattedDate(discount.created_at),
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        value: getFormattedDate(discount.updated_at),
      },
    ],
    [
      discount.channel,
      discount.created_at,
      discount.end_date,
      discount.start_date,
      discount.strategy,
      discount.updated_at,
    ],
  );

  const appliesTo: OptionsInfoItem[] = useMemo(() => {
    if (
      discount.applies_to === DiscountTypes.AppliesToEnum.SpecificCollections
    ) {
      return [
        {
          key: 'collections',
          label: 'Collections',
          color: 'success',
          value: discount.collections.map((p) => p.title),
        },
      ];
    }

    return [
      {
        key: 'products',
        label: 'Products',
        color: 'warning',
        value: discount.products.map((p) => p.title),
      },
    ];
  }, [discount.applies_to, discount.collections, discount.products]);

  const customerEligibility: OptionsInfoItem[] = useMemo(() => {
    if (
      discount.customer_eligibility ===
      DiscountTypes.CustomerEligibilityEnum.SpecificCustomerSegments
    ) {
      return [
        {
          key: 'customer_segments',
          label: 'Customer segments',
          color: 'success',
          value: discount.customer_segments.map((p) => p.name),
        },
      ];
    }
    if (
      discount.customer_eligibility ===
      DiscountTypes.CustomerEligibilityEnum.SpecificCustomers
    ) {
      return [
        {
          key: 'customers_individual',
          label: 'Customers',
          color: 'warning',
          value: discount.customers_individual.map((p) => p.name),
        },
      ];
    }

    return [];
  }, [
    discount.customer_eligibility,
    discount.customer_segments,
    discount.customers_individual,
  ]);

  const freeProducts: OptionsInfoItem[] = useMemo(
    () => [
      {
        key: 'free_gift_product_categories',
        label: 'Free product categories',
        color: 'primary',
        value: discount.free_gift_product_categories,
      },
      {
        key: 'free_gift_products',
        label: 'Free products',
        color: 'secondary',
        value: discount.free_gift_products,
      },
    ],
    [discount],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard
            title="General info"
            data={generalInfo}
            color={colors.darkOrange}
          >
            <OptionsInfo options={freeProducts} />
          </InfoCard>
          <InfoCard
            title="Other info"
            data={otherInfo}
            color={colors.darkYellow}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard
            title="Rules info"
            data={rulesInfo}
            color={colors.darkGreen}
          >
            <OptionsInfo options={appliesTo} />
          </InfoCard>
          <InfoCard
            title="Customer eligibility"
            subheader={discount.customer_eligibility}
            color={colors.darkBlue}
          >
            <OptionsInfo options={customerEligibility} />
          </InfoCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
