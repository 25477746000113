import { Skeleton, Stack } from '@mui/material';
import { dummyArray } from 'helpers/common';

const PerformanceLoader = (): JSX.Element | null => (
  <Stack rowGap={3}>
    <Stack columnGap={2} direction="row" justifyContent="center">
      {dummyArray(5).map((i) => (
        <Stack rowGap={1} key={i} width="100%">
          <Skeleton
            variant="rectangular"
            width="25%"
            height={20}
            sx={(theme) => ({
              borderRadius: `${theme.shape.borderRadius}px`,
            })}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={(theme) => ({
              borderRadius: `${theme.shape.borderRadius}px`,
            })}
          />
        </Stack>
      ))}
    </Stack>
    <Skeleton
      variant="rectangular"
      height={100}
      sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
    />
    <Skeleton
      variant="rectangular"
      height={100}
      sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
    />
    <Skeleton
      variant="rectangular"
      height={300}
      sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
    />
  </Stack>
);

export default PerformanceLoader;
