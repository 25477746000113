import { useMemo } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { InfluencerTypes } from 'api';
import { useStoreContext } from 'store';
import PostCard from './PostCard';

interface Props {
  inline?: boolean;
  data: InfluencerTypes.ItemData;
}

const PostsTab = (props: Props): JSX.Element | null => {
  const { data, inline } = props;
  const {
    state: { activeSocialPlatform },
  } = useStoreContext();

  const posts = useMemo(() => {
    const activePlatform =
      activeSocialPlatform || InfluencerTypes.PlatformEnum.Instagram;
    const platform =
      activePlatform.toLowerCase() as InfluencerTypes.PlatformType;
    const postKey: InfluencerTypes.PlatformPostType = `${platform}_post`;
    const postsSN = data[postKey];

    if (postsSN && 'posts' in postsSN) {
      return postsSN.posts || [];
    }

    return [];
  }, [activeSocialPlatform, data]);

  return (
    <Box>
      {!inline && (
        <Typography variant="h6" paragraph>
          Posts
        </Typography>
      )}
      <Box>
        {posts.length === 0 ? (
          <Typography paragraph>No posts available</Typography>
        ) : (
          <Grid container spacing={1}>
            {posts.map((post) => (
              <Grid item xs={6} key={post._id?.$oid}>
                <PostCard
                  influencerData={data}
                  data={post}
                  size={inline ? 'large' : 'small'}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PostsTab;
