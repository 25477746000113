import React from 'react';
import { Stack } from '@mui/material';
import { TooltipChip, BrandChip, CountryChip, ShopInfo } from 'components';
import { SalesPlanningTypes } from 'api';
import {
  getSalesPlanningStatusColor,
  getSalesPlanningTypeColor,
} from 'helpers/getStatusColor';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';

interface Props {
  planning: SalesPlanningTypes.ItemData;
}

const InfoRow = (props: Props): JSX.Element => {
  const { planning } = props;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      <ShopInfo shopId={planning.shop_id.$oid} />
      {planning.brands.map((brand) => (
        <BrandChip key={brand} brand={brand} />
      ))}
      <CountryChip country={planning.country_team} />
      <TooltipChip
        title="Status"
        label={planning.status}
        color={getSalesPlanningStatusColor(planning.status)}
      />
      <TooltipChip
        title="Type"
        label={planning.type}
        color={getSalesPlanningTypeColor(planning.type)}
      />
      <TooltipChip
        title="Start date"
        label={`Start ${getFormattedDateFromNow(planning.start_date)}`}
        color="success"
      />
      {planning.end_date && (
        <TooltipChip
          title="End date"
          label={`End ${getFormattedDateFromNow(planning.end_date)}`}
          color="error"
        />
      )}
    </Stack>
  );
};

export default InfoRow;
