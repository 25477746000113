import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStoreContext } from 'store';
import { setUserData } from 'store/actions';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import { useApiRequest } from 'hooks';
import { users } from 'api';

interface Props {
  children: JSX.Element;
}

const UserDataWrapper = (props: Props): JSX.Element | null => {
  const { children } = props;
  const { dispatch } = useStoreContext();
  const { requestFn } = useApiRequest(users.getItem);
  const { enqueueSnackbar } = useSnackbar();
  const $isLoading = useRef(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleCheckUser = useCallback(async () => {
    const token = LocalStorage.get(LocalStorageEnum.TOKEN);
    const email = LocalStorage.get(LocalStorageEnum.USER_EMAIL);

    if (token && email) {
      try {
        const user = await requestFn({
          args: { user: { email } },
        });

        enqueueSnackbar({
          variant: 'info',
          message: 'Previous session restored',
        });

        dispatch(setUserData(user));
      } catch (e) {
        LocalStorage.remove(LocalStorageEnum.USER_EMAIL);
        LocalStorage.remove(LocalStorageEnum.TOKEN);
      }
    }
  }, [dispatch, enqueueSnackbar, requestFn]);

  useEffect(() => {
    if (!$isLoading.current) {
      $isLoading.current = true;
      handleCheckUser().then(() => setIsLoading(false));
    }
  }, [handleCheckUser]);

  if (isLoading) {
    return null;
  }

  return children;
};

export default UserDataWrapper;
