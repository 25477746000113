import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useStoreContext } from 'store';
import { toggleIsSideNavOpen } from 'store/actions';
import { menuLinks } from 'routes/links';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import NavLinks from './NavLinks';
import Logo from '../../Logo';
import { DrawerHeader, Drawer } from './styled';
import { activeLinkItemCollapsedMixin, activeLinkItemMixin } from './mixins';

const SideNav = (): JSX.Element | null => {
  const {
    state: { isSideNavOpen },
    dispatch,
  } = useStoreContext();

  const toggleSideNav = useCallback(() => {
    dispatch(toggleIsSideNavOpen());
    LocalStorage.set(
      LocalStorageEnum.IS_SIDE_NAV_OPEN,
      (!isSideNavOpen).toString(),
    );
  }, [dispatch, isSideNavOpen]);

  return (
    <Drawer variant="permanent" open={isSideNavOpen}>
      <DrawerHeader>
        <Logo />
      </DrawerHeader>
      <List sx={{ overflowX: 'hidden', overflowY: 'auto' }} disablePadding>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            component={NavLink}
            to={menuLinks.dashboard.link}
            sx={(theme) =>
              isSideNavOpen
                ? activeLinkItemMixin(theme)
                : activeLinkItemCollapsedMixin(theme)
            }
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSideNavOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <menuLinks.dashboard.Icon />
            </ListItemIcon>
            <ListItemText
              primary={menuLinks.dashboard.name}
              sx={{ opacity: isSideNavOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <NavLinks links={menuLinks.mainMenuLinks} />
        <Divider sx={{ my: 0.5 }} />
        <NavLinks links={menuLinks.adminMenuLinks} />
      </List>
      <Divider sx={{ mt: 'auto' }} />
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          onClick={toggleSideNav}
          sx={(theme) => ({
            ...(isSideNavOpen
              ? activeLinkItemMixin(theme)
              : activeLinkItemCollapsedMixin(theme)),
            py: 1,
          })}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isSideNavOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <MenuOpenIcon />
          </ListItemIcon>
          <ListItemText
            primary="Collapse menu"
            sx={{ opacity: isSideNavOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
};

export default SideNav;
