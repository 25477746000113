import dayjs, { Dayjs } from 'dayjs';
import { arrayToOptions, dummyArray } from './common';
import { CALENDAR_MONTHS } from '../constants/dateOptions';

const getFormattedDate = (date: string): string => dayjs.utc(date).format('ll');

const getFormattedDateTime = (date: string): string =>
  dayjs.utc(date).format('lll');

const getCurrentDate = (): string => {
  try {
    return dayjs().utc(true).startOf('day').toISOString();
  } catch (e) {
    const currentDate = new Date();
    currentDate.setHours(0, -currentDate.getTimezoneOffset(), 0, 0);
    return currentDate.toISOString();
  }
};

const getIsoUtcDate = (date: Dayjs): string =>
  dayjs(date).utc(true).toISOString();

const getShortMonthYear = (date: string): string =>
  dayjs.utc(date).format('MMM YYYY');

const getFormattedDateFromNow = (date: string): string => {
  const d = dayjs.utc(date);
  const now = dayjs.utc();

  if (now.diff(d, 'days') > 3) {
    return d.format('ll');
  }

  return d.fromNow();
};

export type PeriodKeys =
  | 'last_year'
  | 'last_quarter'
  | 'last_month'
  | 'last_week'
  | 'yesterday'
  | 'today'
  | 'this_month'
  | 'this_quarter'
  | 'tomorrow'
  | 'next_week'
  | 'next_month'
  | 'prev_30_days'
  | 'next_30_days'
  | 'next_quarter'
  | 'quarter_to_date'
  | 'year_to_date';

const getDateByKey = (key: PeriodKeys) => {
  switch (key) {
    case 'last_year':
      return {
        $gte: dayjs.utc().subtract(1, 'year').startOf('year').format(),
        $lte: dayjs.utc().subtract(1, 'year').endOf('year').format(),
      };
    case 'last_month':
      return {
        $gte: dayjs.utc().subtract(1, 'month').startOf('month').format(),
        $lte: dayjs.utc().subtract(1, 'month').endOf('month').format(),
      };
    case 'last_week':
      return {
        $gte: dayjs.utc().subtract(1, 'week').startOf('week').format(),
        $lte: dayjs.utc().subtract(1, 'week').endOf('week').format(),
      };
    case 'yesterday':
      return {
        $gte: dayjs.utc().subtract(1, 'day').startOf('day').format(),
        $lte: dayjs.utc().subtract(1, 'day').endOf('day').format(),
      };
    case 'today':
      return {
        $gte: dayjs.utc().startOf('day').format(),
        $lte: dayjs.utc().endOf('day').format(),
      };
    case 'this_month':
      return {
        $gte: dayjs.utc().startOf('month').format(),
        $lte: dayjs.utc().endOf('month').format(),
      };
    case 'tomorrow':
      return {
        $gte: dayjs.utc().add(1, 'day').startOf('day').format(),
        $lte: dayjs.utc().add(1, 'day').endOf('day').format(),
      };
    case 'next_week':
      return {
        $gte: dayjs.utc().add(7, 'day').day(1).startOf('day').format(),
        $lte: dayjs.utc().add(7, 'day').day(7).endOf('day').format(),
      };
    case 'next_month':
      return {
        $gte: dayjs.utc().add(1, 'month').startOf('month').format(),
        $lte: dayjs.utc().add(1, 'month').endOf('month').format(),
      };
    case 'prev_30_days':
      return {
        $gte: dayjs.utc().subtract(1, 'months').endOf('day').format(),
        $lte: dayjs.utc().startOf('day').format(),
      };
    case 'next_30_days':
      return {
        $gte: dayjs.utc().startOf('day').format(),
        $lte: dayjs.utc().add(30, 'day').endOf('day').format(),
      };
    case 'year_to_date':
      return {
        $gte: dayjs.utc().subtract(1, 'year').startOf('day').format(),
        $lte: dayjs.utc().endOf('day').format(),
      };
    case 'quarter_to_date':
      return {
        $gte: dayjs.utc().subtract(3, 'month').startOf('day').format(),
        $lte: dayjs.utc().endOf('day').format(),
      };
    case 'last_quarter':
      return {
        $gte: dayjs.utc().subtract(1, 'quarter').startOf('quarter').format(),
        $lte: dayjs.utc().subtract(1, 'quarter').endOf('quarter').format(),
      };
    case 'this_quarter':
      return {
        $gte: dayjs.utc().startOf('quarter').format(),
        $lte: dayjs.utc().endOf('quarter').format(),
      };
    case 'next_quarter':
      return {
        $gte: dayjs.utc().add(1, 'quarter').startOf('quarter').format(),
        $lte: dayjs.utc().add(1, 'quarter').endOf('quarter').format(),
      };
    default:
      return {
        $gte: dayjs.utc().startOf('month').format(),
        $lte: dayjs.utc().endOf('month').format(),
      };
  }
};
const getNextMonthsArray = () => {
  const monthNames = CALENDAR_MONTHS.map((c) => c.monthName);
  const now = new Date();
  const datesArray = dummyArray(6).map(
    (e) => new Date(now.getFullYear(), now.getMonth() + e, 1),
  );
  return datesArray.map((d) =>
    [monthNames[d.getMonth()], d.getFullYear()].join(' '),
  );
};

const getNextMonthsOptions = () => {
  const dates = getNextMonthsArray();

  return arrayToOptions(dates);
};

export {
  getFormattedDate,
  getFormattedDateFromNow,
  getDateByKey,
  getShortMonthYear,
  getCurrentDate,
  getIsoUtcDate,
  getNextMonthsArray,
  getNextMonthsOptions,
  getFormattedDateTime,
};
