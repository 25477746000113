import React from 'react';
import { UseFormWatch } from 'react-hook-form';
import { TableCell, TableRow, Typography } from '@mui/material';
import { DealTypes, SalesPlanningTypes } from 'api';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import {
  convertToFixed,
  getNegativeRatio,
  getRoi,
  getSum,
} from 'helpers/calculations';

interface Props {
  watch: UseFormWatch<SalesPlanningTypes.ItemCreateParams>;
  itemKey?: DealTypes.StrategiesEnum;
}

const AutocalculationsRow: React.FC<Props> = (props: Props): JSX.Element => {
  const { itemKey, watch } = props;

  const autoCalculatedValues = (() => {
    const getVal = (arr: any, field: any) =>
      convertToFixed(
        getSum(arr.map((v: any) => (field in v ? v[field] || 0 : 0))),
      );

    const getItemTotals = (s: DealTypes.StrategiesEnum) => {
      const value = watch(`budget_distribution.${s}`) as any;

      if (!value) {
        return {
          actual: {
            budget_target: 0,
            revenue_target: 0,
            cm3_target: 0,
            roi_target: 0,
            cm3_ratio: 0,
          },
          autoCalculated: {
            budget_target: 0,
            roi_target: 0,
            revenue_target: 0,
            cm3_target: 0,
            cm3_ratio: 0,
          },
        };
      }

      if (value && !value.sub_items) {
        const result = {
          budget_target: value.budget_target || 0,
          revenue_target: value.revenue_target || 0,
          cm3_target: value.cm3_target || 0,
          roi_target: value.roi_target || 0,
          cm3_ratio: getNegativeRatio({
            value: value.cm3_target || 0,
            total: value.revenue_target || 0,
          }),
        };

        return {
          actual: result,
          autoCalculated: result,
        };
      }

      const arr = Object.values(value.sub_items);

      const totalBudget = getVal(arr, 'budget_target');
      const totalRevenue = getVal(arr, 'revenue_target');
      const totalCm3 = getVal(arr, 'cm3_target');
      const totalRoi = getRoi({ budget: totalBudget, revenue: totalRevenue });
      const totalCm3Ratio = getNegativeRatio({
        value: totalCm3,
        total: totalRevenue,
      });

      return {
        actual: {
          budget_target: value.budget_target || 0,
          revenue_target: value.revenue_target || 0,
          cm3_target: value.cm3_target || 0,
          roi_target:
            getRoi({
              budget: value.budget_target,
              revenue: value.revenue_target,
            }) || 0,
          cm3_ratio:
            getNegativeRatio({
              value: value.cm3_target,
              total: value.revenue_target,
            }) || 0,
        },
        autoCalculated: {
          budget_target: totalBudget || 0,
          roi_target: totalRoi || 0,
          revenue_target: totalRevenue || 0,
          cm3_target: totalCm3 || 0,
          cm3_ratio: totalCm3Ratio || 0,
        },
      };
    };

    const getTotals = (arr: any[]) => {
      const totalBudget = getVal(arr, 'budget_target');
      const totalRevenue = getVal(arr, 'revenue_target');
      const totalCm3 = getVal(arr, 'cm3_target');
      const totalRoi = getRoi({ budget: totalBudget, revenue: totalRevenue });
      const totalCm3Ratio = getNegativeRatio({
        value: totalCm3,
        total: totalRevenue,
      });

      return {
        budget_target: totalBudget,
        roi_target: totalRoi,
        revenue_target: totalRevenue,
        cm3_target: totalCm3,
        cm3_ratio: totalCm3Ratio,
      };
    };

    const strategiesTotals = Object.values(DealTypes.StrategiesEnum).map(
      (strategy) => getItemTotals(strategy),
    );

    const actualTotals = getTotals(strategiesTotals.map((s) => s.actual));
    const autoCalculatedTotals = getTotals(
      strategiesTotals.map((s) => s.autoCalculated),
    );

    if (itemKey) {
      const itemTotals = getItemTotals(itemKey);
      const actualRevenueRatio = getNegativeRatio({
        value: itemTotals.actual.revenue_target,
        total: actualTotals.revenue_target,
      });
      const autoCalculatedRevenueRatio = getNegativeRatio({
        value: itemTotals.autoCalculated.revenue_target,
        total: autoCalculatedTotals.revenue_target,
      });

      return {
        actual: {
          ...itemTotals.actual,
          revenue_ratio: actualRevenueRatio,
        },
        autoCalculated: {
          ...itemTotals.autoCalculated,
          revenue_ratio: autoCalculatedRevenueRatio,
        },
      };
    }

    const totals = {
      actual: actualTotals,
      autoCalculated: autoCalculatedTotals,
    };

    const actualTotalRevenueRatio = getNegativeRatio({
      value: totals.actual.revenue_target,
      total: actualTotals.revenue_target,
    });
    const autoCalculatedTotalRevenueRatio = getNegativeRatio({
      value: totals.autoCalculated.revenue_target,
      total: autoCalculatedTotals.revenue_target,
    });

    return {
      actual: {
        ...totals.actual,
        revenue_ratio: actualTotalRevenueRatio || 0,
      },
      autoCalculated: {
        ...totals.autoCalculated,
        revenue_ratio: autoCalculatedTotalRevenueRatio || 0,
      },
    };
  })();

  return (
    <TableRow
      sx={{
        backgroundColor: (t) =>
          t.palette.mode === 'dark'
            ? t.palette.grey['900']
            : t.palette.grey.A200,
      }}
    >
      <TableCell />
      <TableCell component="th" scope="row">
        Auto calculated
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.budget_target ===
            autoCalculatedValues.actual.budget_target
              ? 'success.main'
              : 'error'
          }
        >
          {formatCurrency(autoCalculatedValues.autoCalculated.budget_target)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.roi_target ===
            autoCalculatedValues.actual.roi_target
              ? 'success.main'
              : 'error'
          }
        >
          {autoCalculatedValues.autoCalculated.roi_target || 0}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.revenue_target ===
            autoCalculatedValues.actual.revenue_target
              ? 'success.main'
              : 'error'
          }
        >
          {formatCurrency(autoCalculatedValues.autoCalculated.revenue_target)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.cm3_target ===
            autoCalculatedValues.actual.cm3_target
              ? 'success.main'
              : 'error'
          }
        >
          {formatCurrency(autoCalculatedValues.autoCalculated.cm3_target)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.cm3_ratio ===
            autoCalculatedValues.actual.cm3_ratio
              ? 'success.main'
              : 'error'
          }
        >
          {formatPercent(autoCalculatedValues.autoCalculated.cm3_ratio)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={
            autoCalculatedValues.autoCalculated.revenue_ratio ===
            autoCalculatedValues.actual.revenue_ratio
              ? 'success.main'
              : 'error'
          }
        >
          {formatPercent(autoCalculatedValues.autoCalculated.revenue_ratio)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default AutocalculationsRow;
