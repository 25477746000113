import React, { useCallback, useMemo, useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  CopyAllOutlined,
  DeleteOutlined,
  LaunchOutlined,
  LinkOutlined,
} from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  ConfirmDialogControlled,
  CloseDetailsDrawer,
  EditButton,
} from 'components';
import { appLinks } from 'routes/links';
import { deals, DealTypes } from 'api';
import { useApiRequest, useClipboard, useFiltersSearchParams } from 'hooks';

interface Props {
  data: DealTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Actions = (props: Props): JSX.Element => {
  const { data, refetch, inline } = props;
  const navigate = useNavigate();
  const handleCopy = useClipboard();
  const { handleFilterChange } = useFiltersSearchParams();

  const { isLoading, requestFn } = useApiRequest(() =>
    deals.remove(data._id.$oid),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleDelete = useCallback(async () => {
    await requestFn({
      successMessage: 'Deal deleted',
    });

    setIsDeleteDialogOpen(false);

    if (inline) {
      handleFilterChange('splitItemId', undefined);
    } else {
      navigate(-1);
    }
  }, [handleFilterChange, inline, navigate, requestFn]);

  const actions: ActionButtonProps<DealTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.deals.details.as(item.deal_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin + appLinks.deals.details.as(item.deal_id),
          ),
      },
      {
        name: 'Clone deal',
        Icon: CopyAllOutlined,
        to: (item) => appLinks.deals.create.clone(item.deal_id),
      },
      {
        name: 'Delete deal',
        Icon: DeleteOutlined,
        onClick: handleOpenDialog,
        disabled: data.is_deleted,
      },
      {
        name: 'Bookmark deal',
        disabled: data.is_deleted,
        BookmarkProps: {
          itemId: (item) => item.deal_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'deal_ids',
        },
      },
    ],
    [handleCopy, inline, data.is_deleted],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={7}
          actions={actions}
          data={data}
          refetch={refetch}
        />
        <ConfirmDialogControlled
          isLoading={isLoading}
          name="deal"
          isOpen={isDeleteDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDelete}
        />
      </Grid>
      <Grid item>
        <EditButton
          variant="button"
          idKey="dealId"
          inline={inline}
          id={data.deal_id}
          link={appLinks.deals.edit.as}
          disabled={data.is_deleted}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
