import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { BookmarkAddOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { bookmarks } from 'api';
import { useDataTableContext } from '../context';
import DataTableProps from '../DataTableProps';
import Loader from '../../Loader';
import { selectAllRows } from '../context/actions';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
  bookmarkType: DataTableProps.BulkActionsProps['bookmarkType'];
  idKey: string;
}

const BookmarkAddAction = (props: Props): JSX.Element | null => {
  const { refetch, bookmarkType, idKey } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { requestFn: createBookmarkFn, isLoading } = useApiRequest(
    bookmarks.create,
  );

  const nonBookmarkedItems = selectedItems
    .filter((s) => !s.bookmark_id)
    .map((s) => s[idKey]);

  const handleCreateBookmarks = async (e: React.MouseEvent) => {
    e.stopPropagation();

    await createBookmarkFn({
      args: {
        [bookmarkType]: nonBookmarkedItems,
      },
      successMessage: `${nonBookmarkedItems.length} bookmarks created`,
    });

    await refetch();
    dispatch(selectAllRows([]));
  };

  return (
    <Tooltip title={`Add ${nonBookmarkedItems.length} bookmarks`}>
      <span>
        <Button
          onClick={handleCreateBookmarks}
          disabled={isLoading || nonBookmarkedItems.length === 0}
        >
          {isLoading ? (
            <Loader
              variant="inline"
              circularProgressProps={{ size: 24, color: 'primary' }}
            />
          ) : (
            <BookmarkAddOutlined
              color={nonBookmarkedItems.length > 0 ? 'success' : undefined}
            />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default BookmarkAddAction;
