import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Control,
  useController,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  createFilterOptions,
  debounce,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { discounts, DiscountTypes } from 'api';
import { Loader } from 'components';
import { ArrowDownTinIcon } from 'components/icons';
import { MarketingTypes } from 'api/marketing';

interface Props {
  control: Control<MarketingTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<MarketingTypes.ItemCreateParams>;
  setValue: UseFormSetValue<MarketingTypes.ItemCreateParams>;
  setIsCustomCouponCode?: Dispatch<SetStateAction<boolean>>;
}

type SelectOption = DiscountTypes.AutocompleteSearchItem & {
  label: string;
  isCustomOption?: boolean;
};

const filter = createFilterOptions<SelectOption>();

const initialOption: DiscountTypes.AutocompleteSearchItem = {
  coupon_code: '',
  coupon_description: '',
  value: 0,
  discount_details: DiscountTypes.DetailsEnum.PercentageDiscount,
  shop_id: { $oid: '' },
  discount_id: '',
  discount_type: DiscountTypes.DiscountTypeEnum.Percentage,
  free_gift_product_categories: [],
  free_gift_products: [],
};

const CouponCodeInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    setValue: setFormValue,
    watch,
    setIsCustomCouponCode,
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'coupon_code',
  });

  const shopId = watch('shop_id');
  const couponCode = watch('coupon_code');

  const initialValue: SelectOption | null = useMemo(() => {
    if (field.value) {
      return {
        ...initialOption,
        coupon_code: field.value,
        label: field.value,
      };
    }

    return null;
  }, [field.value]);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SelectOption | null>(initialValue);
  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useQuery(
    [discounts.endpoints.autocompleteSearch, inputValue],
    () =>
      discounts
        .autocompleteSearch({
          coupon_code: inputValue,
          shop_id: shopId,
        })
        .then((res) => res.map((r) => ({ ...r, label: r.coupon_code }))),
    { enabled: open, initialData: [] },
  );

  const handleFilterOptions = (
    options: SelectOption[],
    params: FilterOptionsState<SelectOption>,
  ) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((opt) => inputValue === opt.coupon_code);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        ...initialOption,
        coupon_code: inputValue,
        label: `Add "${inputValue}"`,
        isCustomOption: true,
      });
    }

    if (value) {
      if (Array.isArray(value)) {
        const selected = (value || []).map(
          (v: { coupon_code: any }) => v.coupon_code,
        );
        return filtered.filter((f) => !selected.includes(f.coupon_code));
      }

      return filtered.filter((f) => f.coupon_code !== value.coupon_code);
    }

    return filtered;
  };

  const handleValueChange = useCallback(
    (event: unknown, newValue: string | SelectOption | null) => {
      if (typeof newValue === 'string') {
        setValue(null);
        field.onChange(undefined);
      } else if (newValue) {
        const cleanValue =
          newValue.label.indexOf('Add') > -1
            ? { ...newValue, label: newValue.coupon_code }
            : newValue;

        setValue(cleanValue);
        field.onChange(cleanValue.coupon_code);
        setFormValue('discount_id', cleanValue.discount_id);

        if (setIsCustomCouponCode) {
          setIsCustomCouponCode(!!newValue.isCustomOption);
        }
      } else {
        setValue(newValue);
        field.onChange(newValue);
        if (newValue) {
          setFormValue('discount_id', null);
        }
      }
    },
    [field, setIsCustomCouponCode, setFormValue],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
      if (reason === 'clear') {
        field.onChange(undefined);
      }
    },
    500,
  );

  useEffect(() => {
    if (couponCode === null && value !== null) {
      setValue(couponCode);
    }
  }, [value, couponCode]);

  const options = uniqBy(
    Array.isArray(value)
      ? [...value, ...data]
      : [...(value ? [value] : []), ...data],
    'coupon_code',
  );

  return (
    <Autocomplete
      popupIcon={<ArrowDownTinIcon />}
      clearOnEscape
      autoComplete
      filterSelectedOptions
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value || null}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) =>
        option.coupon_code === value.coupon_code
      }
      options={options || []}
      loading={isLoading}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      onBlur={field.onBlur}
      filterOptions={handleFilterOptions}
      onChange={handleValueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={field.ref}
          name={field.name}
          label="Coupon code"
          placeholder="Search coupon code"
          error={!!error}
          helperText={error && error.message}
          InputLabelProps={{
            shrink: true,
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            notched: false,
            endAdornment: isLoading ? (
              <>
                {params.InputProps.endAdornment}
                <Loader circularProgressProps={{ size: 20 }} />
              </>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
      ChipProps={{
        color: 'primary',
      }}
    />
  );
};

export default CouponCodeInput;
