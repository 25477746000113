import React, { useState } from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { CloseRounded, SendRounded } from '@mui/icons-material';
import { timeline, TimelineTypes } from 'api';
import { useApiRequest } from 'hooks';
import Loader from '../Loader';

interface Props {
  id: string;
  commentId: string;
  type: TimelineTypes.ModelEnum;
  value: string;
  onClose: () => void;
}

const CommentUpdateForm = (props: Props): JSX.Element | null => {
  const { type, id, commentId, value, onClose } = props;

  const { isLoading, requestFn } = useApiRequest((data) =>
    timeline.update(type, id, commentId, data),
  );

  const [inputValue, setInputValue] = useState(value);

  const handleSubmit = async () => {
    if (inputValue) {
      await requestFn({
        args: { comment_text: inputValue },
        successMessage: 'Comment updated',
      });

      setInputValue('');
      onClose();
    }
  };

  return (
    <TextField
      multiline
      rows={2}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder="Write comment..."
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Stack columnGap={1} direction="row">
              <IconButton onClick={onClose}>
                <CloseRounded />
              </IconButton>
              <IconButton
                color="primary"
                disabled={!inputValue || isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Loader circularProgressProps={{ size: 20 }} />
                ) : (
                  <SendRounded />
                )}
              </IconButton>
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CommentUpdateForm;
