import { Stack } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { DealTypes } from 'api';
import AttachmentsPreview from './AttachmentsPreview';
import Checklist from './Checklist';

interface Props {
  deal: DealTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const ChecklistTab = (props: Props): JSX.Element | null => {
  const { deal, refetch, inline } = props;

  return (
    <Stack rowGap={2}>
      <AttachmentsPreview deal={deal} refetch={refetch} inline={inline} />
      <Checklist deal={deal} inline={inline} />
    </Stack>
  );
};

export default ChecklistTab;
