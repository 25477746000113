import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormCard, FormContainer, FormInput, ShopInput } from 'components';
import { users, UserTypes } from 'api';
import { getFieldsWithValues, getModifiedFields } from 'helpers/getFormFields';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { PersonOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { enumToOptions } from 'helpers/common';
import { setUserData } from 'store/actions';
import { useStoreContext } from 'store';
import { appLinks } from 'routes/links';

interface Props {
  id: string | undefined;
  initialData: UserTypes.ItemCreateParams;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, drawerTitle, drawer } = props;
  const {
    state: { user, refetchTableData },
    dispatch,
  } = useStoreContext();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isDirty, isSubmitting },
    setValue,
  } = useForm<UserTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const { requestFn } = useApiRequest(
    id ? (data) => users.update(id, data) : users.create,
  );

  const onSubmit = useCallback(
    async (data: UserTypes.ItemCreateParams) => {
      if (user?.access !== UserTypes.AccessEnum.ADMIN) return;

      const values = id
        ? getModifiedFields(dirtyFields, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `User ${id ? 'updated' : 'created'}`,
      });

      if (res) {
        if (user?.user_id === res.user_id) {
          dispatch(setUserData(res));
        }

        if (drawer) {
          updateFiltersSearchParams({
            userId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.team.details.as(res._id.$oid), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      user?.access,
      user?.user_id,
      id,
      dirtyFields,
      requestFn,
      drawer,
      dispatch,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.team.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={
        (!!id && !isDirty) || user?.access !== UserTypes.AccessEnum.ADMIN
      }
    >
      <FormCard title="General information" avatar={<PersonOutlined />}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormInput
              control={control}
              inputType="email"
              name="email"
              label="Email"
              disabled={!!id}
              required
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <ShopInput
              control={control}
              onChange={(shop) => {
                setValue('brand', shop.brand);
                setValue('country_team', shop.country_team);
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="brand"
              inputType="select"
              label="Brand"
              options={BRANDS.OPTIONS}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="country_team"
              inputType="select"
              label="Country team"
              options={COUNTRIES.OPTIONS}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="access"
              inputType="select"
              label="Access"
              options={enumToOptions(UserTypes.AccessEnum)}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="role"
              inputType="select"
              label="Role"
              options={enumToOptions(UserTypes.RoleEnum)}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
      </FormCard>
    </FormContainer>
  );
};

export default Form;
