import React, { useCallback, useMemo, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { useModel, useFiltersSearchParams } from 'hooks';
import FiltersDrawer from './FiltersDrawer';
import Filters from './Filters';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';
import {
  clearFilter,
  clearFilters,
  setDataTableState,
  setFiltersState,
} from '../context/actions';

interface Props {
  inline: boolean;
  filtersConfig: DataTableProps.Filters<Record<string, unknown>>;
  children?: JSX.Element;
}

const TableFilters = (props: Props): JSX.Element | null => {
  const { filtersConfig, children, inline } = props;
  const {
    state: {
      initialFiltersState,
      emptyFiltersState,
      pageNumber,
      perPage,
      sortDesc,
      sortBy,
      activeFilters,
      filtersState,
    },
    dispatch,
  } = useDataTableContext();

  const {
    handleFilterChange,
    updateFiltersSearchParams,
    setFiltersSearchParams,
  } = useFiltersSearchParams();

  const handleQueryChange = useCallback(
    (key: string, value: any) => {
      if (!inline) {
        handleFilterChange(key, value);
      }
    },
    [handleFilterChange, inline],
  );

  const handleQueryUpdate = useCallback(
    (value: any) => {
      if (!inline) {
        updateFiltersSearchParams(value);
      }
    },
    [updateFiltersSearchParams, inline],
  );

  const handleSetQuery = useCallback(
    (value: any) => {
      if (!inline) {
        setFiltersSearchParams(value);
      }
    },
    [setFiltersSearchParams, inline],
  );

  const sortedFilters = useMemo(
    () =>
      [...filtersConfig].sort((a) => (activeFilters.includes(a.key) ? -1 : 1)),
    [filtersConfig, activeFilters],
  );

  const [state, handleChange, setState] =
    useModel<Record<string, any>>(initialFiltersState);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleClearFilters = useCallback(() => {
    handleSetQuery({
      limit: perPage,
      page: pageNumber,
      sortBy: sortBy || undefined,
      sortDesc,
    });

    dispatch(clearFilters());
    setState(emptyFiltersState);
    setIsFiltersOpen(false);
  }, [
    dispatch,
    emptyFiltersState,
    pageNumber,
    perPage,
    handleSetQuery,
    setState,
    sortBy,
    sortDesc,
  ]);

  const handleFormatRawFiltersState = useCallback(
    (state: Record<string, any>) => {
      // todo - deal with api: id should be used instead of name

      // set search filter options as string[] for filters request;
      // keep AutocompleteSearchItem[] only for local state
      const activeKeys = Object.entries(state)
        .filter(([, value]) =>
          Array.isArray(value) ? value.length !== 0 : value !== undefined,
        )
        .map(([key]) => key);

      const activeSearchKeys = filtersConfig
        .filter((f) => f.type === 'search' && activeKeys.includes(f.key))
        .map((f) => f.key);

      return Object.entries(state).reduce(
        (previousValue, [key, value]) => {
          if (activeSearchKeys.includes(key) && Array.isArray(value)) {
            // const v = value.map((v: AutocompleteSearchItem) => v.name);
            return {
              ...previousValue,
              [key]: value.length === 0 ? undefined : value,
            };
          }

          return { ...previousValue, [key]: value };
        },
        {} as Record<string, any>,
      );
    },
    [filtersConfig],
  );

  // start popup fns

  // const handlePopupCloseClick = useCallback(() => {
  //   handleQueryUpdate(state);
  //   const newState = handleFormatRawFiltersState(state);
  //   dispatch(setFiltersState(newState));
  // }, [dispatch, handleFormatRawFiltersState, state, handleQueryUpdate]);

  const handleClearFilterClick = useCallback(
    (key: string) => {
      const filter = filtersConfig.find((f) => f.key === key);

      if (filter?.required) {
        handleChange(key, filter.initialValue);
        handleQueryChange(key, filter.initialValue);
      } else {
        handleChange(key, undefined);
        handleQueryChange(key, undefined);
        dispatch(clearFilter(key));
      }
    },
    [dispatch, filtersConfig, handleChange, handleQueryChange],
  );

  const handlePopupApplyClick = useCallback(() => {
    handleQueryUpdate(state);
    const newState = handleFormatRawFiltersState(state);

    if (pageNumber !== 1) {
      dispatch(setDataTableState({ pageNumber: 1 }));
      handleQueryChange('page', 1);
    }

    dispatch(setFiltersState(newState));
  }, [
    dispatch,
    handleQueryChange,
    handleFormatRawFiltersState,
    pageNumber,
    state,
    handleQueryUpdate,
  ]);

  const handlePopupCancelClick = useCallback(
    (key: string) => {
      handleChange(key, filtersState[key]);
    },
    [filtersState, handleChange],
  );

  // end popup fns

  // start drawer fns

  const handleOpenFilters = useCallback(() => {
    setIsFiltersOpen(true);
  }, []);

  const handleCloseFilters = useCallback(() => {
    setIsFiltersOpen(false);
    setState(initialFiltersState);
  }, [initialFiltersState, setState]);

  const handleApplyClick = useCallback(() => {
    handleQueryUpdate(state);
    const newState = handleFormatRawFiltersState(state);

    if (pageNumber !== 1) {
      dispatch(setDataTableState({ pageNumber: 1 }));
      handleQueryChange('page', 1);
    }

    dispatch(setFiltersState(newState));
    setIsFiltersOpen(false);
  }, [
    dispatch,
    handleQueryChange,
    handleFormatRawFiltersState,
    pageNumber,
    state,
    handleQueryUpdate,
  ]);

  // end drawer fns

  return (
    <Grid container columnSpacing={1.5} wrap="nowrap" sx={{ p: 1.5 }}>
      {children && (
        <>
          <Grid item>{children}</Grid>
          <Grid item>
            <Divider variant="fullWidth" orientation="vertical" />
          </Grid>
        </>
      )}
      <Grid item sx={{ flexGrow: 1, overflow: 'hidden', overflowX: 'auto' }}>
        <Filters
          inline
          filters={sortedFilters}
          filtersState={state}
          onChange={handleChange}
          onClearFilter={handleClearFilterClick}
          onClose={handlePopupCancelClick}
          onApply={handlePopupApplyClick}
        />
      </Grid>
      <Grid item sx={{ ml: 'auto' }}>
        <FiltersDrawer
          isFiltersOpen={isFiltersOpen}
          onOpen={handleOpenFilters}
          onClose={handleCloseFilters}
          onClearFilters={handleClearFilters}
          onApplyClick={handleApplyClick}
          filters={filtersConfig}
          filtersState={state}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default TableFilters;
