import { useCallback, useMemo } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { SalesPlanningTypes } from 'api';
import { Palette } from '@mui/material/styles/createPalette';
import { InfoCard } from 'components';
import { formatCurrency, formatPercent } from 'helpers/formatters';

interface Props {
  inline?: boolean;
  performance: SalesPlanningTypes.PerformanceSummary;
}

const ArrowTooltip = (props: TooltipProps & { inline?: boolean }) => {
  const { title, inline, children, ...rest } = props;

  return (
    <Tooltip
      title={title}
      arrow
      open
      disableInteractive
      PopperProps={{
        sx: (theme) => ({
          zIndex: inline ? theme.zIndex.drawer : 15,
          pointerEvents: 'all',
          '&:hover': {
            zIndex: inline ? theme.zIndex.drawer + 1 : 16,
            cursor: 'default',
            '& .MuiTooltip-tooltip': {
              backgroundColor: theme.palette.grey['700'],
              boxShadow: theme.shadows[8],
            },
            '& .MuiTooltip-arrow': {
              color: theme.palette.grey['700'],
            },
          },
        }),
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

const TrafficLightProgress = (props: Props): JSX.Element | null => {
  const { performance, inline } = props;

  const spPerfSummary = useMemo(() => {
    const revenue = {
      actual: performance.revenue_actual || 0,
      target: performance.revenue_target || 0,
      potential: performance.revenue_potential || 0,
      running_forecast: performance.revenue_running_forecast || 0,
      traffic_light: performance.revenue_traffic_light || 0,
      pending_planned: performance.pending_revenue_planned || 0,
      scheduled_planned: performance.scheduled_revenue_planned || 0,
    };
    const budget = {
      actual: performance.budget_actual || 0,
      target: performance.budget_target || 0,
      potential: performance.budget_potential || 0,
      running_forecast: performance.budget_running_forecast || 0,
      traffic_light: performance.budget_traffic_light || 0,
      pending_planned: performance.pending_budget_planned || 0,
      scheduled_planned: performance.scheduled_budget_planned || 0,
    };

    return {
      revenue,
      budget,
    };
  }, [
    performance.budget_actual,
    performance.budget_potential,
    performance.budget_running_forecast,
    performance.budget_target,
    performance.budget_traffic_light,
    performance.pending_budget_planned,
    performance.pending_revenue_planned,
    performance.revenue_actual,
    performance.revenue_potential,
    performance.revenue_running_forecast,
    performance.revenue_target,
    performance.revenue_traffic_light,
    performance.scheduled_budget_planned,
    performance.scheduled_revenue_planned,
  ]);

  const getProgressBarData = useCallback(
    (type: keyof typeof spPerfSummary) => {
      const data = spPerfSummary[type];

      const total = data.actual + data.scheduled_planned + data.pending_planned;
      const max = Math.max(data.target, total);

      const getMax = (value: number) =>
        Math.max(max > 0 ? (value / max) * 100 : 0, 0.1);

      return {
        data,
        actual: getMax(data.actual),
        target: getMax(data.target),
        potential: getMax(data.potential),
        running_forecast: getMax(data.running_forecast),
        traffic_light: getMax(data.traffic_light),
        pending_planned: getMax(data.pending_planned),
        scheduled_planned: getMax(data.scheduled_planned),
      };
    },
    [spPerfSummary],
  );

  const getVariant = useCallback(
    (
      type: keyof typeof spPerfSummary,
    ): keyof Pick<Palette, 'success' | 'error' | 'warning' | 'primary'> => {
      if (type === 'revenue') {
        const { target, running_forecast: value } = spPerfSummary.revenue;

        if (value < 0.8 * target) {
          return 'error';
        }
        if (value >= 0.8 * target && value < target) {
          return 'warning';
        }
        return 'success';
      }

      if (type === 'budget') {
        const { target, running_forecast: value } = spPerfSummary.budget;

        if (value < 0.8 * target || value > 1.2 * target) {
          return 'error';
        }
        if (value >= 0.8 * target && value < 0.9 * target) {
          return 'warning';
        }
        if (value > target && value < 1.2 * target) {
          return 'warning';
        }
        return 'success';
      }

      return 'primary';
    },
    [spPerfSummary.budget, spPerfSummary.revenue],
  );

  const options: (keyof typeof spPerfSummary)[] = ['budget', 'revenue'];

  return (
    <InfoCard title="Marketing spent">
      <Stack rowGap={2}>
        {options.map((o) => (
          <Card variant="outlined" key={o}>
            <CardHeader
              title={`${o} traffic light`}
              titleTypographyProps={{
                variant: 'h5',
                textTransform: 'capitalize',
              }}
              avatar={
                <Avatar
                  sx={(theme) => ({
                    width: '100%',
                    padding: 1.5,
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor: theme.palette[getVariant(o)].main,
                  })}
                >
                  {formatPercent(spPerfSummary[o].traffic_light)}
                </Avatar>
              }
            />
            <CardContent>
              <Box
                sx={{
                  position: 'relative',
                  height: 16,
                  mt: 4,
                  mb: 5,
                }}
              >
                <Box
                  id="chart"
                  sx={(theme) => ({
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    height: 16,
                    overflow: 'hidden',
                    borderRadius: `${theme.shape.borderRadius}px`,
                  })}
                >
                  <ArrowTooltip
                    inline={inline}
                    title={`Actual: ${formatCurrency(spPerfSummary[o].actual)}`}
                  >
                    <Box
                      id="actual"
                      sx={(theme) => ({
                        position: 'absolute',
                        width: `${getProgressBarData(o).actual}%`,
                        height: '100%',
                        transition: theme.transitions.create('width'),
                        backgroundColor: theme.palette.info.light,
                      })}
                    />
                  </ArrowTooltip>
                  <ArrowTooltip
                    inline={inline}
                    title={`Scheduled: ${formatCurrency(
                      spPerfSummary[o].scheduled_planned,
                    )}`}
                  >
                    <Box
                      id="scheduled"
                      sx={(theme) => ({
                        position: 'absolute',
                        width: `${getProgressBarData(o).scheduled_planned}%`,
                        left: `${getProgressBarData(o).actual}%`,
                        height: '100%',
                        transition: theme.transitions.create('width'),
                        backgroundColor: theme.palette.primary.light,
                      })}
                    />
                  </ArrowTooltip>
                  <ArrowTooltip
                    inline={inline}
                    title={`Pending: ${formatCurrency(
                      spPerfSummary[o].pending_planned,
                    )}`}
                  >
                    <Box
                      id="pending"
                      sx={(theme) => ({
                        position: 'absolute',
                        width: `${getProgressBarData(o).pending_planned}%`,
                        left: `${
                          getProgressBarData(o).actual +
                          getProgressBarData(o).scheduled_planned
                        }%`,
                        height: '100%',
                        transition: theme.transitions.create('width'),
                        backgroundColor: theme.palette.secondary.light,
                      })}
                    />
                  </ArrowTooltip>
                </Box>
                {!!spPerfSummary[o].target && (
                  <ArrowTooltip
                    inline={inline}
                    title={`Target: ${formatCurrency(spPerfSummary[o].target)}`}
                    placement="top"
                  >
                    <Box
                      id="target"
                      sx={(theme) => ({
                        position: 'absolute',
                        left: `${getProgressBarData(o).target}%`,
                        height: '150%',
                        width: 2,
                        bottom: 0,
                        transition: theme.transitions.create('width'),
                        border: `1px dashed ${theme.palette.error.main}`,
                        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                      })}
                    />
                  </ArrowTooltip>
                )}

                <ArrowTooltip
                  inline={inline}
                  title={`Running forecast: ${formatCurrency(
                    spPerfSummary[o].running_forecast,
                  )}`}
                  placement="top"
                >
                  <Box
                    id="running_forecast"
                    sx={(theme) => ({
                      position: 'absolute',
                      left: `${getProgressBarData(o).running_forecast}%`,
                      height: '150%',
                      width: 2,
                      bottom: 0,
                      transition: theme.transitions.create('width'),
                      border: `1px dashed ${theme.palette.text.primary}`,
                      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                    })}
                  />
                </ArrowTooltip>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </InfoCard>
  );
};

export default TrafficLightProgress;
