import { DownloadProgress } from 'ky/distribution/types/options';
import ky from 'ky';
import apiClient from '../apiClient';
import CampaignTypes from './types';

const endpoints = {
  getList: 'campaigns/filter',
  autocompleteSearch: 'campaigns/autocomplete',
  getById: (id: string) => `campaigns/${id}`,
  create: 'campaigns',
  update: (id: string) => `campaigns/${id}`,
  addAttachment: (id: string) => `campaigns/${id}/attachment`,
  remove: (id: string) => `campaigns/${id}`,
  performanceReport: 'campaigns/performance_full_report',
  performanceInsightsChart: 'campaigns/performance_insights',
  performanceInsightsVar: 'campaigns/performance_insights_with_var',
  performanceInsightsInfluencers: 'campaigns/performance_insights_influencers',
};

const getList = (
  params: CampaignTypes.GetListParams,
): CampaignTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const autocompleteSearch = (
  params: CampaignTypes.AutocompleteSearchParams,
): CampaignTypes.AutocompleteSearchResponse =>
  apiClient
    .post(endpoints.autocompleteSearch, {
      json: {
        name: params.name,
        status: params.status ? [params.status] : undefined,
        limit: 20,
      },
    })
    .then((response) => response.json());

const getById = (id: string): CampaignTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const create = (
  data: CampaignTypes.ItemCreateParams,
): CampaignTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, { json: { campaign: data } })
    .then((response) => response.json());

const update = (
  id: string,
  data: Partial<CampaignTypes.ItemCreateParams>,
): CampaignTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), { json: { campaign: data } })
    .then((response) => response.json());

const remove = (id: string): CampaignTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), { json: { campaign: { is_deleted: true } } })
    .then((response) => response.json());

const addAttachment = (
  id: string,
  file: File,
  onDownloadProgress: (progress: DownloadProgress, chunk: Uint8Array) => void,
) => {
  const formData = new FormData();
  formData.append('name', file.name);
  formData.append('file', file);

  return ky
    .post(`${process.env.REACT_APP_API_URL}/${endpoints.addAttachment(id)}`, {
      body: formData,
      onDownloadProgress,
    })
    .then((response) => response.json());
};

const removeAttachment = (id: string) =>
  apiClient
    .post(endpoints.addAttachment(id), {
      body: null,
    })
    .then((response) => response.json());

const getPerformanceReport = (
  params: CampaignTypes.PerformanceParams,
): CampaignTypes.PerformanceResponse =>
  apiClient
    .post(endpoints.performanceReport, {
      json: { ...params, date_selector: 2 },
    })
    .then((response) => response.json());

const getPerformanceMarketingChart = (
  params: CampaignTypes.PerformanceParams,
): Promise<CampaignTypes.PerformanceChartData> =>
  apiClient
    .post(endpoints.performanceInsightsChart, {
      json: {
        ...params,
        returned_keys: [
          'sum_budget_actual',
          'sum_budget_potential',
          'sum_budget_running_forecast',
        ],
        report_type: 'time_series',
        date_selector: 2,
      },
    })
    .then((response) => response.json());

const getPerformanceSalesChart = (
  params: CampaignTypes.PerformanceParams,
): Promise<CampaignTypes.PerformanceChartData> =>
  apiClient
    .post(endpoints.performanceInsightsChart, {
      json: {
        ...params,
        returned_keys: [
          'sum_revenue_actual',
          'sum_revenue_potential',
          'sum_revenue_running_forecast',
        ],
        report_type: 'time_series',
        date_selector: 2,
      },
    })
    .then((response) => response.json());

const getPerformanceTopInfluencers = (
  params: CampaignTypes.PerformanceParams,
): Promise<CampaignTypes.PerformanceInfluencerData> =>
  apiClient
    .post(endpoints.performanceInsightsChart, {
      json: {
        filters: {
          strategy: ['Influencers'],
          status: ['Finished', 'Posted'],
          ...params.filters,
        },
        limit: 5,
        sort_by: 'sum_revenue_actual',
        sort_order: -1,
        group_by: 'influencer_profile_name',
        returned_keys: ['sum_revenue_actual', 'avg_roi_actual'],
        report_type: 'list_keys',
        date_selector: 2,
        period: params.period,
      },
    })
    .then((response) => response.json());

const getPerformanceInsightsVar = (
  params: CampaignTypes.PerformanceParams,
): Promise<CampaignTypes.PerformanceInsightsVarData> =>
  apiClient
    .post(endpoints.performanceInsightsVar, {
      json: {
        ...params,
        date_selector: 2,
      },
    })
    .then((response) => response.json());

const getPerformanceInsightsInfluencers = (
  params: CampaignTypes.PerformanceParams,
): Promise<CampaignTypes.PerformanceInsightsInfluencersData> =>
  apiClient
    .post(endpoints.performanceInsightsInfluencers, {
      json: {
        ...params,
        date_selector: 2,
      },
    })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  autocompleteSearch,
  getById,
  create,
  update,
  remove,
  addAttachment,
  removeAttachment,
  getPerformanceReport,
  getPerformanceMarketingChart,
  getPerformanceSalesChart,
  getPerformanceTopInfluencers,
  getPerformanceInsightsVar,
  getPerformanceInsightsInfluencers,
};
