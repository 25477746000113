import React, { useLayoutEffect, useRef, useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { DealTypes } from 'api';
import Form from './Form';

interface Props {
  deal: DealTypes.ItemData;
  inline?: boolean;
}

export type ChecklistItem<T> = {
  label: string;
  key: keyof T;
};

const contentChecklist: ChecklistItem<DealTypes.ContentChecklist>[] = [
  { key: 'visibility_of_logo', label: 'Visibility of logo' },
  { key: 'brand_tagged', label: 'Brand tagged' },
  { key: 'swipe_up_included', label: 'Swipe up included' },
  { key: '4_6_sequences', label: '4-6  sequences' },
  { key: 'coupon_code_visible', label: 'Coupon code visible' },
  { key: 'urgency_of_code', label: 'Urgency of code' },
  { key: 'action_followed', label: 'Action followed' },
  { key: 'product_included_and_applied', label: 'Product included & applied' },
  { key: 'showing_more_than_1_product', label: 'Showing more than 1 product' },
  { key: 'lighting', label: 'Lighting' },
  { key: 'influencer_speaking', label: 'Influencer speaking' },
  { key: 'face_story', label: 'Face Story' },
  { key: 'subtitles', label: 'Subtitles' },
  { key: 'resasons_to_love', label: 'Reasons to love' },
  { key: 'mix_and_match', label: 'Mix & Match' },
  { key: 'aesthetic', label: 'Aesthetic' },
];

const preChecklist: ChecklistItem<DealTypes.PreChecklist>[] = [
  { key: 'code_created', label: 'Code created' },
  { key: 'briefing_sent', label: 'Briefing sent' },
  { key: 'product_sent', label: 'Product sent' },
  { key: 'contract_sent', label: 'Contract sent' },
  { key: 'contract_signed', label: 'Contract signed' },
  { key: 'content_submitted_for_approval', label: 'Content approved' },
  { key: 'permission_to_reuse_content', label: 'Permission to reuse content' },
  { key: 'invoice_received', label: 'Invoice received' },
  { key: 'insights_requested', label: 'Insights requested' },
];

const TABS = {
  preChecklist: 'Pre checklist',
  contentChecklist: 'Content checklist',
};

const Checklist = (props: Props): JSX.Element | null => {
  const { deal, inline } = props;

  const $previewBlock = useRef(420);

  const [activeTab, setActiveTab] = useState(TABS.preChecklist);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  useLayoutEffect(() => {
    const el = document.getElementById('post-preview');
    $previewBlock.current = el?.clientHeight || 340;
  }, []);

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{ mb: 1 }}
      >
        <Tab
          label={TABS.preChecklist}
          value={TABS.preChecklist}
          sx={{ whiteSpace: 'nowrap' }}
        />
        <Tab
          label={TABS.contentChecklist}
          value={TABS.contentChecklist}
          sx={{ whiteSpace: 'nowrap' }}
        />
      </Tabs>

      <Box
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          // height: `calc(100vh - header - padding - previewBlock - rowGap - tabs)`,
          height: inline
            ? '100%'
            : `calc(100vh - 64px - 32px - ${$previewBlock.current}px - 16px - 48px)`,
        }}
      >
        {activeTab === TABS.preChecklist && (
          <Form
            id={deal._id.$oid}
            type="pre_checklist"
            value={deal.pre_checklist}
            options={preChecklist}
            disabled={deal.is_deleted}
          />
        )}
        {activeTab === TABS.contentChecklist && (
          <Form
            id={deal._id.$oid}
            type="checklist_content"
            value={deal.checklist_content}
            options={contentChecklist}
            disabled={deal.is_deleted}
          />
        )}
      </Box>
    </Box>
  );
};

export default Checklist;
