import { ChangeEvent } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListItemButton,
  TextField,
  MenuItem,
  Chip,
  Avatar,
  ListItemAvatar,
} from '@mui/material';
import { HandleChangeValue } from 'hooks/useModel';
import { MenuOptions } from 'types/common';

type Value = string[] | undefined;

interface Props {
  label: string;
  value: Value;
  onChange: (value: HandleChangeValue<Value>) => void;
  options: MenuOptions;
  inline?: boolean;
}

const InlineFilter = (props: Omit<Props, 'label' | 'inline'>) => {
  const { onChange, value, options } = props;

  const handleChange = (value: string) => {
    onChange((prev: string[] = []) => {
      const newValue = prev?.includes(value)
        ? prev?.filter((p) => p !== value)
        : [...prev, value];

      return newValue.length === 0 ? undefined : newValue;
    });
  };

  return (
    <List>
      {options.map((option) => (
        <ListItem key={option.value} disableGutters disablePadding>
          <ListItemButton
            selected={value?.includes(option.value)}
            onClick={() => handleChange(option.value)}
          >
            {option.image && (
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Box
                  component="img"
                  src={option.image}
                  sx={{ width: 'auto', height: 20 }}
                />
              </ListItemIcon>
            )}
            {option.Icon && (
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <Avatar sx={{ width: 30, height: 30 }}>
                  <option.Icon fontSize="small" />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText primary={option.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const SelectFilter = (props: Omit<Props, 'inline'>) => {
  const { onChange, value, label, options } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as unknown as string[]);
  };

  return (
    <TextField
      select
      label={label}
      placeholder={label}
      value={value || []}
      onChange={handleChange}
      SelectProps={{
        displayEmpty: true,
        multiple: true,
        // @ts-ignore
        renderValue: (value1: string[]) =>
          value1.map((v) => {
            if (value1.length === 0) {
              return `Select ${label}`;
            }

            const option = options.find((o) => o.value === v);

            if (!option) return null;

            return (
              <Chip
                key={v}
                color="primary"
                avatar={
                  // eslint-disable-next-line no-nested-ternary
                  option.Icon ? (
                    <Avatar src={option.image}>
                      <option.Icon fontSize="small" />
                    </Avatar>
                  ) : option.image ? (
                    <Avatar src={option.image} />
                  ) : undefined
                }
                label={option.label}
                sx={{ mr: 1 }}
              />
            );
          }),
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ display: 'flex' }}
        >
          {option.image && (
            <ListItemIcon>
              <Box
                component="img"
                src={option.image}
                sx={{ width: 'auto', height: 20 }}
              />
            </ListItemIcon>
          )}
          {option.Icon && (
            <ListItemIcon>
              <option.Icon sx={{ width: 'auto', height: 20 }} />
            </ListItemIcon>
          )}
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
};

const MultipleFilter = (props: Props): JSX.Element | null => {
  const { inline, ...rest } = props;

  if (inline) {
    return <InlineFilter {...rest} />;
  }

  return <SelectFilter {...rest} />;
};

export default MultipleFilter;
