import React, { useEffect, useMemo, useState } from 'react';
import { discounts, DiscountTypes } from 'api';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary, Loader } from 'components';
import { useStoreContext } from 'store';
import { getCurrentDate } from 'helpers/dayJsFormats';
import Form from './Form';
import {
  dataInitialSelectsOptions,
  dataToInitialModel,
  initialModel,
} from './fields';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { itemId } = props;
  const { id: paramsId = '' } = useParams<Params>();
  const {
    state: { user },
  } = useStoreContext();

  const id = useMemo(() => paramsId || itemId || '', [itemId, paramsId]);

  const [initialData, setInitialData] = useState<
    DiscountTypes.ItemCreateParams | undefined
  >(undefined);
  const [initialSelectsOptions, setInitialSelectsOptions] = useState<
    ReturnType<typeof dataInitialSelectsOptions> | undefined
  >(undefined);

  const { data, error } = useQuery(
    [discounts.endpoints.getById(id)],
    () => discounts.getById(id),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!id) {
      setInitialData({ ...initialModel, start_date: getCurrentDate() });
    } else if (data) {
      const initialFormData = dataToInitialModel(data);
      const initialSelectsData = dataInitialSelectsOptions(data);
      setInitialData(initialFormData);
      setInitialSelectsOptions(initialSelectsData);
    }
  }, [data, id, user?.full_name]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant="centered" />
  ) : (
    <Form
      drawer={!!itemId}
      drawerTitle={`Edit ${data?.coupon_code || 'discount'}`}
      id={id}
      initialData={initialData}
      initialSelectsOptions={initialSelectsOptions}
    />
  );
};

export default Index;
