import INFLUENCERS from 'constants/influencers';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { InfluencerTypes, users } from 'api';
import { BrandsEnum, BrandStatusEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import { FormProps } from 'components';
import { DeepPartial } from 'types/common';

const brandInitialModel: InfluencerTypes.BrandInfoCreateParams = {
  status: BrandStatusEnum.New,
  brand: BrandsEnum.avaAndMay,
  country_team: CountriesEnum.DE,
};

const eventInitialModel: InfluencerTypes.ImportantDateCreateParam = {
  label: '',
  date: '',
  reminder_date: '',
};

const initialModel: InfluencerTypes.ItemCreateParams = {
  brand_infos: [],
  important_dates: [],
  vertical_primary: InfluencerTypes.VerticalsEnum.Beauty,
  verticals_secondary: [],
  tags: [],
  target_countries: [],
  notes: '',
  agency_contact_info: {
    agency_name: '',
    contact_name: '',
    email: '',
    phone: '',
  },
  audience_info: {},
  personal_info: {
    email: '',
    phone: '',
    birth_date: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    private_address: '',
    shipping_address: '',
    age_group: InfluencerTypes.AgeEnum['26-29'],
    gender: InfluencerTypes.GenderEnum.Female,
  },
  instagram: {
    username: '',
  },
  instagram_post: {
    avg_views_nb: '',
  },
  instagram_story: {
    avg_views_nb: '',
    avg_link_clicks_nb: 0,
  },
  snapchat: {
    username: '',
  },
  youtube: {
    username: '',
    page_url: '',
  },
  tiktok: {
    username: '',
  },
  pinterest: {
    username: '',
  },
  pinterest_post: {
    avg_views_nb: '',
  },
  snapchat_post: {
    avg_views_nb: '',
  },
  tiktok_post: {
    avg_views_nb: '',
  },
  youtube_post: {
    avg_views_nb: '',
  },
};

const brandsInfo: FormProps.FieldArraySection<
  InfluencerTypes.ItemCreateParams,
  InfluencerTypes.BrandInfoCreateParams
> = {
  key: 'brand_infos',
  label: 'Brands information',
  itemLabel: 'Brand info',
  appendValue: brandInitialModel,
  itemFields: [
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.brand`,
      label: 'Brand name',
      options: BRANDS.OPTIONS,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.status`,
      label: 'Status',
      options: BRANDS.STATUS_OPTIONS,
    },
    {
      inputType: 'autocompleteSearch',
      name: (index: number) => `brand_infos.${index}.lead`,
      label: 'Lead',
      queryFn: users.autocompleteSearch,
      queryKey: users.endpoints.autocompleteSearch,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.country_team`,
      label: 'Country team',
      options: COUNTRIES.OPTIONS,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.timeframe`,
      label: 'Timeframe',
      options: BRANDS.TIMEFRAME_OPTIONS,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.relations_quality`,
      label: 'Relations quality',
      options: BRANDS.RELATIONSHIP_QUALITY_OPTIONS,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.sales_potential`,
      label: 'Sales potential',
      options: BRANDS.SALES_POTENTIAL_OPTIONS,
    },
    {
      inputType: 'select',
      name: (index: number) => `brand_infos.${index}.business_focuses`,
      label: 'Business focuses',
      options: BRANDS.BUSINESS_FOCUSES_OPTIONS,
      SelectProps: {
        multiple: true,
      },
    },
    {
      inputType: 'textarea',
      name: (index: number) => `brand_infos.${index}.comments`,
      label: 'Comments',
      rows: 4,
      multiline: true,
      GridProps: {
        xs: 12,
      },
    },
  ],
};

const eventsInfo: FormProps.FieldArraySection<
  InfluencerTypes.ItemCreateParams,
  InfluencerTypes.ImportantDateCreateParam
> = {
  key: 'important_dates',
  label: 'Dates & occasions',
  itemLabel: 'Event info',
  appendValue: eventInitialModel,
  itemFields: [
    {
      inputType: 'select',
      name: (index: number) => `important_dates.${index}.label`,
      label: 'Date occasion',
      options: INFLUENCERS.EVENT_OPTIONS,
    },
    {
      inputType: 'datepicker',
      name: (index: number) => `important_dates.${index}.date`,
      label: 'Date',
    },
    {
      inputType: 'datepicker',
      name: (index: number) => `important_dates.${index}.reminder_date`,
      label: 'Reminder date',
    },
  ],
};

const dataToInitialModel = (
  data: InfluencerTypes.ItemData,
): DeepPartial<InfluencerTypes.ItemCreateParams> => ({
  country_for_brand_mentions: data.country_for_brand_mentions,
  enable_brand_mentions: data.enable_brand_mentions,
  audience_info: data.audience_info,
  brand_infos: (data.brand_infos || []).map((b) => ({
    brand: b.brand,
    business_focuses: b.business_focuses,
    comments: b.comments,
    lead: b.lead,
    country_team: b.country_team || CountriesEnum.DE,
    relations_quality: b.relations_quality,
    sales_potential: b.sales_potential,
    status: b.status,
    timeframe: b.timeframe,
  })),
  important_dates: data.important_dates,
  vertical_primary: data.vertical_primary,
  verticals_secondary: data.verticals_secondary,
  tags: data.tags,
  target_countries: data.target_countries,
  notes: data.notes,
  agency_contact_info: Object.keys(initialModel.agency_contact_info).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: data.agency_contact_info
        ? // @ts-ignore
          data.agency_contact_info[currentValue]
        : undefined,
    }),
    {},
  ),
  personal_info:
    Object.keys(initialModel.personal_info).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: data.personal_info
          ? // @ts-ignore
            data.personal_info[currentValue]
          : undefined,
      }),
      {},
    ) || initialModel.personal_info,
  instagram: {
    username: data.instagram?.username,
  },
  instagram_post: {
    avg_views_nb: data.instagram_post?.avg_views_nb,
  },
  instagram_story: {
    avg_views_nb: data.instagram_story?.avg_views_nb,
    avg_link_clicks_nb: data.instagram_story?.avg_link_clicks_nb,
  },
  snapchat: {
    username: data.snapchat?.username,
  },
  snapchat_post: {
    avg_views_nb: data.snapchat_post?.avg_views_nb,
  },
  tiktok: {
    username: data.tiktok?.username,
  },
  tiktok_post: {
    avg_views_nb: data.tiktok_post?.avg_views_nb,
  },
  youtube: {
    username: data.youtube?.username,
    page_url: data.youtube?.page_url,
  },
  youtube_post: {
    avg_views_nb: data.youtube_post?.avg_views_nb,
  },
  pinterest: {
    username: data.pinterest?.username,
  },
  pinterest_post: {
    avg_views_nb: data.pinterest_post?.avg_views_nb,
  },
});

export {
  brandsInfo,
  eventsInfo,
  eventInitialModel,
  brandInitialModel,
  initialModel,
  dataToInitialModel,
};
