import { Dispatch } from 'react';
import { SnackbarNotificationData } from 'types/common';
import { InfluencerTypes, UserTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import { PaletteMode } from '@mui/material';
import { CountriesEnum } from '../api/types/Countries';
import { BrandsEnum } from '../api/types/Brands';

export interface StoreStateData {
  isSideNavOpen: boolean;
  user: UserTypes.ItemData | undefined;
  snackbarNotification?: SnackbarNotificationData;
  hideSiteHeader?: boolean;
  activeSocialPlatform: InfluencerTypes.PlatformEnum | undefined;
  activeCountry: CountriesEnum | undefined;
  activeBrand: BrandsEnum | undefined;
  tableItemsCount?: number;
  refetchTableData?: () => Promise<QueryObserverResult>;
  appearance: PaletteMode | undefined;
}

export enum StoreActionEnums {
  TOGGLE_IS_SIDENAV_OPEN = 'TOGGLE_IS_SIDENAV_OPEN',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_SNACKBAR_NOTIFICATION = 'SET_SNACKBAR_NOTIFICATION',
  SET_STORE_STATE = 'SET_STORE_STATE',
}

interface ToggleIsSideNavOpen {
  type: StoreActionEnums.TOGGLE_IS_SIDENAV_OPEN;
}

interface SetUserData {
  type: StoreActionEnums.SET_USER_DATA;
  payload: UserTypes.ItemData | undefined;
}

interface SetSnackbarNotification {
  type: StoreActionEnums.SET_SNACKBAR_NOTIFICATION;
  payload: SnackbarNotificationData | undefined;
}

interface SetStoreState {
  type: StoreActionEnums.SET_STORE_STATE;
  payload: Partial<StoreStateData>;
}

export type StoreActions =
  | ToggleIsSideNavOpen
  | SetUserData
  | SetSnackbarNotification
  | SetStoreState;

export interface StoreContext {
  state: StoreStateData;
  dispatch: Dispatch<StoreActions>;
}
