import React from 'react';
import {
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
} from 'react-hook-form';
import { Box, Button, Stack, Typography } from '@mui/material';
import BlockItem from './BlockItem';
import FormProps from '../FormProps';

type Props<T extends FieldValues = any, A = any> = UseFieldArrayProps<T> & {
  itemFields: FormProps.FormItemMulti<T>[];
  appendValue: A;
  title: string;
  itemTitle: string;
};

const FieldArrayBlock: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    itemFields,
    name,
    keyName,
    shouldUnregister,
    rules,
    control,
    appendValue,
    title,
    itemTitle,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name,
    keyName,
    shouldUnregister,
    rules,
  });

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Button onClick={() => append(appendValue)}>Add</Button>
      </Stack>
      {fields.map((field, index) => (
        <BlockItem
          key={field.id}
          itemFields={itemFields}
          title={itemTitle}
          index={index}
          onRemove={() => remove(index)}
          control={control}
        />
      ))}
    </Stack>
  );
};

export default FieldArrayBlock;
