import { useCallback } from 'react';
import { ToggleButton, Tooltip } from '@mui/material';
import { VerticalSplitOutlined } from '@mui/icons-material';
import { useDataTableContext } from '../context';
import { toggleSplitView } from '../context/actions';
import { useFiltersSearchParams } from '../../../hooks';

const SplitViewToggleButton = (): JSX.Element | null => {
  const {
    state: { isSplitViewEnabled },
    dispatch,
  } = useDataTableContext();
  const {
    filtersSearchParams: { splitItemId },
    handleFilterChange,
  } = useFiltersSearchParams();

  const handleChange = useCallback(() => {
    dispatch(toggleSplitView());

    if (splitItemId) {
      handleFilterChange('splitItemId', undefined);
    }
  }, [dispatch, splitItemId, handleFilterChange]);

  return (
    <Tooltip title={`${isSplitViewEnabled ? 'Disable' : 'Enable'} split view`}>
      <ToggleButton
        value="check"
        color="primary"
        size="small"
        selected={isSplitViewEnabled}
        onChange={handleChange}
      >
        <VerticalSplitOutlined />
      </ToggleButton>
    </Tooltip>
  );
};

export default SplitViewToggleButton;
