import { Alert } from '@mui/material';
import React from 'react';

const DeletedAlert = () => (
  <Alert severity="error" variant="filled" sx={{ mb: 3, py: 2 }}>
    This deal has been deleted.
  </Alert>
);

export default DeletedAlert;
