import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY HH:mm',
    LLLL: 'dddd, MMMM D, YYYY HH:mm',
    l: 'D.M.YYYY',
    ll: 'MMM D, YYYY',
    lll: 'MMM D, YYYY HH:mm',
    llll: 'ddd, MMM D, YYYY HH:mm',
  },
});

interface Props {
  children: JSX.Element;
}

const Localization = (props: Props): JSX.Element | null => {
  const { children } = props;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={dayjs.utc}
    >
      {children}
    </LocalizationProvider>
  );
};

export default Localization;
