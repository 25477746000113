import { NegotiationTypes, DealTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';

const initialModel: NegotiationTypes.ItemCreateParams = {
  status: NegotiationTypes.StatusEnum.pending,
  brand: BrandsEnum.avaAndMay,
  strategy: DealTypes.StrategiesEnum.influencers,
  channel: DealTypes.ChannelInfluencerEnum.InstagramStories,
  lead: '',
  country_team: CountriesEnum.DE,
  influencer_id: '',
  shop_id: '',
};

const dataToInitialModel = (
  data: NegotiationTypes.ItemData,
): NegotiationTypes.ItemCreateParams => ({
  shop_id: data.shop_id ? data.shop_id.$oid : '',
  status: data.status,
  brand: data.brand,
  strategy: data.strategy,
  channel: data.channel,
  lead: data.lead,
  country_team: data.country_team,
  influencer_id: data.influencer_id.$oid,
});

export { dataToInitialModel, initialModel };
