import { ChangeEvent, useCallback, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { FileUploadRounded } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { deals } from 'api';
import { useApiRequest } from 'hooks';

const acceptedFiles = 'application/pdf, image/*';

interface Props {
  id: string;
  onUploaded: () => Promise<QueryObserverResult>;
}

const PreviewUpload = (props: Props): JSX.Element => {
  const { id, onUploaded } = props;

  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const { requestFn: uploadFn, isLoading: isUploading } = useApiRequest(
    (data) =>
      deals.uploadPostAttachment(id, data, (progressEvent) => {
        setUploadProgress(
          Math.round(progressEvent.percent / progressEvent.totalBytes),
        );
      }),
  );

  const handleUpload = useCallback(
    async (file: File) => {
      await uploadFn({
        args: file,
        successMessage: 'Image uploaded',
      });

      await onUploaded();
      setInputValue('');
      setUploadProgress(0);
    },
    [onUploaded, uploadFn],
  );

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      await handleUpload(file);
    }
  };

  return (
    <Box>
      <Button
        color="primary"
        component="label"
        htmlFor="file-upload"
        disabled={isUploading}
        endIcon={
          isUploading ? (
            <CircularProgress
              color="inherit"
              size={20}
              {...(uploadProgress !== 0 && {
                variant: 'determinate',
                value: uploadProgress,
              })}
            />
          ) : (
            <FileUploadRounded />
          )
        }
      >
        Upload preview
      </Button>
      <Box
        component="input"
        type="file"
        disabled={isUploading}
        sx={{
          visibility: 'hidden',
          width: 0,
          height: 0,
        }}
        onChange={handleFileSelect}
        id="file-upload"
        name="file-upload"
        value={inputValue}
        accept={acceptedFiles}
      />
    </Box>
  );
};

export default PreviewUpload;
