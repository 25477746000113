import { ChangeEvent, useCallback, useMemo } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { TABLE_PER_PAGE_OPTIONS } from 'constants/common';
import { useFiltersSearchParams } from 'hooks';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import { useDataTableContext } from './context';
import { setDataTableState, setPerPage } from './context/actions';

interface Props {
  isLoading: boolean;
  itemsCount: number;
  inline?: boolean;
  inTable?: boolean;
  defaultPerPage?: number;
}

const TableViewFooter = (props: Props): JSX.Element => {
  const { itemsCount, isLoading, inline, inTable, defaultPerPage } = props;
  const {
    state: { pageNumber, perPage },
    dispatch,
  } = useDataTableContext();

  const { updateFiltersSearchParams, handleFilterChange } =
    useFiltersSearchParams();

  const handlePageChange = useCallback(
    (event: unknown, pageNumber: number) => {
      dispatch(setDataTableState({ pageNumber }));

      if (!inline) {
        handleFilterChange('page', pageNumber);
      }
    },
    [dispatch, handleFilterChange, inline],
  );

  const handlePerPageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const count = +event.target.value;
      dispatch(setPerPage(count));

      if (!inline) {
        updateFiltersSearchParams({
          limit: count,
          page: 1,
        });
        LocalStorage.set(LocalStorageEnum.TABLE_PER_PAGE, count.toString());
      }
    },
    [dispatch, updateFiltersSearchParams, inline],
  );

  const info = useMemo(
    () => ({
      from: itemsCount === 0 ? 0 : perPage * (pageNumber - 1) + 1,
      to: perPage * pageNumber > itemsCount ? itemsCount : perPage * pageNumber,
      of: itemsCount,
    }),
    [itemsCount, pageNumber, perPage],
  );

  if (isLoading) {
    return (
      <Box
        id="table-pagination"
        sx={{ display: 'flex', justifyContent: 'flex-end', p: 1.5, pb: 0 }}
      >
        <Skeleton variant="text" sx={{ width: '50%', height: '50px' }} />
      </Box>
    );
  }

  return (
    <Box
      id="table-pagination"
      sx={(theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark' && !inline
            ? theme.palette.background.default
            : theme.palette.background.paper,
        borderTop: inTable ? `1px solid ${theme.palette.divider}` : undefined,
        borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
        borderBottomRightRadius: `${theme.shape.borderRadius}px`,
        borderRadius: inTable ? undefined : `${theme.shape.borderRadius}px`,
        zIndex: 1,
        p: 1.5,
      })}
    >
      <Grid container columnSpacing={2} wrap="nowrap" alignItems="center">
        <Grid item flexGrow={1}>
          <Stack direction="row" columnGap={2} alignItems="center">
            <TextField
              size="small"
              select
              fullWidth={false}
              value={perPage}
              onChange={handlePerPageChange}
              disabled={!!defaultPerPage}
            >
              {TABLE_PER_PAGE_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              {defaultPerPage && (
                <MenuItem value={defaultPerPage}>{defaultPerPage}</MenuItem>
              )}
            </TextField>
            <Typography
              variant="caption"
              color="text.secondary"
            >{`Showing ${info.from} - ${info.to} of ${info.of}`}</Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Pagination
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            siblingCount={0}
            boundaryCount={1}
            count={Math.ceil(itemsCount / perPage)}
            page={pageNumber}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableViewFooter;
