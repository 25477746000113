import React, { useMemo } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CloseOutlined, EuroOutlined } from '@mui/icons-material';
import { NegotiationTypes } from 'api';
import { getUserInitials } from 'helpers/common';
import { FormInput } from 'components';
import { COMPANY_NAME } from 'constants/common';
import { getCPM } from 'helpers/calculations';

interface Props {
  index: number;
  isLastItem: boolean;
  control: Control<NegotiationTypes.ItemLogs> | undefined;
  watch: UseFormWatch<NegotiationTypes.ItemLogs>;
  negotiation: NegotiationTypes.ItemData;
  onRemove: () => void;
  onAppend: () => void;
  onSubmit: (reason?: NegotiationTypes.LogItem['decision']) => void;
  onMouseLeave?: () => void;
}

const OfferForm = (props: Props): JSX.Element | null => {
  const {
    index,
    control,
    onRemove,
    watch,
    negotiation,
    onAppend,
    onSubmit,
    isLastItem,
    onMouseLeave,
  } = props;

  const source = watch(`logs.${index}.source`);
  const decision = watch(`logs.${index}.decision`);
  const posts = watch(`logs.${index}.posts_nb`);
  const budget = watch(`logs.${index}.total_budget`);

  const title = useMemo(() => {
    if (decision && isLastItem) {
      return 'Final offer';
    }
    if (index === 0) {
      return 'First offer';
    }

    return 'Counter offer';
  }, [decision, index, isLastItem]);

  const cpmPerPost = useMemo(() => {
    const avgPostBudget = posts > 0 ? budget / posts : undefined;

    return getCPM({
      budget: avgPostBudget,
      reach: negotiation.channel_reach_at_creation,
    });
  }, [budget, negotiation.channel_reach_at_creation, posts]);

  const sourceOptions = [
    {
      label: COMPANY_NAME,
      value: COMPANY_NAME,
    },
    {
      label: negotiation.influencer_profile_name,
      value: negotiation.influencer_profile_name,
    },
  ];

  return (
    <Stack
      direction="row"
      columnGap={2}
      alignItems="center"
      onMouseLeave={onMouseLeave}
    >
      <Divider orientation="vertical" flexItem>
        <Avatar
          {...(decision && {
            sx: {
              backgroundColor: (theme) =>
                theme.palette[decision === 'Approved' ? 'success' : 'error']
                  .main,
            },
          })}
        >
          {getUserInitials(source)}
        </Avatar>
      </Divider>
      <Card variant="outlined" sx={{ width: '100%', mt: index === 0 ? 0 : 3 }}>
        <CardHeader
          title={
            <Typography variant="h5">
              {title}
              {decision && (
                <Typography
                  variant="inherit"
                  component="span"
                  sx={{
                    ml: 1,
                    color: (theme) =>
                      theme.palette[
                        decision === 'Approved' ? 'success' : 'error'
                      ].main,
                  }}
                >
                  {decision}
                </Typography>
              )}
            </Typography>
          }
          action={
            isLastItem ? (
              <IconButton onClick={onRemove}>
                <CloseOutlined />
              </IconButton>
            ) : undefined
          }
        />
        <CardContent>
          <Stack direction="row" columnGap={2}>
            <FormInput
              control={control}
              name={`logs.${index}.source`}
              inputType="select"
              options={sourceOptions}
              label="Source"
              rules={{ required: true }}
              disabled={!isLastItem}
            />
            <FormInput
              control={control}
              name={`logs.${index}.posts_nb`}
              inputType="number"
              label="Posts count"
              rules={{ required: true, min: 0 }}
              disabled={!isLastItem}
            />
            <FormInput
              control={control}
              name={`logs.${index}.total_budget`}
              inputType="number"
              label="Budget"
              rules={{ required: true, min: 0 }}
              disabled={!isLastItem}
            />
            <TextField
              label="CPM per post"
              disabled
              value={cpmPerPost}
              InputProps={{
                notched: false,
                endAdornment: <EuroOutlined />,
              }}
            />
          </Stack>
        </CardContent>
        {isLastItem && (
          <CardActions>
            {!decision ? (
              <>
                <Button
                  variant="outlined"
                  onClick={onAppend}
                  sx={{ mr: 'auto' }}
                >
                  Propose a counter offer
                </Button>
                <Button onClick={() => onSubmit('Approved')}>Accept</Button>
                <Button color="error" onClick={() => onSubmit('Declined')}>
                  Decline
                </Button>
              </>
            ) : (
              <Button onClick={() => onSubmit()}>Undo decision</Button>
            )}
          </CardActions>
        )}
      </Card>
    </Stack>
  );
};

export default OfferForm;
