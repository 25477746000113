import React, { useCallback, useMemo, useState } from 'react';
import {
  TextField,
  debounce,
  List,
  ListItem,
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  ListItemText,
  Checkbox,
  Stack,
  Chip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import {
  AutocompleteSearchItem,
  AutocompleteSearchResponse,
} from 'api/types/common';
import Loader from 'components/Loader';
import { HandleChangeValue } from 'hooks';
import { SelectOption, SelectOptions } from '../../../types/common';
import { getCampaignStatusColor } from '../../../helpers/getStatusColor';

type Value = AutocompleteSearchItem[] | undefined;

// todo - break into components

interface AutocompleteProps {
  label: string;
  value: Value;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;

  queryKey: string;
  queryFn: (query: string, option?: SelectOption) => AutocompleteSearchResponse;
  defaultOption?: SelectOption;
  options?: SelectOptions;
}

const AutocompleteFilter = (props: Omit<AutocompleteProps, 'inline'>) => {
  const { queryFn, queryKey, label, value, onChange, options, defaultOption } =
    props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(defaultOption);

  const { data, isLoading } = useQuery(
    [queryKey, inputValue, selectedOption],
    () => queryFn(inputValue, selectedOption),
    { enabled: open, initialData: [] },
  );

  const handleValueChange = useCallback(
    (event: unknown, newValue: AutocompleteSearchItem[] | undefined) => {
      onChange(newValue?.length === 0 ? undefined : newValue);
    },
    [onChange],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
    },
    500,
  );

  return (
    <Stack>
      {options && (
        <Stack direction="row" spacing={1} pb={1}>
          {options.map((option) => (
            <Chip
              sx={{ flex: '1 0 auto' }}
              size="small"
              onClick={() =>
                setSelectedOption(
                  option.value === selectedOption?.value ? undefined : option,
                )
              }
              variant={
                option.value === selectedOption?.value ? 'filled' : 'outlined'
              }
              color={getCampaignStatusColor(option.value as any)}
              label={option.label}
            />
          ))}
        </Stack>
      )}
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple
        clearOnEscape
        disableCloseOnSelect
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value || []}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(
          option: AutocompleteSearchItem,
          value: AutocompleteSearchItem,
        ) => option._id.$oid === value._id.$oid}
        getOptionLabel={(option: AutocompleteSearchItem) => option.name}
        renderOption={(props1, option) => (
          <ListItem {...props1}>
            <ListItemText
              primary={option.name}
              secondary={'status' in option ? (option.status as string) : ''}
            />
          </ListItem>
        )}
        options={uniqBy(value ? [...value, ...data] : data, 'name') || []}
        loading={isLoading}
        ChipProps={{
          color: 'primary',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={`Search ${label}`}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              notched: false,
              endAdornment: (
                <>
                  {isLoading && open ? (
                    <Loader circularProgressProps={{ size: 20 }} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

const InlineFilter = (props: Omit<AutocompleteProps, 'inline'>) => {
  const {
    queryFn,
    queryKey,
    value,
    onChange,
    label,
    options: filterOptions,
    defaultOption,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    SelectOption | undefined
  >(defaultOption);

  const { data, isLoading } = useQuery(
    [queryKey, inputValue, selectedFilterOption],
    () => queryFn(inputValue, selectedFilterOption),
    { initialData: [] },
  );

  const handleValueChange = useCallback(
    (item: AutocompleteSearchItem) => {
      onChange((prev: AutocompleteSearchItem[] = []) => {
        const value =
          prev?.map((f) => f._id.$oid).indexOf(item._id.$oid) > -1
            ? prev.filter((p) => p._id.$oid !== item._id.$oid)
            : [...prev, item];

        return value.length === 0 ? undefined : value;
      });
    },
    [onChange],
  );

  const handleInputChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
    },
    500,
  );

  const options = useMemo(
    () => uniqBy(value ? [...value, ...data] : data, 'name'),
    [data, value],
  );

  return (
    <Box sx={{ p: 2, pt: 0 }}>
      {filterOptions && (
        <Stack
          direction="row"
          spacing={1}
          mt={2}
          sx={{
            position: 'sticky',
            top: 0,
          }}
        >
          {filterOptions.map((option) => (
            <Chip
              sx={{ flex: '1 0 auto' }}
              size="small"
              onClick={() =>
                setSelectedFilterOption(
                  option.value === selectedFilterOption?.value
                    ? undefined
                    : option,
                )
              }
              variant={
                option.value === selectedFilterOption?.value
                  ? 'filled'
                  : 'outlined'
              }
              color={getCampaignStatusColor(option.value as any)}
              label={option.label}
            />
          ))}
        </Stack>
      )}
      <TextField
        autoFocus
        size="small"
        placeholder={`Search ${label}`}
        variant="standard"
        onChange={handleInputChange}
        InputProps={{
          endAdornment: isLoading ? (
            <Loader circularProgressProps={{ size: 20 }} />
          ) : null,
        }}
        sx={(theme) => ({
          mt: filterOptions ? 1 : 2,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
        })}
      />
      <List>
        {options.map((item, index) => (
          <ListItem
            disableGutters
            disablePadding
            key={item._id?.$oid || index}
            onClick={() => handleValueChange(item)}
            sx={{
              ml: '-9px',
              mr: '9px',
            }}
          >
            <Checkbox
              checked={
                value
                  ? value?.map((f) => f._id?.$oid).indexOf(item._id?.$oid) > -1
                  : false
              }
            />
            <ListItemText
              primary={item.name}
              secondary={'status' in item ? (item.status as string) : ''}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const UsersSearchFilter = (props: AutocompleteProps): JSX.Element => {
  const { inline, ...rest } = props;

  if (inline) {
    return <InlineFilter {...rest} />;
  }

  return <AutocompleteFilter {...rest} />;
};

export default UsersSearchFilter;
