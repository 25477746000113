import { PeriodKeys } from 'helpers/dayJsFormats';
import dayjs from 'dayjs';
import { SelectOptions } from '../types/common';

const DATE_OPTIONS: { key: PeriodKeys; label: string }[] = [
  { key: 'yesterday', label: 'Yesterday' },
  { key: 'today', label: 'Today' },
  { key: 'tomorrow', label: 'Tomorrow' },
  { key: 'last_week', label: 'Last week' },
  { key: 'next_week', label: 'Next week' },
  { key: 'last_month', label: 'Last month' },
  { key: 'this_month', label: 'This month' },
  { key: 'next_month', label: 'Next month' },
  { key: 'next_30_days', label: 'Next 30 days' },
  { key: 'last_year', label: 'Last year' },
  { key: 'year_to_date', label: 'Year to date' },
  { key: 'quarter_to_date', label: 'Quarter to date' },
  { key: 'last_quarter', label: 'Last quarter' },
  { key: 'this_quarter', label: 'This quarter' },
  { key: 'next_quarter', label: 'Next quarter' },
];

const CALENDAR_MONTHS = Array.from(new Array(12)).map((_, i) => ({
  monthNumber: i,
  monthName: dayjs().month(i).format('MMMM'),
  monthShortname: dayjs().month(i).format('MMM'),
}));

// todo - automate calendar options
const SPECIAL_DAY_OPTIONS = [
  { day: 11, month: 11, label: 'Singles Day' },
  { day: 16, month: 11, label: 'AM Birthday' },
  { day: 17, month: 11, label: 'AM Birthday' },
  { day: 20, month: 11, label: 'Black Week' },
  { day: 24, month: 11, label: 'Black Friday' },
  { day: 27, month: 11, label: 'Cyber Monday' },
  { day: 6, month: 12, label: 'Saint Nicholas Day' },
  { day: 8, month: 12, label: 'Immacolata' },
  { day: 13, month: 12, label: 'Santa Lucia' },
  { day: 25, month: 12, label: 'Christmas' },
  { day: 31, month: 12, label: 'New Year’s Eve ' },
];

const DAY_OPTIONS: SelectOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map((day) => ({ value: day, label: day }));

export { DATE_OPTIONS, CALENDAR_MONTHS, SPECIAL_DAY_OPTIONS, DAY_OPTIONS };
