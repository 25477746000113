import React, { forwardRef } from 'react';
import { Box, Divider, Grid, lighten, Stack, Typography } from '@mui/material';
import { InfluencerChartOption } from 'types/influencers';

interface Props {
  value: InfluencerChartOption['type'];
  onChange: (type: InfluencerChartOption['type']) => void;
  options: InfluencerChartOption[];
  children: React.ReactNode;
}

const ChartTabs = forwardRef((props: Props, ref): JSX.Element | null => {
  const { value, onChange, children, options } = props;

  return (
    <Box ref={ref}>
      <Grid container wrap="nowrap">
        <Grid item xs={4} md={3} lg={2}>
          <Stack divider={<Divider />}>
            {options.map((o) => (
              <Box
                key={o.type}
                py={2}
                px={2.5}
                onClick={() => onChange(o.type)}
                sx={(theme) => ({
                  ...(value === o.type
                    ? {
                        backgroundColor: theme.palette.background.paper,
                        height: '100%',
                      }
                    : {
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? lighten(theme.palette.background.paper, 0.05)
                            : theme.palette.grey['100'],
                      }),
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                })}
              >
                <Typography variant="h5" gutterBottom>
                  {o.value}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={value === o.type ? 'primary' : 'text.secondary'}
                >
                  {o.label}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={8} md={9} lg={10}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
});

export default ChartTabs;
