import React from 'react';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { formatPercent } from 'helpers/formatters';

interface Props {
  percentage: number | undefined;
}

const PercentText = (props: Props): JSX.Element | null => {
  const { percentage = 0 } = props;

  return (
    <Typography
      variant="inherit"
      component="span"
      sx={(theme) => ({
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.25,
        ml: 1,
        color:
          percentage > 0
            ? theme.palette.success.main
            : theme.palette.warning.main,
      })}
    >
      {formatPercent(percentage)}
      {percentage > 0 ? (
        <ArrowUpwardRounded fontSize="inherit" />
      ) : (
        <ArrowDownwardRounded fontSize="inherit" />
      )}
    </Typography>
  );
};

export default PercentText;
