import React, { useMemo } from 'react';
import { Chip, ChipProps, Skeleton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { campaigns, DealTypes } from 'api';
import { appLinks } from 'routes/links';

interface Props {
  deal: DealTypes.ItemData;
}

type ProductsInfoItems = {
  key: keyof DealTypes.ItemData;
  label: string;
  value: string[] | undefined;
  color: ChipProps['color'];
}[];

const ProductsInfo = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const campaignIds = useMemo(
    () => (deal.campaign_ids || []).map((c) => c.$oid),
    [deal.campaign_ids],
  );

  const { data: campaignsData, error: campaignsError } = useQuery(
    [campaigns.endpoints.getList, campaignIds],
    () =>
      campaigns.getList({ filters: { _id: campaignIds } }).then((res) =>
        res.data.map((r) => ({
          _id: r._id,
          name: r.name,
          campaignId: r.campaign_id,
        })),
      ),
    {
      enabled: campaignIds.length > 0,
      refetchOnWindowFocus: false,
    },
  );

  const campaignsNode = useMemo(() => {
    if (
      campaignIds.length === 0 ||
      (campaignsData && campaignsData.length === 0)
    ) {
      return <Typography color="text.secondary">No data</Typography>;
    }

    if (campaignsData) {
      return (
        <Stack direction="row" gap={1} flexWrap="wrap">
          {campaignsData.map((c) => (
            <Chip
              key={c.campaignId}
              label={c.name}
              color="info"
              variant="outlined"
              component="a"
              target="_blank"
              href={appLinks.campaigns.details.as(c.campaignId)}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          ))}
        </Stack>
      );
    }

    if (campaignsError) {
      return (
        <Typography color="text.secondary">
          Failed to load campaigns data
        </Typography>
      );
    }

    return (
      <Stack direction="row" gap={1} flexWrap="wrap">
        <Skeleton variant="text" width="70%" height="20px" />
        <Skeleton variant="text" width="70%" height="20px" />
        <Skeleton variant="text" width="70%" height="20px" />
      </Stack>
    );
  }, [campaignIds.length, campaignsData, campaignsError]);

  const products: ProductsInfoItems = useMemo(
    () => [
      {
        key: 'product_categories',
        label: 'Product categories',
        color: 'success',
        value: deal.product_categories,
      },
      {
        key: 'products',
        label: 'Products',
        color: 'warning',
        value: deal.products,
      },
      {
        key: 'free_gift_product_categories',
        label: 'Free product categories',
        color: 'primary',
        value: deal.free_gift_product_categories,
      },
      {
        key: 'free_gift_products',
        label: 'Free products',
        color: 'secondary',
        value: deal.free_gift_products,
      },
    ],
    [deal],
  );

  return (
    <Stack rowGap={1} mt={1}>
      <Stack gap={1}>
        <Typography variant="body2" flexGrow={1}>
          Campaigns
        </Typography>
        {campaignsNode}
      </Stack>
      {products.map((p) => (
        <Stack gap={1} key={p.key}>
          <Typography variant="body2" flexGrow={1}>
            {p.label}
          </Typography>
          {p.value && p.value.length > 0 ? (
            <Stack direction="row" gap={1} flexWrap="wrap">
              {p.value.map((c) => (
                <Chip
                  key={c + p.key}
                  label={c}
                  color={p.color}
                  variant="outlined"
                />
              ))}
            </Stack>
          ) : (
            <Typography color="text.secondary">No data</Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default ProductsInfo;
