import INFLUENCERS from 'constants/influencers';
import { InfluencerTypes } from 'api';

const getSocialNetworkLink = (
  code: InfluencerTypes.PlatformEnum,
  data: InfluencerTypes.ItemData,
) => {
  try {
    const fieldName = code.toLowerCase() as InfluencerTypes.PlatformType;
    const socialNetwork = data[fieldName];
    const getLinkUrl = INFLUENCERS.SOCIAL_NETWORKS_LINKS[code];

    if (code === InfluencerTypes.PlatformEnum.Youtube) {
      return socialNetwork?.page_url ? getLinkUrl(socialNetwork?.page_url) : '';
    }
    return socialNetwork?.username ? getLinkUrl(socialNetwork?.username) : '';
  } catch (e) {
    return '';
  }
};

export default getSocialNetworkLink;
