import { useState, useMemo, MouseEvent } from 'react';
import { alpha, Box, Button, Popover, Stack } from '@mui/material';
import { Close, ArrowDropDown } from '@mui/icons-material';
import { MenuOptions } from 'types/common';
import { createElementId } from 'helpers/common';

interface Props {
  children: JSX.Element;
  onClear: () => void;
  onClose: () => void;
  onApply: () => void;
  label: string;
  value: boolean | string | string[] | undefined;
  labelFormatter?: (value: any) => string;
  options?: MenuOptions;
  disableClearable?: boolean;
}

const PopoverFilter = (props: Props): JSX.Element | null => {
  const {
    value,
    label,
    options = [],
    children,
    onClear,
    onClose,
    labelFormatter,
    disableClearable,
    onApply,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleApply = () => {
    setAnchorEl(null);
    onApply();
  };

  const buttonLabel = useMemo(() => {
    if (value && labelFormatter) {
      return labelFormatter(value);
    }

    if (typeof value === 'boolean' && value) {
      return label;
    }

    if (!value || value.length === 0) return label;

    if (typeof value === 'string') {
      if (options && options.length > 0) {
        return options.find((o) => o.value === value)?.label;
      }
      return value;
    }

    const firstSelectedOption = options.find((o) => o.value === value[0]);

    if (firstSelectedOption) {
      if (value.length > 1) {
        return `${firstSelectedOption.label} +${value.length - 1}`;
      }
      return firstSelectedOption.label;
    }
    return label;
  }, [value, labelFormatter, label, options]);

  const open = Boolean(anchorEl);
  const id = open ? createElementId(label) : undefined;

  return (
    <>
      <Button
        // variant="outlined"
        // color={value !== undefined ? 'primary' : 'inherit'}
        // sx={
        //   value !== undefined
        //     ? (theme) => ({
        //         // color: theme.palette.primary.main,
        //         background: alpha(theme.palette.primary.main, 0.1),
        //         '&:hover': {
        //           // color: theme.palette.primary.main,
        //           background: alpha(theme.palette.primary.main, 0.3),
        //         },
        //       })
        //     : undefined
        // }
        onClick={handleClick}
        variant={value !== undefined ? 'contained' : 'outlined'}
        color={value !== undefined ? 'primary' : 'inherit'}
        sx={(theme) => ({
          whiteSpace: 'nowrap',
          ...(value !== undefined
            ? {
                boxShadow: 'unset',
                color: theme.palette.primary.main,
                background: alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  boxShadow: 'unset',
                  color: theme.palette.primary.main,
                  background: alpha(theme.palette.primary.main, 0.2),
                },
              }
            : {}),
        })}
        endIcon={
          value !== undefined && !disableClearable ? (
            <Close
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            />
          ) : (
            <ArrowDropDown />
          )
        }
      >
        {buttonLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleApply();
        }}
      >
        <Box
          sx={{
            maxHeight: '60vh',
            width: '30vw',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {children}
        </Box>
        <Stack
          direction="row"
          columnGap={1}
          justifyContent="flex-end"
          sx={{
            position: 'sticky',
            bottom: 0,
            px: 1.5,
            py: 1,
          }}
        >
          <Button size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" type="submit">
            Apply
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default PopoverFilter;
