import React, {
  Key,
  UIEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  debounce,
  Table,
  TableBody as MuiTableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useFiltersSearchParams } from 'hooks';
import { HEADER_HEIGHT } from 'theme/constants';
import { QueryObserverResult } from '@tanstack/react-query';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';
import { selectAllRows, setDataTableState } from '../context/actions';
import TableBodyItem from './TableBodyItem';
import TableBodyItemSkeleton from './TableBodyItemSkeleton';

interface Props {
  inline: boolean;
  data: DataTableProps.Item[];
  isLoading: boolean;
  tableBodyItems: DataTableProps.Columns;
  withBulkActions: boolean | undefined;
  withReportsBlock: boolean | undefined;
  refetch: () => Promise<QueryObserverResult>;
  isHeaderEmpty: boolean;
  idKey?: string;
}

const TableBody: React.FC<Props> = (props: Props) => {
  const {
    tableBodyItems,
    data,
    isLoading,
    withBulkActions,
    withReportsBlock,
    inline,
    refetch,
    isHeaderEmpty,
    idKey,
  } = props;

  const {
    state: { perPage, selectedItems, sortDesc, sortBy },
    dispatch,
  } = useDataTableContext();

  const $tableHead = useRef<HTMLTableSectionElement>(null);

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = sortBy === property ? !sortDesc : true;

      dispatch(
        setDataTableState({
          sortBy: property,
          sortDesc: isAsc,
        }),
      );

      if (!inline) {
        updateFiltersSearchParams({
          sortBy: property,
          sortDesc: isAsc,
        });
      }
    },
    [dispatch, sortBy, sortDesc, updateFiltersSearchParams, inline],
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(selectAllRows(data));
        return;
      }

      dispatch(selectAllRows([]));
    },
    [data, dispatch],
  );

  const dummyArray = useMemo(
    () => Array.from(new Array(perPage)).map((_, i) => i),
    [perPage],
  );

  const [tableHeight, setTableHeight] = useState('80vh');
  const [scrollPosition, setScrollPosition] = useState(-1);

  const handleScroll = debounce((e: UIEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (scrollPosition !== e.target.scrollLeft)
      // @ts-ignore
      setScrollPosition(e.target.scrollLeft);
  }, 100);

  useLayoutEffect(() => {
    const root = document.getElementById('data-table');

    const clb: ResizeObserverCallback = debounce(() => {
      const filtersHeight =
        document.getElementById('table-head')?.clientHeight || 0;

      const pagination =
        document.getElementById('table-pagination')?.clientHeight || 0;
      const reports =
        document.getElementById('table-reports-block')?.clientHeight || 0;

      const h1 =
        window.innerHeight - // window height
        HEADER_HEIGHT + // header height
        1 - // header border
        filtersHeight - // filters
        4 - // filters borders
        pagination - // pagination
        (withReportsBlock ? reports + 4 : 0); // reports block + scroll bar

      setTableHeight(`${h1}px`);
    }, 200);

    const observer = new ResizeObserver(clb);

    if (inline) {
      setTableHeight('400px');
    } else if (root) {
      observer.observe(root);
    }

    return () => {
      observer.disconnect();
    };
  }, [inline, isHeaderEmpty, withReportsBlock]);

  return (
    <TableContainer
      onScroll={handleScroll}
      sx={(theme) => ({
        overflowX: 'auto',
        height: inline ? undefined : tableHeight,
        maxHeight: inline ? tableHeight : undefined,
        borderTopRightRadius: isHeaderEmpty
          ? `${theme.shape.borderRadius}px`
          : undefined,
        borderTopLeftRadius: isHeaderEmpty
          ? `${theme.shape.borderRadius}px`
          : undefined,
      })}
    >
      <Table
        size={inline ? 'small' : 'medium'}
        stickyHeader
        sx={{ minWidth: 750 }}
      >
        <TableHead ref={$tableHead}>
          <TableRow>
            {withBulkActions && (
              <TableCell
                padding="checkbox"
                size={inline ? 'small' : 'medium'}
                sx={(theme) => ({
                  background:
                    theme.palette.mode === 'dark' && !inline
                      ? theme.palette.background.default
                      : theme.palette.background.paper,
                })}
              >
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < data.length
                  }
                  checked={
                    data.length > 0 && selectedItems.length === data.length
                  }
                  onChange={handleSelectAll}
                  disabled={isLoading}
                />
              </TableCell>
            )}
            {tableBodyItems
              .filter((t) => t.type !== 'rowLink')
              .map((headCell, index) =>
                headCell.isSortable ? (
                  <TableCell
                    key={headCell.key as Key}
                    align={headCell.align || 'left'}
                    sortDirection={
                      sortBy === headCell.key && sortDesc ? 'desc' : 'asc'
                    }
                    size={inline ? 'small' : 'medium'}
                    sx={(theme) => ({
                      color:
                        sortBy === headCell.key
                          ? theme.palette.primary.main
                          : undefined,
                      background:
                        theme.palette.mode === 'dark' && !inline
                          ? theme.palette.background.default
                          : theme.palette.background.paper,
                      position: index === 0 ? 'sticky' : undefined,
                      left: index === 0 ? 0 : undefined,
                      zIndex: index === 0 ? 10 : undefined,
                      borderRight:
                        index === 0
                          ? `1px solid ${
                              scrollPosition > 1
                                ? theme.palette.divider
                                : 'transparent'
                            }`
                          : undefined,
                    })}
                  >
                    <TableSortLabel
                      active={sortBy === headCell.key}
                      direction={
                        sortBy === headCell.key && sortDesc ? 'desc' : 'asc'
                      }
                      onClick={() => handleRequestSort(headCell.key as string)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    align={headCell.align || 'left'}
                    key={headCell.key as Key}
                    size={inline ? 'small' : 'medium'}
                    sx={
                      index !== 0 && headCell.type !== 'actions'
                        ? (theme) => ({
                            background:
                              theme.palette.mode === 'dark' && !inline
                                ? theme.palette.background.default
                                : theme.palette.background.paper,
                          })
                        : (theme) => ({
                            borderRight:
                              index === 0
                                ? `1px solid ${
                                    scrollPosition > 1
                                      ? theme.palette.divider
                                      : 'transparent'
                                  }`
                                : undefined,
                            borderLeft:
                              headCell.type === 'actions'
                                ? `1px solid ${theme.palette.divider}`
                                : undefined,
                            position: 'sticky',
                            zIndex: 10,
                            left: headCell.type === 'actions' ? undefined : 0,
                            right: headCell.type === 'actions' ? 0 : undefined,
                            background:
                              theme.palette.mode === 'dark' && !inline
                                ? theme.palette.background.default
                                : theme.palette.background.paper,
                          })
                    }
                  >
                    {headCell.label}
                  </TableCell>
                ),
              )}
          </TableRow>
        </TableHead>
        <MuiTableBody>
          {isLoading &&
            dummyArray.map((item) => (
              <TableBodyItemSkeleton
                key={item}
                inline={inline}
                tableBodyItems={tableBodyItems}
                withBulkActions={withBulkActions}
              />
            ))}
          {!isLoading &&
            data.length !== 0 &&
            data.map((item) => (
              <TableBodyItem
                key={idKey ? item[idKey] : item._id.$oid}
                inline={inline}
                item={item}
                tableBodyItems={tableBodyItems}
                refetch={refetch}
                scrollPosition={scrollPosition}
                withBulkActions={withBulkActions}
                idKey={idKey}
                withSplitView={!inline}
              />
            ))}
        </MuiTableBody>
      </Table>
      {!isLoading && data.length === 0 && (
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          sx={{ m: (theme) => theme.spacing(5) }}
        >
          No records found
        </Typography>
      )}
    </TableContainer>
  );
};

export default TableBody;
