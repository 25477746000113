import React, { useCallback, useMemo, useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Button, Grid } from '@mui/material';
import {
  DeleteOutlined,
  LaunchOutlined,
  LinkOutlined,
  Loyalty,
  LoyaltyOutlined,
  NewspaperOutlined,
} from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  ConfirmDialogControlled,
  CloseDetailsDrawer,
} from 'components';
import { NavLink, useNavigate } from 'react-router-dom';
import { getInfluencerName } from 'helpers/common';
import { appLinks } from 'routes/links';
import { influencers, InfluencerTypes } from 'api';
import { useApiRequest, useClipboard, useFiltersSearchParams } from 'hooks';
import BrandMentionsStatus from './BrandMentionsStatus';

interface Props {
  data: InfluencerTypes.ItemExtended;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Actions = (props: Props): JSX.Element => {
  const { data, refetch, inline } = props;
  const navigate = useNavigate();
  const { handleFilterChange } = useFiltersSearchParams();
  const handleCopy = useClipboard();

  const { isLoading, requestFn } = useApiRequest(() =>
    influencers.remove(data._id.$oid),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const [isBrandMentionsDialogOpen, setIsBrandMentionsDialogOpen] =
    useState(false);

  const handleOpenBrandMentionsDialog = () =>
    setIsBrandMentionsDialogOpen(true);
  const handleCloseBrandMentionsDialog = () =>
    setIsBrandMentionsDialogOpen(false);

  const handleDelete = useCallback(async () => {
    await requestFn({
      successMessage: `${getInfluencerName(data)} deleted`,
    });

    setIsDeleteDialogOpen(false);

    if (inline) {
      handleFilterChange('splitItemId', undefined);
    } else {
      navigate(-1);
    }
  }, [data, handleFilterChange, inline, navigate, requestFn]);

  const influencerName = useMemo(() => getInfluencerName(data), [data]);

  const actions: ActionButtonProps<InfluencerTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.influencers.details.as(item.influencer_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin +
              appLinks.influencers.details.as(item.influencer_id),
          ),
      },
      {
        name: `Edit ${influencerName}`,
        EditButtonProps: {
          inline,
          idKey: 'influencerId',
          id: (item) => item.influencer_id,
          link: appLinks.influencers.edit.as,
        },
      },
      {
        name: `Create negotiation`,
        Icon: NewspaperOutlined,
        to: (item) => appLinks.negotiations.create.as(item.influencer_id),
      },
      {
        name: `Delete ${influencerName}`,
        Icon: DeleteOutlined,
        onClick: handleOpenDialog,
        disabled: !data.can_delete,
      },
      {
        name: `Bookmark ${influencerName}`,
        BookmarkProps: {
          itemId: (item) => item.influencer_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'influencer_ids',
        },
      },
      {
        name: `Brand mentions list`,
        Icon: data.enable_brand_mentions ? Loyalty : LoyaltyOutlined,
        iconColor: data.enable_brand_mentions ? 'success' : 'error',
        onClick: handleOpenBrandMentionsDialog,
      },
    ],
    [
      data.can_delete,
      data.enable_brand_mentions,
      handleCopy,
      influencerName,
      inline,
    ],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={7}
          actions={actions}
          data={data}
          refetch={refetch}
        />
        <ConfirmDialogControlled
          isLoading={isLoading}
          name={influencerName}
          isOpen={isDeleteDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDelete}
        />
        <BrandMentionsStatus
          influencerId={data.influencer_id}
          isEnabledBrandMentions={data.enable_brand_mentions || false}
          brandMentionsCountry={data.country_for_brand_mentions}
          isOpen={isBrandMentionsDialogOpen}
          onClose={handleCloseBrandMentionsDialog}
          refetch={refetch}
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          component={NavLink}
          to={appLinks.deals.create.as(data.influencer_id || '')}
        >
          New deal
        </Button>
      </Grid>
    </Grid>
  );
};

export default Actions;
