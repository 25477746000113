import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { SalesPlanningTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import BudgetTable from './BudgetTable';
import BrandRevenueBlock from './BrandRevenueBlock';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const BudgetDistributionTab = (props: Props): JSX.Element | null => {
  const { planning, inline } = props;

  const [activeBrand, setActiveBrand] = useState<BrandsEnum | undefined>(
    undefined,
  );

  return (
    <Stack rowGap={2}>
      <BrandRevenueBlock
        inline={inline}
        planning={planning}
        activeBrand={activeBrand}
        setActiveBrand={setActiveBrand}
      />
      <BudgetTable
        planning={planning}
        selectedBrand={activeBrand}
        selectedBrandPercent={
          planning.brand_revenue_distribution &&
          activeBrand &&
          planning.brand_revenue_distribution[activeBrand]
            ? (planning.brand_revenue_distribution[activeBrand] || 0) / 100
            : undefined
        }
      />
    </Stack>
  );
};

export default BudgetDistributionTab;
