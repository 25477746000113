import React from 'react';
import {
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { appLinks } from 'routes/links';
import { formatCurrency, formatNumber } from 'helpers/formatters';
import { CampaignTypes } from 'api';

interface Props {
  performance: CampaignTypes.PerformanceInfluencerData;
}

const CardTable: React.FC<Props> = (props: Props): JSX.Element => {
  const { performance } = props;

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.divider}`,
              })}
            >
              Influencer
            </TableCell>
            <TableCell>Revenue</TableCell>
            <TableCell>ROI</TableCell>
            <TableCell>Deal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {performance.map((item) => (
            <TableRow
              key={item.influencer_profile_name}
              sx={{
                '&:last-child td, &:last-child th': { borderBottom: 0 },
              }}
            >
              <TableCell
                sx={(theme) => ({
                  borderRight: `1px solid ${theme.palette.divider}`,
                })}
              >
                {item.influencer_profile_name}
              </TableCell>
              <TableCell>{formatCurrency(item.sum_revenue_actual)}</TableCell>
              <TableCell>{formatNumber(item.roi_actual)}</TableCell>
              <TableCell>
                <Link
                  target="_blank"
                  href={appLinks.deals.details.as(item.deal_id)}
                >
                  {item.deal_id}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
