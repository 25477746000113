import parseJwt from './parseJwt';

const isJwtExpired = (token: string) => {
  const { exp } = parseJwt(token);
  const currentTime = new Date().getTime() / 1000;

  return currentTime > exp;
};

export default isJwtExpired;
