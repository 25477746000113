import { Dispatch } from 'react';

export interface DataTableState {
  initialFiltersState: Record<string, any>;
  emptyFiltersState: Record<string, any>;
  filtersState: Record<string, any>;
  activeFilters: string[];
  selectedViewKey: string;
  sortBy: keyof Record<string, any>;
  sortDesc: boolean;
  pageNumber: number;
  perPage: number;
  query: string[] | undefined;
  selectedItems: Record<string, any>[];
  isSplitViewEnabled: boolean;
}

export enum DataTableActionEnums {
  SET_STORE_STATE = 'SET_STORE_STATE',
  clearFilters = 'clearFilters',
  setPerPage = 'setPerPage',
  selectRow = 'selectRow',
  selectAllRows = 'selectAllRows',
  setFiltersState = 'setFiltersState',
  clearFilter = 'clearFilter',
  toggleSplitView = 'toggleSplitView',
}

interface SetDataTableState {
  type: DataTableActionEnums.SET_STORE_STATE;
  payload: Partial<DataTableState>;
}

interface SetPerPage {
  type: DataTableActionEnums.setPerPage;
  payload: number;
}

interface SelectRow {
  type: DataTableActionEnums.selectRow;
  payload: Record<string, any>;
}
interface SelectAllRows {
  type: DataTableActionEnums.selectAllRows;
  payload: Record<string, any>[];
}
interface SetFiltersState {
  type: DataTableActionEnums.setFiltersState;
  payload: Record<string, any>;
}
interface ClearFilter {
  type: DataTableActionEnums.clearFilter;
  payload: string;
}
interface ClearFilters {
  type: DataTableActionEnums.clearFilters;
}
interface ToggleSplitView {
  type: DataTableActionEnums.toggleSplitView;
}

export type DataTableStateActions =
  | SetDataTableState
  | ClearFilters
  | SetPerPage
  | SelectRow
  | SelectAllRows
  | SetFiltersState
  | ClearFilter
  | ToggleSplitView;

export interface DataTableContext {
  state: DataTableState;
  dispatch: Dispatch<DataTableStateActions>;
}
