import React, { ChangeEvent, useCallback } from 'react';
import { Control, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { Grid } from '@mui/material';
import { AssignmentOutlined } from '@mui/icons-material';
import { DealTypes } from 'api';
import {
  getProductCategories,
  getProductOptions,
  getProducts,
} from 'helpers/products';
import { FormCard, FormInput } from 'components';

interface Props {
  control: Control<DealTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
}

const ProductsBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const brand = watch('brand');
  const productCategories = watch('product_categories');
  const products = watch('products');
  const urlLanding = watch('url_landing');

  const handleProductCategoryChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      if (products && products.length > 0) {
        const availableProducts = getProducts(
          brand,
          (event.target.value || []) as unknown as never[],
        );

        const selectedProducts = products.filter((p) =>
          availableProducts.includes(p),
        );

        setValue('products', selectedProducts);
      }

      onChange(event.target.value);
    },
    [brand, products, setValue],
  );

  return (
    <FormCard title="Products details" avatar={<AssignmentOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="product_categories"
            inputType="select"
            label="Product categories"
            options={getProductCategories(brand)}
            onChange={handleProductCategoryChange}
            SelectProps={{
              multiple: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="products"
            inputType="select"
            label="Products"
            options={getProductOptions(brand, productCategories as never[])}
            disabled={productCategories?.length === 0}
            SelectProps={{
              multiple: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="url_landing"
            inputType="input"
            label="Landing URL"
          />
        </Grid>
        <Grid item xs={6}>
          {urlLanding && (
            <FormInput
              control={control}
              name="short_url_utm"
              inputType="input"
              label="Short UTM URL"
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="url_auto_redemption"
            inputType="switch"
            label="Auto Redemption of discount"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ProductsBlock;
