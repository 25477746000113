import React, { useEffect, useMemo, useState } from 'react';
import { salesPlanning, SalesPlanningTypes, shops } from 'api';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary, Loader } from 'components';
import { getCurrentDate } from 'helpers/dayJsFormats';
import Form from './Form';
import { dataToInitialModel, initialModel } from './fields';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { itemId } = props;
  const { id: paramsId = '' } = useParams<Params>();

  const id = useMemo(() => paramsId || itemId || '', [itemId, paramsId]);

  const [initialData, setInitialData] = useState<
    SalesPlanningTypes.ItemCreateParams | undefined
  >(undefined);
  const [shopId, setShopId] = useState('');

  const { data, error } = useQuery(
    [salesPlanning.endpoints.getById(id)],
    () => salesPlanning.getById(id),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  const { data: shopsData } = useQuery(
    [shops.endpoints.getFlatList],
    shops.getFlatList,
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!id) {
      setInitialData({ ...initialModel, start_date: getCurrentDate() });
    }

    if (id && data && shopsData) {
      const initialFormData = dataToInitialModel(data);
      const shop = shopsData.find(
        (s) => initialFormData.shop_id === s._id.$oid,
      );

      if (shop) {
        setShopId(shop.shop_id);
      }

      setInitialData(initialFormData);
    }
  }, [data, id, shopsData]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant="centered" />
  ) : (
    <Form
      drawer={!!itemId}
      drawerTitle={`Edit ${data?.name || 'sales planning'}`}
      id={id}
      initialData={initialData}
      shopId={shopId}
      setShopId={setShopId}
    />
  );
};

export default Index;
