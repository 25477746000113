import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowDownTinIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5303 7.46967C15.8232 7.76256 15.8232 8.23744 15.5303 8.53033L10 14.0607L4.46967 8.53033C4.17678 8.23744 4.17678 7.76256 4.46967 7.46967C4.76256 7.17678 5.23744 7.17678 5.53033 7.46967L10 11.9393L14.4697 7.46967C14.7626 7.17678 15.2374 7.17678 15.5303 7.46967Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowDownTinIcon;
