import { DiscountTypes, TimelineTypes } from 'api';
import { Timeline } from 'components';

interface Props {
  discount: DiscountTypes.ItemData;
}

const ActivityLogTab = (props: Props): JSX.Element | null => {
  const { discount } = props;

  return (
    <Timeline id={discount._id.$oid} type={TimelineTypes.ModelEnum.Discount} />
  );
};

export default ActivityLogTab;
