import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { InfluencerTypes, posts } from 'api';
import { Loader } from 'components';
import MentionsBlock from './MentionsBlock';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const TABS = {
  hashtags: 'Hashtags',
  brands: 'Brands',
};

type RangeKey = keyof Pick<
  InfluencerTypes.TopStats,
  'last_3_months' | 'last_6_months' | 'last_12_months'
>;

interface RangeItem {
  label: string;
  value: RangeKey;
}

type RangeDateItem = {
  [key in RangeKey]: {
    $gte: string;
    $lte: string;
  };
};

const RANGE_ITEMS: RangeItem[] = [
  { value: 'last_3_months', label: 'Last 3 month' },
  { value: 'last_6_months', label: 'Last 6 month' },
  { value: 'last_12_months', label: 'Last 12 month' },
];

const RANGE_DATES: RangeDateItem = {
  last_3_months: {
    $gte: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
    $lte: dayjs().format('YYYY-MM-DD'),
  },
  last_6_months: {
    $gte: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
    $lte: dayjs().format('YYYY-MM-DD'),
  },
  last_12_months: {
    $gte: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    $lte: dayjs().format('YYYY-MM-DD'),
  },
};

const Mentions = (props: Props): JSX.Element | null => {
  const { data } = props;

  const [activeTab, setActiveTab] = useState(TABS.hashtags);
  const [selectedRange, setSelectedRange] =
    useState<RangeKey>('last_12_months');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRange(e.target.value as RangeKey);
  };

  const { data: brandsData, isLoading } = useQuery(
    [posts.endpoints.getById(data.influencer_id || ''), selectedRange],
    () =>
      posts.getBrandMentionsById(data.influencer_id || '', {
        filters: {
          date: RANGE_DATES[selectedRange],
        },
      }),
  );

  const hashtagsData = useMemo(() => {
    const total = Object.values(
      data.top_mentions?.[selectedRange] || {},
    ).reduce((prev, curr) => {
      // eslint-disable-next-line no-param-reassign
      prev += curr;
      return prev;
    }, 0);
    return Object.entries(data.top_mentions?.[selectedRange] || {}).map(
      ([key, value]) => ({
        label: key,
        count: value,
        value: (value / total) * 100,
      }),
    );
  }, [data.top_mentions, selectedRange]);

  const brandsMentions = useMemo(() => {
    if (brandsData) {
      const total = Object.values(brandsData.data || {}).reduce(
        (prev, curr) => {
          // eslint-disable-next-line no-param-reassign
          prev += curr;
          return prev;
        },
        0,
      );
      return Object.entries(brandsData.data || {}).map(([key, value]) => ({
        label: key,
        count: value,
        value: (value / total) * 100,
      }));
    }

    return [];
  }, [brandsData]);

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" wrap="nowrap" mb={1}>
          <Grid item xs={8}>
            <Typography variant="h6">Top Mentions</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              size="small"
              placeholder="Select range"
              value={selectedRange}
              onChange={handleRangeChange}
            >
              {RANGE_ITEMS.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
          {Object.values(TABS).map((item) => (
            <Tab label={item} key={item} value={item} />
          ))}
        </Tabs>
        <Box>
          {isLoading ? (
            <Loader variant="centered" height="400px" />
          ) : (
            <>
              {activeTab === TABS.hashtags && (
                <MentionsBlock data={hashtagsData} />
              )}
              {activeTab === TABS.brands && (
                <MentionsBlock data={brandsMentions} />
              )}
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Mentions;
