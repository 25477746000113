import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import { SalesPlanningTypes } from 'api';
import { colors } from 'theme/constants';
import { formatCurrency, formatNumber } from 'helpers/formatters';
import { TooltipChip, ColorCard } from 'components';

interface Props {
  planning: SalesPlanningTypes.ItemData;
}

type InfoItems = {
  key: keyof SalesPlanningTypes.ItemData;
  label: string;
  potential: ReactNode;
  actual: ReactNode;
  trend: 'up' | 'down';
}[];

const BudgetComparisonCard = (props: Props): JSX.Element | null => {
  const { planning } = props;

  const getTrend = useCallback(
    (
      actualKey: keyof SalesPlanningTypes.ItemData,
      potentialKey: keyof SalesPlanningTypes.ItemData,
    ) =>
      (planning[potentialKey] || 0) < (planning[actualKey] || 0)
        ? 'up'
        : 'down',
    [planning],
  );

  const tableFields: InfoItems = useMemo(
    () => [
      {
        key: 'budget_actual',
        label: 'Budget',
        potential: formatCurrency(planning.budget_potential || 0),
        actual: formatCurrency(planning.budget_actual || 0),
        trend: getTrend('budget_actual', 'budget_potential'),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue',
        potential: formatCurrency(planning.revenue_potential || 0),
        actual: formatCurrency(planning.revenue_actual || 0),
        trend: getTrend('revenue_actual', 'revenue_potential'),
      },
      {
        key: 'roi_actual',
        label: 'ROI',
        potential: formatNumber(planning.roi_potential || 0),
        actual: formatNumber(planning.roi_actual || 0),
        trend: getTrend('roi_actual', 'roi_potential'),
      },
    ],
    [
      planning.budget_actual,
      planning.budget_potential,
      planning.revenue_actual,
      planning.revenue_potential,
      planning.roi_actual,
      planning.roi_potential,
      getTrend,
    ],
  );

  return (
    <ColorCard color={colors.yellow}>
      <CardHeader
        title="Budget comparison"
        subheader={
          <Typography variant="inherit">
            Comparison of potential metrics with actual
          </Typography>
        }
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Potential</TableCell>
              <TableCell align="right">Actual</TableCell>
              <TableCell align="right">Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableFields.map((row) => (
              <TableRow
                key={row.key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">
                  <Typography color="text.secondary" variant="inherit">
                    {row.potential}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="text.secondary" variant="inherit">
                    {row.actual}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TooltipChip
                    title="Trend"
                    color={row.trend === 'up' ? 'success' : 'warning'}
                    sx={{
                      '& span': {
                        display: 'flex',
                        padding: 1,
                      },
                    }}
                    label={
                      row.trend === 'up' ? (
                        <TrendingUpRounded />
                      ) : (
                        <TrendingDownRounded />
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </ColorCard>
  );
};

export default BudgetComparisonCard;
