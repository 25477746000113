import React from 'react';
import COUNTRIES from 'constants/countries';
import { CountriesEnum } from 'api/types/Countries';
import { useStoreContext } from 'store';
import { setStoreState } from 'store/actions';
import FilterMenu, { FilterMenuActiveItem } from './FilterMenu';

const defaultOption: FilterMenuActiveItem = {
  value: 'All countries',
  label: 'All countries',
  image: undefined,
};
const options: FilterMenuActiveItem[] = [defaultOption, ...COUNTRIES.OPTIONS];

const CountriesFilter = (): JSX.Element | null => {
  const {
    state: { activeCountry },
    dispatch,
  } = useStoreContext();

  const handleSelect = (value: FilterMenuActiveItem) => {
    const newValue = value.value === 'All countries' ? undefined : value.value;
    dispatch(
      setStoreState({ activeCountry: newValue as CountriesEnum | undefined }),
    );
  };

  return (
    <FilterMenu
      defaultOption={defaultOption}
      options={options}
      value={activeCountry}
      onSelect={handleSelect}
    />
  );
};

export default CountriesFilter;
