import React, { Fragment, Key } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import TableCellSkeleton from './TableCellContent/TableCellSkeleton';
import DataTableProps from '../DataTableProps';

interface Props {
  inline: boolean;
  tableBodyItems: DataTableProps.Columns;
  withBulkActions: boolean | undefined;
}

const TableBodyItemSkeleton: React.FC<Props> = (props: Props) => {
  const { tableBodyItems, withBulkActions, inline } = props;

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
      }}
    >
      {tableBodyItems
        .filter((t) => t.type !== 'rowLink')
        .map((cell, index) =>
          index === 0 ? (
            <Fragment key={cell.key as Key}>
              {withBulkActions && (
                <TableCell
                  size={inline ? 'small' : 'medium'}
                  padding="checkbox"
                >
                  <Checkbox color="primary" checked={false} disabled />
                </TableCell>
              )}
              <TableCellSkeleton
                size={inline ? 'small' : 'medium'}
                cell={cell}
                isFirstItem
              />
            </Fragment>
          ) : (
            <TableCellSkeleton
              size={inline ? 'small' : 'medium'}
              key={cell.key as Key}
              cell={cell}
              isFirstItem={index === 0}
            />
          ),
        )}
    </TableRow>
  );
};

export default TableBodyItemSkeleton;
