import { FC } from 'react';
import { Avatar, Chip, Typography } from '@mui/material';
import { CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import DataTableProps from '../../DataTableProps';
import CountryCell from './CountryCell';
import GrowthCell from './GrowthCell';
import UserCell from './UserCell';
import PlatformsCell from './PlatformsCell';
import SparklineCell from './SparklineCell';
import ActionsCell from './ActionsCell';
import BrandsStatus from '../../../Chips/BrandsStatus';
import BrandsCell from './BrandsCell';
import TitleCell from './TitleCell';

interface Props {
  cell: DataTableProps.Cell;
  data: DataTableProps.Item;
  refetch: () => Promise<QueryObserverResult>;
}

const TableCellContent: FC<Props> = (props: Props) => {
  const { data, cell, refetch } = props;

  return (
    <>
      {cell.type === 'node' && cell.render(data)}
      {cell.type === 'brands' && <BrandsCell cell={cell} data={data} />}
      {cell.type === 'actions' && (
        <ActionsCell cell={cell} data={data} refetch={refetch} />
      )}
      {cell.type === 'country' && <CountryCell cell={cell} data={data} />}
      {cell.type === 'user' && <UserCell cell={cell} data={data} />}
      {cell.type === 'growth' && <GrowthCell cell={cell} data={data} />}
      {cell.type === 'platforms' && <PlatformsCell cell={cell} data={data} />}
      {cell.type === 'sparkline' && <SparklineCell cell={cell} data={data} />}
      {cell.type === 'text' && (
        <Typography
          textTransform="capitalize"
          {...(typeof cell.TypographyProps === 'function'
            ? cell.TypographyProps(data)
            : cell.TypographyProps)}
        >
          {(cell.children ? cell.children(data) : data[cell.key]) || '-'}
        </Typography>
      )}
      {cell.type === 'avatar' && (
        <Avatar
          src={(cell.src ? cell.src(data) : data[cell.key]) || ''}
          {...cell.AvatarProps}
        />
      )}
      {cell.type === 'chip' && (
        <Chip
          variant="outlined"
          color={cell.color ? cell.color(data) : undefined}
          label={(cell.text ? cell.text(data) : data[cell.key]) || '-'}
          {...cell.ChipProps}
        />
      )}
      {cell.type === 'chipCheckmark' && (
        <Chip
          variant="outlined"
          color={cell.checked(data) ? 'success' : 'error'}
          label={
            cell.checked(data) ? (
              <CheckOutlined fontSize="small" />
            ) : (
              <CloseOutlined fontSize="small" />
            )
          }
          sx={{ p: 1, '& span': { p: 0, display: 'flex' } }}
        />
      )}
      {cell.type === 'brands-status' && (
        <BrandsStatus brands={cell.brands(data)} />
      )}
      {cell.type === 'title' && <TitleCell cell={cell} data={data} />}
    </>
  );
};

export default TableCellContent;
