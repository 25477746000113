import { useQuery } from '@tanstack/react-query';
import { Link, Typography } from '@mui/material';
import { DealTypes, influencers, InfluencerTypes } from 'api';
import { Loader } from 'components';
import { appLinks } from 'routes/links';
import { getRevenue } from 'helpers/calculations';
import { UseFormSetValue } from 'react-hook-form';
import { useCallback } from 'react';

interface Props {
  id?: string;
  influencerId: string;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
}

const SuggestedBudgetInfo = (props: Props): JSX.Element | null => {
  const { id, influencerId, setValue } = props;

  const setInfluencerData = useCallback(
    (influencer: InfluencerTypes.ItemData) => {
      if (!id) {
        if (influencer.latest_deal) {
          const roi = influencer.latest_deal.roi_potential || 0;
          const budget = influencer.latest_deal.budget_potential_lc || 0;
          const revenue = influencer.latest_deal.revenue_potential_lc || 0;

          setValue(
            'avg_overall_reach',
            +(influencer.instagram_story?.avg_views_nb || 0),
          );
          setValue('roi_potential', roi);
          setValue('budget_potential_lc', budget);
          setValue('revenue_potential_lc', revenue);
          setValue(
            'type',
            influencer.latest_deal.type === DealTypes.TypeEnum.NewDeal
              ? DealTypes.TypeEnum.ReTest
              : DealTypes.TypeEnum.LongTerm,
          );

          if (
            influencer.latest_deal.budget_type ===
            DealTypes.BudgetTypeEnum.commissionRate
          ) {
            setValue('budget_type', DealTypes.BudgetTypeEnum.commissionRate);
            setValue('commission', influencer.latest_deal.commission);
          }

          if (
            influencer.latest_deal.budget_type ===
            DealTypes.BudgetTypeEnum.fixedAndCommission
          ) {
            setValue(
              'budget_type',
              DealTypes.BudgetTypeEnum.fixedAndCommission,
            );
            setValue('commission', influencer.latest_deal.commission);
            setValue('budget_fixed', influencer.latest_deal.budget_fixed);
          }
        }
      }
    },
    [id, setValue],
  );

  const { data, error } = useQuery(
    [influencers.endpoints.getById(influencerId), influencerId],
    () => influencers.getById(influencerId),
    {
      enabled: !!influencerId,
      refetchOnWindowFocus: false,
      onSuccess: setInfluencerData,
    },
  );

  return (
    <>
      {!data && !error && (
        <>
          <Typography gutterBottom>Influencer latest deal info</Typography>
          <Loader variant="centered" height="100px" />
        </>
      )}
      {(error || !data?.latest_deal) && (
        <>
          <Typography gutterBottom>Influencer latest deal info</Typography>
          <Typography color="error">No info</Typography>
        </>
      )}

      {data?.latest_deal && data?.latest_deal.deal_id === id && (
        <>
          <Link
            target="_blank"
            href={appLinks.deals.details.as(data.latest_deal?.deal_id || '')}
          >
            Influencer latest deal info
          </Link>
          <Typography color="text.secondary">
            This is the latest deal
          </Typography>
        </>
      )}
      {data?.latest_deal && data?.latest_deal?.deal_id !== id && (
        <>
          <Link
            target="_blank"
            href={appLinks.deals.details.as(data.latest_deal.deal_id!)}
          >
            Influencer latest deal info
          </Link>
          <Typography color="text.secondary">
            Latest deal ROI {data.latest_deal.roi_potential || 0}
          </Typography>
          <Typography color="text.secondary">
            Latest deal budget {data.latest_deal.budget_potential || 0}
          </Typography>
          <Typography color="text.secondary">
            Latest deal revenue{' '}
            {getRevenue({
              budget: data.latest_deal.budget_potential || 0,
              roi: data.latest_deal.roi_potential || 0,
            })}
          </Typography>
        </>
      )}
    </>
  );
};

export default SuggestedBudgetInfo;
