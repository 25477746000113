import React, { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { DealTypes, SalesPlanningTypes } from 'api';
import { formatCurrency } from 'helpers/formatters';
import {
  convertToFixed,
  getAmazonCM3,
  getCM3,
  getRevenue,
  getRoi,
  getSum,
} from 'helpers/calculations';
import { CancelOutlined, LightbulbCircleOutlined } from '@mui/icons-material';
import SALES_PLANNING from 'constants/salesPlanning';
import FormInput from './TextInput';
import { budgetDistributionConfig, Field, labels } from '../../budgetConfig';

interface Props {
  control: Control<SalesPlanningTypes.ItemCreateParams>;
  watch: UseFormWatch<SalesPlanningTypes.ItemCreateParams>;
  setValue: UseFormSetValue<SalesPlanningTypes.ItemCreateParams>;

  field: Field;
  itemKey: DealTypes.StrategiesEnum;
  itemSubKey?: string;
  itemSubSubKey?: string;
  disabledFields: string[];
  cm2Data: SalesPlanningTypes.CM2FlatListData;
  hasSubChannels: boolean;
}

interface KSS {
  key: string;
  subKey?: string;
  subSubKey?: string;
}

interface FKSS extends KSS {
  field: Field;
}

const getKey = (params: KSS) => {
  const { subKey, subSubKey, key } = params;
  if (subKey && subSubKey) {
    return `budget_distribution.${key}.sub_items.${subKey}.sub_items.${subSubKey}`;
  }

  if (subKey) {
    return `budget_distribution.${key}.sub_items.${subKey}`;
  }

  return `budget_distribution.${key}`;
};

const getFieldKey = (params: FKSS) => {
  const { field, key, subKey, subSubKey } = params;
  const keyPath = getKey({
    key,
    subKey,
    subSubKey,
  });

  return `${keyPath}.${field}`;
};

const InputCell: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    control,
    itemKey,
    itemSubKey,
    itemSubSubKey,
    field,
    watch,
    setValue,
    disabledFields,
    cm2Data,
    hasSubChannels,
  } = props;

  const countryTeam =
    itemSubKey === DealTypes.ChannelMarketplaceEnum.Amazon
      ? watch('country_team')
      : undefined;

  const tax = useMemo(
    () =>
      countryTeam ? SALES_PLANNING.AMAZON_TAX_OPTIONS[countryTeam] : undefined,
    [countryTeam],
  );

  const isDisabledRevenue = useMemo(() => {
    const root = budgetDistributionConfig[itemKey];

    if (root.sub_items) {
      if (itemSubKey && itemSubSubKey) {
        const first = root.sub_items[itemSubKey];
        if (first.sub_items) {
          return first.sub_items[itemSubSubKey].disabled.includes(field);
        }
      }

      if (itemSubKey) {
        const first = root.sub_items[itemSubKey];

        return first.disabled.includes(field);
      }
    }

    return root.disabled.includes(field);
  }, [field, itemKey, itemSubKey, itemSubSubKey]);

  const cm2Item = useMemo(() => {
    const cm2ExactItem = cm2Data.find(
      (c) =>
        c.channel === itemKey && c.sub_channel_type === (itemSubKey || itemKey),
    );

    if (cm2ExactItem) {
      return {
        type: 'exactMatch',
        ...cm2ExactItem,
      };
    }

    const cm2ItemChannelDefault = cm2Data.find(
      (c) => c.channel === itemKey && c.sub_channel_type === 'default',
    );

    if (cm2ItemChannelDefault) {
      return {
        type: 'channelMatch',
        ...cm2ItemChannelDefault,
      };
    }

    const cm2ItemDefault = cm2Data.find(
      (c) => c.channel === 'default' && c.sub_channel_type === 'default',
    );

    if (cm2ItemDefault) {
      return {
        type: 'defaultItem',
        ...cm2ItemDefault,
      };
    }

    return undefined;
  }, [cm2Data, itemKey, itemSubKey]);

  const getFieldValue = useCallback(
    (params: FKSS) => {
      const { field, key, subKey, subSubKey } = params;

      if (key) {
        const fieldKey = getFieldKey({ field, key, subKey, subSubKey });
        return watch(
          fieldKey as keyof SalesPlanningTypes.ItemCreateParams,
        ) as number;
      }
      return watch(field);
    },
    [watch],
  );

  const computeRevenue = useCallback(
    (params: KSS) => {
      const { key, subKey, subSubKey } = params;

      const budget = getFieldValue({
        field: 'budget_target',
        key,
        subKey,
        subSubKey,
      });
      const roi = getFieldValue({
        field: 'roi_target',
        key,
        subKey,
        subSubKey,
      });
      if (budget !== undefined && roi !== undefined) {
        const revenue = getRevenue({ budget, roi });
        const revenueKey = getFieldKey({
          field: 'revenue_target',
          key,
          subKey,
          subSubKey,
        }) as any;

        setValue(revenueKey, revenue);
      }
    },
    [getFieldValue, setValue],
  );

  const computeRoi = useCallback(
    (params: KSS) => {
      const { key, subKey, subSubKey } = params;

      const budget = getFieldValue({
        field: 'budget_target',
        key,
        subKey,
        subSubKey,
      });
      const revenue = getFieldValue({
        field: 'revenue_target',
        key,
        subKey,
        subSubKey,
      });

      if (budget !== undefined && revenue !== undefined) {
        const roi = getRoi({ budget, revenue });
        const roiKey = getFieldKey({
          field: 'roi_target',
          key,
          subKey,
          subSubKey,
        }) as any;

        setValue(roiKey, roi);
      }
    },
    [getFieldValue, setValue],
  );

  const calculateBudgetTotal = useCallback(() => {
    const budgetDist = watch('budget_distribution');
    const values = Object.values(budgetDist);

    const budget = getSum(values.map((v: any) => v.budget_target || 0));
    const revenue = getSum(values.map((v: any) => v.revenue_target || 0));
    const roi = getRoi({ budget, revenue });
    const cm3 = getSum(values.map((v: any) => v.cm3_target || 0));

    setValue('budget_target', convertToFixed(budget));
    setValue('revenue_target', convertToFixed(revenue));
    setValue('roi_target', convertToFixed(roi));
    setValue('cm3_target', convertToFixed(cm3));
  }, [setValue, watch]);

  const calculateFieldTotal = useCallback(
    ({
      field,
      key,
      subKey,
    }: {
      field: Field;
      key: string;
      subKey?: string;
    }) => {
      const valueKey = getKey({
        key,
        subKey,
      }) as any;

      const value = watch(valueKey as any);

      if (value.sub_items) {
        const total = convertToFixed(
          getSum(
            Object.values(value.sub_items).map((v: any) =>
              field in v ? v[field] || 0 : 0,
            ),
          ),
        );

        setValue(`${valueKey}.${field}` as any, total);

        return total;
      }

      return 0;
    },
    [setValue, watch],
  );

  const calculateTotal = useCallback(
    ({ key, subKey }: { key: string; subKey?: string }) => {
      const budget = calculateFieldTotal({
        field: 'budget_target',
        key,
        subKey,
      });
      const revenue = calculateFieldTotal({
        field: 'revenue_target',
        key,
        subKey,
      });

      const roiKey = getFieldKey({
        field: 'roi_target',
        key,
        subKey,
      }) as any;

      // calculateFieldTotal({
      //   field: 'cm3_target',
      //   key,
      // });

      setValue(roiKey as any, getRoi({ revenue, budget }));
    },
    [calculateFieldTotal, setValue],
  );

  const computeCM3 = useCallback(() => {
    if (cm2Item) {
      const fieldKey = getFieldKey({
        field: 'cm3_target',
        key: itemKey,
        subKey: itemSubKey,
      }) as keyof SalesPlanningTypes.ItemCreateParams;
      const budget = getFieldValue({
        field: 'budget_target',
        key: itemKey,
        subKey: itemSubKey,
      });
      const revenue = getFieldValue({
        field: 'revenue_target',
        key: itemKey,
        subKey: itemSubKey,
      });

      if (
        itemKey === DealTypes.StrategiesEnum.marketplace &&
        itemSubKey === DealTypes.ChannelMarketplaceEnum.Amazon &&
        tax?.value
      ) {
        const value = getAmazonCM3({
          revenue,
          budget,
          tax: tax.value,
          cm2Percent: +cm2Item.cm2_prct.toFixed(2),
        });

        setValue(fieldKey, value);
      } else {
        const value = getCM3({
          revenue,
          budget,
          cm2Percent: +cm2Item.cm2_prct.toFixed(2),
        });

        setValue(fieldKey, value);
      }
    }
  }, [cm2Item, getFieldValue, itemKey, itemSubKey, setValue, tax]);

  const computeValue = useCallback(async () => {
    if (field === 'budget_target') {
      if (isDisabledRevenue) {
        computeRevenue({
          key: itemKey,
          subKey: itemSubKey,
          subSubKey: itemSubSubKey,
        });
      } else {
        computeRoi({
          key: itemKey,
          subKey: itemSubKey,
          subSubKey: itemSubSubKey,
        });
      }
    }
    if (field === 'roi_target') {
      computeRevenue({
        key: itemKey,
        subKey: itemSubKey,
        subSubKey: itemSubSubKey,
      });
    }
    if (field === 'revenue_target') {
      computeRoi({
        key: itemKey,
        subKey: itemSubKey,
        subSubKey: itemSubSubKey,
      });
    }

    if (itemSubKey) {
      if (itemSubSubKey) {
        calculateTotal({
          key: itemKey,
          subKey: itemSubKey,
        });
      }

      calculateTotal({ key: itemKey });
    }

    computeCM3();

    calculateFieldTotal({
      field: 'cm3_target',
      key: itemKey,
    });

    calculateBudgetTotal();
  }, [
    calculateBudgetTotal,
    calculateFieldTotal,
    calculateTotal,
    computeCM3,
    computeRevenue,
    computeRoi,
    field,
    isDisabledRevenue,
    itemKey,
    itemSubKey,
    itemSubSubKey,
  ]);

  const fieldKey = useMemo(
    () =>
      getFieldKey({
        field,
        key: itemKey,
        subKey: itemSubKey,
        subSubKey: itemSubSubKey,
      }) as keyof SalesPlanningTypes.ItemCreateParams,
    [field, itemKey, itemSubKey, itemSubSubKey],
  );

  const autocalLabel = useMemo(
    () => (
      <Tooltip
        title={
          <Box>
            <Typography>
              CM3 value auto calculated with next formula:
            </Typography>
            {tax ? (
              <>
                <Typography>
                  {`CM3 = revenue * CM2% - (revenue / (1 - ${tax.value}) * 0.15) - budget.`}
                </Typography>
                <Typography>{`${tax.value} - Amazon tax value ${tax.label} in ${countryTeam}`}</Typography>
                <Typography>
                  0.15 - Amazon works with a 15% commission cost of the gross
                  revenue
                </Typography>
              </>
            ) : (
              <Typography>CM3 = revenue * CM2% - budget</Typography>
            )}
            <Typography>CM2% = {cm2Item?.cm2_prct.toFixed(2)}</Typography>
            {cm2Item?.type === 'exactMatch' && (
              <Typography>{`Current CM2% is for ${itemKey} channel${
                itemSubKey ? `, sub channel ${itemSubKey}` : ''
              }`}</Typography>
            )}
            {cm2Item?.type === 'channelMatch' && (
              <Typography>{`Current CM2% is default for ${itemKey} channel`}</Typography>
            )}
            {cm2Item?.type === 'defaultItem' && (
              <Typography>Current CM2% is default for all channels</Typography>
            )}
          </Box>
        }
      >
        <LightbulbCircleOutlined
          color={
            // eslint-disable-next-line no-nested-ternary
            cm2Item?.type === 'exactMatch'
              ? 'success'
              : cm2Item?.type === 'channelMatch'
              ? 'secondary'
              : 'warning'
          }
        />
      </Tooltip>
    ),
    [cm2Item?.cm2_prct, cm2Item?.type, countryTeam, itemKey, itemSubKey, tax],
  );

  const cellValue = (() => {
    const value = getFieldValue({
      field,
      key: itemKey,
      subKey: itemSubKey,
      subSubKey: itemSubSubKey,
    });

    if (!value && value !== 0) {
      return {
        formatted: '-',
        value,
      };
    }

    if (field === 'roi_target') {
      return { value, formatted: value };
    }

    return { value, formatted: formatCurrency(value) };
  })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(debounce(computeValue, 1000), [computeValue]);

  const handleCm3Clear = useCallback(() => {
    const fieldKey = getFieldKey({
      field: 'cm3_target',
      key: itemKey,
      subKey: itemSubKey,
    }) as keyof SalesPlanningTypes.ItemCreateParams;

    setValue(fieldKey, 0);
  }, [itemKey, itemSubKey, setValue]);

  const endAdornment = useMemo(() => {
    if (field === 'cm3_target' && !!cm2Item?.cm2_prct) {
      if (itemKey && !itemSubKey) {
        if (!hasSubChannels) {
          return autocalLabel;
        }

        return undefined;
      }

      return undefined;
    }

    return undefined;
  }, [
    autocalLabel,
    cm2Item?.cm2_prct,
    field,
    hasSubChannels,
    itemKey,
    itemSubKey,
  ]);

  return (
    <TableCell>
      {disabledFields.includes(field) ? (
        <Stack direction="row">
          <Typography>{cellValue.formatted || cellValue.value}</Typography>
          {field === 'cm3_target' &&
            !!cellValue.value &&
            !cm2Item?.cm2_prct && (
              <Box marginLeft="auto" pr={1.85}>
                <Tooltip
                  title={
                    <Box>
                      <Typography>
                        CM3 value was auto calculated but currently cm2 value is
                        missing. This can cause calculations errors. Click here
                        to remove this value
                      </Typography>
                    </Box>
                  }
                >
                  <IconButton
                    size="small"
                    onClick={handleCm3Clear}
                    sx={{ p: 0 }}
                  >
                    <CancelOutlined color="error" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          {field === 'cm3_target' && !!cm2Item?.cm2_prct && !itemSubSubKey && (
            <Box marginLeft="auto" pr={1.85}>
              {autocalLabel}
            </Box>
          )}
        </Stack>
      ) : (
        <FormInput
          control={control}
          name={fieldKey}
          placeholder={labels[field]}
          onChange={onChange}
          InputProps={{
            inputProps: {
              step: 0.01,
            },
            endAdornment,
          }}
          rules={{
            min: field === 'cm3_target' ? undefined : 0,
          }}
        />
      )}
    </TableCell>
  );
};

export default React.memo(InputCell);
