import { Box, Typography } from '@mui/material';
import { CampaignTypes } from 'api';
import PreviousCampaigns from './PreviousCampaigns';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

const PrevCampaignsTab = (props: Props): JSX.Element | null => {
  const { campaign, inline } = props;

  return (
    <Box>
      {!inline && (
        <Typography variant="subtitle2" paragraph>
          Previous campaigns
        </Typography>
      )}
      <PreviousCampaigns campaign={campaign} inline={inline} />
    </Box>
  );
};

export default PrevCampaignsTab;
