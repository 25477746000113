const formatCompactNumber = (value?: number | null) =>
  new Intl.NumberFormat('en', { notation: 'compact' }).format(value || 0);

const formatCurrency = (value?: number | null, maximumFractionDigits = 2) =>
  `€${new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(value || 0)}`;

const formatPercent = (value?: number | null, isPercentValue = false) => {
  const number = isPercentValue ? value || 0 : (value || 0) / 100;

  return new Intl.NumberFormat('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const formatNumber = (value?: number | null, fractionDigits = 2) =>
  new Intl.NumberFormat('de-DE', {
    // style: 'number',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value || 0);

const formatShortNumber = (value?: number | null) =>
  new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value || 0);

export {
  formatPercent,
  formatCurrency,
  formatCompactNumber,
  formatNumber,
  formatShortNumber,
};
