import { UserTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';

const initialModel: UserTypes.ItemCreateParams = {
  access: UserTypes.AccessEnum.READWRITE,
  brand: BrandsEnum.avaAndMay,
  country_team: CountriesEnum.DE,
  email: '',
  role: UserTypes.RoleEnum.MARKETING_MANAGER,
  shop_id: '',
};

const dataToInitialModel = (
  data: UserTypes.ItemData,
): UserTypes.ItemCreateParams => ({
  role: data.role,
  email: data.email,
  country_team: data.country_team,
  brand: data.brand,
  access: data.access,
  shop_id: data.shop_id?.$oid || '',
});

export { dataToInitialModel, initialModel };
