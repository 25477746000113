import { CALENDAR_MONTHS } from 'constants/dateOptions';

const getChartOptions = ({
  data,
  avgData,
}: {
  data: Record<string, number>;
  avgData: Record<string, number>;
}) => {
  const years = Object.keys(data)
    .map((d) => +d.split(' ')[1])
    .filter((v, i, a) => a.indexOf(v) === i);

  const opt = years
    .map((year) => CALENDAR_MONTHS.map((month) => ({ ...month, year })))
    .flat();

  return opt.map((option) => {
    const key = `${option.monthName} ${option.year}`;

    return {
      ...option,
      date: key,
      value: data[key] || 0,
      avgValue: avgData[key] || 0,
    };
  });
};

export { getChartOptions };
