import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency, formatNumber } from 'helpers/formatters';
import { CampaignTypes } from 'api';

interface Props {
  performance: CampaignTypes.PerformanceInsightsInfluencersData;
}

const config: {
  key: keyof CampaignTypes.PerformanceInsightsInfluencersItem;
  label: string;
  format?: (value: number) => string;
}[] = [
  { key: 'type', label: 'Type' },
  {
    key: 'count_deals',
    label: 'Total deals',
    format: (v) => formatNumber(v, 0),
  },
  { key: 'deals_done', label: 'Deals done', format: (v) => formatNumber(v, 0) },
  {
    key: 'sum_revenue_potential',
    label: 'Sum pot. revenue',
    format: formatCurrency,
  },
  {
    key: 'sum_revenue_actual',
    label: 'Sum actual revenue',
    format: formatCurrency,
  },
  {
    key: 'sum_budget_potential',
    label: 'Sum pot. budget',
    format: formatCurrency,
  },
  {
    key: 'sum_budget_actual',
    label: 'Sum actual budget',
    format: formatCurrency,
  },
  { key: 'ttl_roi_potential', label: 'Pot. ROI', format: formatNumber },
  { key: 'ttl_roi_actual', label: 'Actual ROI', format: formatNumber },
  { key: 'rev_per_post', label: 'Avg. Rev./Deal', format: formatCurrency },
];

const CardTable: React.FC<Props> = (props: Props): JSX.Element => {
  const { performance } = props;

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            {config.map((c, index) => (
              <TableCell
                key={c.key}
                sx={(theme) => ({
                  borderRight:
                    index === 0
                      ? `1px solid ${theme.palette.divider}`
                      : undefined,
                })}
              >
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {performance.map((item) => (
            <TableRow
              key={item.type}
              sx={{
                '&:last-child td, &:last-child th': { borderBottom: 0 },
              }}
            >
              {config.map((c, index) => (
                <TableCell
                  key={c.key}
                  component={index === 0 ? 'th' : undefined}
                  sx={(theme) => ({
                    minWidth: index === 0 ? 250 : undefined,
                    borderRight:
                      index === 0
                        ? `1px solid ${theme.palette.divider}`
                        : undefined,
                  })}
                >
                  {c.format
                    ? c.format(item[c.key] as number)
                    : item[c.key] || '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
