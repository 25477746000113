import { CardContent, Stack, Typography, Box, Tooltip } from '@mui/material';
import { ChatBubbleOutline, FavoriteBorder } from '@mui/icons-material';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';
import { InfluencerTypes } from 'api';
import { CardImage } from 'components';
import { formatCompactNumber } from 'helpers/formatters';

interface Props {
  data: Partial<InfluencerTypes.PostsPost>;
  height?: string;
}

const PostInfo = (props: Props): JSX.Element | null => {
  const { data, height } = props;

  return (
    <>
      <CardImage
        src={data.thumbnail_url}
        alt={data.influencer_profile_name}
        sx={{ height }}
        PlaceholderSx={(theme) => ({
          height,
          objectFit: 'none',
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.grey['900']
              : theme.palette.grey['100'],
        })}
      />
      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
        <Typography variant="caption" color="text.secondary">
          {getFormattedDateFromNow(data?.date || '')}
        </Typography>
        <Stack direction="row" columnGap={1}>
          <Tooltip title={`${data.likes_nb} likes`}>
            <Box display="flex" alignItems="center" columnGap={1}>
              <FavoriteBorder
                sx={{ fontSize: '14px', color: 'text.primary' }}
              />
              <Typography variant="body2" color="text.primary">
                {formatCompactNumber(data.likes_nb) || '-'}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title={`${data.comments_nb} comments`}>
            <Box display="flex" alignItems="center" columnGap={1}>
              <ChatBubbleOutline sx={{ fontSize: '14px' }} />
              <Typography variant="body2" color="text.primary">
                {formatCompactNumber(data.comments_nb) || '-'}
              </Typography>
            </Box>
          </Tooltip>
        </Stack>
      </CardContent>
    </>
  );
};

export default PostInfo;
