import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormCard, FormInput, ShopInput } from 'components';
import { SalesPlanningTypes } from 'api';
import { PersonOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import SALES_PLANNING from 'constants/salesPlanning';
import dayjs from 'dayjs';

interface Props {
  control: Control<SalesPlanningTypes.ItemCreateParams>;
  watch: UseFormWatch<SalesPlanningTypes.ItemCreateParams>;
  setValue: UseFormSetValue<SalesPlanningTypes.ItemCreateParams>;
  setShopId: React.Dispatch<React.SetStateAction<string>>;
}

const GeneralInfo: React.FC<Props> = (props: Props): JSX.Element => {
  const { watch, setValue, control, setShopId } = props;

  const startDate = watch('start_date');
  const endDate = watch('end_date');

  return (
    <FormCard title="General information" avatar={<PersonOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormInput
            control={control}
            name="name"
            inputType="input"
            label="Name"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            control={control}
            name="type"
            inputType="select"
            label="Type"
            options={SALES_PLANNING.TYPE_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            control={control}
            name="status"
            inputType="select"
            label="Status"
            options={SALES_PLANNING.STATUS_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ShopInput
            control={control}
            onChange={(shop) => {
              setShopId(shop.shop_id);
              setValue('country_team', shop.country_team);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            control={control}
            name="brands"
            inputType="select"
            label="Brands"
            options={BRANDS.OPTIONS}
            SelectProps={{
              multiple: true,
            }}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            control={control}
            name="country_team"
            inputType="select"
            label="Country team"
            options={COUNTRIES.OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="start_date"
            label="Start Date"
            maxDate={dayjs.utc(endDate).subtract(1, 'days')}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="end_date"
            label="End Date"
            minDate={dayjs.utc(startDate).add(1, 'days')}
            rules={{
              required: true,
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default GeneralInfo;
