import { NegotiationTypes } from 'api';
import { enumToOptions } from 'helpers/common';

const STATUS_OPTIONS = enumToOptions(NegotiationTypes.StatusEnum);

const NEGOTIATIONS = {
  STATUS_OPTIONS,
};

export default NEGOTIATIONS;
