import React from 'react';
import { CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { Pie, PieChart, Cell, Legend } from 'recharts';
import { BrandsEnum } from 'api/types/Brands';
import { ColorCard } from 'components';

interface Props {
  title: string;
  color: string;
  data: {
    name: BrandsEnum;
    value: number;
    color: string;
    isNegative: boolean;
    actualValue: number;
    formattedValue: string | number;
  }[];
}

const PieChartCard = (props: Props): JSX.Element | null => {
  const { data, color, title } = props;
  const theme = useTheme();

  const renderCustomizedLabel = ({
    cx,
    percent,
    x,
    y,
    fill,
    formattedValue,
  }: any) => (
    <text
      x={x}
      y={y - 12}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fill={fill}
    >
      {formattedValue}
      <tspan dy={24} x={x} fill={theme.palette.text.secondary}>{`${(
        percent * 100
      ).toFixed(0)}%`}</tspan>
    </text>
  );

  if (data.length === 0) {
    return (
      <ColorCard color={color} sx={{ width: '100%' }}>
        <CardHeader title={title} />
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle2">No data available</Typography>
        </CardContent>
      </ColorCard>
    );
  }

  return (
    <ColorCard color={color} sx={{ width: '100%' }}>
      <CardHeader title={title} />
      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <PieChart width={300} height={250}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            outerRadius={80}
            fill={theme.palette.primary.light}
            dataKey="value"
          >
            {data.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={entry.isNegative ? theme.palette.error.main : entry.color}
                name={entry.name}
              />
            ))}
          </Pie>
          <Legend iconType="circle" />
        </PieChart>
      </CardContent>
    </ColorCard>
  );
};

export default PieChartCard;
