import { Box, Drawer, Fab, Tooltip } from '@mui/material';
import { HEADER_HEIGHT } from 'theme/constants';
import { ReactNode } from 'react';
import { ChevronRight } from '@mui/icons-material';

interface Props {
  children: ReactNode;
  open: boolean;
  onClick: () => void;
}

const SidePanelDrawer = (props: Props): JSX.Element | null => {
  const { children, open, onClick } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      <Tooltip title={open ? 'Close panel' : 'Open panel'} placement="left">
        <Fab
          color="primary"
          size="small"
          onClick={onClick}
          sx={(theme) => ({
            position: 'fixed',
            top: `calc(215px + ${HEADER_HEIGHT}px)`,
            zIndex: theme.zIndex.drawer - 1,
            transition: 'all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            transform: `translateX(${open ? '0' : '50%'}) ${
              open ? 'rotate(0deg)' : 'rotate(180deg)'
            }`,
            right: open ? '340px' : '10px',
            borderRadius: open ? '50% 0% 0% 50%' : '0% 50% 50% 0%',
            '&:hover': {
              right: open ? '350px' : '20px',
            },
          })}
        >
          <ChevronRight />
        </Fab>
      </Tooltip>
      <Box
        sx={{
          width: open ? '375px' : '20px',
        }}
      />
      <Drawer
        open={open}
        variant="persistent"
        anchor="right"
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
            p: 2,
            width: '350px',
            top: `${HEADER_HEIGHT}px`,
            border: `1px solid ${theme.palette.divider}`,
          }),
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
};

export default SidePanelDrawer;
