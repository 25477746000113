import { ChipProps } from '@mui/material';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';

const getBrandColor = (brand: BrandsEnum): ChipProps['color'] => {
  switch (brand) {
    case BrandsEnum.avaAndMay:
      return 'primary';
    case BrandsEnum.faynt:
      return 'secondary';
    case BrandsEnum.clubNoe:
      return 'info';
    case BrandsEnum.reflekt:
      return 'warning';
    case BrandsEnum.ultravit:
      return 'error';
    case BrandsEnum.millionFacets:
      return 'error';
    default:
      return 'default';
  }
};

const getCountryColor = (brand: CountriesEnum): ChipProps['color'] => {
  switch (brand) {
    case CountriesEnum.FR:
      return 'primary';
    case CountriesEnum.PL:
      return 'secondary';
    case CountriesEnum.IN:
      return 'info';
    case CountriesEnum.DE:
      return 'warning';
    case CountriesEnum.IT:
      return 'error';
    default:
      return 'default';
  }
};

export { getBrandColor, getCountryColor };
