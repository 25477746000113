import React, { useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { SalesPlanningTypes } from 'api';
import PrevSalesPlannings from './PrevSalesPlannings';
import DetailsTab from './DetailsTab';
import BudgetDistributionTab from './BudgetDistributionTab';
import PerformanceTab from './PerformanceTab';

const TABS = {
  budget: 'Budget distribution',
  performance: 'Performance',
  details: 'Details',
  prevPlannings: 'Prev plannings',
};

interface Props {
  inline?: boolean;
  data: SalesPlanningTypes.ItemData;
}

const PageTabs = (props: Props): JSX.Element | null => {
  const { data, inline } = props;

  const [activeTab, setActiveTab] = useState(TABS.budget);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <Tab label={TABS.budget} value={TABS.budget} />
        <Tab label={TABS.performance} value={TABS.performance} />
        <Tab label={TABS.details} value={TABS.details} />
        {inline && (
          <Tab label={TABS.prevPlannings} value={TABS.prevPlannings} />
        )}
      </Tabs>
      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.performance && (
          <PerformanceTab planning={data} inline={inline} />
        )}
        {activeTab === TABS.details && (
          <DetailsTab planning={data} inline={inline} />
        )}
        {activeTab === TABS.budget && (
          <BudgetDistributionTab planning={data} inline={inline} />
        )}
        {activeTab === TABS.prevPlannings && (
          <PrevSalesPlannings planning={data} inline />
        )}
      </Stack>
    </>
  );
};

export default PageTabs;
