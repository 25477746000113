import { Box, BoxProps, Skeleton } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import imageIcon from 'assets/imageIcon.svg';

type Props = React.VideoHTMLAttributes<HTMLVideoElement> &
  Omit<BoxProps, 'component'>;

const Video = forwardRef((props: Props, ref): JSX.Element | null => {
  const { src, ...rest } = props;

  const [isBroken, setIsBroken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleBrokenLink = () => {
    setIsBroken(true);
    setIsLoading(false);
  };

  const handleLoaded = () => {
    setIsLoading(false);
  };

  if (isBroken || !src) {
    return <Box sx={rest.sx} component="img" src={imageIcon} />;
  }

  return (
    <>
      <Box
        {...rest}
        ref={ref}
        component="video"
        src={src}
        onError={handleBrokenLink}
        onCanPlay={handleLoaded}
        {...(isLoading && {
          style: {
            display: 'none',
          },
        })}
      />
      {isLoading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          {...(rest.sx && { sx: rest.sx })}
        />
      )}
    </>
  );
});

export default Video;
