import { InfluencerTypes } from 'api';
import {
  InstagramIcon,
  PinterestIcon,
  TikTokIcon,
  YoutubeIcon,
  SnapchatIcon,
  InstagramColorIcon,
  TikTokColorIcon,
  YoutubeColorIcon,
} from 'components/icons';
import { MenuOptions, SelectOptions } from 'types/common';
import { enumToOptions } from 'helpers/common';

const SIZE_CAPTIONS = {
  [InfluencerTypes.SizeEnum.MICRO]: '<20k followers',
  [InfluencerTypes.SizeEnum.MACRO]: '20-150k followers',
  [InfluencerTypes.SizeEnum.MEGA]: '150-1000k followers',
  [InfluencerTypes.SizeEnum.CELEBRITY]: '> 1000k followers',
};

type PlatformLowercase = {
  [key in Lowercase<InfluencerTypes.PlatformEnum>]: InfluencerTypes.PlatformEnum;
};

const PLATFORM_LOWERCASE: PlatformLowercase = {
  instagram: InfluencerTypes.PlatformEnum.Instagram,
  tiktok: InfluencerTypes.PlatformEnum.Tiktok,
  youtube: InfluencerTypes.PlatformEnum.Youtube,
  snapchat: InfluencerTypes.PlatformEnum.Snapchat,
  pinterest: InfluencerTypes.PlatformEnum.Pinterest,
};

const PLATFORM_ICONS = {
  [InfluencerTypes.PlatformEnum.Instagram]: InstagramIcon,
  [InfluencerTypes.PlatformEnum.Tiktok]: TikTokIcon,
  [InfluencerTypes.PlatformEnum.Youtube]: YoutubeIcon,
  [InfluencerTypes.PlatformEnum.Snapchat]: SnapchatIcon,
  [InfluencerTypes.PlatformEnum.Pinterest]: PinterestIcon,
};

const PLATFORM_ICONS_COLOR = {
  [InfluencerTypes.PlatformEnum.Instagram]: InstagramColorIcon,
  [InfluencerTypes.PlatformEnum.Tiktok]: TikTokColorIcon,
  [InfluencerTypes.PlatformEnum.Youtube]: YoutubeColorIcon,
  [InfluencerTypes.PlatformEnum.Snapchat]: SnapchatIcon,
  [InfluencerTypes.PlatformEnum.Pinterest]: PinterestIcon,
};

const AGE_OPTIONS: SelectOptions = enumToOptions(InfluencerTypes.AgeEnum);

const SIZE_OPTIONS: MenuOptions = Object.values(InfluencerTypes.SizeEnum).map(
  (o) => ({
    label: o,
    value: o,
    caption: SIZE_CAPTIONS[o],
  }),
);

const VERTICALS_OPTIONS: SelectOptions = enumToOptions(
  InfluencerTypes.VerticalsEnum,
);

const GENDER_OPTIONS: SelectOptions = enumToOptions(InfluencerTypes.GenderEnum);

const PLATFORMS_OPTIONS_COLOR = Object.entries(PLATFORM_ICONS_COLOR).map(
  ([key, value]) => ({ name: key, Icon: value }),
);

const PLATFORMS_OPTIONS = Object.entries(PLATFORM_ICONS).map(
  ([key, value]) => ({ label: key, value: key, Icon: value }),
);

const SOCIAL_NETWORKS_LINKS = {
  [InfluencerTypes.PlatformEnum.Instagram]: (username: string) =>
    `https://instagram.com/${username}`,
  [InfluencerTypes.PlatformEnum.Tiktok]: (username: string) =>
    `https://www.tiktok.com/@${username}`,
  [InfluencerTypes.PlatformEnum.Youtube]: (link: string) => link,
  [InfluencerTypes.PlatformEnum.Snapchat]: (username: string) =>
    `https://www.snapchat.com/add/${username}`,
  [InfluencerTypes.PlatformEnum.Pinterest]: (username: string) =>
    `https://pinterest.com/${username}`,
};

const EVENT_OPTIONS = [
  'Ava & May anniversary',
  'Babyparty',
  'Birthday',
  'Birthday of kids',
  'Marriage anniversary',
  'Moving day / House building',
  'Pregnancy',
].map((o) => ({ label: o, value: o }));

const INFLUENCERS = {
  AGE_OPTIONS,
  SIZE_OPTIONS,
  VERTICALS_OPTIONS,
  GENDER_OPTIONS,
  SOCIAL_NETWORKS_LINKS,
  PLATFORMS_OPTIONS_COLOR,
  PLATFORM_ICONS,
  PLATFORM_ICONS_COLOR,
  PLATFORMS_OPTIONS,
  EVENT_OPTIONS,
  PLATFORM_LOWERCASE,
};

export default INFLUENCERS;
