import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  DeleteOutlined,
  LaunchOutlined,
  LinkOutlined,
} from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  ConfirmDialogControlled,
  CloseDetailsDrawer,
} from 'components';
import { NavLink, useNavigate } from 'react-router-dom';
import { appLinks } from 'routes/links';
import { negotiations, NegotiationTypes } from 'api';
import { useApiRequest, useClipboard, useFiltersSearchParams } from 'hooks';
import { QueryObserverResult } from '@tanstack/react-query';

interface Props {
  negotiation: NegotiationTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Actions = (props: Props): JSX.Element => {
  const { negotiation, refetch, inline } = props;
  const navigate = useNavigate();
  const handleCopy = useClipboard();
  const { handleFilterChange } = useFiltersSearchParams();

  const { isLoading, requestFn } = useApiRequest(() =>
    negotiations.remove(negotiation._id.$oid),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleDelete = useCallback(async () => {
    await requestFn({
      successMessage: 'Negotiation deleted',
    });

    setIsDeleteDialogOpen(false);

    if (inline) {
      handleFilterChange('splitItemId', undefined);
    } else {
      navigate(-1);
    }
  }, [handleFilterChange, inline, navigate, requestFn]);

  const actions: ActionButtonProps<NegotiationTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.negotiations.details.as(item.negotiation_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin +
              appLinks.negotiations.details.as(item.negotiation_id),
          ),
      },
      {
        name: 'Edit negotiation',
        EditButtonProps: {
          inline,
          idKey: 'negotiationId',
          id: (item) => item.negotiation_id,
          link: appLinks.negotiations.edit.as,
        },
      },
      {
        name: 'Delete negotiation',
        Icon: DeleteOutlined,
        onClick: handleOpenDialog,
      },
      {
        name: 'Bookmark negotiation',
        BookmarkProps: {
          itemId: (item) => item.negotiation_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'negotiation_ids',
        },
      },
    ],
    [handleCopy, inline],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={inline ? 7 : 4}
          actions={actions}
          data={negotiation}
          refetch={refetch}
        />
        <ConfirmDialogControlled
          isLoading={isLoading}
          name="negotiation"
          isOpen={isDeleteDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDelete}
        />
      </Grid>
      {negotiation.status === NegotiationTypes.StatusEnum.approved && (
        <Grid item>
          <Button
            color="primary"
            component={NavLink}
            to={appLinks.deals.create.as(negotiation.influencer_id.$oid)}
          >
            Add deal
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Actions;
