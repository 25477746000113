import { uniqBy } from 'lodash';
import { AutocompleteSearchItem } from 'api/types/common';
import { LocalStorage, LocalStorageEnum } from './localStorage';

interface SearchHistoryData {
  key: string;
  items: AutocompleteSearchItem[];
}

const getSearchHistoryItems = (key: string) => {
  const searchHistory = LocalStorage.get(LocalStorageEnum.SEARCH_HISTORY);

  const parsed = searchHistory
    ? (JSON.parse(searchHistory) as SearchHistoryData[])
    : [];

  const item = parsed.find((p) => p.key === key);

  return item || { key, items: [] };
};

const setSearchHistoryItems = (key: string, data: AutocompleteSearchItem) => {
  const searchHistory = LocalStorage.get(LocalStorageEnum.SEARCH_HISTORY);
  const parsedData = searchHistory
    ? (JSON.parse(searchHistory) as SearchHistoryData[])
    : [];

  if (!parsedData.find((p) => p.key === key)) {
    parsedData.push({ key, items: [] });
  }

  const newData = parsedData.map((p) => {
    if (p.key !== key) {
      return p;
    }
    const newItems = uniqBy([data, ...p.items], 'name');
    return {
      ...p,
      items: newItems.length > 5 ? newItems.slice(0, -1) : newItems,
    };
  });

  LocalStorage.set(LocalStorageEnum.SEARCH_HISTORY, JSON.stringify(newData));
};

const removeSearchHistoryItem = (key: string, id: string) => {
  const searchHistory = LocalStorage.get(LocalStorageEnum.SEARCH_HISTORY);
  const parsedData = searchHistory
    ? (JSON.parse(searchHistory) as SearchHistoryData[])
    : [];

  if (!parsedData.find((p) => p.key === key)) {
    parsedData.push({ key, items: [] });
  }

  const newData = parsedData.map((p) => {
    if (p.key !== key) {
      return p;
    }

    return {
      ...p,
      items: p.items.filter((item) => item._id.$oid !== id),
    };
  });

  LocalStorage.set(LocalStorageEnum.SEARCH_HISTORY, JSON.stringify(newData));
};

export {
  getSearchHistoryItems,
  setSearchHistoryItems,
  removeSearchHistoryItem,
};
