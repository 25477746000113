import React, { ChangeEvent, useMemo, useState } from 'react';
import { NegotiationTypes } from 'api';
import { Stack, TextField } from '@mui/material';
import { EuroOutlined } from '@mui/icons-material';
import { getBudget, getCPM } from 'helpers/calculations';

interface Props {
  negotiation: NegotiationTypes.ItemData;
}

const CPMCalculator = (props: Props): JSX.Element | null => {
  const { negotiation } = props;

  const channelReach = useMemo(
    () => negotiation.channel_reach_at_creation || 0,
    [negotiation.channel_reach_at_creation],
  );

  const [reach, setReach] = useState<string | number>(0);
  const [budget, setBudget] = useState<string | number>(0);
  const [cpm, setCpm] = useState<string | number>(0);

  const handleBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? +e.target.value : '';
    setBudget(value);

    if (value) {
      const newCPM = getCPM({
        budget: +value.toFixed(2),
        reach: channelReach,
      });

      setCpm(newCPM);
    }
  };

  const handleCpmChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? +e.target.value : '';
    setCpm(value);

    if (value) {
      const newBudget = getBudget({
        cpm: +value.toFixed(2),
        reach: channelReach,
      });

      setBudget(newBudget);
    }
  };

  return (
    <Stack rowGap={2}>
      <TextField
        type="number"
        name="avgViews"
        label={`${negotiation.channel} avg views`}
        value={channelReach || reach}
        disabled={!!channelReach}
        onChange={(e) => setReach(e.target.value ? +e.target.value : '')}
        onBlur={(e) => setReach(+(+e.target.value).toFixed(2))}
      />
      <TextField
        type="number"
        name="budget"
        label="Budget"
        value={budget}
        onChange={handleBudgetChange}
        onBlur={(e) => setBudget(+(+e.target.value).toFixed(2))}
        InputProps={{
          endAdornment: <EuroOutlined />,
          notched: false,
          inputProps: {
            min: 0,
          },
        }}
      />
      <TextField
        type="number"
        name="cpm"
        label="CPM"
        value={cpm}
        onChange={handleCpmChange}
        onBlur={(e) => setCpm(+(+e.target.value).toFixed(2))}
        InputProps={{
          notched: false,
          endAdornment: <EuroOutlined />,
        }}
      />
    </Stack>
  );
};

export default CPMCalculator;
