import React from 'react';
import { Stack } from '@mui/material';
import { TooltipChip, BrandChip } from 'components';
import { DealTypes } from 'api';
import { getDealStatusColor } from 'helpers/getStatusColor';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const InfoRow = (props: Props): JSX.Element => {
  const { deal } = props;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      <BrandChip brand={deal.brand} country={deal.country_team} />
      <TooltipChip
        title="Status"
        label={deal.status}
        color={getDealStatusColor(deal.status)}
      />
      <TooltipChip
        title="Channel"
        label={deal.strategy}
        color={getDealStatusColor(deal.status)}
      />
      <TooltipChip
        title="Sub channel"
        label={deal.channel}
        color={getDealStatusColor(deal.status)}
      />
      <TooltipChip
        title="Start date"
        label={`Start ${getFormattedDateFromNow(deal.start_date)}`}
        color="success"
      />
      {deal.end_date && (
        <TooltipChip
          title="End date"
          label={`End ${getFormattedDateFromNow(deal.end_date)}`}
          color="error"
        />
      )}
      {deal.reminder_date && (
        <TooltipChip
          title="Reminder date"
          label={`Reminder ${getFormattedDateFromNow(deal.reminder_date)}`}
          color="warning"
        />
      )}
    </Stack>
  );
};

export default InfoRow;
