import {
  Box,
  Skeleton,
  TableCell as MuiTableCell,
  TableCellProps,
} from '@mui/material';
import { FC } from 'react';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.Cell;
  isFirstItem: boolean;
  size: TableCellProps['size'] | undefined;
}

const TableCellSkeleton: FC<Props> = (props: Props) => {
  const { cell, isFirstItem, size } = props;

  return (
    <MuiTableCell
      size={size}
      align={cell.align || 'left'}
      scope={isFirstItem ? 'row' : undefined}
      component={isFirstItem ? 'th' : undefined}
      sx={(theme) => ({
        maxWidth: 400,
        overflow: 'hidden',
        borderLeft: `1px solid ${
          cell.type === 'actions' ? theme.palette.divider : 'transparent'
        }`,
      })}
    >
      {cell.type === 'node' && <Skeleton variant="text" height={32} />}
      {cell.type === 'actions' && <Skeleton variant="text" height={32} />}
      {cell.type === 'brands' && <Skeleton variant="text" height={32} />}
      {cell.type === 'title' && <Skeleton variant="text" height={32} />}
      {cell.type === 'text' && <Skeleton variant="text" height={24} />}
      {cell.type === 'avatar' && (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      {cell.type === 'chip' && (
        <Skeleton variant="circular" height={32} sx={{ borderRadius: 4 }} />
      )}
      {cell.type === 'brands-status' && (
        <Skeleton variant="circular" height={32} sx={{ borderRadius: 4 }} />
      )}
      {cell.type === 'country' && (
        <Skeleton variant="circular" height={32} sx={{ borderRadius: 1 }} />
      )}
      {cell.type === 'user' && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ width: 'calc(100% - 40px)', ml: 1 }}>
            <Skeleton variant="text" height={24} width="100%" />
            {cell.nickname && (
              <Skeleton variant="text" height={16} width="60%" />
            )}
          </Box>
        </Box>
      )}
      {cell.type === 'growth' && (
        <Box>
          <Skeleton variant="text" height={16} />
          <Skeleton variant="text" height={16} />
        </Box>
      )}
      {cell.type === 'platforms' && (
        <Skeleton variant="rectangular" height={16} sx={{ borderRadius: 4 }} />
      )}
      {cell.type === 'sparkline' && (
        <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 4 }} />
      )}
      {cell.type === 'chipCheckmark' && (
        <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 4 }} />
      )}
    </MuiTableCell>
  );
};

export default TableCellSkeleton;
