import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardActions, CardHeader, Skeleton, Stack } from '@mui/material';
import { deals, DealTypes } from 'api';
import { colors } from 'theme/constants';
import FileUploadCard from './FileUploadCard';

interface Props {
  deal: DealTypes.ItemData;
}

const LoadingCard = () => (
  <Card sx={{ p: 1 }}>
    <CardHeader
      title={<Skeleton variant="text" width="60%" />}
      subheader={<Skeleton variant="text" />}
    />
    <CardActions>
      <Skeleton
        variant="rectangular"
        sx={(theme) => ({
          height: '40px',
          width: '50%',
          borderRadius: `${theme.shape.borderRadius}px`,
        })}
      />
    </CardActions>
  </Card>
);

const AttachmentUpload = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const { data: briefing, refetch: refetchBriefing } = useQuery(
    [
      deals.endpoints.getAttachment(
        deal._id.$oid,
        DealTypes.AttachmentTypeEnum.Briefing,
      ),
    ],
    () =>
      deals.getAttachment(deal._id.$oid, DealTypes.AttachmentTypeEnum.Briefing),
  );

  const { data: invoice, refetch: refetchInvoice } = useQuery(
    [
      deals.endpoints.getAttachment(
        deal._id.$oid,
        DealTypes.AttachmentTypeEnum.Invoice,
      ),
    ],
    () =>
      deals.getAttachment(deal._id.$oid, DealTypes.AttachmentTypeEnum.Invoice),
  );

  const { data: contract, refetch: refetchContract } = useQuery(
    [
      deals.endpoints.getAttachment(
        deal._id.$oid,
        DealTypes.AttachmentTypeEnum.Contract,
      ),
    ],
    () =>
      deals.getAttachment(deal._id.$oid, DealTypes.AttachmentTypeEnum.Contract),
  );

  return (
    <Stack rowGap={3}>
      {!briefing ? (
        <LoadingCard />
      ) : (
        <FileUploadCard
          id={deal._id.$oid}
          type={DealTypes.AttachmentTypeEnum.Briefing}
          data={briefing}
          onSubmit={refetchBriefing}
          color={colors.red}
          disabled={deal.is_deleted}
        />
      )}
      {!invoice ? (
        <LoadingCard />
      ) : (
        <FileUploadCard
          id={deal._id.$oid}
          type={DealTypes.AttachmentTypeEnum.Invoice}
          data={invoice}
          onSubmit={refetchInvoice}
          color={colors.yellow}
          disabled={deal.is_deleted}
        />
      )}
      {!contract ? (
        <LoadingCard />
      ) : (
        <FileUploadCard
          id={deal._id.$oid}
          type={DealTypes.AttachmentTypeEnum.Contract}
          data={contract}
          onSubmit={refetchContract}
          color={colors.purple}
          disabled={deal.is_deleted}
        />
      )}
    </Stack>
  );
};

export default AttachmentUpload;
