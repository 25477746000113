import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/links';
import LogoIcon from './icons/LogoIcon';

interface Props {
  size?: number;
}

const Logo = (props: Props): JSX.Element => {
  const { size = 4 } = props;

  const dimensions = useMemo(
    () => ({
      width: size * 8,
      height: size * 7,
    }),
    [size],
  );

  return (
    <NavLink to={appLinks.index.link}>
      <LogoIcon sx={dimensions} />
    </NavLink>
  );
};

export default Logo;
