import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { UserTypes } from 'api';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { colors } from 'theme/constants';
import { InfoCard, InfoCardItem } from 'components';

interface Props {
  inline?: boolean;
  user: UserTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { user, inline } = props;

  const generalInfo: InfoCardItem<UserTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'email',
        label: 'Email',
        value: user.email,
      },
      {
        key: 'first_name',
        label: 'First name',
        value: user.first_name,
      },
      {
        key: 'last_name',
        label: 'Last name',
        value: user.last_name,
      },
      {
        key: 'full_name',
        label: 'Full name',
        value: user.full_name,
      },
      {
        key: 'brand',
        label: 'Brand',
        value: user.brand,
      },
      {
        key: 'country_team',
        label: 'Country team',
        value: user.country_team,
      },
    ],
    [
      user.email,
      user.first_name,
      user.last_name,
      user.full_name,
      user.brand,
      user.country_team,
    ],
  );

  const otherInfo: InfoCardItem<UserTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'access',
        label: 'Access',
        value: user.access,
      },
      {
        key: 'role',
        label: 'Role',
        value: user.role,
      },
      {
        key: 'invite_sent',
        label: 'Invite sent',
        value: JSON.stringify(user.invite_sent),
      },
      {
        key: 'created_at',
        label: 'Created at',
        value: getFormattedDate(user.created_at),
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        value: getFormattedDate(user.updated_at),
      },
    ],
    [
      user.access,
      user.created_at,
      user.invite_sent,
      user.role,
      user.updated_at,
    ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <InfoCard
          title="General info"
          data={generalInfo}
          color={colors.darkOrange}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <InfoCard
          title="Other info"
          data={otherInfo}
          color={colors.darkYellow}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
