import { MouseEvent } from 'react';
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ChevronRight, Verified } from '@mui/icons-material';
import { getUserInitials } from 'helpers/common';
import { useNavigate } from 'react-router-dom';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.UserCell<DataTableProps.Item> &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const UserCell = (props: Props): JSX.Element | null => {
  const { data, cell } = props;
  const navigate = useNavigate();

  const handleLinkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (cell.to) {
      navigate(cell.to(data));
    }
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Avatar
          src={(cell.src ? cell.src(data) : data[cell.key]) || ''}
          alt={cell.name ? cell.name(data) : undefined}
          {...cell.AvatarProps}
        >
          {cell.name && cell.name(data)
            ? getUserInitials(cell.name(data))
            : undefined}
        </Avatar>
        <Box ml={1.5} mr={cell.to ? 1 : undefined}>
          {cell.isVerified && cell.isVerified(data) ? (
            <Box display="flex" alignItems="center" columnGap={1}>
              <Typography>{cell.name(data) || '-'}</Typography>
              <Verified color="primary" fontSize="small" />
            </Box>
          ) : (
            <Typography>{cell.name(data) || '-'}</Typography>
          )}
          {cell.nickname && cell.nickname(data) && (
            <Typography variant="caption" color="text.secondary">
              {cell.nickname(data)}
            </Typography>
          )}
        </Box>
        {cell.to && cell.to(data) && (
          <Box ml="auto" mr={0}>
            <Tooltip title={`Open ${cell.name(data)} page`}>
              <IconButton size="small" onClick={handleLinkClick}>
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserCell;
