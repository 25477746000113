import React, { useRef, useState } from 'react';
import {
  Box,
  Card,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { BarChartOutlined, ShowChartOutlined } from '@mui/icons-material';
import { ChartVariant, InfluencerChartOption } from 'types/influencers';
import Chart from './Chart';
import ChartTabs from './ChartTabs';

interface Props {
  isLoading: boolean;
  chartData: any[];
  tabs: InfluencerChartOption[];
  chartDataType: InfluencerChartOption['type'];
  setChartDataType: React.Dispatch<
    React.SetStateAction<InfluencerChartOption['type']>
  >;
}

const ComparisonChart = (props: Props): JSX.Element | null => {
  const { chartData, setChartDataType, isLoading, chartDataType, tabs } = props;

  const $chartTabs = useRef<HTMLDivElement | null>(null);

  const [chartVariant, setChartVariant] = useState<ChartVariant>('line');

  const handleChartVariantChange = (_: unknown, value: ChartVariant) => {
    setChartVariant(value);
  };

  const handleChartDataTypeChange = (value: InfluencerChartOption['type']) => {
    setChartDataType(value);
  };

  const selectedTab = tabs.find((o) => o.type === chartDataType);

  const blockHeight = ($chartTabs.current?.clientHeight || 400) - 48 - 40;

  return (
    <Card variant="outlined">
      <ChartTabs
        ref={$chartTabs}
        value={chartDataType}
        onChange={handleChartDataTypeChange}
        options={tabs}
      >
        <Stack p={3}>
          <Stack direction="row" columnGap={2} alignItems="center">
            <Typography variant="h6" gutterBottom mr="auto">
              {selectedTab?.label}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={chartVariant}
              exclusive
              size="small"
              onChange={handleChartVariantChange}
              aria-label="Platform"
            >
              <ToggleButton value="line">
                <ShowChartOutlined />
              </ToggleButton>
              <ToggleButton value="bar">
                <BarChartOutlined />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {isLoading ? (
            <Box mt={3}>
              <Skeleton
                variant="rectangular"
                height={`${blockHeight - 24}px`}
                sx={(t) => ({ borderRadius: `${t.shape.borderRadius}px` })}
              />
            </Box>
          ) : (
            <Chart
              variant={chartVariant}
              data={chartData}
              height={blockHeight}
            />
          )}
        </Stack>
      </ChartTabs>
    </Card>
  );
};

export default ComparisonChart;
