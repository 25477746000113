import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { useMemo } from 'react';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import { ListReportData } from 'api/types/common';

interface Props {
  data: ListReportData | undefined;
}

const ReportsBlock = (props: Props): JSX.Element | null => {
  const { data } = props;

  const values = useMemo(
    () => [
      {
        label: 'Potential Budget',
        value: formatCurrency(data?.sum_budget_potential, 0),
      },
      {
        label: 'Actual Budget',
        value: formatCurrency(data?.sum_budget_actual, 0),
      },
      {
        label: 'Potential Revenue',
        value: formatCurrency(data?.sum_revenue_potential, 0),
      },
      {
        label: 'Actual Revenue',
        value: formatCurrency(data?.sum_revenue_actual, 0),
      },
      {
        label: 'Gross Profit',
        value: formatCurrency(data?.sum_gross_profit_actual, 0),
      },
      {
        label: 'Potential ROI',
        value: formatNumber(data?.avg_potential_roi),
      },
      {
        label: 'Actual ROI',
        value: formatNumber(data?.avg_roi_actual),
      },
      {
        label: 'NCR',
        value: formatPercent(data?.avg_new_customers_ratio),
      },
      {
        label: 'Orders',
        value: formatNumber(data?.sum_orders_nb_actual),
      },
      {
        label: 'Basket size',
        value: formatCurrency(data?.avg_basket, 0),
      },
      {
        label: 'Actual CM3',
        value: formatCurrency(data?.cm3_actual, 0),
      },
      {
        label: 'Actual CM3 %',
        value: formatPercent(data?.cm3_actual_pc),
      },
      {
        label: 'Conversion rate %',
        value: formatPercent(data?.conversion_rate_pc),
      },
    ],
    [data],
  );

  return (
    <Box
      id="table-reports-block"
      p={1.5}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        overflowX: 'auto',
      }}
    >
      <Stack spacing={1} direction="row" justifyContent="space-between">
        {values.map((v) => (
          <Box
            key={v.label}
            sx={(theme) => ({
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey['900']
                  : theme.palette.grey['100'],
              borderRadius: `${theme.shape.borderRadius}px`,
              p: [1.5, 1],
              textAlign: 'center',
              width: '100%',
            })}
          >
            <Typography variant="h6" color="text.primary">
              {data ? v.value : <Skeleton />}
            </Typography>
            <Typography variant="body1" noWrap>
              {v.label}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default ReportsBlock;
