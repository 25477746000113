import React, { useCallback } from 'react';
import {
  ListItemIcon,
  ListItemText,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
import { InfluencerTypes } from 'api';
import INFLUENCERS from 'constants/influencers';
import { useStoreContext } from 'store';
import { setStoreState } from 'store/actions';
import { Diversity3Outlined } from '@mui/icons-material';

const options = [
  {
    name: 'All platforms',
    Icon: Diversity3Outlined,
  },
  ...INFLUENCERS.PLATFORMS_OPTIONS_COLOR,
];

const SocialNetworkFilter = () => {
  const {
    state: { activeSocialPlatform },
    dispatch,
  } = useStoreContext();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        event.target.value === 'All platforms'
          ? undefined
          : (event.target.value as InfluencerTypes.PlatformEnum);

      dispatch(
        setStoreState({
          activeSocialPlatform: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <TextField
      id="social-networks"
      size="small"
      select
      value={activeSocialPlatform || 'All platforms'}
      onChange={handleChange}
      SelectProps={{
        renderValue: (value1) => {
          const option = options.find((v) => v.name === value1);
          if (!option) return '';
          const { Icon, name } = option;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={name} sx={{ m: 0 }} />
            </Box>
          );
        },
      }}
    >
      {options.map((option) => {
        const { Icon } = option;

        return (
          <MenuItem
            key={option.name}
            value={option.name}
            sx={{ display: 'flex' }}
          >
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SocialNetworkFilter;
