import { colors } from 'theme/constants';

const getRandomColor = (index?: number) => {
  const contrastColors = [
    colors.accent,
    colors.orange,
    colors.red,
    colors.yellow,
    colors.blue,
    colors.green,
    colors.darkGreen,
    colors.darkOrange,
    colors.darkYellow,
    colors.darkRed,
    colors.darkBlue,
    colors.purple,
    colors.pink1,
    colors.pink2,
    colors.green2,
  ];

  if (index) {
    return contrastColors[index] || contrastColors[0];
  }

  const random = Math.floor((Math.random() * 100) / contrastColors.length);

  return contrastColors[random];
};

export default getRandomColor;
