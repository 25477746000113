import { DownloadProgress } from 'ky/distribution/types/options';
import ky from 'ky';
import apiClient, { authBeforeRequestHook } from '../apiClient';
import DealTypes from './types';

const endpoints = {
  getList: 'deals/filter',
  bulkUpdateStatus: 'deals/bulk_update_status',
  bulkCampaignAssign: 'deals/bulk_campaign_assign',
  getById: (id: string) => `deals/${id}`,
  getDealLogbook: (id: string) => `deals/${id}/logbook`,
  getDealProducts: (id: string) => `deals/${id}/fetch_product_analytics`,
  create: 'deals',
  update: (id: string) => `deals/${id}`,
  remove: (id: string) => `deals/${id}`,
  getPostAttachments: (id: string) => `deals/${id}/post_attachments`,
  uploadPostAttachment: (id: string) => `deals/${id}/post_upload`,
  getReminderStories: (id: string) => `deals/${id}/igs_sequences/reminder_post`,
  getStories: (id: string) => `deals/${id}/igs_sequences/post`,
  getAttachment: (id: string, type: DealTypes.AttachmentTypeEnum) =>
    `deals/${id}/${type}`,
  removeAttachment: (id: string, type: DealTypes.AttachmentTypeEnum) =>
    `deals/${id}/${type}`,
  uploadAttachment: (id: string, type: DealTypes.AttachmentTypeEnum) =>
    `deals/${id}/${type}`,
};

const getList = (params: DealTypes.GetListParams): DealTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getListCondensed = (
  params: Omit<DealTypes.GetListParams, 'limit' | 'page'>,
): DealTypes.ListCondensedResponse =>
  apiClient
    .post(endpoints.getList, {
      json: { ...params, response_format: 'condensed' },
    })
    .then((response) => response.json());

const getById = (id: string): DealTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const getDealLogbook = (id: string): DealTypes.LogbookResponse =>
  apiClient
    .get(endpoints.getDealLogbook(id))
    .then((response) => response.json());

const getDealProducts = (id: string): DealTypes.DealProductsResponse =>
  apiClient
    .get(endpoints.getDealProducts(id))
    .then((response) => response.json());

const create = (data: DealTypes.ItemCreateParams): DealTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, { json: { deal: data }, throwHttpErrors: false })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data: any) => {
          throw new Error(JSON.stringify(data));
        });
      }
      return response.json();
    });

const update = (
  id: string,
  data: Partial<DealTypes.ItemCreateParams | DealTypes.ItemData>,
): DealTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), { json: { deal: data } })
    .then((response) => response.json());

const remove = (id: string): DealTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), { json: { deal: { is_deleted: true } } })
    .then((response) => response.json());

const getStories = (id: string) =>
  apiClient
    .post(endpoints.getStories(id), {
      headers: {
        'Content-Type': 'application/json; application/octet-stream',
      },
      // responseType: 'arraybuffer',
    })
    .then((response) => response.arrayBuffer());

const getReminderStories = (id: string) =>
  apiClient
    .post(endpoints.getReminderStories(id), {
      headers: {
        'Content-Type': 'application/json; application/octet-stream',
      },
      // responseType: 'arraybuffer',
    })
    .then((response) => response.arrayBuffer());

const getPostAttachments = (id: string): Promise<string[]> =>
  apiClient
    .get(endpoints.getPostAttachments(id))
    .then((response) => response.json());

const uploadPostAttachment = (
  id: string,
  file: File,
  onDownloadProgress: (progress: DownloadProgress, chunk: Uint8Array) => void,
) => {
  const headers = {
    'content-type': 'video/mpeg,video/mp4,video/quicktime,image/jpeg,image/png',
  };
  return apiClient
    .post(endpoints.uploadPostAttachment(id), {
      headers,
      body: file,
      onDownloadProgress,
    })
    .then((response) => response.json());
};

const uploadAttachment = ({
  id,
  type,
  file,
  onDownloadProgress,
}: {
  id: string;
  type: DealTypes.AttachmentTypeEnum;
  file: File;
  onDownloadProgress: (progress: DownloadProgress, chunk: Uint8Array) => void;
}): DealTypes.AttachmentResponse => {
  const formData = new FormData();
  formData.append('name', file.name);
  formData.append('file', file);

  return ky
    .post(
      `${process.env.REACT_APP_API_URL}/${endpoints.uploadAttachment(
        id,
        type,
      )}`,
      {
        body: formData,
        onDownloadProgress,
        timeout: 1 * 60 * 1000,
        hooks: {
          beforeRequest: [authBeforeRequestHook],
        },
      },
    )
    .then((response) => response.json());
};

const getAttachment = (
  id: string,
  type: DealTypes.AttachmentTypeEnum,
): DealTypes.AttachmentResponse =>
  apiClient
    .get(endpoints.getAttachment(id, type))
    .then((response) => response.json());

const removeAttachment = (
  id: string,
  type: DealTypes.AttachmentTypeEnum,
): Promise<void> =>
  apiClient
    .delete(endpoints.removeAttachment(id, type))
    .then((response) => response.json());

const getListWithAttachments = async (params: DealTypes.GetListParams) => {
  const data = await getList(params);

  // const value = await Promise.all(
  //   data.data.map(async (item) => {
  //     const attachments = await getPostAttachments(item.deal_id);
  //
  //     return { ...item, attachments };
  //   })
  // );

  const value = await Promise.all(
    data.data.map((item) =>
      getPostAttachments(item.deal_id).then((att) => ({
        ...item,
        attachments: att,
      })),
    ),
  );

  return {
    total: data.total,
    data: value,
  };
};

const bulkUpdateStatus = (
  params: DealTypes.BulkChangeStatusParams,
): DealTypes.ListResponse =>
  apiClient
    .post(endpoints.bulkUpdateStatus, {
      json: { deal: params },
    })
    .then((response) => response.json());

const bulkCampaignAssign = (
  params: DealTypes.BulkCampaignAssignParams,
): DealTypes.ListResponse =>
  apiClient
    .post(endpoints.bulkCampaignAssign, {
      json: { deal: params },
    })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  getListCondensed,
  create,
  update,
  getById,
  remove,
  getStories,
  getReminderStories,
  getPostAttachments,
  uploadPostAttachment,
  uploadAttachment,
  getAttachment,
  removeAttachment,
  getListWithAttachments,
  bulkUpdateStatus,
  bulkCampaignAssign,
  getDealProducts,
  getDealLogbook,
};
