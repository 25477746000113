import { InfluencerTypes } from 'api';
import React from 'react';
import { SelectOptions } from '../types/common';

const getUserInitials = (name: string | null): string =>
  name
    ? name
        .split(' ')
        .map((w) => w.charAt(0))
        .join('')
        .substring(0, 2)
        .toUpperCase()
    : '';

const createElementId = (name: string) =>
  name.replaceAll(' ', '-').toLowerCase();

const getInfluencerName = (
  item: InfluencerTypes.ItemData | InfluencerTypes.ItemCondensed,
) => {
  if (item.personal_info?.first_name && item.personal_info?.last_name) {
    return `${item.personal_info.first_name} ${item.personal_info.last_name}`;
  }

  if (item.personal_info?.first_name) {
    return item.personal_info?.first_name;
  }

  if (item.personal_info?.last_name) {
    return item.personal_info?.last_name;
  }

  return item.profile_name || 'unknown';
};

const enumToOptions = (enums: Record<string, string>): SelectOptions =>
  Object.values(enums).map((o) => ({
    label: o,
    value: o,
  }));

const arrayToOptions = (value: string[]): SelectOptions =>
  value
    ? value.map((o) => ({
        label: o,
        value: o,
      }))
    : [];

const dummyAction = (item: unknown, e: React.MouseEvent) => {
  // todo - add actions
  e.stopPropagation();
  // eslint-disable-next-line no-alert
  alert(JSON.stringify(item));
};

const isImageUrl = (url: string) => url.match(/\.(jpeg|jpg|gif|png)/) != null;

const isNotEmptyValue = (value: any) => value !== undefined && value !== null;

const dummyArray = (length: number) =>
  Array.from(new Array(length)).map((_, i) => i);

const sleep = (timeout: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export {
  getUserInitials,
  createElementId,
  getInfluencerName,
  enumToOptions,
  arrayToOptions,
  dummyAction,
  isImageUrl,
  isNotEmptyValue,
  dummyArray,
  sleep,
};
