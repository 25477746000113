import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import {
  DataTableActionEnums,
  DataTableState,
  DataTableStateActions,
} from './types';

const reducer = (
  state: DataTableState,
  action: DataTableStateActions,
): DataTableState => {
  switch (action.type) {
    case DataTableActionEnums.SET_STORE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case DataTableActionEnums.setPerPage:
      return {
        ...state,
        perPage: action.payload,
        pageNumber: 1,
      };
    case DataTableActionEnums.selectAllRows:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case DataTableActionEnums.selectRow:
      return {
        ...state,
        selectedItems:
          state.selectedItems.indexOf(action.payload) > -1
            ? state.selectedItems.filter((p) => p !== action.payload)
            : [...state.selectedItems, action.payload],
      };
    case DataTableActionEnums.clearFilters:
      return {
        ...state,
        filtersState: state.emptyFiltersState,
        activeFilters: [],
      };
    case DataTableActionEnums.clearFilter:
      return {
        ...state,
        filtersState: { ...state.filtersState, [action.payload]: undefined },
        activeFilters: Object.entries({
          ...state.filtersState,
          [action.payload]: undefined,
        })
          .filter(([, value]) =>
            Array.isArray(value) ? value.length !== 0 : value !== undefined,
          )
          .map(([key]) => key),
      };
    case DataTableActionEnums.setFiltersState:
      return {
        ...state,
        filtersState: action.payload,
        activeFilters: Object.entries(action.payload)
          .filter(([, value]) =>
            Array.isArray(value) ? value.length !== 0 : value !== undefined,
          )
          .map(([key]) => key),
      };
    case DataTableActionEnums.toggleSplitView: {
      LocalStorage.set(
        LocalStorageEnum.IS_SPLIT_VIEW_ACTIVE,
        (!state.isSplitViewEnabled).toString(),
      );

      return {
        ...state,
        isSplitViewEnabled: !state.isSplitViewEnabled,
      };
    }
    default:
      return state;
  }
};

export default reducer;
