import React, { useMemo } from 'react';
import { Drawer } from '@mui/material';
import { HEADER_HEIGHT } from 'theme/constants';
import { useFiltersSearchParams } from 'hooks';
import DetailsRoutes from 'components/DetailsDrawer/DetailsRoutes';
import DataTableProps from './DataTableProps';
import { DrawerMode } from '../../types/SearchParams';

interface Props {
  data: DataTableProps.Item[];
  tableBodyItems: DataTableProps.Columns;
}

const DataTableDetailsDrawer: React.FC<Props> = (props: Props) => {
  const { tableBodyItems, data } = props;
  const {
    filtersSearchParams: { splitItemId, drawerMode },
    handleFilterChange,
  } = useFiltersSearchParams();

  const rowLinkType = useMemo(() => {
    if (data && data.length > 0) {
      const item = data[0];
      const link = tableBodyItems.find((cell) => cell.type === 'rowLink');

      if (link?.type === 'rowLink' && link.idKey) {
        return typeof link.idKey === 'string' ? link.idKey : link.idKey(item);
      }
    }

    return '';
  }, [data, tableBodyItems]);

  return (
    <Drawer
      open={!!splitItemId && !!rowLinkType}
      variant="persistent"
      anchor="right"
      onClose={() => handleFilterChange('splitItemId', undefined)}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
          width: '40vw',
          minWidth: `calc(640px - ${HEADER_HEIGHT}px)`,
          top: `${HEADER_HEIGHT}px`,
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          border: `1px solid ${theme.palette.divider}`,
        }),
      }}
    >
      {splitItemId && rowLinkType ? (
        <DetailsRoutes
          type={rowLinkType}
          id={splitItemId as string}
          drawerMode={drawerMode as DrawerMode}
        />
      ) : null}
    </Drawer>
  );
};

export default DataTableDetailsDrawer;
