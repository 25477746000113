import React from 'react';
import {
  Button,
  Chip,
  Drawer,
  Grid,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { FilterListOutlined } from '@mui/icons-material';
import { HandleChangeValue } from 'hooks';
import Filters from './Filters';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';

interface Props {
  isFiltersOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClearFilters: () => void;
  onApplyClick: () => void;
  filters: DataTableProps.Filters<Record<string, unknown>>;
  filtersState: Record<string, unknown>;
  onChange: (key: string, value: HandleChangeValue<unknown>) => void;
}

const FiltersDrawer = (props: Props): JSX.Element | null => {
  const {
    isFiltersOpen,
    filtersState,
    filters,
    onClearFilters,
    onApplyClick,
    onChange,
    onOpen,
    onClose,
  } = props;
  const {
    state: { activeFilters },
  } = useDataTableContext();
  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onOpen}
        sx={{
          whiteSpace: 'nowrap',
          //   fontWeight: 400,
          //   fontSize: '16px',
          //   color: 'rgba(0, 0, 0, 0.8)',
          //   borderColor: 'rgba(0, 0, 0, 0.23)',
          //   '&:hover': {
          //     backgroundColor: 'transparent',
          //     color: 'rgba(0, 0, 0, 0.8)',
          //     borderColor: 'rgba(0, 0, 0, 0.87)',
          //   },
        }}
        endIcon={
          !activeFilters.length ? (
            <FilterListOutlined />
          ) : (
            <Chip
              color="primary"
              size="small"
              label={activeFilters.length}
              sx={{
                '& span': {
                  fontSize: '0.8125rem',
                },
              }}
            />
          )
        }
      >
        All filters
      </Button>
      <Drawer
        anchor="right"
        open={isFiltersOpen}
        onClose={onClose}
        PaperProps={{ sx: { width: 480, p: 3 } }}
      >
        <Stack sx={{ height: '100vh' }}>
          <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">All filters</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                onClick={onClearFilters}
                disabled={!activeFilters.length}
              >
                Clear all
              </Button>
            </Grid>
          </Grid>
          <Filters
            filters={filters}
            filtersState={filtersState}
            onChange={onChange}
            StackProps={{
              spacing: 3,
              sx: {
                flex: '1 1 auto',
              },
            }}
          />
          <Box
            sx={{
              bottom: 0,
              position: 'sticky',
              zIndex: 1,
              mt: 6,
            }}
          >
            <Button color="primary" fullWidth onClick={onApplyClick}>
              Apply
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default FiltersDrawer;
