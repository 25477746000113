import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useFiltersSearchParams } from 'hooks';
import { DrawerType } from '../../types/SearchParams';

interface Props {
  id: string;
  idKey: DrawerType;
  link: (id: string) => string;
  inline?: boolean;
  inMenu?: boolean;
  name: string;
}

const EditButton = (props: Props): JSX.Element | null => {
  const { id, inline, link, idKey, inMenu, name } = props;

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const componentProps = useMemo(
    () =>
      inline
        ? {
            onClick: () => {
              updateFiltersSearchParams({
                [idKey]: id,
                drawerMode: 'edit',
              });
            },
          }
        : {
            component: NavLink,
            to: link(id),
          },
    [id, idKey, inline, link, updateFiltersSearchParams],
  );

  if (inMenu) {
    return (
      <MenuItem {...componentProps}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary={name} />
      </MenuItem>
    );
  }

  return (
    <Tooltip title={name}>
      <span>
        <IconButton size="small" {...componentProps}>
          <EditOutlined />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default EditButton;
