import React, { ChangeEvent } from 'react';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { HandleChangeValue } from 'hooks/useModel';

type Value = string | undefined;

interface Props {
  label: string;
  value: Value | undefined;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
  TextFieldProps?: TextFieldProps;
}

const RangeFilter = (props: Props): JSX.Element | null => {
  const { onChange, value, label, inline, TextFieldProps } = props;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(event.target.value);
  };

  return (
    <Box
      p={inline ? 2 : undefined}
      sx={inline ? { minWidth: '300px' } : undefined}
    >
      <TextField
        label={label}
        placeholder={`Enter ${label}`}
        {...TextFieldProps}
        value={value || ''}
        onChange={handleChange}
      />
    </Box>
  );
};

export default RangeFilter;
