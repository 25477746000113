import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, Stack } from '@mui/material';
import ErrorBoundary from 'components/ErrorBoundary';
import { useQuery } from '@tanstack/react-query';
import { useStoreContext } from 'store';
import { DRAWER_WIDTH } from 'theme/constants';
import { setStoreState } from 'store/actions';
import { AutocompleteSearchItem } from 'api/types/common';
import { useFiltersSearchParams } from 'hooks';
import TableFilters from './TableFilters';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import BulkActions from './BulkActions';
import DataTableProps from './DataTableProps';
import { useDataTableContext } from './context';
import DealsCalendar from './CalendarView/FullCalendar';
import MarketingCalendar from './MarketingCalendarView/FullCalendar';
import CardsView from './CardsView';
import DataTableDetailsDrawer from './DataTableDetailsDrawer';

const DataTableContainer: React.FC<DataTableProps.Props> = (
  props: DataTableProps.Props,
) => {
  const {
    getDataApi,
    columns,
    headers,
    createButton,
    filters,
    SearchProps,
    viewOptions,
    extraFiltersValues,
    withReportsBlock,
    title,
    dealsCalendar,
    marketingCalendar,
    skeletonNode,
    cardNode,
    idKey,
    BulkActionsProps,
    inline = false,
    defaultPerPage,
    GridItemProps,
  } = props;

  const isCalendar = dealsCalendar || marketingCalendar;

  const {
    state: { isSideNavOpen },
    dispatch,
  } = useStoreContext();

  const {
    state: {
      filtersState,
      pageNumber,
      perPage,
      selectedViewKey,
      selectedItems,
      sortDesc,
      sortBy,
      query,
      isSplitViewEnabled,
    },
  } = useDataTableContext();

  const {
    filtersSearchParams: { splitItemId },
  } = useFiltersSearchParams();

  const selectedView = useMemo(() => {
    if (!viewOptions && columns) {
      return columns;
    }

    if (!viewOptions && !columns) {
      return null;
    }

    return (
      viewOptions.find(
        (v) => v.key === selectedViewKey,
      ) as DataTableProps.ViewOption
    ).columns;
  }, [columns, selectedViewKey, viewOptions]);

  const queryFiltersState = useMemo(
    () =>
      filters?.reduce(
        (prev, current) => {
          if (current.type === 'date') {
            const searchValue = filtersState[current.key] as
              | DataTableProps.DateFilterOption
              | undefined;

            const initialValue = current.initialValue
              ? current.initialValue.value
              : undefined;

            const value = searchValue?.value || initialValue;

            return {
              ...prev,
              [current.key]: value,
            };
          }

          if (
            current.type === 'search' &&
            Array.isArray(filtersState[current.key]) &&
            filtersState[current.key].length > 0 &&
            typeof filtersState[current.key][0] === 'object'
          ) {
            const value = filtersState[current.key].map(
              (v: AutocompleteSearchItem) => v.name,
            );

            return {
              ...prev,
              [current.key]: value,
            };
          }

          return {
            ...prev,
            [current.key]: filtersState[current.key],
          };
        },
        {} as Record<string, any>,
      ) || {},
    [filters, filtersState],
  );

  const [calendarViewDateRange, setCalendarViewDateRange] = useState<
    DataTableProps.DateFilterOption['value'] | undefined
  >(undefined);

  const {
    data: fetchedData,
    isError,
    isFetching,
    isFetched,
    refetch,
  } = useQuery(
    [
      headers.link,
      sortDesc,
      sortBy,
      {
        query,
        pageNumber,
        queryFiltersState,
        perPage,
        extraFiltersValues,
        calendarViewDateRange,
      },
    ],
    () =>
      getDataApi({
        limit: perPage,
        page: pageNumber,
        sort_by: sortBy || undefined,
        sort_desc: sortDesc,
        filters: {
          ...queryFiltersState,
          ...extraFiltersValues,
          ...(SearchProps?.filterKey && { [SearchProps?.filterKey]: query }),
          ...(isCalendar && { start_date: calendarViewDateRange }),
        },
      }),
    {
      initialData: {
        data: [],
        total: 0,
      },
      ...(isCalendar && { enabled: !!calendarViewDateRange }),
    },
  );

  useEffect(() => {
    if (!inline) {
      if (refetch) {
        dispatch(setStoreState({ refetchTableData: refetch }));
      }
    }
  }, [dispatch, refetch, inline]);

  useEffect(() => {
    if (!inline) {
      dispatch(setStoreState({ tableItemsCount: fetchedData.total }));
    }

    return () => {
      if (!inline) {
        dispatch(setStoreState({ tableItemsCount: undefined }));
      }
    };
  }, [dispatch, fetchedData.total, inline]);

  const isLoading = isFetching && !isFetched;

  const isHeaderEmpty =
    !SearchProps &&
    !viewOptions &&
    !createButton &&
    !withReportsBlock &&
    !filters &&
    !BulkActionsProps;

  if (isError) {
    return <ErrorBoundary />;
  }

  return (
    <Box id={inline ? undefined : 'data-table'} p={inline ? undefined : 0}>
      <Card
        variant={inline ? 'outlined' : undefined}
        sx={(theme) =>
          splitItemId && !isCalendar
            ? {
                background: (theme) =>
                  // eslint-disable-next-line no-nested-ternary
                  theme.palette.mode === 'dark'
                    ? inline
                      ? theme.palette.background.paper
                      : theme.palette.background.default
                    : theme.palette.background.paper,
                borderRadius: 0,
                border: 'unset',
                borderTop: `1px solid ${theme.palette.divider}`,
                width: '100%',
                transition: 'max-width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                maxWidth: (theme) =>
                  isSideNavOpen
                    ? `calc(100vw - ${DRAWER_WIDTH}px - 40vw)`
                    : `calc(100vw - ${theme.spacing(8)} - 4px - 40vw)`,
              }
            : {
                borderRadius: inline ? undefined : 0,
                border: inline ? undefined : 'unset',
                borderTop: inline
                  ? undefined
                  : `1px solid ${theme.palette.divider}`,
                background: (theme) =>
                  // eslint-disable-next-line no-nested-ternary
                  theme.palette.mode === 'dark'
                    ? inline
                      ? theme.palette.background.paper
                      : theme.palette.background.default
                    : theme.palette.background.paper,
              }
        }
      >
        <TableHeader
          inline={inline}
          SearchProps={SearchProps}
          createButton={createButton}
          viewOptions={viewOptions}
          withReportsBlock={withReportsBlock}
          reports={fetchedData.report}
          title={title}
          itemsCount={fetchedData.total}
          withSplitView={!isCalendar && !inline}
        >
          {(filters || BulkActionsProps) && (
            <Box
              sx={(theme) => ({
                borderBottom: cardNode
                  ? undefined
                  : `1px solid ${theme.palette.divider}`,
              })}
            >
              {filters && (
                <TableFilters filtersConfig={filters} inline={inline}>
                  {selectedItems.length > 0 && BulkActionsProps && idKey ? (
                    <BulkActions
                      idKey={idKey}
                      refetch={refetch}
                      BulkActionsProps={BulkActionsProps}
                    />
                  ) : undefined}
                </TableFilters>
              )}
              {!filters && BulkActionsProps && idKey && (
                <BulkActions
                  idKey={idKey}
                  refetch={refetch}
                  BulkActionsProps={BulkActionsProps}
                />
              )}
            </Box>
          )}
        </TableHeader>
        <Box
          sx={{
            width: '100%',
            maxWidth: (theme) =>
              isSideNavOpen
                ? `calc(100vw - ${DRAWER_WIDTH}px)`
                : `calc(100vw - ${theme.spacing(8)} - 1px)`,
          }}
        >
          {isCalendar ? (
            <>
              {dealsCalendar && (
                <DealsCalendar
                  refetch={refetch}
                  data={fetchedData.data}
                  isLoading={isLoading}
                  setDateRange={setCalendarViewDateRange}
                />
              )}
              {marketingCalendar && (
                <MarketingCalendar
                  refetch={refetch}
                  data={fetchedData.data}
                  isLoading={isLoading}
                  setDateRange={setCalendarViewDateRange}
                />
              )}
            </>
          ) : (
            selectedView && (
              <Box>
                <TableBody
                  inline={inline}
                  idKey={idKey}
                  refetch={refetch}
                  isLoading={isLoading}
                  data={fetchedData.data}
                  tableBodyItems={selectedView}
                  withBulkActions={!!BulkActionsProps}
                  withReportsBlock={withReportsBlock}
                  isHeaderEmpty={isHeaderEmpty}
                />
                <TableFooter
                  inline={inline}
                  inTable
                  isLoading={isLoading}
                  itemsCount={fetchedData.total}
                />
                {isSplitViewEnabled && (
                  <DataTableDetailsDrawer
                    data={fetchedData.data}
                    tableBodyItems={selectedView}
                  />
                )}
              </Box>
            )
          )}
        </Box>
      </Card>
      {cardNode && skeletonNode && (
        <Box
          sx={{
            py: 2,
            px: inline ? 0 : 2,
            width: '100%',
            maxWidth: (theme) =>
              isSideNavOpen
                ? `calc(100vw - ${DRAWER_WIDTH}px)`
                : `calc(100vw - ${theme.spacing(8)} - 1px)`,
          }}
        >
          <Stack rowGap={3}>
            <CardsView
              data={fetchedData.data}
              isLoading={isLoading}
              cardNode={cardNode}
              skeletonNode={skeletonNode}
              perPage={perPage}
              GridItemProps={GridItemProps}
            />
            <Card>
              <TableFooter
                inline={inline}
                isLoading={isLoading}
                itemsCount={fetchedData.total}
                defaultPerPage={defaultPerPage}
              />
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default DataTableContainer;
