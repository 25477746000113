import { Stack } from '@mui/material';
import { CampaignTypes } from 'api';
import MarketingChart from './MarketingChart';
import SalesChart from './SalesChart';

interface Props {
  params: CampaignTypes.PerformanceParams;
}

const PerformanceCharts = (props: Props): JSX.Element | null => {
  const { params } = props;

  return (
    <Stack rowGap={3}>
      <MarketingChart params={params} />
      <SalesChart params={params} />
    </Stack>
  );
};

export default PerformanceCharts;
