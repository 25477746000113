import { MouseEvent } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.TitleCell<DataTableProps.Item> &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const TitleCell = (props: Props): JSX.Element | null => {
  const { data, cell } = props;
  const navigate = useNavigate();

  const handleLinkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (cell.to) {
      navigate(cell.to(data));
    }
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Box mr={cell.to ? 1 : undefined}>
          <Typography
            color="text.primary"
            sx={(theme) => ({
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              },
            })}
          >
            {cell.children(data) || '-'}
          </Typography>
        </Box>
        {cell.to && cell.to(data) && (
          <Box ml="auto" mr={0}>
            <Tooltip title={`Open ${cell.children(data)} page`}>
              <IconButton size="small" onClick={handleLinkClick}>
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TitleCell;
