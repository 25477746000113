import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { timeline, TimelineTypes } from 'api';
import CommentCreateForm from './CommentCreateForm';
import CommentsList from './CommentsList';
import CommentsLoadingList from './CommentsLoadingList';

interface Props {
  id: string;
  type: TimelineTypes.ModelEnum;
}

const Timeline = (props: Props): JSX.Element | null => {
  const { id, type } = props;

  const { data, error, refetch } = useQuery(
    [timeline.endpoints.getList(type, id), type, id],
    () => timeline.getList(type, id),
  );

  return (
    <Card>
      <CardContent>
        <Stack>
          <CommentCreateForm id={id} type={type} refetch={refetch} />
          {error ? (
            <Box py={3}>
              <Typography variant="subtitle2" color="error">
                Failed to load timeline data
              </Typography>
            </Box>
          ) : (
            <Box>
              {!data ? (
                <CommentsLoadingList />
              ) : (
                <CommentsList
                  id={id}
                  type={type}
                  data={data}
                  refetch={refetch}
                />
              )}
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Timeline;
