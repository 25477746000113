import React, { FormEventHandler, ReactNode } from 'react';
import { Button, Stack, Box, Container, ContainerProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HEADER_HEIGHT } from 'theme/constants';
import LoadingButton from '../LoadingButton';

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  disabled: boolean;
  children: ReactNode;
  ContainerProps?: ContainerProps;
}

const PageFormContainer = (props: Props): JSX.Element | null => {
  const { loading, disabled, onSubmit, children, ContainerProps } = props;
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" {...ContainerProps}>
      <Box
        px={4}
        pt={4}
        sx={{
          px: 4,
          pt: 4,
        }}
      >
        <Stack
          component="form"
          onSubmit={onSubmit}
          sx={{ minHeight: `calc(100vh - ${HEADER_HEIGHT}px - 32px)` }}
        >
          <Box mb={4}>{children}</Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
              pb: 2,
              mt: 'auto',
              mb: 0,
            }}
          >
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
              disabled={loading}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[9],
              })}
            >
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              loading={loading}
              disabled={disabled}
              sx={(theme) => ({
                boxShadow: theme.shadows[9],
              })}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default PageFormContainer;
