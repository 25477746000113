import {
  Avatar,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { SendRounded } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { timeline, TimelineTypes } from 'api';
import { useApiRequest } from 'hooks';
import { useStoreContext } from 'store';
import { getUserInitials } from 'helpers/common';
import Loader from '../Loader';

interface Props {
  id: string;
  type: TimelineTypes.ModelEnum;
  refetch: () => Promise<QueryObserverResult>;
}

const CommentCreateForm = (props: Props): JSX.Element | null => {
  const { type, id, refetch } = props;
  const {
    state: { user },
  } = useStoreContext();

  const { isLoading, requestFn } = useApiRequest((data) =>
    timeline.create(type, id, data),
  );

  const [inputValue, setInputValue] = useState('');

  const handleSubmit = useCallback(async () => {
    if (inputValue) {
      await requestFn({
        args: { comment_text: inputValue },
        successMessage: 'Comment created',
      });

      setInputValue('');
      refetch();
    }
  }, [inputValue, refetch, requestFn]);

  return (
    <Stack
      direction="row"
      columnGap={2}
      alignItems="center"
      sx={{ px: 1, py: 1.5 }}
    >
      <Avatar src={user?.display_picture_url}>
        {getUserInitials(user?.full_name || 'User')}
      </Avatar>
      <TextField
        multiline
        rows={2}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Write comment..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                disabled={!inputValue || isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Loader circularProgressProps={{ size: 20 }} />
                ) : (
                  <SendRounded />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default CommentCreateForm;
