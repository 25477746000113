import React from 'react';
import { Chip, ChipProps, Stack, Typography } from '@mui/material';
import { DiscountTypes } from 'api';

export type OptionsInfoItem = {
  key: keyof DiscountTypes.ItemData;
  label: string;
  value: string[] | undefined;
  color: ChipProps['color'];
};

interface Props {
  options: OptionsInfoItem[];
}

const ProductsInfo = (props: Props): JSX.Element | null => {
  const { options } = props;

  return (
    <Stack rowGap={1} mt={1}>
      {options.map((p) => (
        <Stack gap={1} key={p.key}>
          <Typography variant="body2" flexGrow={1}>
            {p.label}
          </Typography>
          {p.value && p.value.length > 0 ? (
            <Stack direction="row" gap={1} flexWrap="wrap">
              {p.value.map((c) => (
                <Chip
                  key={c + p.key}
                  label={c}
                  color={p.color}
                  variant="outlined"
                />
              ))}
            </Stack>
          ) : (
            <Typography color="text.secondary">No data</Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default ProductsInfo;
