import { enumToOptions } from '../helpers/common';
import { DiscountTypes } from '../api';

const DETAILS_OPTIONS = enumToOptions(DiscountTypes.DetailsEnum);

const STATUS_OPTIONS = enumToOptions(DiscountTypes.ShopifyStatusEnum);

const APPLIES_TO_OPTIONS = enumToOptions(DiscountTypes.AppliesToEnum);

const CUSTOMER_ELIGIBILITY_OPTIONS = enumToOptions(
  DiscountTypes.CustomerEligibilityEnum,
);

const TYPE_OPTIONS = enumToOptions(DiscountTypes.DiscountTypeEnum);

const AMOUNT_TYPE_OPTIONS = enumToOptions(DiscountTypes.DiscountAmountTypeEnum);

const MINIMUM_REQUIREMENTS_OPTIONS = enumToOptions(
  DiscountTypes.MinimumRequirementsEnum,
);

const DETAILS_BY_TYPE = {
  [DiscountTypes.DiscountTypeEnum.Percentage]: [
    DiscountTypes.DetailsEnum.PercentageDiscount,
    DiscountTypes.DetailsEnum.PercentageDiscountAndFreeGift,
    DiscountTypes.DetailsEnum.PercentageDiscountAndFreeShipping,
    DiscountTypes.DetailsEnum.SplitDiscounts,
    DiscountTypes.DetailsEnum.TieredDiscounts,
  ],
  [DiscountTypes.DiscountTypeEnum.FixedAmount]: [
    DiscountTypes.DetailsEnum.ValueDiscount,
    DiscountTypes.DetailsEnum.ValueDiscountAndFreeGift,
    DiscountTypes.DetailsEnum.ValueDiscountAndFreeShipping,
  ],
};

const DISCOUNTS = {
  DETAILS_BY_TYPE,
  DETAILS_OPTIONS,
  STATUS_OPTIONS,
  TYPE_OPTIONS,
  APPLIES_TO_OPTIONS,
  CUSTOMER_ELIGIBILITY_OPTIONS,
  AMOUNT_TYPE_OPTIONS,
  MINIMUM_REQUIREMENTS_OPTIONS,
};

export default DISCOUNTS;
