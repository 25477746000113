import { QueryObserverResult } from '@tanstack/react-query';
import DataTableProps from '../../DataTableProps';
import ActionButtonsGroup from '../../../ActionButtonsGroup';

interface Props {
  cell: DataTableProps.ActionsCell<DataTableProps.Item>;
  data: DataTableProps.Item;
  refetch: () => Promise<QueryObserverResult>;
}

const ActionsCell = (props: Props): JSX.Element => {
  const { data, cell, refetch } = props;

  return (
    <ActionButtonsGroup
      actions={cell.actions}
      data={data}
      refetch={refetch}
      maxActions={4}
    />
  );
};

export default ActionsCell;
