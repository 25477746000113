import React, { useMemo } from 'react';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DealTypes } from 'api';
import { colors } from 'theme/constants';
import { formatCurrency } from 'helpers/formatters';
import DEALS from 'constants/deals';
import BRANDS from 'constants/brands';
import { BrandsEnum } from 'api/types/Brands';
import { ColorCard } from 'components';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

interface Metric {
  isNegative: boolean;
  value: number;
  formattedValue: string | number;
}

type InfoItems = {
  key: BrandsEnum;
  label: string;
  cm3: Metric;
  quantity: Metric;
  revenue: Metric;
}[];

const TableBlock = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const tableFields = useMemo(() => {
    const getData = (
      itemKey:
        | 'brand_product_cm3'
        | 'brand_product_quantity'
        | 'brand_product_revenue',
    ) =>
      deal[itemKey].map((p) => {
        const [key, value] = Object.entries(p)[0];
        const brand =
          DEALS.BRAND_PRODUCT_MAPPING[
            key as unknown as DealTypes.BrandProductEnum
          ];

        return {
          brand,
          isNegative: value < 0,
          value,
          formattedValue:
            itemKey === 'brand_product_quantity'
              ? value
              : formatCurrency(value),
        };
      });

    const data = {
      cm3: getData('brand_product_cm3'),
      quantity: getData('brand_product_quantity'),
      revenue: getData('brand_product_revenue'),
    };

    return Object.keys(BRANDS.ACTIVE_LIST).map((b) => ({
      key: b,
      label: b,
      cm3: data.cm3.find((d) => d.brand === b) || { formattedValue: '-' },
      quantity: data.quantity.find((d) => d.brand === b) || {
        formattedValue: '-',
      },
      revenue: data.revenue.find((d) => d.brand === b) || {
        formattedValue: '-',
      },
    })) as InfoItems;
  }, [deal]);

  return (
    <ColorCard color={colors.green}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell align="right">CM3</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Revenue</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableFields.map((row) => (
              <TableRow
                key={row.key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color={row.cm3.isNegative ? 'error' : 'text.secondary'}
                    variant="inherit"
                  >
                    {row.cm3.formattedValue}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color={row.quantity.isNegative ? 'error' : 'text.secondary'}
                    variant="inherit"
                  >
                    {row.quantity.formattedValue}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color={row.revenue.isNegative ? 'error' : 'text.secondary'}
                    variant="inherit"
                  >
                    {row.revenue.formattedValue}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </ColorCard>
  );
};

export default TableBlock;
