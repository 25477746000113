import React, { ReactNode } from 'react';
import {
  CardHeader,
  CardHeaderProps,
  Card,
  CardContent,
  CardProps,
  CardContentProps,
} from '@mui/material';

interface Props {
  title: string;
  avatar?: CardHeaderProps['avatar'];
  children: ReactNode;
  CardHeaderProps?: CardHeaderProps;
  CardProps?: CardProps;
  CardContentProps?: CardContentProps;
}

const FormCard = (props: Props): JSX.Element | null => {
  const {
    avatar,
    title,
    children,
    CardProps,
    CardHeaderProps,
    CardContentProps,
  } = props;

  return (
    <Card variant="outlined" {...CardProps}>
      <CardHeader
        avatar={avatar}
        title={title}
        sx={{ pb: 0 }}
        {...CardHeaderProps}
      />
      <CardContent {...CardContentProps}>{children}</CardContent>
    </Card>
  );
};

export default FormCard;
