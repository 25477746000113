import apiClient from '../apiClient';
import NegotiationTypes from './types';

const endpoints = {
  getList: 'negotiations/filter',
  getById: (id: string) => `negotiations/${id}`,
  create: 'negotiations',
  update: (id: string) => `negotiations/${id}`,
  remove: (id: string) => `negotiations/${id}`,
};

const getList = (
  params: NegotiationTypes.GetListParams,
): NegotiationTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getById = (id: string): NegotiationTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const create = (
  data: NegotiationTypes.ItemCreateParams,
): NegotiationTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, {
      json: { negotiation: data },
      throwHttpErrors: false,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data: any) => {
          if ('influencer_x_channel' in data) {
            const errObj = data.influencer_x_channel[0];
            if (errObj?.error?.message) {
              throw new Error('Negotiation already exists');
            }
          }

          throw new Error(JSON.stringify(data));
        });
      }

      return response.json();
    });

const update = (
  id: string,
  data: NegotiationTypes.ItemCreateParams,
): NegotiationTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), { json: { negotiation: data } })
    .then((response) => response.json());

const remove = (id: string): NegotiationTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), { json: { negotiation: { is_deleted: true } } })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  getById,
  create,
  update,
  remove,
};
