import React from 'react';
import { Avatar, Card, Grid, Stack, Typography } from '@mui/material';
import { SalesPlanningTypes } from 'api';
import { getUserInitials } from 'helpers/common';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const Header = (props: Props): JSX.Element => {
  const { planning, inline } = props;

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Avatar sx={{ width: '100px', height: '100px' }}>
            {getUserInitials(planning.name)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Stack rowGap={2}>
            <Typography variant="h4">{planning.name}</Typography>
            <InfoRow planning={planning} />
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          <Avatar sx={{ width: '100px', height: '100px' }}>
            {getUserInitials(planning.name)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="center" mb={3}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h4">{planning.name}</Typography>
            </Grid>
            <Grid item>
              <Actions planning={planning} />
            </Grid>
          </Grid>
          <InfoRow planning={planning} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
