import React, { useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { InfluencerTypes, NegotiationTypes } from 'api';
import CalculatorTab from './CalculatorTab';
import ActivityLogTab from './ActivityLogTab';
import OffersTab from './OffersTab';

const TABS = {
  offers: 'Offers',
  activityLog: 'Activity log',
  calculator: 'Calculator',
};

interface Props {
  inline?: boolean;
  data: NegotiationTypes.ItemData;
  influencer: InfluencerTypes.ItemExtended | undefined;
}

const PageTabs = (props: Props): JSX.Element | null => {
  const { data, inline, influencer } = props;

  const [activeTab, setActiveTab] = useState(TABS.offers);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <Tab label={TABS.offers} value={TABS.offers} />
        <Tab label={TABS.activityLog} value={TABS.activityLog} />
        {inline && <Tab label={TABS.calculator} value={TABS.calculator} />}
      </Tabs>

      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.offers && <OffersTab negotiation={data} />}
        {activeTab === TABS.activityLog && (
          <ActivityLogTab negotiation={data} />
        )}
        {activeTab === TABS.calculator && (
          <CalculatorTab negotiation={data} influencer={influencer} />
        )}
      </Stack>
    </>
  );
};

export default PageTabs;
