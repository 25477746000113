import * as React from 'react';
import { DayCellContentArg } from '@fullcalendar/core';
import { Typography } from '@mui/material';
import { SPECIAL_DAY_OPTIONS } from 'constants/dateOptions';

interface Props {
  arg: DayCellContentArg;
}

const DayCellContent = (props: Props): JSX.Element | null => {
  const { arg } = props;
  const day = arg.date.getDate();
  const month = arg.date.getMonth();
  const specialDate = SPECIAL_DAY_OPTIONS.find(
    (e) => e.day === day && e.month === month + 1,
  );

  return (
    <Typography>
      {specialDate && (
        <Typography
          component="span"
          color="error"
          sx={{ mr: 0.5, fontWeight: 500 }}
        >
          {specialDate.label}
        </Typography>
      )}
      {arg.dayNumberText}
    </Typography>
  );
};

export default DayCellContent;
