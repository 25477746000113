import { Control, UseFormSetValue } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { FormCard, FormInput, ShopInput } from 'components';
import { MarketingTypes } from 'api/marketing';

interface Props {
  control: Control<MarketingTypes.ItemCreateParams> | undefined;
  setValue: UseFormSetValue<MarketingTypes.ItemCreateParams>;
}

const GeneralBlock = (props: Props): JSX.Element | null => {
  const { control, setValue } = props;

  return (
    <FormCard title="General details" avatar={<PersonOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ShopInput
            control={control}
            onChange={(shop) => {
              setValue('brand', shop.brand);
              setValue('country_team', shop.country_team);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="brand"
            inputType="select"
            label="Brand"
            options={BRANDS.OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="country_team"
            inputType="select"
            label="Country team"
            options={COUNTRIES.OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default GeneralBlock;
