import React, { useMemo } from 'react';
import {
  Box,
  Chip,
  ChipProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import BRANDS from 'constants/brands';
import { getBrandStatusColor } from 'helpers/getStatusColor';
import { BrandInfo } from 'api/types/Brands';

interface SingleProps {
  brand: BrandInfo;
  brands?: never;
  ChipProps?: ChipProps;
}

interface MultipleProps {
  brand?: never;
  brands: BrandInfo[] | undefined;
  ChipProps?: ChipProps;
}

type Props = SingleProps | MultipleProps;

const Single = (
  props: Pick<SingleProps, 'brand' | 'ChipProps'>,
): JSX.Element | null => {
  const { brand, ChipProps } = props;

  const brandInfo = useMemo(
    () => ({
      ...brand,
      image: brand.brand ? BRANDS.LIST[brand.brand].image : '',
      color: brand.status ? getBrandStatusColor(brand.status) : 'default',
    }),
    [brand],
  );

  if (!brand.brand) {
    return null;
  }

  return (
    <Chip
      variant="outlined"
      color={brandInfo.color}
      label={
        <Stack direction="row" columnGap={1}>
          <Box
            component="img"
            sx={(theme) => ({
              width: theme.spacing(1.5),
              height: 'auto',
            })}
            src={brandInfo.image}
            alt={brandInfo.brand}
          />
          <Typography fontSize="inherit" color="inherit">
            {brandInfo.status || 'No info'}
          </Typography>
        </Stack>
      }
      {...ChipProps}
    />
  );
};

const Multiple = (
  props: Pick<MultipleProps, 'brands' | 'ChipProps'>,
): JSX.Element | null => {
  const { brands, ChipProps } = props;

  const brandsInfo = useMemo(
    () =>
      !brands
        ? []
        : brands
            .filter(
              (b) =>
                b.brand && Object.keys(BRANDS.ACTIVE_LIST).includes(b.brand),
            )
            .filter((b) => !!b.country_team)
            .map((brand) => ({
              ...brand,
              image: brand.brand ? BRANDS.LIST[brand.brand].image : '',
              color: brand.status
                ? getBrandStatusColor(brand.status)
                : 'default',
            })),
    [brands],
  );

  if (brandsInfo.length === 0) {
    return <Chip label="No info" />;
  }

  if (brandsInfo.length === 1) {
    return <Single brand={brandsInfo[0]} ChipProps={ChipProps} />;
  }

  return (
    <Stack columnGap={1} direction="row">
      {brandsInfo.map(
        (brand) =>
          brand.brand &&
          brand.image && (
            <Tooltip
              title={`${brand.brand} ${brand.country_team} ${
                brand.status || 'No info'
              }`}
              key={`${brand.brand} ${brand.country_team} ${brand.status}`}
            >
              <Chip
                color={brand.color}
                variant="outlined"
                sx={{
                  span: {
                    p: 1.25,
                    display: 'flex',
                  },
                }}
                label={
                  <Box
                    component="img"
                    sx={(theme) => ({
                      width: theme.spacing(1.5),
                      height: 'auto',
                    })}
                    src={brand.image}
                    alt={brand.brand}
                  />
                }
                {...ChipProps}
              />
            </Tooltip>
          ),
      )}
    </Stack>
  );
};

const BrandsStatus = (props: Props) => {
  const { brands, brand, ChipProps } = props;

  if (brand) {
    return <Single brand={brand} ChipProps={ChipProps} />;
  }

  return <Multiple brands={brands} ChipProps={ChipProps} />;
};

export default BrandsStatus;
