import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DealTypes } from 'api';
import { TooltipChip, Image, Video, StoriesSlider } from 'components';
import { QueryObserverResult } from '@tanstack/react-query';
import PreviewUpload from './PreviewUpload';

interface Props {
  deal: DealTypes.ItemData;
  attachments: DealTypes.Post['attachments'];
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const size = {
  width: '100%',
  height: 'auto',
  minHeight: '360px',
};

const Previews = (props: Props): JSX.Element | null => {
  const { deal, refetch, attachments, inline } = props;

  if (
    deal.status !== DealTypes.StatusEnum.Finished &&
    deal.status !== DealTypes.StatusEnum.Posted
  ) {
    return (
      <Stack
        justifyContent="center"
        rowGap={2}
        alignItems="center"
        sx={{ height: '370px' }}
      >
        <Typography textAlign="center">
          The preview will be retrieved once the deal status is
        </Typography>
        <TooltipChip
          title="Deal status"
          label="Posted"
          color="info"
          sx={{ minWidth: '120px' }}
        />
      </Stack>
    );
  }

  if (!deal.post || attachments.length === 0) {
    return (
      <Stack
        justifyContent="center"
        rowGap={2}
        alignItems="center"
        sx={{ height: '370px' }}
      >
        <Typography textAlign="center">No preview available</Typography>
        <PreviewUpload id={deal._id.$oid} onUploaded={refetch} />
      </Stack>
    );
  }

  if (deal.post.channel === DealTypes.ChannelInfluencerEnum.InstagramPost) {
    return (
      <Box sx={{ px: 3, pb: 1.5 }}>
        <Image
          src={attachments[0]}
          sx={(theme) => ({
            ...size,
            borderRadius: `${theme.shape.borderRadius}px`,
          })}
        />
      </Box>
    );
  }

  if (
    deal.post.channel === DealTypes.ChannelInfluencerEnum.InstagramReels ||
    deal.post.channel === DealTypes.ChannelInfluencerEnum.InstagramTVLive
  ) {
    return (
      <Box sx={{ px: 3, pb: 1.5 }}>
        <Video
          src={attachments[0]}
          sx={(theme) => ({
            ...size,
            borderRadius: `${theme.shape.borderRadius}px`,
          })}
        />
      </Box>
    );
  }

  if (inline) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '25vw',
          }}
        >
          <StoriesSlider maxHeight="50vh" attachments={attachments} />
        </Box>
      </Box>
    );
  }

  return <StoriesSlider attachments={attachments} />;
};

export default Previews;
