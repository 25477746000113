import React, { useState, useMemo } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemButton,
  MenuItem,
  Menu,
  Divider,
  alpha,
} from '@mui/material';
import { MenuLink, MenuNavLink } from 'routes/links';
import { NavLink, useLocation } from 'react-router-dom';
import { activeGroupItemCollapsedMixin } from './mixins';

interface Props extends Omit<MenuNavLink, 'children'> {
  links: MenuLink[];
}

const NavLinkMenu = (props: Props): JSX.Element => {
  const { link, links, name, Icon } = props;
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (target: any) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActiveGroup = useMemo(() => {
    const paths = location.pathname.split('/').filter((x) => !!x);

    return paths.includes(link.replace('/', ''));
  }, [link, location.pathname]);

  const open = Boolean(anchorEl);

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={(theme) => ({
          justifyContent: 'center',
          position: 'relative',
          ...activeGroupItemCollapsedMixin(theme, isActiveGroup),
        })}
        onMouseEnter={(e) => handleOpen(e.currentTarget)}
        onMouseLeave={handleClose}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 'auto',
            justifyContent: 'center',
          }}
        >
          <Icon />
        </ListItemIcon>
        <Menu
          id={`${name}-menu`}
          anchorEl={anchorEl}
          open={open}
          sx={{
            position: 'inherit',
            zIndex: 'unset',
          }}
          PaperProps={{
            sx: (theme) => ({
              position: 'fixed',
              zIndex: theme.zIndex.modal,
              marginLeft: theme.spacing(0.75),
            }),
          }}
          hideBackdrop
          disablePortal
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <MenuItem disabled>{name}</MenuItem>
          <Divider />
          {links.map((link) => (
            <MenuItem
              key={link.link}
              component={NavLink}
              to={link.link}
              sx={(theme) => ({
                '&.active': {
                  color: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.primary.main, 0.04),
                },
              })}
            >
              {link.name}
            </MenuItem>
          ))}
        </Menu>
      </ListItemButton>
    </ListItem>
  );
};

export default NavLinkMenu;
