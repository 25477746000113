import { Grid, GridProps } from '@mui/material';
import DataTableProps from '../DataTableProps';

interface Props {
  data: DataTableProps.Item[];
  isLoading: boolean;
  cardNode: DataTableProps.CardNode;
  skeletonNode: DataTableProps.SkeletonNode;
  perPage: number;
  GridItemProps?: GridProps;
}

const CardsView = (props: Props): JSX.Element | null => {
  const { cardNode, skeletonNode, isLoading, data, perPage, GridItemProps } =
    props;

  return (
    <Grid container spacing={4}>
      {isLoading &&
        Array.from(new Array(perPage))
          .map((_, i) => i)
          .map((item) => (
            <Grid
              key={item}
              item
              xs={6}
              sm={4}
              md={3}
              xl={2}
              {...GridItemProps}
            >
              {skeletonNode}
            </Grid>
          ))}
      {!isLoading &&
        data.map((item) => (
          <Grid
            key={item._id.$oid}
            item
            xs={6}
            sm={4}
            md={3}
            xl={2}
            {...GridItemProps}
          >
            {cardNode(item)}
          </Grid>
        ))}
    </Grid>
  );
};

export default CardsView;
