import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import { useStoreContext } from 'store';
import { appLinks, MenuNavLink } from 'routes/links';
import NavLinkGroup from './NavLinkGroup';
import NavLinkMenu from './NavLinkMenu';
import { activeLinkItemCollapsedMixin, activeLinkItemMixin } from './mixins';

interface Props {
  links: MenuNavLink[];
}

const isDevEnv = process.env.REACT_APP_CI_ENV === 'development';

const NavLinks = (props: Props): JSX.Element => {
  const { links } = props;
  const {
    state: { isSideNavOpen },
  } = useStoreContext();

  const envLinks = isDevEnv
    ? links
    : links.filter((l) => l.link !== appLinks.markingPlan.link);

  return (
    <>
      {envLinks.map(({ Icon, ...link }) =>
        link.children ? (
          <Fragment key={link.link}>
            {isSideNavOpen ? (
              <NavLinkGroup
                Icon={Icon}
                link={link.link}
                name={link.name}
                links={link.children}
              />
            ) : (
              <NavLinkMenu
                Icon={Icon}
                link={link.link}
                name={link.name}
                links={link.children}
              />
            )}
          </Fragment>
        ) : (
          <ListItem key={link.link} disablePadding sx={{ display: 'block' }}>
            <Tooltip title={link.name} placement="right-end">
              <ListItemButton
                component={NavLink}
                to={link.link}
                sx={(theme) =>
                  isSideNavOpen
                    ? activeLinkItemMixin(theme)
                    : activeLinkItemCollapsedMixin(theme)
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isSideNavOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={link.name}
                  sx={{ opacity: isSideNavOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ),
      )}
    </>
  );
};

export default NavLinks;
