import React from 'react';
import {
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ContentCopyRounded } from '@mui/icons-material';
import { DealTypes } from 'api';
import { useClipboard } from 'hooks';
import { ColorCard } from 'components';
import { colors } from 'theme/constants';

interface Props {
  deal: DealTypes.ItemData;
}

const StoryLinksInfoCard = (props: Props): JSX.Element | null => {
  const { deal } = props;
  const handleCopy = useClipboard();

  if (!deal.story_links) {
    return null;
  }

  return (
    <ColorCard color={colors.pink2}>
      <CardHeader
        title="Social media links"
        subheader="Extracted links from social media posts"
      />
      <CardContent>
        {deal.strategy === DealTypes.StrategiesEnum.influencers &&
          deal.channel === DealTypes.ChannelInfluencerEnum.InstagramStories && (
            <Typography
              noWrap
              sx={(theme) => ({
                color: deal.is_posted_url_valid
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              })}
            >
              Url posted by influencer is{' '}
              {deal.is_posted_url_valid ? 'valid' : 'invalid'}
            </Typography>
          )}
        {deal.story_links.length === 0 && (
          <Typography color="text.secondary" noWrap>
            No info
          </Typography>
        )}
        {deal.story_links.map((url) => (
          <Stack key={url} sx={{ mb: 1 }}>
            <Stack direction="row" columnGap={2} alignItems="center">
              <Typography color="text.secondary" noWrap>
                {url || '-'}
              </Typography>
              <IconButton size="small" onClick={() => handleCopy(url || '')}>
                <ContentCopyRounded fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </ColorCard>
  );
};

export default StoryLinksInfoCard;
