import { SvgIcon, SvgIconProps } from '@mui/material';

const TikTokIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5309 5.51459C14.8505 5.51459 13.4833 4.14822 13.4833 2.46875C13.4833 2.20987 13.2733 2 13.0143 2H10.5024C10.2434 2 10.0334 2.20987 10.0334 2.46875V12.7615C10.0334 13.7488 9.22961 14.5521 8.24164 14.5521C7.25371 14.5521 6.44999 13.7488 6.44999 12.7615C6.44999 11.7741 7.25371 10.9708 8.24164 10.9708C8.50067 10.9708 8.71067 10.7609 8.71067 10.5021V7.99162C8.71067 7.73275 8.50067 7.52287 8.24164 7.52287C5.3514 7.52287 3 9.87291 3 12.7615C3.00003 15.65 5.3514 18 8.24167 18C11.132 18 13.4834 15.65 13.4834 12.7615V8.20553C14.4169 8.70288 15.4556 8.96253 16.531 8.96253C16.79 8.96253 17 8.75266 17 8.49378V5.98334C17 5.72447 16.79 5.51459 16.5309 5.51459ZM16.0619 8.00562C15.0606 7.92241 14.1098 7.57281 13.2882 6.98216C13.1454 6.87947 12.957 6.86531 12.8004 6.9455C12.6438 7.02572 12.5453 7.18678 12.5453 7.36266V12.7615C12.5453 15.1331 10.6147 17.0625 8.24164 17.0625C5.86864 17.0625 3.93806 15.1331 3.93806 12.7615C3.93806 10.5482 5.6194 8.72003 7.77261 8.48575V10.0736C6.49007 10.2964 5.51193 11.4169 5.51193 12.7615C5.51193 14.2657 6.73647 15.4896 8.24164 15.4896C9.74685 15.4896 10.9714 14.2657 10.9714 12.7615V2.9375H12.5727C12.7875 4.76 14.2384 6.21003 16.0619 6.42466L16.0619 8.00562Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TikTokIcon;
