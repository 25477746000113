import { uniq } from 'lodash';
import { BrandsEnum } from 'api/types/Brands';
import PRODUCTS from 'constants/products';
import { arrayToOptions } from './common';

const getProductCategories = (brand: BrandsEnum) => {
  if (brand) {
    return arrayToOptions(PRODUCTS.PRODUCT_CATEGORIES[brand]);
  }
  return [];
};

const getProducts = (
  brand: BrandsEnum,
  productCategories: (keyof typeof PRODUCTS.AVA_MAY_PRODUCTS &
    keyof typeof PRODUCTS.FAYNT_PRODUCTS &
    keyof typeof PRODUCTS.MILLION_FACETS_PRODUCTS)[],
) => {
  if (brand && productCategories) {
    const allProducts = PRODUCTS.PRODUCT_OPTIONS[brand];
    const availableProducts = productCategories.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        ...(currentValue in allProducts ? allProducts[currentValue] : []),
      ],
      [] as string[],
    );

    return uniq(availableProducts);
  }
  return [];
};

const getProductOptions = (
  brand: BrandsEnum,
  productCategories: (keyof typeof PRODUCTS.AVA_MAY_PRODUCTS &
    keyof typeof PRODUCTS.FAYNT_PRODUCTS &
    keyof typeof PRODUCTS.MILLION_FACETS_PRODUCTS)[],
) => arrayToOptions(getProducts(brand, productCategories));

export { getProductCategories, getProducts, getProductOptions };
