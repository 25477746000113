import React from 'react';
import { Avatar, Card, Grid, Typography, Stack } from '@mui/material';
import { CampaignTypes } from 'api';
import { getUserInitials } from 'helpers/common';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

const Header = (props: Props): JSX.Element => {
  const { campaign, inline } = props;

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Avatar
            src={campaign.picture_url || ''}
            sx={{ width: '100px', height: '100px' }}
          >
            {getUserInitials(campaign.name)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Stack rowGap={2}>
            <Typography variant="h4">{campaign.name}</Typography>
            <InfoRow campaign={campaign} />
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          <Avatar
            src={campaign.picture_url || ''}
            sx={{ width: '100px', height: '100px' }}
          >
            {getUserInitials(campaign.name)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="center" mb={3}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h4">{campaign.name}</Typography>
            </Grid>
            <Grid item>
              <Actions campaign={campaign} />
            </Grid>
          </Grid>
          <InfoRow campaign={campaign} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
