import React, { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Card, CardContent, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { negotiations, NegotiationTypes } from 'api';
import { COMPANY_NAME } from 'constants/common';
import { useApiRequest } from 'hooks';
import OfferForm from './OfferForm';

const initialItem: NegotiationTypes.LogItem = {
  date: dayjs().toISOString(),
  posts_nb: 0,
  source: COMPANY_NAME,
  total_budget: 0,
  total_budget_lc: 0,
};

interface Props {
  negotiation: NegotiationTypes.ItemData;
}

const OffersTab = (props: Props): JSX.Element | null => {
  const { negotiation } = props;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<NegotiationTypes.ItemLogs>({
    defaultValues: {
      logs: negotiation.logs || [],
    },
    mode: 'onTouched',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'logs',
  });

  const { requestFn } = useApiRequest((data) =>
    negotiations.update(negotiation._id.$oid, data),
  );

  const onSubmit = useCallback(
    async (data: NegotiationTypes.ItemLogs) => {
      const resp = await requestFn({
        args: data,
        successMessage: 'Negotiation updated',
      });

      if (resp) {
        reset({ logs: resp.logs });
      }
    },
    [requestFn, reset],
  );

  const onDecision = (
    index: number,
    reason?: NegotiationTypes.LogItem['decision'],
  ) => {
    setValue(`logs.${index}.decision`, reason);

    handleSubmit(onSubmit)();
  };

  const handleAppend = () => {
    append(initialItem);
  };

  const handleRemove = (index: number) => {
    remove(index);
    handleSubmit(onSubmit)();
  };

  return (
    <Card>
      <CardContent component="form" onSubmit={handleSubmit(onSubmit)}>
        {fields.length === 0 && (
          <Stack justifyContent="center" direction="row">
            <Button onClick={handleAppend}>Create first offer</Button>
          </Stack>
        )}
        <Stack sx={{ p: 1 }}>
          {fields.map((field, index) => (
            <OfferForm
              key={field.id}
              index={index}
              isLastItem={fields.length === index + 1}
              control={control}
              watch={watch}
              negotiation={negotiation}
              onRemove={() => handleRemove(index)}
              onAppend={handleAppend}
              onSubmit={(r) => onDecision(index, r)}
              onMouseLeave={
                isDirty && fields.length === index + 1
                  ? debounce(handleSubmit(onSubmit), 1000)
                  : undefined
              }
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OffersTab;
