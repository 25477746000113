import { DealTypes, discounts, users } from 'api';
import { DataTableProps } from 'components';
import DEALS from 'constants/deals';
import INFLUENCERS from 'constants/influencers';
import { DAY_OPTIONS } from 'constants/dateOptions';

const filters: DataTableProps.Filters<DealTypes.GetListParams['filters']> = [
  {
    type: 'search',
    key: 'lead',
    name: 'Lead',
    queryFn: users.autocompleteSearch,
    queryKey: users.endpoints.autocompleteSearch,
  },
  {
    type: 'checkbox',
    key: 'type',
    name: 'Type',
    options: DEALS.TYPE_OPTIONS,
  },
  {
    type: 'search',
    key: 'coupon_code',
    name: 'Coupon code',
    quickFilter: false,
    queryFn: (params) =>
      discounts.autocompleteSearch({ coupon_code: params }).then((res) =>
        res.map((r) => ({
          ...r,
          _id: { $oid: r.discount_id },
          name: r.coupon_code,
        })),
      ),
    queryKey: discounts.endpoints.autocompleteSearch,
  },
  {
    type: 'switch',
    key: 'show_bookmarks',
    name: 'Bookmarked',
    quickFilter: false,
  },
  {
    type: 'checkbox',
    key: 'influencer.vertical_primary',
    name: 'Primary vertical',
    options: INFLUENCERS.VERTICALS_OPTIONS,
    quickFilter: false,
  },
  {
    type: 'checkbox',
    key: 'budget_type',
    name: 'Budget type',
    options: DEALS.BUDGET_TYPE_OPTIONS,
    quickFilter: false,
  },
  {
    type: 'switch',
    key: 'reminder_date',
    name: 'Reminder date',
    quickFilter: false,
  },
  {
    type: 'checkbox',
    key: 'start_week_day',
    name: 'Day of the week',
    options: DAY_OPTIONS,
    quickFilter: false,
  },
  {
    type: 'range',
    key: 'budget_potential',
    name: 'Budget potential',
    quickFilter: false,
  },
  {
    type: 'range',
    key: 'roi_potential',
    name: 'ROI potential',
    quickFilter: false,
  },
  {
    type: 'range',
    key: 'revenue_potential',
    name: 'Revenue potential',
    quickFilter: false,
  },
  {
    type: 'range',
    key: 'discount_value',
    name: 'Discount value',
    quickFilter: false,
  },
  {
    type: 'input',
    key: 'coupon_description',
    name: 'Coupon description',
    quickFilter: false,
    TextFieldProps: {
      multiline: true,
      rows: 3,
    },
  },
];

export default filters;
