import { useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Circle, CircleOutlined } from '@mui/icons-material';
import { useApiRequest } from 'hooks';
import { notifications, NotificationsTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import Loader from '../../../Loader';

interface Props {
  item: NotificationsTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
}

const ToggleReadStatusButton = (props: Props) => {
  const { item, refetch } = props;

  const { requestFn, isLoading } = useApiRequest(() =>
    notifications.update(item.notification_id, !item.read_at),
  );

  const handleClick = useCallback(async () => {
    await requestFn();

    await refetch();
  }, [refetch, requestFn]);

  return (
    <Tooltip title={`Mark notification as ${item.read_at ? 'unread' : 'read'}`}>
      <IconButton
        onClick={handleClick}
        sx={{
          width: 16,
          height: 16,
        }}
      >
        {isLoading && (
          <Loader
            variant="inline"
            circularProgressProps={{
              color: 'primary',
              sx: {
                width: 16,
                height: 16,
              },
            }}
          />
        )}
        {!isLoading && !item.read_at && (
          <Circle
            color="primary"
            sx={{
              width: 16,
              height: 16,
            }}
          />
        )}
        {!isLoading && !!item.read_at && (
          <CircleOutlined
            color="primary"
            sx={{
              width: 16,
              height: 16,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ToggleReadStatusButton;
