import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  DialogProps,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import LoadingButton, { LoadingButtonProps } from '../LoadingButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;

  name?: string;
  isLoading?: boolean;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  message?: string;
  submitButtonText?: string;
  children?: ReactNode;
  DialogProps?: Partial<DialogProps>;
  LoadingButtonProps?: LoadingButtonProps;
}

const ConfirmDialogControlled: React.FC<Props> = (props: Props) => {
  const {
    onSubmit,
    title,
    name,
    isLoading = false,
    message = `Are You sure You want to delete ${name}?`,
    submitButtonText = 'Delete',
    children,
    onClose,
    isOpen,
    DialogProps,
    LoadingButtonProps,
    subtitle,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={isLoading ? undefined : onClose}
      {...DialogProps}
    >
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item xs={10}>
            {typeof title === 'string' || !title ? (
              <Typography variant="h5" component="p">
                {title || 'Are you sure?'}
              </Typography>
            ) : (
              title
            )}
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <Typography variant="h5" component="p">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          ))}
      </DialogTitle>
      <DialogContent>
        {children || <Typography>{message}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onSubmit}
          {...LoadingButtonProps}
          disabled={isLoading || LoadingButtonProps?.disabled}
          loading={isLoading || LoadingButtonProps?.loading}
        >
          {LoadingButtonProps?.children || submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogControlled;
