import { DealTypes, TimelineTypes } from 'api';
import { Timeline } from 'components';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const ActivityLogTab = (props: Props): JSX.Element | null => {
  const { deal } = props;

  return <Timeline id={deal._id.$oid} type={TimelineTypes.ModelEnum.Deal} />;
};

export default ActivityLogTab;
