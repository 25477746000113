import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { FormContainer } from 'components';
import { getDiffFields, getFieldsWithValues } from 'helpers/getFormFields';
import { useApiRequest } from 'hooks';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import { marketing, MarketingTypes } from 'api/marketing';
import PlatformBlock from './components/PlatformBlock';
import GeneralBlock from './components/GeneralBlock';
import DiscountBlock from './components/DiscountBlock';

interface Props {
  id: string | undefined;
  initialData: MarketingTypes.ItemCreateParams;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    setValue,
  } = useForm<MarketingTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { requestFn } = useApiRequest(
    id ? (data) => marketing.update(id, data) : marketing.create,
  );

  const onSubmit = useCallback(
    async (data: MarketingTypes.ItemCreateParams) => {
      const values = id
        ? getDiffFields(initialData, data)
        : getFieldsWithValues(data);

      // const res =
      await requestFn({
        args: values,
        successMessage: `Marketing info ${id ? 'updated' : 'created'}`,
      });

      if (drawer) {
        if (searchParams.has('editModalId')) {
          searchParams.delete('editModalId');
          setSearchParams(searchParams, { replace: true });

          if (refetchTableData) {
            await refetchTableData();
          }

          navigate(-1);
        }
      } else {
        navigate(appLinks.markingPlan.link);
        // navigate(appLinks.markingPlan.details.as(res.marketing_id), {
        //   replace: true,
        //   state: {
        //     from: id ? 'edit' : 'create',
        //   },
        // });
      }
    },
    [
      id,
      initialData,
      requestFn,
      drawer,
      searchParams,
      setSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.deals.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
    >
      <Stack spacing={2}>
        <GeneralBlock control={control} setValue={setValue} />
        <PlatformBlock control={control} watch={watch} setValue={setValue} />
        <DiscountBlock control={control} watch={watch} setValue={setValue} />
      </Stack>
    </FormContainer>
  );
};

export default Form;
