import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { MenuOption, MenuOptions } from 'types/common';
import { HandleChangeValue } from 'hooks/useModel';
import { ArrowDownTinIcon } from '../../icons';

type Value = string[] | undefined;

interface Props {
  label: string;
  value: Value;
  options: MenuOptions;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
}

const AutocompleteFilter = (props: Omit<Props, 'inline'>) => {
  const { onChange, value = [], label, options } = props;

  const inputValue = useMemo(
    () => options.filter((o) => value.includes(o.value)),
    [options, value],
  );

  const handleValueChange = useCallback(
    (event: unknown, newValue: MenuOption[] | undefined) => {
      const value = newValue?.map((v) => v.value);
      onChange(value?.length === 0 ? undefined : value);
    },
    [onChange],
  );

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      autoComplete
      value={inputValue}
      onChange={handleValueChange}
      options={options}
      getOptionLabel={(option) => option.label}
      ChipProps={{
        color: 'primary',
      }}
      popupIcon={<ArrowDownTinIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={label}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            notched: false,
          }}
        />
      )}
    />
  );
};

const BlockFilter = (props: Props) => {
  const { onChange, value = [], label, options, inline } = props;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    optionValue: string,
  ) => {
    if (event.target.checked) {
      onChange((prevState = []) => [...prevState, optionValue]);
    } else {
      onChange((prevState = []) => {
        const v = prevState.filter((p) => p !== optionValue);
        return v.length === 0 ? undefined : v;
      });
    }
  };

  const getLabel = useCallback((option: MenuOption) => {
    const text = option.caption ? (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{option.label}</Typography>
        <Typography variant="caption">{option.caption}</Typography>
      </Box>
    ) : (
      option.label
    );

    if (option.image) {
      return (
        <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
          <Box
            component="img"
            src={option.image}
            sx={{ width: 'auto', height: 16 }}
          />
          {text}
        </Box>
      );
    }
    if (option.Icon) {
      return (
        <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
          <option.Icon fontSize="small" />
          {text}
        </Box>
      );
    }
    return text;
  }, []);

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      fullWidth
      {...(inline && {
        sx: { m: 2 },
      })}
    >
      {!inline && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup sx={{ flexDirection: inline ? 'column' : 'row' }}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={value.indexOf(option.value) > -1}
                onChange={(e) => handleChange(e, option.value)}
                name={option.value}
              />
            }
            label={getLabel(option)}
            sx={
              inline
                ? {
                    my: option.caption ? 1 : undefined,
                  }
                : {
                    flex: '1 1 50%',
                    marginRight: '11px',
                    my: option.caption ? 1 : undefined,
                  }
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const CheckboxFilter = (props: Props): JSX.Element | null => {
  const { options, inline, ...rest } = props;

  if (!inline && options.length > 6) {
    return <AutocompleteFilter options={options} {...rest} />;
  }

  return <BlockFilter inline={inline} options={options} {...rest} />;
};

export default CheckboxFilter;
