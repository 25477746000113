import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { DealTypes } from 'api';
import { TooltipChip } from 'components';
import { QueryObserverResult } from '@tanstack/react-query';
import DownloadSequences from './DownloadSequences';
import Previews from './Previews';

interface Props {
  deal: DealTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const TABS = {
  posts: 'Posts',
  reminderPosts: 'Reminder posts',
};

const ChipTab = ({
  count,
  label,
  value,
  ...rest
}: {
  label: string;
  count: number | undefined;
  value: string;
} & unknown) => (
  <Tab
    {...rest}
    label={
      <Stack direction="row" alignItems="center" columnGap={0.5}>
        <Typography variant="inherit" noWrap>
          {label}
        </Typography>
        <TooltipChip
          size="small"
          title="Posts count"
          label={count}
          sx={{ height: '20px' }}
        />
      </Stack>
    }
    value={value}
  />
);

const AttachmentsPreview = (props: Props): JSX.Element | null => {
  const { deal, refetch, inline } = props;

  const [activeTab, setActiveTab] = useState(TABS.posts);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Box id="post-preview">
      <Stack direction="row">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          Post preview
        </Typography>
        {deal.post?.attachments && deal.post.attachments.length > 0 && (
          <DownloadSequences deal={deal} />
        )}
      </Stack>
      {deal.reminder_post ? (
        <Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ mb: 1 }}
          >
            <ChipTab
              count={deal.post?.attachments.length}
              label={TABS.posts}
              value={TABS.posts}
            />
            <ChipTab
              count={deal.reminder_post?.attachments.length}
              label={TABS.reminderPosts}
              value={TABS.reminderPosts}
            />
          </Tabs>
          {activeTab === TABS.posts ? (
            <Previews
              deal={deal}
              attachments={deal.post?.attachments || []}
              refetch={refetch}
              inline={inline}
            />
          ) : (
            <Previews
              deal={deal}
              attachments={deal.reminder_post?.attachments || []}
              refetch={refetch}
              inline={inline}
            />
          )}
        </Box>
      ) : (
        <Box mt={1}>
          <Previews
            deal={deal}
            attachments={deal.post?.attachments || []}
            refetch={refetch}
            inline={inline}
          />
        </Box>
      )}
    </Box>
  );
};

export default AttachmentsPreview;
