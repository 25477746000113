import React from 'react';
import { EventContentArg } from '@fullcalendar/core';
import { Stack, Typography } from '@mui/material';
import { getDealStatusColor } from 'helpers/getStatusColor';
import BrandChip from '../../Chips/BrandChip';
import TooltipChip from '../../Chips/TooltipChip';

interface Props {
  eventInfo: EventContentArg;
}

const EventContent = (props: Props): JSX.Element | null => {
  const { eventInfo } = props;

  return (
    <Stack
      direction="row"
      columnGap={0.5}
      sx={{ '&:hover': { cursor: 'pointer' } }}
    >
      <BrandChip
        brand={eventInfo.event.extendedProps.brand}
        onlyLogo
        ChipProps={{
          sx: {
            '&:hover': { cursor: 'pointer' },
          },
        }}
      />
      <TooltipChip
        title={
          <Stack>
            <Typography>
              {`Status: `}
              <Typography variant="body2" component="span">
                {eventInfo.event.extendedProps.status}
              </Typography>
            </Typography>
            <Typography>
              {`Influncer: `}
              <Typography variant="body2" component="span">
                {eventInfo.event.title}
              </Typography>
            </Typography>
          </Stack>
        }
        label={eventInfo.event.title}
        color={getDealStatusColor(eventInfo.event.extendedProps.status)}
        sx={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Stack>
  );
};

export default EventContent;
