import React from 'react';
import { Stack } from '@mui/material';
import { BrandChip, ShopInfo, TooltipChip } from 'components';
import { UserTypes } from 'api';
import { getUserRoleColor } from 'helpers/getStatusColor';
import Users from 'api/users/types';

interface Props {
  user: UserTypes.ItemData;
}

const Header = (props: Props): JSX.Element => {
  const { user } = props;

  return (
    <Stack gap={2} direction="row" flexWrap="wrap">
      <BrandChip brand={user.brand} country={user.country_team} />
      {user.shop_id && <ShopInfo shopId={user.shop_id.$oid} />}
      <TooltipChip
        title="Role"
        label={user.role}
        color={getUserRoleColor(user.role)}
      />
      {user.access && (
        <TooltipChip
          title="Access"
          label={user.access}
          color={user.access === Users.AccessEnum.ADMIN ? 'error' : 'info'}
        />
      )}
    </Stack>
  );
};

export default Header;
