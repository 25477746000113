import React, { useMemo } from 'react';
import { appLinks } from 'routes/links';
import { DataTableProps, DataTable } from 'components';
import { DealTypes, negotiations, NegotiationTypes, users } from 'api';
import { getNegotiationStatusColor } from 'helpers/getStatusColor';
import NEGOTIATIONS from 'constants/negotiations';
import DEALS from 'constants/deals';
import { useStoreContext } from 'store';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';

const filters: DataTableProps.Filters<
  NegotiationTypes.GetListParams['filters']
> = [
  {
    type: 'checkbox',
    key: 'status',
    name: 'Status',
    options: NEGOTIATIONS.STATUS_OPTIONS,
  },
  {
    type: 'checkbox',
    key: 'channel',
    name: 'Sub channel',
    options: DEALS.SUB_CHANNEL_OPTIONS,
  },
  {
    type: 'search',
    key: 'lead',
    name: 'Lead',
    queryFn: users.autocompleteSearch,
    queryKey: users.endpoints.autocompleteSearch,
    quickFilter: true,
  },
];

const columns: DataTableProps.Columns<NegotiationTypes.ItemData> = [
  {
    type: 'rowLink',
    label: '',
    key: 'negotiation_id',
    href: (item) => appLinks.negotiations.details.as(item.negotiation_id),
  },
  {
    type: 'text',
    label: 'Lead',
    key: 'lead',
    isSortable: true,
    children: (item) => item.lead,
  },
  {
    type: 'text',
    label: 'Brand',
    key: 'brand',
    isSortable: true,
    children: (item) => item.brand,
  },
  {
    type: 'country',
    key: 'country_team',
    label: 'Country',
    isSortable: true,
    multiple: false,
  },
  {
    type: 'text',
    label: 'Sub channel',
    key: 'channel',
    isSortable: true,
    children: (item) => item.channel,
  },
  {
    type: 'chip',
    label: 'Status',
    key: 'status',
    isSortable: true,
    text: (item) => item.status,
    color: (item) => getNegotiationStatusColor(item.status),
  },
  {
    type: 'text',
    label: 'Created At',
    key: 'created_at',
    isSortable: true,
    children: (item) => getFormattedDateFromNow(item.created_at),
  },
  {
    type: 'actions',
    key: 'actions',
    label: 'Actions',
    actions: [
      {
        name: `Bookmark negotiation`,
        BookmarkProps: {
          itemId: (item) => item.negotiation_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'negotiation_ids',
        },
      },
    ],
  },
];

interface Props {
  influencerId: string;
}

const NegotiationsTable = (props: Props): JSX.Element => {
  const { influencerId } = props;
  const {
    state: { activeCountry, activeBrand },
  } = useStoreContext();

  const extraFiltersValues: DealTypes.GetListParams['filters'] = useMemo(
    () => ({
      brand: activeBrand,
      country_team: activeCountry,
      influencer_id: influencerId,
    }),
    [activeBrand, activeCountry, influencerId],
  );

  return (
    <DataTable
      inline
      headers={appLinks.negotiations}
      columns={columns}
      filters={filters}
      getDataApi={negotiations.getList}
      extraFiltersValues={extraFiltersValues}
      title="Negotiations"
      createButton={{
        link: appLinks.negotiations.create.as(influencerId),
        name: 'Add',
      }}
    />
  );
};

export default NegotiationsTable;
