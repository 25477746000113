// start user item data

import { CountriesEnum } from '../types/Countries';
import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
} from '../types/common';
import { BrandsEnum } from '../types/Brands';

namespace Users {
  export enum AccessEnum {
    ADMIN = 'admin',
    READWRITE = 'readwrite',
  }

  export enum RoleEnum {
    MARKETING_MANAGER = 'Marketing Manager',
    TECH_TEAM = 'Tech Team',
    MANAGEMENT = 'Management',
  }

  export interface ItemCreateParams {
    access: AccessEnum;
    brand: BrandsEnum;
    country_team?: CountriesEnum;
    email: string;
    role: RoleEnum;
    shop_id: string;
  }

  export interface ItemData extends Omit<ItemCreateParams, 'shop_id'> {
    _id: ID;
    auth_tokens: string[];
    created_at: string;
    full_name?: string;
    first_name?: string;
    last_name?: string;
    google_user_sub: string;
    invite_sent: boolean;
    is_deleted: boolean;
    password_digest: string;
    updated_at: string;
    user_id: string;
    display_picture_url?: string;
    shop_id: ID | null;
  }

  // end user item data

  // start get user

  export type ItemResponse = Promise<ItemData>;

  export interface GetItemParams {
    user: {
      email: string;
    };
  }

  // end get user

  // start sign in

  export interface SignInParams {
    credential: string;
  }

  export interface SignInData {
    session: {
      jwt: string;
      email: string;
    };
  }

  export type SignInResponse = Promise<SignInData>;

  // end sign in

  // start get list

  export interface GetListParams extends ListPaginationParams {
    filters: {
      full_name?: string;
    };
  }

  export type GetListData = ListPaginationResponse<ItemData>;

  export type GetListResponse = Promise<GetListData>;

  // end get list
}

export default Users;
