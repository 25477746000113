import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { DealTypes } from 'api';
import { SelectInputControlled } from 'components';
import BRANDS from 'constants/brands';
import DEALS from 'constants/deals';
import { arrayToOptions } from 'helpers/common';
import { CloseOutlined } from '@mui/icons-material';
import { BrandsEnum } from 'api/types/Brands';

interface Props {
  brands: BrandsEnum[];
  setBrands: (value: BrandsEnum[]) => void;
  strategy: DealTypes.StrategiesEnum | undefined;
  setStrategy: (value: DealTypes.StrategiesEnum | undefined) => void;
  channel: DealTypes.ChannelEnum | undefined;
  setChannel: (value: DealTypes.ChannelEnum | undefined) => void;
}

const PerformanceFilters = (props: Props): JSX.Element | null => {
  const { setChannel, channel, setBrands, brands, strategy, setStrategy } =
    props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectInputControlled
              required
              name="brand"
              label="Brand"
              options={BRANDS.OPTIONS}
              value={brands}
              onChange={(event) =>
                event.target.value.length > 0
                  ? setBrands(event.target.value as unknown as BrandsEnum[])
                  : false
              }
              SelectProps={{
                multiple: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInputControlled
              name="strategy"
              label="Channel"
              options={DEALS.STRATEGY_OPTIONS}
              value={strategy}
              onChange={(event) => {
                const value = event.target.value as DealTypes.StrategiesEnum;
                const channelOptions = DEALS.CHANNEL_OPTIONS[value];

                setStrategy(value);
                setChannel(channelOptions[0]);
              }}
              SelectProps={{
                endAdornment: !strategy ? undefined : (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setStrategy(undefined);
                        setChannel(undefined);
                      }}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInputControlled
              name="channel"
              label="Sub channel"
              options={
                strategy
                  ? arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])
                  : DEALS.SUB_CHANNEL_OPTIONS
              }
              value={channel}
              onChange={(event) => {
                const value = event.target.value as DealTypes.ChannelEnum;

                setChannel(value);
              }}
              SelectProps={{
                endAdornment: !channel ? undefined : (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => setChannel(undefined)}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PerformanceFilters;
