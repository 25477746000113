import apiClient from '../apiClient';
import SalesPlanningTypes from './types';

const endpoints = {
  getList: 'sales_plannings/filter',
  getCM2: 'sales_plannings/fetch_cm2',
  autocompleteSearch: 'sales_plannings/autocomplete',
  getById: (id: string) => `sales_plannings/${id}`,
  create: 'sales_plannings',
  update: (id: string) => `sales_plannings/${id}`,
  remove: (id: string) => `sales_plannings/${id}`,
  performanceReport: 'sales_plannings/performance_full_report',
};

const getList = (
  params: SalesPlanningTypes.GetListParams,
): SalesPlanningTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getCM2 = (
  params: SalesPlanningTypes.GetCM2Params,
): SalesPlanningTypes.CM2FlatListResponse =>
  apiClient
    .get(endpoints.getCM2, {
      searchParams: params as unknown as Record<
        string,
        string | number | boolean
      >,
    })
    .then((response) => response.json());

const autocompleteSearch = (
  query: string,
): SalesPlanningTypes.AutocompleteSearchResponse =>
  apiClient
    .post(endpoints.autocompleteSearch, {
      json: {
        name: query,
        limit: 10,
      },
    })
    .then((response) => response.json());

const getById = (id: string): SalesPlanningTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const create = (
  data: SalesPlanningTypes.ItemCreateParams,
): SalesPlanningTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, { json: { sales_planning: data } })
    .then((response) => response.json());

const update = (
  id: string,
  data: Partial<SalesPlanningTypes.ItemCreateParams>,
): SalesPlanningTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), { json: { sales_planning: data } })
    .then((response) => response.json());

const remove = (id: string): SalesPlanningTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), {
      json: { sales_planning: { is_deleted: true } },
    })
    .then((response) => response.json());

const getPerformanceReport = (
  params: SalesPlanningTypes.PerformanceParams,
): SalesPlanningTypes.PerformanceResponse =>
  apiClient
    .post(endpoints.performanceReport, {
      json: params,
    })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  autocompleteSearch,
  getById,
  create,
  update,
  remove,
  getPerformanceReport,
  getCM2,
};
