import React, { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  debounce,
  IconButton,
  TextField,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  outlinedInputClasses,
  inputBaseClasses,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { AutocompleteSearchItem } from 'api/types/common';
import {
  getSearchHistoryItems,
  removeSearchHistoryItem,
  setSearchHistoryItems,
} from 'helpers/searchHistory';
import { useFiltersSearchParams } from 'hooks';
import Loader from '../../../Loader';
import { useDataTableContext } from '../../context';
import { setDataTableState } from '../../context/actions';
import DataTableProps from '../../DataTableProps';
import AutocompleteOption from './AutocompleteOption';
import { SearchIcon } from '../../../icons';

type Props = DataTableProps.AutocompleteSearch & { inline: boolean };

const AutocompleteSearch = (props: Props) => {
  const { queryFn, queryKey, placeholder, allowSearchWithoutValue, inline } =
    props;
  const {
    state: { query },
    dispatch,
  } = useDataTableContext();
  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const [searchHistory, setSearchHistory] = useState(
    getSearchHistoryItems(queryKey).items,
  );
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useQuery(
    [queryKey, inputValue],
    () => queryFn(inputValue),
    { enabled: open, initialData: [] },
  );

  const initialValue = useMemo(() => {
    const options = uniqBy([...searchHistory, ...data], 'name') || [];
    if (query) {
      return options.filter((o) => query.includes(o.name));
    }

    return [];
  }, [data, query, searchHistory]);

  const [value, setValue] = useState<AutocompleteSearchItem[]>(initialValue);

  const options = useMemo(() => {
    if (inputValue) {
      return uniqBy([...value, ...data], 'name') || [];
    }
    return uniqBy([...searchHistory, ...value, ...data], 'name') || [];
  }, [data, inputValue, searchHistory, value]);

  const handleRemoveSearchHistoryItem = useCallback(
    (id: string) => {
      removeSearchHistoryItem(queryKey, id);
      setSearchHistory((prevState) =>
        prevState.filter((p) => p._id.$oid !== id),
      );
    },
    [queryKey],
  );

  const handleQueryUpdate = useCallback(
    (value: string[] | undefined) => {
      if (!inline) {
        updateFiltersSearchParams({
          query: value,
        });
      }
    },
    [inline, updateFiltersSearchParams],
  );

  const handleValueChange = useCallback(
    (
      event: unknown,
      newValue: AutocompleteSearchItem[] | undefined,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<AutocompleteSearchItem>,
    ) => {
      setValue(newValue || []);

      if (reason === 'selectOption' && details) {
        setSearchHistoryItems(queryKey, {
          ...details.option,
          historyItem: true,
        });
      }

      const names = (newValue || []).map((v) => v.name);
      dispatch(
        setDataTableState({ query: names.length === 0 ? undefined : names }),
      );
      handleQueryUpdate(names.length === 0 ? undefined : names);
    },
    [dispatch, queryKey, handleQueryUpdate],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
      if (reason === 'clear') {
        dispatch(setDataTableState({ query: undefined }));
      }
    },
    500,
  );

  const handleSearch = useCallback(() => {
    let searchValue: string[] | undefined;
    const names = value.map((v) => v.name);

    if (names.length === 0) {
      if (allowSearchWithoutValue && inputValue) {
        const searchItem = {
          _id: { $oid: inputValue },
          name: inputValue,
          historyItem: true,
        };
        searchValue = [inputValue];
        setValue((prev) => [...prev, searchItem]);
        setSearchHistoryItems(queryKey, searchItem);
      }
    } else {
      searchValue = names;
    }

    dispatch(setDataTableState({ query: searchValue }));
    handleQueryUpdate(searchValue);
    setOpen(false);
  }, [
    allowSearchWithoutValue,
    dispatch,
    inputValue,
    queryKey,
    handleQueryUpdate,
    value,
  ]);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        handleSearch();
      }
    },
    [handleSearch],
  );

  const handleOpen = useCallback(() => {
    setSearchHistory(getSearchHistoryItems(queryKey).items);
    setOpen(true);
  }, [queryKey]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      multiple
      value={value}
      clearOnEscape
      popupIcon={false}
      disableClearable
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={handleValueChange}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(
        option: AutocompleteSearchItem,
        value: AutocompleteSearchItem,
      ) => option._id.$oid === value._id.$oid}
      getOptionLabel={(option: AutocompleteSearchItem) => option.name}
      options={options}
      loading={isLoading}
      sx={{
        [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
          padding: '2px',
        },
      }}
      renderOption={(props1, option) => (
        <AutocompleteOption
          option={option}
          onRemove={handleRemoveSearchHistoryItem}
          {...props1}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          placeholder={placeholder || 'Search ...'}
          onKeyUp={isLoading ? () => false : handleKeyUp}
          InputProps={{
            ...params.InputProps,
            sx: (theme) => ({
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? undefined
                  : theme.palette.primary.light,
              '& fieldset': {
                borderColor:
                  theme.palette.mode === 'dark' ? undefined : 'transparent',
              },
            }),
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <IconButton
                  size="small"
                  onClick={handleSearch}
                  disabled={
                    value.length === 0
                      ? !(allowSearchWithoutValue && inputValue)
                      : value.length === 0
                  }
                >
                  <SearchIcon />
                </IconButton>
              </>
            ),
            startAdornment: (
              <>
                {isLoading && open ? (
                  <Loader circularProgressProps={{ size: 20 }} />
                ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
export default AutocompleteSearch;
