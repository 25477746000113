import React, { useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { DiscountTypes } from 'api';
import DetailsTab from './DetailsTab';
import ActivityLogTab from './ActivityLogTab';

const TABS = {
  details: 'Details',
  activityLog: 'Activity log',
};

interface Props {
  inline?: boolean;
  data: DiscountTypes.ItemData;
}

const PageTabs = (props: Props): JSX.Element => {
  const { data, inline } = props;

  const [activeTab, setActiveTab] = useState(TABS.details);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        {Object.values(TABS).map((item) => (
          <Tab label={item} key={item} value={item} />
        ))}
      </Tabs>

      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.details && (
          <DetailsTab discount={data} inline={inline} />
        )}
        {activeTab === TABS.activityLog && <ActivityLogTab discount={data} />}
      </Stack>
    </>
  );
};

export default PageTabs;
