import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import { MouseEventHandler } from 'react';
import { InfluencerTypes } from 'api';
import { ArrowDownTinIcon } from 'components/icons';
import SimilarInfluencerCard from './SimilarInfluencerCard';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const PrevButton = ({ onClick }: { onClick?: MouseEventHandler }) => (
  <IconButton
    onClick={onClick}
    size="small"
    sx={{
      position: 'absolute',
      left: '-8px',
      top: '50%',
      transform: 'translate(-100%, -50%) rotate(180deg)',
      zIndex: 2,
    }}
  >
    <ArrowDownTinIcon sx={{ transform: 'rotate(270deg)' }} />
  </IconButton>
);

const NextButton = ({ onClick }: { onClick?: MouseEventHandler }) => (
  <IconButton
    onClick={onClick}
    size="small"
    sx={{
      position: 'absolute',
      right: '-8px',
      top: '50%',
      transform: 'translate(100%, -50%)',
      zIndex: 2,
    }}
  >
    <ArrowDownTinIcon sx={{ transform: 'rotate(270deg)' }} />
  </IconButton>
);

const SimilarInfluencersBlock = (props: Props): JSX.Element | null => {
  const { data } = props;

  const sliderConfig: Settings = {
    dots: false,
    autoplay: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" paragraph>
          Similar influencers
        </Typography>
        <Box sx={{ px: 4 }}>
          {/* @ts-ignore */}
          <Slider {...sliderConfig}>
            {data.related_influencers.map((influencer) => (
              <SimilarInfluencerCard
                data={influencer}
                key={influencer._id?.$oid}
              />
            ))}
          </Slider>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SimilarInfluencersBlock;
