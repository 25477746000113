import React, { useState } from 'react';
import { IconButton, Menu, Stack } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { CommonItem } from 'api/types/common';
import { QueryObserverResult } from '@tanstack/react-query';
import ActionButton from './ActionButton';
import { DataTableProps } from '../DataTable';

interface Props {
  actions: DataTableProps.Action<CommonItem>[];
  maxActions?: number;
  data: CommonItem;
  refetch?: () => Promise<QueryObserverResult>;
}

const ActionButtonsGroup = (props: Props): JSX.Element => {
  const { data, actions, maxActions = 3, refetch } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack direction="row" columnGap={1} alignItems="center">
      {actions.length > maxActions ? (
        <>
          {Array.from(new Array(maxActions - 1))
            .map((_, i) => i)
            .map((number) => (
              <ActionButton
                onClose={handleClose}
                key={number}
                action={actions[number]}
                data={data}
                refetch={refetch}
              />
            ))}
          <IconButton size="small" onClick={handleClick}>
            <MoreHorizOutlined />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {actions.map((action) => (
              <ActionButton
                onClose={handleClose}
                refetch={refetch}
                action={action}
                data={data}
                inMenu
                key={
                  typeof action.name === 'string'
                    ? action.name
                    : action.name(data)
                }
              />
            ))}
          </Menu>
        </>
      ) : (
        actions.map((action) => (
          <ActionButton
            onClose={handleClose}
            refetch={refetch}
            action={action}
            data={data}
            key={
              typeof action.name === 'string' ? action.name : action.name(data)
            }
          />
        ))
      )}
    </Stack>
  );
};

export default ActionButtonsGroup;
