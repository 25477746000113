import { CampaignTypes } from 'api';
import { enumToOptions } from 'helpers/common';

const STATUS_OPTIONS = enumToOptions(CampaignTypes.StatusEnum);

const TYPE_OPTIONS = enumToOptions(CampaignTypes.TypeEnum);

const CAMPAIGNS = {
  STATUS_OPTIONS,
  TYPE_OPTIONS,
};

export default CAMPAIGNS;
