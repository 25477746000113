import React, { useMemo, useState } from 'react';
import { InfluencerTypes } from 'api';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import { EuroOutlined } from '@mui/icons-material';

interface Props {
  influencer: InfluencerTypes.ItemExtended | undefined;
}

const SalesCalculator = (props: Props): JSX.Element | null => {
  const { influencer } = props;

  const averageLinkClicks = useMemo(
    () => influencer?.instagram_story?.avg_link_clicks_nb || null,
    [influencer?.instagram_story?.avg_link_clicks_nb],
  );

  const [manualAverageLinkClicks, setManualAverageLinkClicks] =
    useState<number>(0);
  const [cr, setCr] = useState<number>(0);
  const [aov, setAov] = useState<number>(0);

  const linkClicks = averageLinkClicks ?? manualAverageLinkClicks;

  return (
    <Stack rowGap={2}>
      <TextField
        type="number"
        name="linkClicks"
        label="Average link clicks"
        value={linkClicks}
        disabled={typeof averageLinkClicks === 'number'}
        onChange={(e) =>
          setManualAverageLinkClicks(e.target.value ? +e.target.value : 0)
        }
        onBlur={(e) =>
          setManualAverageLinkClicks(+(+e.target.value).toFixed(2))
        }
      />
      <TextField
        type="number"
        name="cr"
        label="CR"
        value={cr}
        onChange={(e) => setCr(e.target.value ? +e.target.value : 0)}
        onBlur={(e) => setCr(+(+e.target.value).toFixed(2))}
        InputProps={{
          notched: false,
          inputProps: {
            min: 0,
          },
        }}
      />
      <TextField
        type="number"
        name="aov"
        label="AOV"
        value={aov}
        onChange={(e) => setAov(e.target.value ? +e.target.value : 0)}
        onBlur={(e) => setAov(+(+e.target.value).toFixed(2))}
        InputProps={{
          notched: false,
          endAdornment: <EuroOutlined />,
        }}
      />
      <Chip
        label={
          <Typography fontWeight={500} fontSize={16}>
            Sales potential - ${(linkClicks * cr * aov).toFixed(2)}
          </Typography>
        }
        color="primary"
        variant="outlined"
      />
    </Stack>
  );
};

export default SalesCalculator;
