import apiClient from '../apiClient';
import Posts from './types';

const endpoints = {
  getList: 'posts/filter',
  getById: (id: string) => `posts/${id}/mentions`,
};

const getBrandMentionsById = (
  id: string,
  params: Posts.MentionsParams,
): Posts.MentionsResponse =>
  apiClient
    .post(endpoints.getById(id), { json: params })
    .then((response) => response.json());

const getBrandMentions = (params: Posts.GetListParams): Posts.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
      timeout: 300000,
    })
    .then((response) => response.json());

export default {
  endpoints,
  getBrandMentionsById,
  getBrandMentions,
};
