import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { CountriesEnum } from 'api/types/Countries';
import COUNTRIES from 'constants/countries';
import { getCountryColor } from 'helpers/getBrandColor';

interface Props {
  country: CountriesEnum;
  onlyLogo?: boolean;
}

const CountryChip = (props: Props) => {
  const { country, onlyLogo } = props;

  return (
    <Chip
      variant="outlined"
      color={getCountryColor(country)}
      label={
        <Stack direction="row" columnGap={1} alignItems="center">
          <Box
            component="img"
            sx={(theme) => ({
              width: theme.spacing(2.25),
              height: 'auto',
            })}
            src={COUNTRIES.LIST[country].image}
            alt={country}
          />
          {!onlyLogo && (
            <Typography fontSize="inherit" color="inherit">
              {country}
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

export default CountryChip;
