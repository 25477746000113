import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Typography } from '@mui/material';
import { shops } from 'api';
import TooltipChip from './TooltipChip';

interface Props {
  shopId: string;
  variant?: 'chip' | 'text';
}

const ShopInfo = (props: Props): JSX.Element | null => {
  const { shopId, variant = 'chip' } = props;

  const { data: shopsData, error: shopsError } = useQuery(
    [shops.endpoints.getFlatList],

    shops.getFlatList,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (shopsData) {
    const label =
      shopsData.find((s) => s._id.$oid === shopId)?.name || 'unknown';
    return variant === 'chip' ? (
      <TooltipChip title="Shop" label={label} color="primary" />
    ) : (
      <Typography color="text.secondary" component="span">
        {label}
      </Typography>
    );
  }

  if (shopsError) {
    return variant === 'chip' ? (
      <TooltipChip
        title="Shop"
        label="Failed to load shop data"
        color="error"
      />
    ) : (
      <Typography color="text.secondary" component="span">
        Failed to load shop data
      </Typography>
    );
  }

  return variant === 'chip' ? (
    <Skeleton
      variant="circular"
      width="100px"
      height="40px"
      sx={(theme) => ({ borderRadius: `${theme.shape.borderRadius}px` })}
    />
  ) : (
    <Skeleton variant="text" width="100px" />
  );
};

export default ShopInfo;
