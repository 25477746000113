import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { users } from 'api';
import { DetailsLayout, Loader } from 'components';
import Header from './components/Header';
import Actions from './components/Header/Actions';
import PageTabs from './components/PageTabs';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Details = (props: Props): JSX.Element => {
  const { itemId: propsId } = props;
  const { id: paramsId = '' } = useParams<Params>();

  const id = useMemo(() => propsId || paramsId, [propsId, paramsId]);

  const { data } = useQuery(
    [users.endpoints.getById(id)],
    () => users.getById(id),
    { enabled: !!id },
  );

  if (!data) {
    return <Loader variant="centered" height="80vh" />;
  }

  if (propsId) {
    return (
      <Stack>
        <Box
          sx={{
            p: '11.5px',
            position: 'sticky',
            top: 0,
            width: '100%',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
            background: (t) => t.palette.background.paper,
            zIndex: (t) => t.zIndex.drawer + 1,
          }}
        >
          <Actions user={data} inline />
        </Box>
        <Box p={2} sx={{ overflowX: 'hidden' }}>
          <Header user={data} inline />
          <PageTabs data={data} inline />
        </Box>
      </Stack>
    );
  }

  return (
    <DetailsLayout>
      <Stack p={3}>
        <Header user={data} />
        <Box py={3} sx={{ overflowX: 'hidden' }}>
          <PageTabs data={data} />
        </Box>
      </Stack>
    </DetailsLayout>
  );
};

export default Details;
