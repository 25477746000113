import apiClient from '../apiClient';
import ShopTypes from './types';

const endpoints = {
  getFlatList: 'shops',
};

const getFlatList = (): ShopTypes.FlatListResponse =>
  apiClient.get(endpoints.getFlatList).then((response) => response.json());

export default {
  endpoints,
  getFlatList,
};
