import React, { useState } from 'react';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  CalendarViewMonthRounded,
  PieChartOutlineRounded,
} from '@mui/icons-material';
import { DealTypes } from 'api';
import PieChartsBlock from './PieChartsBlock';
import TableBlock from './TableBlock';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const BrandEconomics = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const [chartVariant, setChartVariant] = useState<'pie' | 'table'>('table');

  const handleChartVariantChange = (_: unknown, value: typeof chartVariant) => {
    setChartVariant(value);
  };

  return (
    <Stack direction="column" rowGap={2}>
      <Stack direction="row" columnGap={3}>
        <Typography variant="h5" flexGrow={1}>
          Brand economics
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={chartVariant}
          exclusive
          size="small"
          onChange={handleChartVariantChange}
          aria-label="Platform"
        >
          <ToggleButton value="pie">
            <PieChartOutlineRounded />
          </ToggleButton>
          <ToggleButton value="table">
            <CalendarViewMonthRounded />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {chartVariant === 'pie' ? (
        <PieChartsBlock deal={deal} />
      ) : (
        <TableBlock deal={deal} />
      )}
    </Stack>
  );
};

export default BrandEconomics;
