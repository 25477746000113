import React from 'react';
import { Box, Chip, ChipProps, Stack, Typography } from '@mui/material';
import BRANDS from 'constants/brands';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import COUNTRIES from 'constants/countries';
import { getBrandColor } from 'helpers/getBrandColor';

interface Props {
  brand: BrandsEnum;
  country?: CountriesEnum;
  onlyLogo?: boolean;
  ChipProps?: ChipProps;
  label?: string;
}

const BrandChip = (props: Props) => {
  const { brand, country, onlyLogo, ChipProps, label } = props;

  return (
    <Chip
      {...ChipProps}
      variant="outlined"
      color={getBrandColor(brand)}
      label={
        <Stack direction="row" columnGap={1} alignItems="center">
          <Box
            component="img"
            sx={(theme) => ({
              width: theme.spacing(1.5),
              height: 'auto',
            })}
            src={BRANDS.LIST[brand].image}
            alt={brand}
          />
          {!onlyLogo && (
            <Typography fontSize="inherit" color="inherit">
              {label || brand}
            </Typography>
          )}
          {country && (
            <Box
              component="img"
              sx={(theme) => ({
                width: theme.spacing(2.25),
                height: 'auto',
              })}
              src={COUNTRIES.LIST[country].image}
              alt={brand}
            />
          )}
        </Stack>
      }
    />
  );
};

export default BrandChip;
