import React, { ReactNode } from 'react';
import { CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { ColorCard } from 'components';
import { DealInfoItems } from './DetailsTab';

interface Props {
  title: ReactNode;
  subheader?: ReactNode;
  children?: ReactNode;
  data: DealInfoItems;
  color?: string;
}

const InfoCard = (props: Props): JSX.Element | null => {
  const { data, title, subheader, color, children } = props;

  return (
    <ColorCard color={color}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <Stack rowGap={1}>
          {data.map((item) => (
            <Stack
              direction="row"
              columnGap={2}
              key={item.label}
              flexWrap="wrap"
            >
              <Typography variant="body2" flexGrow={1}>
                {item.label}
              </Typography>
              <Typography color="text.secondary">
                {item.value ?? '-'}
              </Typography>
            </Stack>
          ))}
        </Stack>
        {children}
      </CardContent>
    </ColorCard>
  );
};

export default InfoCard;
