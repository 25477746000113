import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { DealTypes } from 'api';
import { colors } from 'theme/constants';
import { formatCurrency } from 'helpers/formatters';
import { isNotEmptyValue } from 'helpers/common';
import { MetricCard, MetricCardProps } from 'components';

interface Props {
  inline?: boolean;
  deal: DealTypes.ItemDataExtended;
}

export type DealInfoItems = ({
  key: keyof DealTypes.ItemData | string;
} & MetricCardProps)[];

const PerformanceTab = (props: Props): JSX.Element | null => {
  const { deal, inline } = props;

  const primaryMetricsInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'budget_actual',
        subtitle: 'Budget actual',
        title: isNotEmptyValue(deal.budget_actual)
          ? formatCurrency(deal.budget_actual)
          : '-',
        color: colors.accent,
        showTrend: isNotEmptyValue(deal.budget_actual),
      },
      {
        key: 'roi_actual',
        subtitle: 'ROI actual',
        title: isNotEmptyValue(deal.roi_actual) ? deal.roi_actual : '-',
        color: colors.purple,
        showTrend: isNotEmptyValue(deal.roi_actual),
      },
      {
        key: 'revenue_actual',
        subtitle: 'Revenue actual',
        title: isNotEmptyValue(deal.revenue_actual)
          ? formatCurrency(deal.revenue_actual)
          : '-',
        showTrend: isNotEmptyValue(deal.revenue_actual),
        color: colors.darkGreen,
      },
      {
        key: 'gross_profit_actual',
        subtitle: 'Gross profit actual',
        title: isNotEmptyValue(deal.gross_profit_actual)
          ? formatCurrency(deal.gross_profit_actual)
          : '-',
        showTrend: isNotEmptyValue(deal.gross_profit_actual),
        color: colors.darkBlue,
      },
    ],
    [
      deal.budget_actual,
      deal.gross_profit_actual,
      deal.revenue_actual,
      deal.roi_actual,
    ],
  );

  return (
    <Box>
      <Grid container spacing={3}>
        {primaryMetricsInfo.map((item) => (
          <Grid item xs={12} sm={6} md={inline ? 6 : 3} key={item.key}>
            <MetricCard
              title={item.title}
              subtitle={item.subtitle}
              color={item.color}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PerformanceTab;
