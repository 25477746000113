import React from 'react';
import { ButtonGroup } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import DataTableProps from '../DataTableProps';
import DealsActions from './DealsActions';
import BrandMentionsStatus from './BrandMentionsStatus';
import BookmarkAddAction from './BookmarkAddAction';
import BookmarkRemoveAction from './BookmarkRemoveAction';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
  BulkActionsProps: DataTableProps.BulkActionsProps;
  idKey: string;
}

const BulkActions = (props: Props): JSX.Element | null => {
  const {
    refetch,
    BulkActionsProps: { bookmarkType },
    idKey,
  } = props;

  if (bookmarkType === 'deal_ids') {
    return <DealsActions refetch={refetch} idKey={idKey} />;
  }

  if (bookmarkType === 'influencer_ids') {
    return (
      <ButtonGroup variant="outlined" color="inherit">
        <BookmarkAddAction
          refetch={refetch}
          bookmarkType={bookmarkType}
          idKey={idKey}
        />
        <BookmarkRemoveAction refetch={refetch} />
        <BrandMentionsStatus refetch={refetch} />
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup variant="outlined" color="inherit">
      <BookmarkAddAction
        refetch={refetch}
        bookmarkType={bookmarkType}
        idKey={idKey}
      />
      <BookmarkRemoveAction refetch={refetch} />
    </ButtonGroup>
  );
};

export default BulkActions;
