import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
} from '../types/common';
import { CountriesEnum } from '../types/Countries';
import { BrandsEnum } from '../types/Brands';
import { DealTypes } from '../deals';

namespace DiscountTypes {
  export enum AppliesToEnum {
    // AllProducts = 'All products',
    SpecificCollections = 'Specific collections',
    SpecificProducts = 'Specific products',
  }

  export enum CustomerEligibilityEnum {
    Everyone = 'Everyone',
    SpecificCustomerSegments = 'Specific customer segments',
    SpecificCustomers = 'Specific customers',
  }

  export enum DiscountTypeEnum {
    Percentage = 'Percentage',
    FixedAmount = 'Fixed amount',
  }

  export enum DiscountAmountTypeEnum {
    ProductDiscount = 'Product discount',
    OrderDiscount = 'Order discount',
  }

  export enum MinimumRequirementsEnum {
    None = 'None',
    MinimumPurchaseAmount = 'Minimum purchase amount',
    MinimumQuantity = 'Minimum quantity',
  }

  export enum ShopifyStatusEnum {
    Active = 'ACTIVE',
    Scheduled = 'SCHEDULED',
    Expired = 'EXPIRED',
  }

  export enum DetailsEnum {
    PercentageDiscount = 'Percentage discount',
    PercentageDiscountAndFreeGift = 'Percentage discount and free gift',
    PercentageDiscountAndFreeShipping = 'Percentage discount and free shipping',
    SplitDiscounts = 'Split discounts',
    TieredDiscounts = 'Tiered discounts',
    ValueDiscount = 'Value discount',
    ValueDiscountAndFreeGift = 'Value discount and free gift',
    ValueDiscountAndFreeShipping = 'Value discount and free shipping',
  }

  export interface Product {
    id: string;
    title: string;
    image?: string;
  }

  export type Collection = Product;

  export interface Customer {
    id: string;
    name: string;
  }

  export interface ItemCreateParams {
    shop_id: string;
    brand: BrandsEnum;
    country_team: CountriesEnum;
    coupon_code: string;
    coupon_description: string;
    discount_amount_type: DiscountAmountTypeEnum;
    discount_type: DiscountTypeEnum;
    discount_details: DetailsEnum;
    value: number;
    percentage_description?: string;
    free_gift_product_categories: string[];
    free_gift_products: string[];
    applies_once_per_order: boolean;
    applies_once_per_customer: boolean;
    applies_to?: AppliesToEnum;
    minimum_requirements: MinimumRequirementsEnum;
    minimum_value: number;
    customer_eligibility: CustomerEligibilityEnum;
    usage_limit: number | null;
    start_date: string;
    end_date?: string | null;
    strategy: DealTypes.StrategiesEnum;
    channel: DealTypes.ChannelEnum;

    products: Product[];
    collections: Collection[];
    customer_segments: Customer[];
    customers_individual: Customer[];
  }

  export interface ItemData extends Omit<ItemCreateParams, 'shop_id'> {
    _id: ID;
    shop_id: ID;
    bookmark_id?: string | null;
    title: string;
    shopify_status: ShopifyStatusEnum;
    applies_to_all_customers: boolean;
    applies_to_all_products: boolean;
    discount_id: string;
    influenser_app_channel_id: number;
    influenser_app_subchannel_id: number;
    shopify_DiscountCodeNode_id: string;
    shopify_short_id: string;
    shopify_summary: string;
    created_at: string;
    updated_at: string;
  }

  export type ItemResponse = Promise<ItemData>;

  export type ListData = ListPaginationResponse<ItemData>;

  export type ListResponse = Promise<ListData>;

  export interface GetListParams extends ListPaginationParams {
    filters: {
      // global
      country_team?: string;
      brand?: string;
      // general
      coupon_code?: string;
      no_end_date?: boolean;
      no_strategy?: boolean;
      shopify_status?: ShopifyStatusEnum[];
      discount_type?: DiscountTypeEnum[];
      start_date?: {
        $gte: string;
        $lte: string;
      };
      strategy?: DealTypes.StrategiesEnum[];
      channel?: DealTypes.ChannelEnum[];
      show_bookmarks?: boolean;
    };
  }

  export type AutocompleteSearchItem = Pick<
    ItemData,
    | 'shop_id'
    | 'coupon_code'
    | 'coupon_description'
    | 'discount_details'
    | 'discount_id'
    | 'discount_type'
    | 'free_gift_product_categories'
    | 'free_gift_products'
    | 'value'
    | 'percentage_description'
  >;

  export type AutocompleteSearchData = AutocompleteSearchItem[];

  export type AutocompleteSearchResponse = Promise<AutocompleteSearchData>;

  export interface AutocompleteSearchParams {
    shop_id?: string;
    coupon_code: string;
  }

  // ------------ start shopify types -----------------

  interface ShopifyData<T> {
    data: T;
    extensions: {
      cost: {
        requestedQueryCost: number;
        actualQueryCost: number;
        throttleStatus: {
          maximumAvailable: number;
          currentlyAvailable: number;
          restoreRate: number;
        };
      };
    };
  }

  type ShopifyResponse<T> = Promise<ShopifyData<T>>;

  interface ShopifyParams {
    country_team: CountriesEnum;
    brand: BrandsEnum;
    shop_id: string;
  }

  export interface ShopifyProductsItem {
    id: string;
    title: string;
    images: {
      edges: {
        node: {
          id: string;
          url: string;
        };
      }[];
    };
  }

  export interface ShopifyProductsData {
    products: {
      edges: {
        node: ShopifyProductsItem;
      }[];
    };
  }

  export type ShopifyProductsResponse = ShopifyResponse<ShopifyProductsData>;

  export interface ShopifyProductsParams extends ShopifyParams {
    product_title: string;
  }

  export interface ShopifyCollectionsItem {
    id: string;
    title: string;
    image: {
      id: string;
      url: string;
    };
  }

  export interface ShopifyCollectionsData {
    collections: {
      edges: {
        node: ShopifyCollectionsItem;
      }[];
    };
  }

  export type ShopifyCollectionsResponse =
    ShopifyResponse<ShopifyCollectionsData>;

  export interface ShopifyCollectionsParams extends ShopifyParams {
    collection_title: string;
  }

  export interface ShopifyCustomerSegmentsItem {
    id: string;
    name: string;
  }

  export interface ShopifyCustomerSegmentsData {
    segments: {
      edges: {
        node: ShopifyCustomerSegmentsItem;
      }[];
    };
  }

  export type ShopifyCustomerSegmentsResponse =
    ShopifyResponse<ShopifyCustomerSegmentsData>;

  export interface ShopifyCustomerSegmentsParams extends ShopifyParams {
    customer_list_name: string;
  }

  export interface ShopifyCustomerEmailsItem {
    id: string;
    displayName: string;
    email: string;
  }

  export interface ShopifyCustomerEmailsData {
    customers: {
      edges: {
        node: ShopifyCustomerEmailsItem;
      }[];
    };
  }

  export type ShopifyCustomerEmailsResponse =
    ShopifyResponse<ShopifyCustomerEmailsData>;

  export interface ShopifyCustomerEmailsParams extends ShopifyParams {
    customer_email: string;
  }

  // ------------ end shopify types -----------------
}

export default DiscountTypes;
