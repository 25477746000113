import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { FormContainer } from 'components';
import { salesPlanning, SalesPlanningTypes } from 'api';
import { getDiffFields, getFieldsWithValues } from 'helpers/getFormFields';
import { getSum } from 'helpers/calculations';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import GeneralInfo from './GeneralInfo';
import BudgetDistribution from './BudgetDistribution';
import BrandBudgetDistribution from './BrandBudgetDistribution';

// todo - temporarily disabled; remove changes in future
const isBrandsDistributionEnabled = true;

interface Props {
  id: string | undefined;
  initialData: SalesPlanningTypes.ItemCreateParams;
  drawer?: boolean;
  drawerTitle?: string;
  shopId: string;
  setShopId: React.Dispatch<React.SetStateAction<string>>;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, drawerTitle, drawer, shopId, setShopId } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm<SalesPlanningTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const [isError, setIsError] = useState(false);

  const { requestFn } = useApiRequest(
    id ? (data) => salesPlanning.update(id, data) : salesPlanning.create,
  );

  const onSubmit = useCallback(
    async (data: SalesPlanningTypes.ItemCreateParams) => {
      setIsError(false);

      const sum = isBrandsDistributionEnabled
        ? getSum(
            Object.values(data.brand_revenue_distribution || {}).map(
              (v) => v || 0,
            ),
          )
        : 100;

      if (sum === 100) {
        const values = id
          ? getDiffFields(initialData, data)
          : getFieldsWithValues(data);
        const submitData = {
          ...values,
          budget_distribution: data.budget_distribution,
        };

        const res = await requestFn({
          args: submitData,
          successMessage: `Sales planning ${id ? 'updated' : 'created'}`,
        });

        if (res) {
          if (drawer) {
            updateFiltersSearchParams({
              salesPlanningId: undefined,
              drawerMode: undefined,
            });

            if (refetchTableData) {
              await refetchTableData();
            }
          } else {
            navigate(appLinks.sales.details.as(res.sales_planning_id), {
              replace: true,
              state: {
                from: id ? 'edit' : 'create',
              },
            });
          }
        }
      } else {
        setIsError(true);
        const el = document.getElementById('brand_revenue_distribution');

        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center',
          });
        }
      }
    },
    [
      id,
      initialData,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.sales.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
      ContainerProps={{
        maxWidth: 'xl',
      }}
    >
      <Stack rowGap={2} sx={{ position: 'relative' }}>
        <GeneralInfo
          control={control}
          watch={watch}
          setValue={setValue}
          setShopId={setShopId}
        />
        {isBrandsDistributionEnabled && (
          <BrandBudgetDistribution
            control={control}
            watch={watch}
            setValue={setValue}
            isError={isError}
          />
        )}
        <BudgetDistribution
          shopId={shopId}
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </Stack>
    </FormContainer>
  );
};

export default Form;
