import { Grid } from '@mui/material';
import React from 'react';
import DataTableProps from '../../DataTableProps';
import SocialNetworkFilter from './SocialNetworkFilter';
import AutocompleteSearch from './AutocompleteSearch';

interface Props {
  inline: boolean;
  SearchProps: DataTableProps.AutocompleteSearch | undefined;
}

const Search = (props: Props): JSX.Element | null => {
  const { SearchProps, inline } = props;

  if (!SearchProps || (!SearchProps?.queryFn && !SearchProps?.queryKey)) {
    return null;
  }

  return (
    <>
      {SearchProps?.variant === 'influencers' && (
        <Grid item xs={2}>
          <SocialNetworkFilter />
        </Grid>
      )}
      <Grid item sx={{ flexGrow: 1 }}>
        <AutocompleteSearch {...SearchProps} inline={inline} />
      </Grid>
    </>
  );
};

export default Search;
