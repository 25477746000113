import React, { useCallback, useMemo } from 'react';
import { KeyboardTabOutlined } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useFiltersSearchParams } from 'hooks';
import { DataTableProps } from './DataTable';

interface Props {
  variant?: 'button' | 'icon';
  buttonText?: string;
}

const CloseDetailsDrawer = (props: Props): JSX.Element => {
  const { variant, buttonText = 'Cancel' } = props;

  const {
    filtersSearchParams: {
      influencerId,
      dealId,
      negotiationId,
      campaignId,
      discountId,
      salesPlanningId,
      userId,
      splitItemId, // todo - wtf
    },
    updateFiltersSearchParams,
  } = useFiltersSearchParams();

  const activeItem = useMemo(() => {
    const obj = {
      influencerId,
      dealId,
      negotiationId,
      campaignId,
      discountId,
      salesPlanningId,
      userId,
      splitItemId,
    };

    return Object.entries(obj)
      .map(([key, value]) =>
        value !== undefined
          ? {
              type: key as DataTableProps.DetailsDrawerType,
              id: value as string,
            }
          : undefined,
      )
      .filter((v) => !!v)[0];
  }, [
    campaignId,
    dealId,
    discountId,
    influencerId,
    negotiationId,
    salesPlanningId,
    userId,
    splitItemId,
  ]);

  const handleClose = useCallback(() => {
    if (activeItem?.type) {
      updateFiltersSearchParams({
        [activeItem.type]: undefined,
        drawerMode: undefined,
      });
    }
  }, [activeItem, updateFiltersSearchParams]);

  return variant === 'button' ? (
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={handleClose}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[9],
      })}
    >
      {buttonText}
    </Button>
  ) : (
    <IconButton onClick={handleClose}>
      <KeyboardTabOutlined />
    </IconButton>
  );
};

export default CloseDetailsDrawer;
