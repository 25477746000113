import { SvgIcon } from '@mui/material';
import {
  AddBoxOutlined,
  AddCircleOutlineOutlined,
  OndemandVideoOutlined,
  SlideshowOutlined,
} from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';

const statsFields: Array<{
  key: keyof InfluencerTypes.Post;
  label: string;
  type: 'default' | 'currency' | 'percentage' | 'roi';
}> = [
  { key: 'cpo', label: 'Global CPO', type: 'currency' },
  { key: 'roi', label: 'Global ROI', type: 'roi' },
  {
    key: 'latest_deal_budget',
    label: 'Latest deal budget',
    type: 'currency',
  },
  { key: 'total_posts_nb', label: 'Posts count', type: 'default' },
  {
    key: 'avg_comments_nb',
    label: 'Average comments count',
    type: 'default',
  },
  { key: 'avg_likes_nb', label: 'Average likes count', type: 'default' },
  { key: 'avg_link_clicks_nb', label: 'Average link clicks', type: 'default' },
  {
    key: 'comment_to_like',
    label: 'Average comment-to-like',
    type: 'percentage',
  },
  {
    key: 'engagement_rate',
    label: 'Average engagement rate',
    type: 'percentage',
  },
  { key: 'avg_cpm', label: 'Average CPM', type: 'currency' },
  { key: 'avg_budget', label: 'Average budget', type: 'currency' },
  { key: 'avg_revenue', label: 'Average revenue', type: 'currency' },
  { key: 'avg_views_nb', label: 'Average views', type: 'default' },
  { key: 'reach_rate', label: 'Reach rate', type: 'percentage' },
];

const StatGroupIcon: Partial<
  Record<keyof InfluencerTypes.ItemExtended, typeof SvgIcon>
> = {
  instagram_story: AddCircleOutlineOutlined,
  instagram_post: AddBoxOutlined,
  instagram_reel: SlideshowOutlined,
  instagram_tv: OndemandVideoOutlined,
  tiktok_post: AddBoxOutlined,
  snapchat_post: AddBoxOutlined,
  youtube_post: AddBoxOutlined,
  pinterest_post: AddBoxOutlined,
};

const getStatsFormattedValue = (
  value: any,
  type: 'default' | 'currency' | 'percentage' | 'roi',
) => {
  switch (type) {
    case 'currency':
      return formatCurrency(value);
    case 'percentage':
      return formatPercent(value);
    case 'roi':
      return formatPercent(value);
    default:
      return formatNumber(value);
  }
};

export { statsFields, StatGroupIcon, getStatsFormattedValue };
