import { DealTypes } from '../api';

const convertToFixed = (value: number) => {
  try {
    return +value.toFixed(2);
  } catch (e) {
    return 0;
  }
};

const convertNumber = ({ value = 0, fxRate = 0 }) =>
  value >= 0 && fxRate >= 0 ? +(value * fxRate).toFixed(2) : 0;

const getCPM = ({ budget = 0, reach = 0 }) =>
  budget >= 0 && reach > 0 ? +((budget / reach) * 1000).toFixed(2) : 0;

const getBudget = ({ cpm = 0, reach = 0 }) =>
  cpm >= 0 && reach > 0 ? +(cpm * (reach / 1000)).toFixed(2) : 0;

const getRevenue = ({ budget = 0, roi = 0 }) =>
  budget >= 0 && roi > 0 ? +(roi * budget).toFixed(2) : 0;

const getRatio = ({ value = 0, total = 0 }) =>
  value >= 0 && total > 0 ? +(value / total) * 100 : 0;

const getNegativeRatio = ({ value = 0, total = 0 }) => {
  if (total === 0) return 0;
  return +(value / total) * 100;
};

const getRoi = ({ revenue = 0, budget = 0 }) =>
  revenue >= 0 && budget > 0 ? +(revenue / budget).toFixed(2) : 0;

const getSum = (array: number[] = []) =>
  array.reduce((acc, curr) => acc + curr, 0);

const getCM3 = ({ revenue = 0, budget = 0, cm2Percent = 0 }) =>
  +(+(revenue * cm2Percent).toFixed(2) - budget).toFixed(2);

// CM3 = revenue * Cm2% - (revenue / (1- 0.19) * commission cost 15%) - budget.
const getAmazonCM3 = ({ revenue = 0, budget = 0, cm2Percent = 0, tax = 0 }) =>
  +(
    +(revenue * cm2Percent).toFixed(2) -
    budget -
    +((revenue / (1 - tax)) * 0.15).toFixed(2)
  ).toFixed(2);

const getBudgetPotential = ({
  revenuePotential = 0,
  commission = 0,
  budgetType,
  budgetFixed = 0,
}: {
  budgetType: DealTypes.BudgetTypeEnum;
  revenuePotential: number | undefined;
  commission: number | undefined;
  budgetFixed: number | undefined;
}) => {
  const dealBudgetPotentialLc = revenuePotential * (commission * 0.01);
  const value =
    budgetType === DealTypes.BudgetTypeEnum.fixedAndCommission
      ? dealBudgetPotentialLc + +budgetFixed
      : dealBudgetPotentialLc;

  const v = value.toFixed(2);
  return +v;
};

export {
  getCPM,
  getRoi,
  getSum,
  getRatio,
  getBudgetPotential,
  getRevenue,
  convertNumber,
  getBudget,
  convertToFixed,
  getCM3,
  getNegativeRatio,
  getAmazonCM3,
};
