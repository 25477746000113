import React, { useEffect, useMemo, useState } from 'react';
import { influencers, negotiations, NegotiationTypes } from 'api';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary, Loader } from 'components';
import { useStoreContext } from 'store';
import { AutocompleteSearchItem } from 'api/types/common';
import { getInfluencerName } from 'helpers/common';
import Form from './Form';
import { dataToInitialModel, initialModel } from './fields';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { itemId } = props;
  const { id: paramsId = '' } = useParams<Params>();
  const {
    state: { user },
  } = useStoreContext();

  const id = useMemo(() => paramsId || itemId || '', [itemId, paramsId]);

  const [searchParams] = useSearchParams();

  const influencerId = useMemo(() => {
    const parsed = Object.fromEntries(searchParams);

    return 'influencerId' in parsed ? parsed.influencerId : undefined;
  }, [searchParams]);

  const { data: influencerData } = useQuery(
    [influencers.endpoints.getById(influencerId || ''), influencerId],
    () => influencers.getById(influencerId || ''),
    {
      enabled: !!influencerId,
    },
  );

  const [initialData, setInitialData] = useState<
    | {
        initialFormData: NegotiationTypes.ItemCreateParams;
        initialInfluencer: AutocompleteSearchItem | undefined;
      }
    | undefined
  >(undefined);

  const { data, error } = useQuery(
    [negotiations.endpoints.getById(id)],
    () => negotiations.getById(id),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!id && !influencerId) {
      setInitialData({
        initialFormData: {
          ...initialModel,
          lead: user?.full_name,
        } as NegotiationTypes.ItemCreateParams,
        initialInfluencer: undefined,
      });
    }

    if (!id && influencerId && influencerData) {
      setInitialData({
        initialFormData: {
          ...initialModel,
          lead: user?.full_name,
          influencer_id: influencerData._id.$oid,
        } as NegotiationTypes.ItemCreateParams,
        initialInfluencer: {
          _id: influencerData._id,
          name:
            influencerData.profile_name ||
            getInfluencerName(influencerData) ||
            'unknown',
        },
      });
    }

    if (id && data) {
      const initialFormData = dataToInitialModel(data);
      const initialInfluencer: AutocompleteSearchItem = {
        _id: data.influencer_id,
        name: data.influencer_profile_name || 'unknown',
      };

      setInitialData({
        initialFormData,
        initialInfluencer,
      });
    }
  }, [data, id, influencerData, influencerId, user?.full_name]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant="centered" />
  ) : (
    <Form
      drawer={!!itemId}
      drawerTitle={`Edit ${
        data?.influencer_profile_name
          ? `${data.influencer_profile_name} negotiation`
          : 'negotiation'
      }`}
      id={id}
      initialData={initialData.initialFormData}
      initialInfluencer={initialData.initialInfluencer}
    />
  );
};

export default Index;
