import { Grid } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { DealTypes } from 'api';
import { FormCard } from 'components';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import PotentialDetails from './PotentialDetails';
import LatestDealInfo from './LatestDealInfo';

interface Props {
  id?: string;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
}

const SuggestedBudgetInfo = (props: Props): JSX.Element | null => {
  const { id, watch, setValue } = props;

  const influencerId = watch('influencer_id');
  const brand = watch('brand');

  if (!influencerId) {
    return null;
  }

  return (
    <FormCard title="Suggested budget info" avatar={<InfoOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PotentialDetails influencerId={influencerId} brand={brand} />
        </Grid>
        <Grid item xs={6}>
          <LatestDealInfo
            influencerId={influencerId}
            id={id}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default SuggestedBudgetInfo;
