import FormInputProps from './FormInputProps';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import DatePickerInput from './DatePickerInput';
import AutocompleteCreatableInput from './AutocompleteCreatableInput';
import AutocompleteSearchInput from './AutocompleteSearchInput';
import CheckboxInput from './CheckboxInput';
import RadioInput from './RadioInput';
import SwitchInput from './SwitchInput';
import DateTimePickerInput from './DateTimePickerInput';

const FormInput = (props: FormInputProps.FormInput): JSX.Element => {
  const { inputType, ...rest } = props;

  if (inputType === 'textarea') {
    return <TextInput {...(rest as any)} multiline />;
  }

  if (inputType === 'select') {
    return <SelectInput {...(rest as any)} />;
  }

  if (inputType === 'datepicker') {
    return <DatePickerInput {...(rest as any)} />;
  }

  if (inputType === 'datetimepicker') {
    return <DateTimePickerInput {...(rest as any)} />;
  }

  if (inputType === 'autocompleteCreatable') {
    return <AutocompleteCreatableInput {...(rest as any)} />;
  }

  if (inputType === 'autocompleteSearch') {
    return <AutocompleteSearchInput {...(rest as any)} />;
  }

  if (inputType === 'checkbox') {
    return <CheckboxInput {...(rest as any)} />;
  }

  if (inputType === 'radio') {
    return <RadioInput {...(rest as any)} />;
  }

  if (inputType === 'switch') {
    return <SwitchInput {...(rest as any)} />;
  }

  return <TextInput {...(rest as any)} type={inputType} />;
};

export default FormInput;
