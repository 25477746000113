import { createContext, useContext } from 'react';
import { TABLE_PER_PAGE_OPTIONS } from 'constants/common';
import {
  DataTableState,
  DataTableContext as DataTableContextType,
} from './types';

const initialDataTableState: DataTableState = {
  initialFiltersState: {},
  emptyFiltersState: {},
  selectedViewKey: '',
  sortBy: '',
  sortDesc: false,
  pageNumber: 1,
  perPage: TABLE_PER_PAGE_OPTIONS[0],
  query: undefined,
  filtersState: {},
  selectedItems: [],
  activeFilters: [],
  isSplitViewEnabled: false,
};

const DataTableContext = createContext<DataTableContextType>({
  state: initialDataTableState,
  dispatch: () => null,
});

const useDataTableContext = (): DataTableContextType =>
  useContext(DataTableContext);

export type { DataTableState };
export { DataTableContext, useDataTableContext, initialDataTableState };
