import React, { FormEventHandler, ReactNode } from 'react';
import { Button, Box, Grid, Typography, Tooltip, Stack } from '@mui/material';
import {
  OpenInNewOutlined,
  PermDeviceInformationOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../LoadingButton';
import CloseDetailsDrawer from '../CloseDetailsDrawer';
import { useFiltersSearchParams } from '../../hooks';

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  disabled: boolean;
  children: ReactNode;
  title?: string;
  pageLink?: string;
}

const DrawerFormContainer = (props: Props): JSX.Element | null => {
  const { loading, disabled, onSubmit, children, title, pageLink } = props;
  const navigate = useNavigate();

  const { handleFilterChange } = useFiltersSearchParams();

  const handleOpenPage = () => {
    if (pageLink) {
      navigate(pageLink, { replace: true });
    }
  };

  return (
    <Stack component="form" onSubmit={onSubmit} sx={{ height: '100%' }}>
      <Box
        sx={{
          p: '10.5px',
          position: 'sticky',
          top: 0,
          width: '100%',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          background: (t) => t.palette.background.paper,
          zIndex: (t) => t.zIndex.drawer + 1,
        }}
      >
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <Tooltip title="Close">
              <span>
                <CloseDetailsDrawer />
              </span>
            </Tooltip>
          </Grid>
          <Grid item flexGrow={1}>
            <Typography variant="h5" component="p">
              {title || 'Edit item'}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              endIcon={<PermDeviceInformationOutlined />}
              onClick={() => {
                handleFilterChange('drawerMode', 'view');
              }}
            >
              Details
            </Button>
          </Grid>
          {pageLink && (
            <Grid item>
              <Button
                color="primary"
                endIcon={<OpenInNewOutlined />}
                onClick={handleOpenPage}
              >
                Open edit page
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>{children}</Box>
      <Stack
        columnGap={2}
        sx={{
          marginTop: 'auto',
          position: 'sticky',
          width: '100%',
          bottom: 0,
          borderTop: (t) => `1px solid ${t.palette.divider}`,
          backgroundColor: (theme) => theme.palette.background.paper,
          zIndex: 15,
          p: 2,
          flexDirection: 'row',
        }}
      >
        <CloseDetailsDrawer variant="button" />
        <LoadingButton
          fullWidth
          type="submit"
          color="primary"
          loading={loading}
          disabled={disabled}
          sx={(theme) => ({
            boxShadow: theme.shadows[9],
          })}
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DrawerFormContainer;
