import React, { useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { CampaignTypes, SalesPlanningTypes } from 'api';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { colors } from 'theme/constants';
import { InfoCard, InfoCardItem } from 'components';
import { formatCurrency } from 'helpers/formatters';
import BudgetComparisonCard from './BudgetComparisonCard';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { planning, inline } = props;

  const generalInfo: InfoCardItem<SalesPlanningTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'brands',
        label: 'Brands',
        value: planning.brands.join(', '),
      },
      {
        key: 'country_team',
        label: 'Country',
        value: planning.country_team,
      },
      {
        key: 'status',
        label: 'Status',
        value: planning.status,
      },
      {
        key: 'type',
        label: 'Type',
        value: planning.type,
      },
    ],
    [planning.brands, planning.country_team, planning.status, planning.type],
  );

  const performanceInfo: InfoCardItem<SalesPlanningTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'budget_running_forecast',
        label: 'Budget forecast',
        value: formatCurrency(planning.budget_running_forecast),
      },
      {
        key: 'budget_traffic_light',
        label: 'Budget traffic light',
        value: formatCurrency(planning.budget_traffic_light),
      },
      {
        key: 'revenue_running_forecast',
        label: 'Revenue forecast',
        value: formatCurrency(planning.revenue_running_forecast),
      },
      {
        key: 'revenue_traffic_light',
        label: 'Revenue traffic light',
        value: formatCurrency(planning.revenue_traffic_light),
      },
    ],
    [
      planning.budget_running_forecast,
      planning.budget_traffic_light,
      planning.revenue_running_forecast,
      planning.revenue_traffic_light,
    ],
  );

  const influencersInfo: InfoCardItem<SalesPlanningTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'deals_nb',
        label: 'Influencers deals',
        value: planning.deals_nb,
      },
      {
        key: 'free_deals_nb',
        label: 'Free influencers deals',
        value: planning.free_deals_nb,
      },
      {
        key: 'paid_deals_nb',
        label: 'Paid influencers deals',
        value: planning.paid_deals_nb,
      },
      {
        key: 'influencers_nb',
        label: 'Influencers',
        value: planning.influencers_nb,
      },
      {
        key: 'free_influencers_nb',
        label: 'Free influencers',
        value: planning.free_influencers_nb,
      },
      {
        key: 'paid_influencers_nb',
        label: 'Paid influencers',
        value: planning.paid_influencers_nb,
      },
      {
        key: 'reach_total',
        label: 'Reach',
        value: planning.reach_total,
      },
    ],
    [
      planning.deals_nb,
      planning.free_deals_nb,
      planning.free_influencers_nb,
      planning.influencers_nb,
      planning.paid_deals_nb,
      planning.paid_influencers_nb,
      planning.reach_total,
    ],
  );

  const otherInfo: InfoCardItem<CampaignTypes.ItemData>[] = useMemo(
    () => [
      {
        key: 'start_date',
        label: 'Start date',
        value: getFormattedDate(planning.start_date),
      },
      {
        key: 'end_date',
        label: 'End date',
        value: planning.end_date ? getFormattedDate(planning.end_date) : '',
      },
      {
        key: 'created_at',
        label: 'Created at',
        value: getFormattedDate(planning.created_at),
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        value: getFormattedDate(planning.updated_at),
      },
    ],
    [
      planning.created_at,
      planning.end_date,
      planning.start_date,
      planning.updated_at,
    ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={inline ? 12 : 4}>
        <Stack rowGap={3}>
          <InfoCard
            title="Performance"
            data={performanceInfo}
            color={colors.darkGreen}
          />
          <InfoCard
            title="General info"
            data={generalInfo}
            color={colors.darkOrange}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 4}>
        <InfoCard
          title="Influencers info"
          data={influencersInfo}
          color={colors.darkBlue}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 4}>
        <Stack rowGap={3}>
          <BudgetComparisonCard planning={planning} />
          <InfoCard title="Other info" data={otherInfo} color={colors.blue} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
