import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorBoundary, Loader } from 'components';
import Form from './Form';
import { dataToInitialModel, initialModel } from './fields';
import { marketing, MarketingTypes } from '../../../api/marketing';

type Params = 'id';

interface Props {
  editModalId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { editModalId } = props;
  const { id: paramsId = '' } = useParams<Params>();

  const id = useMemo(
    () => paramsId || editModalId || '',
    [editModalId, paramsId],
  );

  const [initialData, setInitialData] = useState<
    MarketingTypes.ItemCreateParams | undefined
  >(undefined);

  const { data, error } = useQuery(
    [marketing.endpoints.getById(id)],
    () => marketing.getById(id),
    {
      enabled: !!id,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!id) {
      setInitialData(initialModel);
    }

    if (id && data) {
      const initialFormData = dataToInitialModel(data);

      setInitialData(initialFormData);
    }
  }, [data, id]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant={editModalId ? 'fullScreen' : 'centered'} />
  ) : (
    <Form
      // drawer={!!editModalId}
      drawer
      drawerTitle="Edit marketing info"
      id={id}
      initialData={initialData}
    />
  );
};

export default Index;
