import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { LaunchOutlined, LinkOutlined } from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  CloseDetailsDrawer,
} from 'components';
import { appLinks } from 'routes/links';
import { DiscountTypes } from 'api';
import { useClipboard } from 'hooks';
import ChangeStatusButton from './ChangeStatusButton';

interface Props {
  discount: DiscountTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Actions = (props: Props): JSX.Element => {
  const { discount, refetch, inline } = props;
  const handleCopy = useClipboard();

  const actions: ActionButtonProps<DiscountTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.discounts.details.as(item.discount_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin +
              appLinks.discounts.details.as(item.discount_id),
          ),
      },
      {
        name: 'Edit discount',
        EditButtonProps: {
          inline,
          idKey: 'discountId',
          id: (item) => item.discount_id,
          link: appLinks.discounts.edit.as,
        },
      },
      {
        name: 'Bookmark discount',
        BookmarkProps: {
          itemId: (item) => item.discount_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'discount_ids',
        },
      },
    ],
    [handleCopy, inline],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={inline ? 7 : 4}
          actions={actions}
          data={discount}
          refetch={refetch}
        />
      </Grid>
      <Grid item>
        <ChangeStatusButton discount={discount} refetch={refetch} />
      </Grid>
    </Grid>
  );
};

export default Actions;
