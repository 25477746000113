import apiClient from '../apiClient';
import DiscountTypes from './types';

const getErrorMessageFromResponseData = (data: any): string => {
  if ('shopify_msg' in data) {
    return data.shopify_msg;
  }
  if ('coupon_code' in data) {
    return 'Discount code already exists';
  }
  return data;
};

const endpoints = {
  autocompleteSearch: 'discounts/autocomplete',
  autocompleteCollections: 'discounts/autocomplete/collections',
  autocompleteProducts: 'discounts/autocomplete/products',
  autocompleteCustomerSegments: 'discounts/autocomplete/customer_segments',
  autocompleteCustomerEmails: 'discounts/autocomplete/customer_emails',
  getList: 'discounts/filter',
  getById: (id: string) => `discounts/${id}`,
  create: 'discounts',
  update: (id: string) => `discounts/${id}`,
};

const autocompleteSearch = (
  params: DiscountTypes.AutocompleteSearchParams,
): DiscountTypes.AutocompleteSearchResponse =>
  apiClient
    .post(endpoints.autocompleteSearch, {
      json: params,
    })
    .then((response) => response.json());

const autocompleteCollections = (
  params: DiscountTypes.ShopifyCollectionsParams,
): DiscountTypes.ShopifyCollectionsResponse =>
  apiClient
    .post(endpoints.autocompleteCollections, {
      json: params,
    })
    .then((response) => response.json());

const autocompleteProducts = (
  params: DiscountTypes.ShopifyProductsParams,
): DiscountTypes.ShopifyProductsResponse =>
  apiClient
    .post(endpoints.autocompleteProducts, {
      json: params,
    })
    .then((response) => response.json());

const autocompleteCustomerSegments = (
  params: DiscountTypes.ShopifyCustomerSegmentsParams,
): DiscountTypes.ShopifyCustomerSegmentsResponse =>
  apiClient
    .post(endpoints.autocompleteCustomerSegments, {
      json: params,
    })
    .then((response) => response.json());

const autocompleteCustomerEmails = (
  params: DiscountTypes.ShopifyCustomerEmailsParams,
): DiscountTypes.ShopifyCustomerEmailsResponse =>
  apiClient
    .post(endpoints.autocompleteCustomerEmails, {
      json: params,
    })
    .then((response) => response.json());

const getList = (
  params: DiscountTypes.GetListParams,
): DiscountTypes.ListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getById = (id: string): DiscountTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const create = (
  data: DiscountTypes.ItemCreateParams,
): DiscountTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, {
      json: { discount: data },
      throwHttpErrors: false,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data: any) => {
          const msg = getErrorMessageFromResponseData(data);
          throw new Error(msg);
        });
      }
      return response.json();
    });

const update = (
  id: string,
  data: Partial<DiscountTypes.ItemCreateParams>,
): DiscountTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), {
      json: { discount: data },
      throwHttpErrors: false,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((data: any) => {
          const msg = getErrorMessageFromResponseData(data);
          throw new Error(msg);
        });
      }
      return response.json();
    });

export default {
  endpoints,
  autocompleteSearch,
  autocompleteCollections,
  autocompleteProducts,
  autocompleteCustomerSegments,
  autocompleteCustomerEmails,
  getList,
  getById,
  create,
  update,
};
