import { CampaignTypes } from 'api';

const initialModel: CampaignTypes.ItemCreateParams = {
  status: CampaignTypes.StatusEnum.Pending,
  type: CampaignTypes.TypeEnum.CRM,
  brands: [],
  country_teams: [],
  description: '',
  name: '',
  start_date: '',
  end_date: '',
};

const dataToInitialModel = (
  data: CampaignTypes.ItemData,
): CampaignTypes.ItemCreateParams => ({
  status: data.status,
  brands: data.brands || [],
  type: data.type || [],
  start_date: data.start_date,
  name: data.name,
  end_date: data.end_date,
  description: data.description,
  country_teams: data.country_teams,
  picture_url: data.picture_url,
});

export { dataToInitialModel, initialModel };
