import React, { useState } from 'react';
import { Avatar, Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useStoreContext } from 'store';
import { useNavigate } from 'react-router-dom';
import { ArrowDownTinIcon } from '../../icons';
import { appLinks } from '../../../routes/links';

const UserMenu = (): JSX.Element | null => {
  const {
    state: { user },
  } = useStoreContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileLink = () => {
    navigate(appLinks.team.details.as(user?._id.$oid as string));
    handleClose();
  };

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <Button
        variant="text"
        startIcon={
          <Avatar
            src={user?.display_picture_url}
            sx={(theme) => ({
              width: theme.spacing(4),
              height: theme.spacing(4),
            })}
          />
        }
        endIcon={<ArrowDownTinIcon />}
        sx={(theme) => ({
          padding: theme.spacing(1, 1.5),
          backgroundColor:
            theme.palette.mode === 'dark'
              ? undefined
              : theme.palette.primary.light,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? undefined
                : theme.palette.primary.light,
          },
        })}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography color="text.primary">
            {user?.first_name || 'User'}
          </Typography>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          position: 'inherit',
        }}
        PaperProps={{
          sx: {
            position: 'fixed',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          },
        }}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem sx={{ minHeight: '48px' }} onClick={handleProfileLink}>
          Profile
        </MenuItem>
        <MenuItem sx={{ minHeight: '48px' }} onClick={handleClose}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
