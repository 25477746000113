import {
  Card,
  DialogTitle,
  Grid,
  IconButton,
  DialogContent,
  Dialog,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import { getInfluencerName } from 'helpers/common';
import PostInfo from './PostInfo';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Partial<InfluencerTypes.PostsPost>;
  influencerData: InfluencerTypes.ItemData;
}

const PostDialog = (props: Props): JSX.Element | null => {
  const { data, onClose, isOpen, influencerData } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item xs={10}>
            {`${getInfluencerName(influencerData)} post`}
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined">
          <PostInfo data={data} height="75vh" />
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default PostDialog;
