import { Box, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { formatPercent } from 'helpers/formatters';
import { useMemo } from 'react';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.GrowthCell<DataTableProps.Item> &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const GrowthCell = (props: Props): JSX.Element => {
  const { data, cell } = props;

  const value = useMemo(
    () => (cell.value ? cell.value(data) : false),
    [cell, data],
  );
  const percentage = useMemo(() => {
    const value = cell.percentage(data);
    return value ? +value : 0;
  }, [cell, data]);

  return (
    <Box>
      {value !== false && <Typography>{value}</Typography>}
      <Typography
        variant="caption"
        sx={(theme) => ({
          display: 'inline-flex',
          alignItems: 'center',
          gap: 0.25,
          color:
            percentage > 0
              ? theme.palette.success.main
              : theme.palette.warning.main,
        })}
      >
        {formatPercent(percentage)}
        {percentage !== 0 &&
          (percentage > 0 ? (
            <ArrowUpward fontSize="inherit" />
          ) : (
            <ArrowDownward fontSize="inherit" />
          ))}
      </Typography>
    </Box>
  );
};

export default GrowthCell;
