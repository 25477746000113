import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency } from 'helpers/formatters';
import { CampaignTypes } from 'api';

interface Props {
  performance: CampaignTypes.PerformanceInsightsVarData;
}

const config: {
  key: keyof CampaignTypes.PerformanceInsightsVarItem;
  label: string;
}[] = [
  { key: 'strategy', label: 'Channel' },
  { key: 'sum_revenue_actual', label: 'Actual' },
  { key: 'sum_revenue_potential', label: 'Potential' },
  { key: 'variance', label: 'Variance' },
];

const CardTable: React.FC<Props> = (props: Props): JSX.Element => {
  const { performance } = props;

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            {config.map((c, index) => (
              <TableCell
                key={c.key}
                sx={(theme) => ({
                  borderRight:
                    index === 0
                      ? `1px solid ${theme.palette.divider}`
                      : undefined,
                })}
              >
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {performance.map((item) => (
            <TableRow
              key={item.strategy}
              sx={{
                '&:last-child td, &:last-child th': { borderBottom: 0 },
              }}
            >
              {config.map((c, index) => (
                <TableCell
                  key={c.key}
                  sx={(theme) => ({
                    borderRight:
                      index === 0
                        ? `1px solid ${theme.palette.divider}`
                        : undefined,
                  })}
                >
                  {c.key.includes('revenue')
                    ? formatCurrency(item[c.key] as number)
                    : item[c.key] || '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
