import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { deals, DealTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import {
  DeleteRounded,
  DownloadRounded,
  FileUploadRounded,
} from '@mui/icons-material';
import { ConfirmDialogControlled, ColorCard } from 'components';

const acceptedFiles = 'application/pdf, image/*';

interface Props {
  id: string;
  type: DealTypes.AttachmentTypeEnum;
  data: DealTypes.AttachmentData;
  onSubmit: () => Promise<QueryObserverResult>;
  color?: string;
  disabled?: boolean;
}

const FileUploadCard = (props: Props): JSX.Element => {
  const { data, id, onSubmit, type, color, disabled } = props;

  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const { requestFn: uploadFn, isLoading: isUploading } = useApiRequest(
    (data) =>
      deals.uploadAttachment({
        id,
        type,
        file: data,
        onDownloadProgress: (progressEvent) => {
          setUploadProgress(
            Math.round(progressEvent.percent / progressEvent.totalBytes),
          );
        },
      }),
  );

  const { requestFn: removeFn, isLoading: isRemoving } = useApiRequest(() =>
    deals.removeAttachment(id, type),
  );

  const handleUpload = useCallback(
    async (file: File) => {
      await uploadFn({
        args: file,
        successMessage: 'File uploaded',
      });

      await onSubmit();
      setInputValue('');
      setUploadProgress(0);
    },
    [uploadFn, onSubmit],
  );

  const handleRemove = useCallback(async () => {
    await removeFn({
      successMessage: 'File removed',
    });
    await onSubmit();
  }, [onSubmit, removeFn]);

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      await handleUpload(file);
    }
  };

  const isLoading = useMemo(
    () => isRemoving || isUploading,
    [isUploading, isRemoving],
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  return (
    <ColorCard color={color}>
      <Box p={1}>
        <CardHeader
          title={
            <Typography variant="h6" gutterBottom textTransform="capitalize">
              {type}
            </Typography>
          }
          subheader={
            <Typography>
              {'attachment' in data ? data.attachment.name : 'No file uploaded'}
            </Typography>
          }
        />
        <CardActions>
          {'attachment' in data ? (
            <Stack gap={1} direction="row">
              <Button
                color="primary"
                component="a"
                target="_blank"
                href={data.attachment.file}
                endIcon={<DownloadRounded />}
              >
                Download
              </Button>
              <Button
                color="error"
                onClick={handleOpenDialog}
                disabled={isLoading || disabled}
                endIcon={<DeleteRounded />}
              >
                Remove
              </Button>
              <ConfirmDialogControlled
                isLoading={isLoading}
                name={data.attachment.name}
                isOpen={isDeleteDialogOpen}
                onClose={handleCloseDialog}
                onSubmit={handleRemove}
              />
            </Stack>
          ) : (
            <Button
              color="primary"
              component="label"
              htmlFor={`file-upload-${type}`}
              disabled={isUploading || disabled}
              endIcon={
                isUploading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    {...(uploadProgress !== 0 && {
                      variant: 'determinate',
                      value: uploadProgress,
                    })}
                  />
                ) : (
                  <FileUploadRounded />
                )
              }
            >
              Upload file
            </Button>
          )}
          <Box
            component="input"
            type="file"
            disabled={isLoading}
            sx={{
              visibility: 'hidden',
              width: 0,
              height: 0,
            }}
            onChange={handleFileSelect}
            id={`file-upload-${type}`}
            name={`file-upload-${type}`}
            value={inputValue}
            accept={acceptedFiles}
          />
        </CardActions>
      </Box>
    </ColorCard>
  );
};

export default FileUploadCard;
