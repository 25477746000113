import React from 'react';
import BRANDS from 'constants/brands';
import { BrandsEnum } from 'api/types/Brands';
import { useStoreContext } from 'store';
import { setStoreState } from 'store/actions';
import FilterMenu, { FilterMenuActiveItem } from './FilterMenu';

const defaultOption: FilterMenuActiveItem = {
  value: 'All brands',
  label: 'All brands',
  image: undefined,
};

const options = [defaultOption, ...BRANDS.OPTIONS.filter((b) => !b.disabled)];

const BrandsFilter = (): JSX.Element | null => {
  const {
    state: { activeBrand },
    dispatch,
  } = useStoreContext();

  const handleSelect = (value: FilterMenuActiveItem) => {
    const newValue = value.value === 'All brands' ? undefined : value.value;
    dispatch(
      setStoreState({ activeBrand: newValue as BrandsEnum | undefined }),
    );
  };

  return (
    <FilterMenu
      defaultOption={defaultOption}
      options={options}
      value={activeBrand}
      onSelect={handleSelect}
    />
  );
};

export default BrandsFilter;
