import React from 'react';
import { Avatar, Card, Grid, Stack, Typography } from '@mui/material';
import { DiscountTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import { getUserInitials } from 'helpers/common';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  discount: DiscountTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Header = (props: Props): JSX.Element => {
  const { discount, refetch, inline } = props;

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Avatar sx={{ width: '100px', height: '100px' }}>
            {getUserInitials(discount.title)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Stack rowGap={2}>
            <Typography variant="h4">{discount.title}</Typography>
            <InfoRow discount={discount} />
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          <Avatar sx={{ width: '100px', height: '100px' }}>
            {getUserInitials(discount.title)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="center" mb={3}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h4">{discount.title}</Typography>
            </Grid>
            <Grid item>
              <Actions discount={discount} refetch={refetch} />
            </Grid>
          </Grid>
          <InfoRow discount={discount} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
