import React from 'react';
import { TextField, MenuItem, Chip, Box, TextFieldProps } from '@mui/material';
import { SelectOptions } from 'types/common';

type Props = TextFieldProps & {
  options: SelectOptions;
};

const SelectedItems = ({ selected }: { selected: string[] }) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {selected.map((value) => (
      <Chip
        key={value}
        label={value}
        color="primary"
        size="small"
        sx={{ height: 20 }}
      />
    ))}
  </Box>
);

const SelectInputControlled: React.FC<Props> = (props: Props) => {
  const { options, value, label, ...rest } = props;

  const initialValue = rest.SelectProps?.multiple ? [] : '';

  return (
    <TextField
      {...rest}
      label={label}
      value={value || initialValue}
      select
      SelectProps={{
        displayEmpty: true,
        ...rest.SelectProps,
        MenuProps: {
          ...rest.SelectProps?.MenuProps,
          sx: {
            maxHeight: '50vh',
          },
        },
        renderValue: rest.SelectProps?.multiple
          ? // eslint-disable-next-line react/no-unstable-nested-components
            (selected) => {
              if ((selected as []).length === 0) {
                return `Select ${label}`;
              }

              return <SelectedItems selected={selected as string[]} />;
            }
          : undefined,
      }}
    >
      <MenuItem value="" disabled>
        {`Select ${label}`}
      </MenuItem>
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
          {o.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectInputControlled;
