import React from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CampaignOutlined } from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import { FormCard } from 'components';
import { omit } from 'lodash';
import KeyValueInput from './KeyValueInput';

interface Props {
  watch: UseFormWatch<InfluencerTypes.ItemCreateParams>;
  setValue: UseFormSetValue<InfluencerTypes.ItemCreateParams>;
  inline?: boolean;
}

const AudienceBlock = (props: Props): JSX.Element | null => {
  const { watch, setValue, inline } = props;

  const audienceInfo = watch('audience_info');

  const handleAdd = (value: InfluencerTypes.AudienceInfoCreateParams) => {
    setValue(`audience_info.${Object.keys(value)[0]}`, Object.values(value)[0]);
  };

  const handleRemove = (key: string) => {
    setValue(`audience_info`, omit(audienceInfo, key));
  };

  return (
    <FormCard title="Audience information" avatar={<CampaignOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={inline ? 12 : 4}>
          <KeyValueInput
            type="gender"
            label="Audience by gender"
            value={audienceInfo}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        </Grid>
        <Grid item xs={inline ? 12 : 4}>
          <KeyValueInput
            type="age_group"
            label="Audience by age group"
            value={audienceInfo}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        </Grid>
        <Grid item xs={inline ? 12 : 4}>
          <KeyValueInput
            type="country"
            label="Audience by country"
            value={audienceInfo}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default AudienceBlock;
