import { Container, Typography, Button, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/links';
import Logo from './Logo';

interface Props {
  variant?: 'error' | 'notFound' | 'wip';
  message?: string;
  height?: string;
  errorData?: {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
  };
}

const getText = (
  variant: 'error' | 'notFound' | 'wip',
  message: string | undefined,
) => {
  if (variant === 'wip') {
    return 'Coming soon';
  }

  if (variant === 'notFound') {
    return 'Page not found';
  }

  return message || 'Something went wrong';
};

const ErrorBoundary = (props: Props): JSX.Element => {
  const { variant = 'error', message, height = '100vh', errorData } = props;

  return (
    <Container>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={(theme) => ({
          height,
          '& a': {
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : theme.palette.common.black,
          },
        })}
      >
        <Logo size={8} />
        {errorData && errorData.error ? (
          <Stack spacing={2}>
            <Typography variant="h3" paragraph>
              <strong>Name: </strong>
              {errorData.error.name}
            </Typography>
            <Typography variant="h5" paragraph>
              <strong>Message: </strong>
              {errorData.error.message}
            </Typography>
            <Typography variant="h5" paragraph>
              <strong>Stack: </strong>
              {errorData.error.stack}
            </Typography>
          </Stack>
        ) : (
          <Typography variant="h3" paragraph>
            {getText(variant, message)}
          </Typography>
        )}
        <Stack direction="row" spacing={2}>
          {variant === 'error' && (
            <Button
              color="primary"
              variant="outlined"
              onClick={window.location.reload}
            >
              Reload
            </Button>
          )}
          <Button color="primary" component={NavLink} to={appLinks.index.link}>
            Back to main
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ErrorBoundary;
