import {
  DataTableActionEnums,
  DataTableStateActions,
  DataTableState,
} from './types';

const setDataTableState = (
  data: Partial<DataTableState>,
): DataTableStateActions => ({
  type: DataTableActionEnums.SET_STORE_STATE,
  payload: data,
});

const clearFilters = (): DataTableStateActions => ({
  type: DataTableActionEnums.clearFilters,
});

const setPerPage = (payload: number): DataTableStateActions => ({
  type: DataTableActionEnums.setPerPage,
  payload,
});

const selectRow = (payload: Record<string, any>): DataTableStateActions => ({
  type: DataTableActionEnums.selectRow,
  payload,
});

const selectAllRows = (
  payload: Record<string, any>[],
): DataTableStateActions => ({
  type: DataTableActionEnums.selectAllRows,
  payload,
});

const setFiltersState = (
  payload: Record<string, any>,
): DataTableStateActions => ({
  type: DataTableActionEnums.setFiltersState,
  payload,
});

const clearFilter = (payload: string): DataTableStateActions => ({
  type: DataTableActionEnums.clearFilter,
  payload,
});

const toggleSplitView = (): DataTableStateActions => ({
  type: DataTableActionEnums.toggleSplitView,
});

export {
  setDataTableState,
  clearFilters,
  setPerPage,
  selectAllRows,
  selectRow,
  setFiltersState,
  clearFilter,
  toggleSplitView,
};
