import { ID } from '../types/common';

namespace TimelineTypes {
  export enum TypeEnum {
    Create = 'creation',
    Edit = 'edit',
  }

  export enum ModelEnum {
    Deal = 'deal',
    Discount = 'discount',
    Negotiation = 'negotiation',
    Influencer = 'influencer',
  }

  export interface ItemCreateParams {
    comment_text: string;
  }

  export interface ItemData {
    _id: ID;
    author: string;
    comment_text: string | null;
    created_at: string;
    deal_id: ID | null;
    discount_id: ID | null;
    editable: boolean;
    has_attachment: boolean;
    message: string;
    model: ModelEnum;
    timestamp: string;
    type: TypeEnum;
    updated_at: string;
    user_id: ID;
  }

  export type FlatListData = ItemData[];

  export type FlatListResponse = Promise<FlatListData>;
}

export default TimelineTypes;
