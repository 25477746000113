import React, { useMemo } from 'react';
import { appLinks } from 'routes/links';
import { DataTable } from 'components';
import { deals, DealTypes } from 'api';
import { useStoreContext } from 'store';
import filters from './filters';
import ContentCard from './ContentCard';
import SkeletonCard from './SkeletonCard';

interface Props {
  inline?: boolean;
  campaignId: string;
}

const BrandMentionsTab = (props: Props): JSX.Element => {
  const { campaignId, inline } = props;

  const {
    state: { activeCountry, activeBrand },
  } = useStoreContext();

  const extraFiltersValues: DealTypes.GetListParams['filters'] = useMemo(
    () => ({
      brand: activeBrand,
      country_team: activeCountry,
      campaign_ids: campaignId,
      status: [DealTypes.StatusEnum.Posted, DealTypes.StatusEnum.Finished],
      strategy: [
        DealTypes.StrategiesEnum.influencers,
        DealTypes.StrategiesEnum.internal,
      ],
    }),
    [activeBrand, activeCountry, campaignId],
  );

  return (
    <DataTable
      inline
      title="Brand mentions"
      headers={appLinks.mentions}
      filters={filters}
      getDataApi={deals.getListWithAttachments}
      extraFiltersValues={extraFiltersValues}
      cardNode={(item) => ContentCard({ data: item })}
      skeletonNode={<SkeletonCard />}
      defaultParams={{
        sortBy: 'start_date',
        sortDesc: true,
      }}
      defaultPerPage={12}
      GridItemProps={
        inline
          ? {
              xs: 12,
              sm: 6,
              md: 6,
              xl: 6,
            }
          : undefined
      }
    />
  );
};

export default BrandMentionsTab;
