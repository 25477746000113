import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { LoyaltyOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { influencers, InfluencerTypes } from 'api';
import COUNTRIES from 'constants/countries';
import React, { useState } from 'react';
import { useDataTableContext } from '../../context';
import { selectAllRows } from '../../context/actions';
import { ConfirmDialogControlled } from '../../../ConfirmDialogs';
import { FormInput } from '../../../Form';
import Loader from '../../../Loader';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
}

const BrandMentionsStatus = (props: Props): JSX.Element | null => {
  const { refetch } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { control, handleSubmit, watch } =
    useForm<InfluencerTypes.BulkBrandMentionParams>({
      defaultValues: {
        enable_brand_mentions: true,
        influencer_ids: [],
      },
    });

  const isEnabledBM = watch('enable_brand_mentions');

  const { requestFn, isLoading } = useApiRequest(influencers.bulkBrandMentions);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleChangeStatus = async (
    data: InfluencerTypes.BulkBrandMentionParams,
  ) => {
    const ids = selectedItems.map((s) => s.influencer_id);

    await requestFn({
      args: {
        influencer_ids: ids,
        enable_brand_mentions: data.enable_brand_mentions,
        country_for_brand_mentions: data.country_for_brand_mentions,
      },
      successMessage: `${ids.length} influencers ${
        data.enable_brand_mentions ? 'added to' : 'removed from'
      } brand mentions list`,
    });

    handleCloseDialog();
    dispatch(selectAllRows([]));

    await refetch();
  };

  return (
    <>
      <Tooltip title="Brand mentions list">
        <Button onClick={handleOpenDialog}>
          {isLoading ? (
            <Loader
              variant="inline"
              circularProgressProps={{ size: 24, color: 'primary' }}
            />
          ) : (
            <LoyaltyOutlined />
          )}
        </Button>
      </Tooltip>
      <ConfirmDialogControlled
        isOpen={isOpen}
        onClose={handleCloseDialog}
        name={`${selectedItems.length} deals`}
        onSubmit={handleSubmit(handleChangeStatus)}
        isLoading={isLoading}
        title="Brand mentions list"
        submitButtonText="Save changes"
      >
        <Typography
          paragraph
          variant="subtitle1"
        >{`Add or remove ${selectedItems.length} influencers to brand mentions list`}</Typography>
        <Box minWidth="calc(600px - 48px)">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="enable_brand_mentions"
                inputType="switch"
                label="Enable brand mention"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="country_for_brand_mentions"
                inputType="select"
                label="Brand mentions country"
                options={COUNTRIES.OPTIONS}
                rules={{
                  required: isEnabledBM,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </ConfirmDialogControlled>
    </>
  );
};

export default BrandMentionsStatus;
