import { DealTypes } from 'api';
import { uniqBy } from 'lodash';
import { enumToOptions } from 'helpers/common';
import { BrandsEnum } from '../api/types/Brands';
import { CountriesEnum } from '../api/types/Countries';

const STATUS_OPTIONS = enumToOptions(DealTypes.StatusEnum);

const STRATEGY_OPTIONS = enumToOptions(DealTypes.StrategiesEnum);

const CHANNEL_OPTIONS = {
  [DealTypes.StrategiesEnum.affiliate]: Object.values(
    DealTypes.ChannelAffiliateEnum,
  ),
  [DealTypes.StrategiesEnum.B2B]: Object.values(DealTypes.ChannelB2BEnum),
  [DealTypes.StrategiesEnum.CRM]: Object.values(DealTypes.ChannelCRMEnum),
  [DealTypes.StrategiesEnum.customerService]: Object.values(
    DealTypes.ChannelCustomersServiceEnum,
  ),
  [DealTypes.StrategiesEnum.HR]: Object.values(DealTypes.ChannelHrEnum),
  [DealTypes.StrategiesEnum.influencers]: Object.values(
    DealTypes.ChannelInfluencerEnum,
  ),
  [DealTypes.StrategiesEnum.internal]: Object.values(
    DealTypes.ChannelInternalEnum,
  ),
  [DealTypes.StrategiesEnum.localPromotion]: Object.values(
    DealTypes.ChannelLocalPromoEnum,
  ),
  [DealTypes.StrategiesEnum.organic]: Object.values(
    DealTypes.ChannelOrganicEnum,
  ),
  [DealTypes.StrategiesEnum.performanceMarketing]: Object.values(
    DealTypes.ChannelPerformanceMarketingEnum,
  ),
  [DealTypes.StrategiesEnum.PR]: Object.values(DealTypes.ChannelPrEnum),
  [DealTypes.StrategiesEnum.sampling]: Object.values(
    DealTypes.ChannelSamplingEnum,
  ),
  [DealTypes.StrategiesEnum.website]: Object.values(
    DealTypes.ChannelWebsiteEnum,
  ),
  [DealTypes.StrategiesEnum.marketplace]: Object.values(
    DealTypes.ChannelMarketplaceEnum,
  ),
  [DealTypes.StrategiesEnum.coCreation]: Object.values(
    DealTypes.ChannelCoCreation,
  ),
};

const SUB_CHANNEL_OPTIONS = uniqBy(
  Object.values(CHANNEL_OPTIONS)
    .flat()
    .map((o) => ({
      label: o,
      value: o,
    })),
  'value',
);

const BUDGET_TYPE_OPTIONS = enumToOptions(DealTypes.BudgetTypeEnum);

const TYPE_OPTIONS = enumToOptions(DealTypes.TypeEnum);

const FOCUS_OPTIONS = enumToOptions(DealTypes.FocusEnum);

const STORIES_TAG_ENUM = {
  [BrandsEnum.avaAndMay]: {
    [CountriesEnum.DE]: DealTypes.StoriesTagEnum.AvaMayDE,
    [CountriesEnum.FR]: DealTypes.StoriesTagEnum.AvaMayFR,
    [CountriesEnum.IT]: DealTypes.StoriesTagEnum.AvaMayIT,
    [CountriesEnum.PL]: DealTypes.StoriesTagEnum.AvaMayPL,
  },
  [BrandsEnum.faynt]: {
    [CountriesEnum.DE]: DealTypes.StoriesTagEnum.FayntDE,
    [CountriesEnum.FR]: DealTypes.StoriesTagEnum.FayntFR,
    [CountriesEnum.IT]: DealTypes.StoriesTagEnum.FayntIT,
    [CountriesEnum.PL]: DealTypes.StoriesTagEnum.FayntPL,
  },
  [BrandsEnum.clubNoe]: {
    [CountriesEnum.DE]: DealTypes.StoriesTagEnum.ClubNoeDE,
    [CountriesEnum.FR]: DealTypes.StoriesTagEnum.ClubNoeFR,
    [CountriesEnum.IT]: DealTypes.StoriesTagEnum.ClubNoeIT,
    [CountriesEnum.PL]: undefined,
  },
  [BrandsEnum.millionFacets]: {
    [CountriesEnum.DE]: undefined,
    [CountriesEnum.FR]: undefined,
    [CountriesEnum.IT]: undefined,
    [CountriesEnum.PL]: undefined,
  },
  [BrandsEnum.ultravit]: {
    [CountriesEnum.DE]: undefined,
    [CountriesEnum.FR]: undefined,
    [CountriesEnum.IT]: undefined,
    [CountriesEnum.PL]: undefined,
  },
  [BrandsEnum.reflekt]: {
    [CountriesEnum.DE]: undefined,
    [CountriesEnum.FR]: undefined,
    [CountriesEnum.IT]: undefined,
    [CountriesEnum.PL]: undefined,
  },
};

const BRAND_PRODUCT_MAPPING = {
  [DealTypes.BrandProductEnum.AvaAndMay]: BrandsEnum.avaAndMay,
  [DealTypes.BrandProductEnum.Faynt]: BrandsEnum.faynt,
  [DealTypes.BrandProductEnum.ClubNoe]: BrandsEnum.clubNoe,
};

const DEALS = {
  CHANNEL_OPTIONS,
  STRATEGY_OPTIONS,
  STATUS_OPTIONS,
  SUB_CHANNEL_OPTIONS,
  BUDGET_TYPE_OPTIONS,
  STORIES_TAG_ENUM,
  TYPE_OPTIONS,
  FOCUS_OPTIONS,
  BRAND_PRODUCT_MAPPING,
};

export default DEALS;
