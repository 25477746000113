import { CountriesEnum } from 'api/types/Countries';
import deIcon from 'assets/countries/de.png';
import frIcon from 'assets/countries/fr.png';
import inIcon from 'assets/countries/in.png';
import itIcon from 'assets/countries/it.png';
import plIcon from 'assets/countries/pl.png';

const LIST = {
  [CountriesEnum.DE]: {
    name: 'Germany',
    image: deIcon,
  },
  [CountriesEnum.FR]: {
    name: 'France',
    image: frIcon,
  },
  [CountriesEnum.IN]: {
    name: 'India',
    image: inIcon,
  },
  [CountriesEnum.IT]: {
    name: 'Italy',
    image: itIcon,
  },
  [CountriesEnum.PL]: {
    name: 'Poland',
    image: plIcon,
  },
};

const OPTIONS = Object.entries(LIST).map(([key, value]) => ({
  value: key as CountriesEnum,
  label: value.name,
  image: value.image,
}));

const COUNTRIES = {
  LIST,
  OPTIONS,
};

export default COUNTRIES;
