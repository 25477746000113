import { useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { influencers, InfluencerTypes } from 'api';
import COUNTRIES from 'constants/countries';
import { ConfirmDialogControlled, FormInput } from 'components';
import { CountriesEnum } from 'api/types/Countries';

interface Props {
  influencerId: string;
  isEnabledBrandMentions: boolean;
  brandMentionsCountry?: CountriesEnum;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<QueryObserverResult>;
}

const BrandMentionsStatus = (props: Props): JSX.Element | null => {
  const {
    refetch,
    onClose,
    isOpen,
    isEnabledBrandMentions,
    brandMentionsCountry,
    influencerId,
  } = props;

  const { control, handleSubmit, watch } = useForm<
    Partial<InfluencerTypes.ItemCreateParams>
  >({
    defaultValues: {
      enable_brand_mentions: isEnabledBrandMentions,
      country_for_brand_mentions: brandMentionsCountry,
    },
  });

  const isEnabledBM = watch('enable_brand_mentions');

  const { requestFn, isLoading } = useApiRequest((data) =>
    influencers.update(influencerId, data),
  );

  const handleChangeStatus = async (
    data: Partial<InfluencerTypes.ItemCreateParams>,
  ) => {
    await requestFn({
      args: {
        enable_brand_mentions: data.enable_brand_mentions,
        country_for_brand_mentions: data.country_for_brand_mentions,
      },
      successMessage: `Influencer ${
        data.enable_brand_mentions ? 'added to' : 'removed from'
      } brand mentions list`,
    });

    await refetch();
    onClose();
  };

  return (
    <ConfirmDialogControlled
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(handleChangeStatus)}
      isLoading={isLoading}
      title="Brand mentions list"
      submitButtonText="Save changes"
    >
      <Typography paragraph variant="subtitle1">
        Add or remove influencer to brand mentions list
      </Typography>
      <Box minWidth="calc(600px - 48px)">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="enable_brand_mentions"
              inputType="switch"
              label="Enable brand mention"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="country_for_brand_mentions"
              inputType="select"
              label="Brand mentions country"
              options={COUNTRIES.OPTIONS}
              rules={{
                required: isEnabledBM,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </ConfirmDialogControlled>
  );
};

export default BrandMentionsStatus;
