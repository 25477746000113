import { SvgIcon, SvgIconProps } from '@mui/material';

const InstagramColorIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 2H7C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7L2 13C2 14.3261 2.52678 15.5979 3.46447 16.5355C4.40215 17.4732 5.67392 18 7 18H13C14.3261 18 15.5979 17.4732 16.5355 16.5355C17.4732 15.5979 18 14.3261 18 13V7C18 5.67392 17.4732 4.40215 16.5355 3.46447C15.5979 2.52678 14.3261 2 13 2ZM16.5 13C16.5 14.93 14.93 16.5 13 16.5H7C5.07 16.5 3.5 14.93 3.5 13V7C3.5 5.07 5.07 3.5 7 3.5H13C14.93 3.5 16.5 5.07 16.5 7V13Z"
      fill="url(#paint0_linear_743_90439)"
    />
    <path
      d="M10 6C8.93913 6 7.92172 6.42143 7.17157 7.17157C6.42143 7.92172 6 8.93913 6 10C6 11.0609 6.42143 12.0783 7.17157 12.8284C7.92172 13.5786 8.93913 14 10 14C11.0609 14 12.0783 13.5786 12.8284 12.8284C13.5786 12.0783 14 11.0609 14 10C14 8.93913 13.5786 7.92172 12.8284 7.17157C12.0783 6.42143 11.0609 6 10 6ZM10 12.5C9.3372 12.4992 8.70178 12.2356 8.23311 11.7669C7.76444 11.2982 7.50079 10.6628 7.5 10C7.5 8.621 8.622 7.5 10 7.5C11.378 7.5 12.5 8.621 12.5 10C12.5 11.378 11.378 12.5 10 12.5Z"
      fill="url(#paint1_linear_743_90439)"
    />
    <path
      d="M14.5 6C14.7761 6 15 5.77614 15 5.5C15 5.22386 14.7761 5 14.5 5C14.2239 5 14 5.22386 14 5.5C14 5.77614 14.2239 6 14.5 6Z"
      fill="url(#paint2_linear_743_90439)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_743_90439"
        x1="3.4645"
        y1="16.5355"
        x2="16.5355"
        y2="3.4645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC107" />
        <stop offset="0.5074" stopColor="#F44336" />
        <stop offset="0.9901" stopColor="#9C27B0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_743_90439"
        x1="7.1716"
        y1="12.8284"
        x2="12.8284"
        y2="7.1716"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC107" />
        <stop offset="0.5074" stopColor="#F44336" />
        <stop offset="0.9901" stopColor="#9C27B0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_743_90439"
        x1="14.1462"
        y1="5.85375"
        x2="14.8538"
        y2="5.14625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC107" />
        <stop offset="0.5074" stopColor="#F44336" />
        <stop offset="0.9901" stopColor="#9C27B0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default InstagramColorIcon;
