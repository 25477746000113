import React, { useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { campaigns, CampaignTypes } from 'api';
import COUNTRIES from 'constants/countries';
import { OpenInNewOutlined } from '@mui/icons-material';
import { HEADER_HEIGHT } from 'theme/constants';
import { appLinks } from 'routes/links';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

interface Metric {
  key: keyof CampaignTypes.ItemData;
  label: string;
  value: React.ReactNode;
}

const PreviousCampaigns = (props: Props): JSX.Element | null => {
  const { campaign, inline } = props;

  const { data, error } = useQuery([campaigns.endpoints.getList], () =>
    campaigns
      .getList({
        sort_by: 'start_date',
        sort_desc: true,
        limit: 5,
        filters: {
          start_date: { $lte: campaign.start_date },
          brands: campaign.brands,
          country_teams: campaign.country_teams,
        },
      })
      .then((res) => res.data.filter((r) => r._id.$oid !== campaign._id.$oid)),
  );

  const campaignMetrics = useCallback((c: CampaignTypes.ItemData) => {
    const metrics: Metric[] = [
      {
        key: 'deals_nb',
        label: 'Deals',
        value: c.deals_nb,
      },
      {
        key: 'influencers_nb',
        label: 'Influencers',
        value: c.influencers_nb,
      },
      {
        key: 'reach_total',
        label: 'Reach',
        value: c.reach_total,
      },
      {
        key: 'aov_actual',
        label: 'AOV',
        value: c.aov_actual,
      },
      {
        key: 'new_customers_actual',
        label: 'New customers',
        value: c.aov_actual,
      },
    ];

    return metrics;
  }, []);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: inline ? '100%' : `calc(100vh - ${HEADER_HEIGHT}px - 64px)`,
      }}
    >
      <>
        {error && (
          <Typography variant="subtitle2">
            Failed to load previous campaigns
          </Typography>
        )}
        {!data &&
          !error &&
          Array.from(new Array(5))
            .map((_, i) => i)
            .map((item) => (
              <Card variant="outlined" key={item} sx={{ mb: 2 }}>
                <CardHeader
                  avatar={
                    <Skeleton
                      variant="circular"
                      sx={{
                        width: 25,
                        height: 25,
                        borderRadius: (t) => `${t.shape.borderRadius}px`,
                      }}
                    />
                  }
                  title={<Skeleton variant="text" sx={{ width: '70%' }} />}
                />
                <CardContent>
                  <Skeleton variant="text" sx={{ width: '80%', height: 50 }} />
                </CardContent>
                <CardActions>
                  <Skeleton variant="text" sx={{ width: '30%', height: 30 }} />
                </CardActions>
              </Card>
            ))}
        {data && data.length > 0 ? (
          data.map((c) => (
            <Card variant="outlined" key={c._id.$oid} sx={{ mb: 2 }}>
              <CardHeader
                avatar={
                  <Avatar
                    src={COUNTRIES.LIST[c.country_teams[0]].image}
                    sx={{
                      width: 25,
                      height: 25,
                      borderRadius: (t) => `${t.shape.borderRadius}px`,
                    }}
                  />
                }
                title={c.name}
                titleTypographyProps={{ variant: 'subtitle2' }}
              />
              <CardContent>
                {campaignMetrics(c).map((m) => (
                  <Stack
                    direction="row"
                    columnGap={2}
                    key={m.key}
                    flexWrap="wrap"
                  >
                    <Typography variant="body2" flexGrow={1}>
                      {m.label}
                    </Typography>
                    <Typography color="text.secondary">
                      {m.value || '-'}
                    </Typography>
                  </Stack>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  href={appLinks.campaigns.details.as(c.campaign_id)}
                  target="_blank"
                  endIcon={<OpenInNewOutlined />}
                >
                  Open campaign
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="subtitle2">No previous campaigns</Typography>
        )}
      </>
    </Box>
  );
};

export default PreviousCampaigns;
