import { StoreActionEnums, StoreActions, StoreStateData } from './types';

const reducer = (
  state: StoreStateData,
  action: StoreActions,
): StoreStateData => {
  switch (action.type) {
    case StoreActionEnums.TOGGLE_IS_SIDENAV_OPEN:
      return {
        ...state,
        isSideNavOpen: !state.isSideNavOpen,
      };
    case StoreActionEnums.SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case StoreActionEnums.SET_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        snackbarNotification: action.payload,
      };
    case StoreActionEnums.SET_STORE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
