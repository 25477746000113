import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';

const stageFirebaseConfig = {
  apiKey: 'AIzaSyAB5fr4mWkWOLuPa8NTJAsJm-IM3b7rhW4',
  authDomain: 'influencer-marketing-staging.firebaseapp.com',
  projectId: 'influencer-marketing-staging',
  storageBucket: 'influencer-marketing-staging.appspot.com',
  messagingSenderId: '1098802725280',
  appId: '1:1098802725280:web:b7787125124db8e5d7e4c5',
  measurementId: 'G-L70PL5K6Z1',
};

const prodFirebaseConfig = {
  apiKey: 'AIzaSyCsrg0ehZnMB8dKlBJP0gJkVP8ijahRAR8',
  authDomain: 'hy-influencer-marketing.firebaseapp.com',
  projectId: 'hy-influencer-marketing',
  storageBucket: 'hy-influencer-marketing.appspot.com',
  messagingSenderId: '625400658832',
  appId: '1:625400658832:web:5015ad3b1b4a328e6a1115',
  measurementId: 'G-DMWRFTJBH9',
};

const firebaseConfig =
  process.env.REACT_APP_CI_ENV === 'development'
    ? stageFirebaseConfig
    : prodFirebaseConfig;

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

setUserProperties(analytics, { app_environment: process.env.REACT_APP_CI_ENV });

export { analytics };

interface Props {
  children: JSX.Element;
}

const Analytics = (props: Props): JSX.Element | null => {
  const { children } = props;

  return children;
};

export default Analytics;
