import React from 'react';
import { Card, Stack } from '@mui/material';
import { InfluencerTypes } from 'api';
import DealsTable from './DealsTable';
import NegotiationsTable from './NegotiationsTable';
import DealsChart from './DealsChart';
import DealsPerformance from './DealsPerformance';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const DealsTab = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Stack spacing={3}>
      <DealsPerformance data={data} />
      <DealsChart data={data} />
      <Card variant="outlined">
        <DealsTable influencerId={data._id.$oid} />
      </Card>
      <Card variant="outlined">
        <NegotiationsTable influencerId={data._id.$oid} />
      </Card>
    </Stack>
  );
};

export default DealsTab;
