import React from 'react';
import {
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ContentCopyRounded } from '@mui/icons-material';
import { DealTypes } from 'api';
import { useClipboard } from 'hooks';
import { ColorCard } from 'components';

interface Props {
  deal: DealTypes.ItemData;
}

type InfoItems = {
  key: keyof DealTypes.ItemData;
  label: string;
  value: string | undefined;
}[];

const InfoCard = (props: Props): JSX.Element | null => {
  const { deal } = props;
  const handleCopy = useClipboard();

  const urls: InfoItems = [
    {
      key: 'deal_id',
      label: 'Page url',
      value: window.location.href,
    },
    {
      key: 'url_landing',
      label: 'Landing url',
      value: deal.url_landing,
    },
    {
      key: 'url_utm',
      label: 'UTM url',
      value: deal.url_utm,
    },
    {
      key: 'short_url_utm',
      label: 'Short UTM url',
      value: deal.short_url_utm,
    },
  ];

  return (
    <ColorCard>
      <CardHeader
        title="Urls info"
        subheader={`Url auto redemption ${
          deal.url_auto_redemption ? 'enabled' : 'disabled'
        }`}
      />
      <CardContent>
        {urls.map((url) => (
          <Stack key={url.key} sx={{ mb: 1 }}>
            <Stack direction="row" columnGap={2} alignItems="center">
              <Typography variant="body2" flexGrow={1}>
                {url.label}
              </Typography>
              <IconButton
                size="small"
                onClick={() => handleCopy(url.value || '')}
                disabled={!url.value}
              >
                <ContentCopyRounded fontSize="small" />
              </IconButton>
            </Stack>
            <Typography color="text.secondary" noWrap>
              {url.value || '-'}
            </Typography>
          </Stack>
        ))}
      </CardContent>
    </ColorCard>
  );
};

export default InfoCard;
