import React from 'react';
import { Stack } from '@mui/material';
import { BrandsEnum } from 'api/types/Brands';
import DataTableProps from '../../DataTableProps';
import BrandChip from '../../../Chips/BrandChip';

interface Props {
  cell: DataTableProps.BrandsCell &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const BrandsStatus = (props: Props) => {
  const { data, cell } = props;

  if (cell.multiple) {
    return (
      <Stack
        spacing={0.5}
        display="inline-flex"
        direction="row"
        alignItems="center"
      >
        {data[cell.key]?.map((brand: BrandsEnum) => (
          <BrandChip key={brand} brand={brand} onlyLogo />
        ))}
      </Stack>
    );
  }

  return <BrandChip brand={data[cell.key] as BrandsEnum} />;
};

export default BrandsStatus;
