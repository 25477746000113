import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useStoreContext } from 'store';

interface Props {
  children: JSX.Element;
}

const AuthRoute = (props: Props) => {
  const { children } = props;

  const [params] = useSearchParams();

  const path = params.get('redirectTo');
  const redirectTo = path ? decodeURI(path) : '';

  return redirectTo ? <Navigate replace to={redirectTo} /> : children;
};

const PublicRoute = (props: Props): JSX.Element => {
  const { children } = props;
  const {
    state: { user },
  } = useStoreContext();

  const isAuth = useMemo(() => !!user, [user]);

  if (isAuth) {
    return <AuthRoute>{children}</AuthRoute>;
  }

  return children;
};

export default PublicRoute;
