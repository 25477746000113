import React, { useState } from 'react';
import { BoxProps, CardMedia, Skeleton } from '@mui/material';
import imageIcon from 'assets/imageIcon.svg';

type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  Omit<BoxProps, 'component'> & {
    PlaceholderSx?: BoxProps['sx'];
  };

const CardImage = (props: Props): JSX.Element | null => {
  const { src, sx, PlaceholderSx } = props;

  const [isBroken, setIsBroken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleBrokenLink = () => {
    setIsBroken(true);
    setIsLoading(false);
  };

  const handleLoaded = () => {
    setIsLoading(false);
  };

  if (isBroken || !src) {
    return (
      <CardMedia component="img" src={imageIcon} sx={PlaceholderSx || sx} />
    );
  }

  return (
    <>
      <CardMedia
        component="img"
        src={src}
        onError={handleBrokenLink}
        onLoad={handleLoaded}
        sx={sx}
        {...(isLoading && {
          style: {
            display: 'none',
          },
        })}
      />
      {isLoading && (
        <Skeleton variant="rectangular" animation="wave" {...(sx && { sx })} />
      )}
    </>
  );
};

export default CardImage;
