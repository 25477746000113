import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { influencers, InfluencerTypes } from 'api';
import { useStoreContext } from 'store';
import {
  formatCompactNumber,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import { InfluencerChartOption, InsightsChartOption } from 'types/influencers';
import { getChartOptions } from 'helpers/chartHelpers';
import ComparisonChart from '../ComparisonChart';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const InsightsChart = (props: Props): JSX.Element | null => {
  const { data: influencerData } = props;
  const {
    state: { activeSocialPlatform },
  } = useStoreContext();

  const influencerId = useMemo(() => influencerData._id.$oid, [influencerData]);

  const [chartDataType, setChartDataType] =
    useState<InfluencerTypes.InsightsChartType>('engagement_rate');

  const { data, isLoading } = useQuery(
    [
      influencers.endpoints.getInsightsChartById(influencerId),
      chartDataType,
      activeSocialPlatform,
    ],
    () =>
      influencers.getInsightsChartById(
        influencerId,
        chartDataType,
        (
          activeSocialPlatform || InfluencerTypes.PlatformEnum.Instagram
        ).toLowerCase() as InfluencerTypes.InsightsChartPlatformType,
      ),
    {
      enabled: !(
        activeSocialPlatform === InfluencerTypes.PlatformEnum.Pinterest ||
        activeSocialPlatform === InfluencerTypes.PlatformEnum.Snapchat
      ),
    },
  );

  const chartData = useMemo(() => {
    if (data) {
      const key: InfluencerTypes.InsightsChartDataKey =
        chartDataType === 'likes' || chartDataType === 'comments'
          ? `avg_${chartDataType}`
          : chartDataType;

      return getChartOptions({
        data: data[key],
        avgData: data.global_avg_details,
      });
    }

    return [];
  }, [chartDataType, data]);

  const tabs = useMemo(() => {
    const activePlatform =
      activeSocialPlatform || InfluencerTypes.PlatformEnum.Instagram;
    const platform =
      activePlatform.toLowerCase() as InfluencerTypes.PlatformType;
    const postKey: InfluencerTypes.PlatformPostType = `${platform}_post`;
    const postsSN = influencerData[postKey];

    return [
      {
        type: 'engagement_rate',
        label: 'Engagement rate',
        value: formatPercent(postsSN?.engagement_rate),
      },
      {
        type: 'followers',
        label: 'No. of followers',
        value: formatCompactNumber(postsSN?.followers_nb),
      },
      {
        type: 'likes',
        label: 'Avg. likes',
        value: formatNumber(postsSN?.avg_likes_nb, 0),
      },
      {
        type: 'comments',
        label: 'Avg. comments',
        value: formatNumber(postsSN?.avg_comments_nb, 0),
      },
      {
        type: 'comments_to_likes',
        label: 'Avg. comments to likes',
        value: formatPercent(postsSN?.comment_to_like),
      },
    ] as InsightsChartOption[];
  }, [activeSocialPlatform, influencerData]);

  if (
    activeSocialPlatform === InfluencerTypes.PlatformEnum.Pinterest ||
    activeSocialPlatform === InfluencerTypes.PlatformEnum.Snapchat
  ) {
    return null;
  }

  return (
    <ComparisonChart
      tabs={tabs}
      isLoading={isLoading}
      chartData={chartData}
      chartDataType={chartDataType}
      setChartDataType={
        setChartDataType as React.Dispatch<
          React.SetStateAction<InfluencerChartOption['type']>
        >
      }
    />
  );
};

export default InsightsChart;
