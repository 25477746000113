import { SalesPlanningTypes } from 'api';
import { CountriesEnum } from 'api/types/Countries';
import { BrandsEnum } from '../../../api/types/Brands';

const initialModel: SalesPlanningTypes.ItemCreateParams = {
  status: SalesPlanningTypes.StatusEnum.Pending,
  brands: [BrandsEnum.avaAndMay, BrandsEnum.faynt, BrandsEnum.clubNoe],
  country_team: CountriesEnum.DE,
  shop_id: '',
  budget_target: '' as any, // should be "0" but removed to keep init value empty field
  cm3_target: '' as any, // should be "0" but removed to keep init value empty field
  roi_target: '' as any, // should be "0" but removed to keep init value empty field
  revenue_target: '' as any, // should be "0" but removed to keep init value empty field
  name: '',
  end_date: '',
  start_date: '',
  type: SalesPlanningTypes.TypeEnum.Content,
  budget_distribution:
    {} as SalesPlanningTypes.ItemCreateParams['budget_distribution'],
};

const dataToInitialModel = (
  data: SalesPlanningTypes.ItemData,
): SalesPlanningTypes.ItemCreateParams => ({
  shop_id: data.shop_id.$oid,
  status: data.status,
  brands: data.brands,
  country_team: data.country_team,
  type: data.type,
  name: data.name,
  start_date: data.start_date,
  end_date: data.end_date,
  roi_target: data.roi_target,
  cm3_target: data.cm3_target,
  budget_target: data.budget_target,
  revenue_target: data.revenue_target,
  budget_distribution: data.budget_distribution,
  brand_revenue_distribution: data.brand_revenue_distribution,
});

export { dataToInitialModel, initialModel };
