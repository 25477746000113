import { Box, Link, Stack } from '@mui/material';
import { InfluencerTypes } from 'api';
import INFLUENCERS from 'constants/influencers';
import getSocialNetworkLink from 'helpers/getSocialNetworkLink';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.PlatformsCell &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const PlatformsCell = (props: Props): JSX.Element => {
  const { data, cell } = props;

  return (
    <Stack direction="row" alignItems="center" columnGap={0.5}>
      {data[cell.key].map((code: InfluencerTypes.PlatformEnum) => {
        const Icon = INFLUENCERS.PLATFORM_ICONS[code];
        const link = getSocialNetworkLink(code, data);

        return link ? (
          <Link
            key={code}
            href={link}
            target="_blank"
            display="block"
            rel="noopener noreferrer"
            sx={(theme) => ({
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              },
            })}
          >
            <Icon fontSize="small" />
          </Link>
        ) : (
          <Box key={code}>
            <Icon fontSize="small" color="disabled" />
          </Box>
        );
      })}
    </Stack>
  );
};

export default PlatformsCell;
