import React, { useMemo } from 'react';
import { Avatar, Grid, Card } from '@mui/material';
import { InfluencerName } from 'components';
import { InfluencerTypes } from 'api';
import { getInfluencerName, getUserInitials } from 'helpers/common';
import { QueryObserverResult } from '@tanstack/react-query';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  data: InfluencerTypes.ItemExtended;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Header = (props: Props): JSX.Element => {
  const { data, refetch, inline } = props;

  const initials = useMemo(
    () => getUserInitials(getInfluencerName(data)),
    [data],
  );

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Avatar
            src={data.profile_picture_url}
            sx={{ width: '100px', height: '100px' }}
          >
            {initials}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <InfluencerName
            data={data}
            TypographyProps={{ variant: 'h6' }}
            withSocialNetworkSelect
          />
          <InfoRow data={data} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          <Avatar
            src={data.profile_picture_url}
            sx={{ width: '100px', height: '100px' }}
          >
            {initials}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="baseline" mb={2}>
            <Grid item sx={{ flexGrow: 1 }}>
              <InfluencerName
                data={data}
                TypographyProps={{ variant: 'h6' }}
                withSocialNetworkSelect
              />
            </Grid>
            <Grid item>
              <Actions data={data} refetch={refetch} />
            </Grid>
          </Grid>
          <InfoRow data={data} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
