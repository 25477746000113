import React, { useMemo, useState } from 'react';
import { Card, CardActionArea } from '@mui/material';
import { InfluencerTypes } from 'api';
import PostDialog from './PostDialog';
import PostInfo from './PostInfo';

interface Props {
  size?: 'small' | 'large';
  data: Partial<InfluencerTypes.PostsPost>;
  influencerData: InfluencerTypes.ItemData;
  height?: string;
}

const PostCard = (props: Props): JSX.Element | null => {
  const { data, size, influencerData, height: customHeight } = props;

  const height = useMemo(
    () => customHeight || (size === 'large' ? '50vh' : '180px'),
    [size, customHeight],
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Card variant="outlined">
        <CardActionArea onClick={handleOpenDialog}>
          <PostInfo data={data} height={height} />
        </CardActionArea>
      </Card>
      <PostDialog
        data={data}
        isOpen={isOpen}
        onClose={handleCloseDialog}
        influencerData={influencerData}
      />
    </>
  );
};

export default PostCard;
