import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { InfluencerTypes, NegotiationTypes } from 'api';
import CPMCalculator from './CPMCalculator';
import SalesCalculator from './SalesCalculator';

interface Props {
  negotiation: NegotiationTypes.ItemData;
  influencer: InfluencerTypes.ItemExtended | undefined;
}

const CalculatorTab = (props: Props): JSX.Element | null => {
  const { negotiation, influencer } = props;

  return (
    <Stack rowGap={4}>
      <Box>
        <Typography variant="subtitle2" paragraph>
          CPM Calculator
        </Typography>
        <CPMCalculator negotiation={negotiation} />
      </Box>
      <Divider sx={{ mx: -2 }} />
      <Box>
        <Typography variant="subtitle2" paragraph>
          Sales Potential Calculator
        </Typography>
        <SalesCalculator influencer={influencer} />
      </Box>
    </Stack>
  );
};

export default CalculatorTab;
