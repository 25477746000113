import React, { useMemo, useState } from 'react';
import {
  Avatar as MuiAvatar,
  Box,
  Button,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { ArrowDownTinIcon } from '../../icons';

export interface FilterMenuActiveItem {
  value: string;
  label: string;
  image: string | undefined;
}

interface AvatarProps {
  bgColor: 'primary' | 'white';
  item: FilterMenuActiveItem;
}

const Avatar = (props: AvatarProps): JSX.Element | null => {
  const { item, bgColor } = props;

  if (!item.image) {
    return null;
  }

  return (
    <MuiAvatar
      variant="rounded"
      sx={(theme) => ({
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor:
          // eslint-disable-next-line no-nested-ternary
          bgColor === 'primary'
            ? theme.palette.primary.light
            : theme.palette.mode === 'dark'
            ? theme.palette.grey.A200
            : theme.palette.background.default,
        '& img': {
          width: theme.spacing(2.25),
          height: 'auto',
        },
      })}
      src={item.image}
      alt={item.value}
    />
  );
};

interface Props {
  defaultOption: FilterMenuActiveItem;
  options: FilterMenuActiveItem[];
  value: string | undefined;
  onSelect: (value: FilterMenuActiveItem) => void;
}

const FilterMenu = (props: Props): JSX.Element | null => {
  const { onSelect, value, options, defaultOption } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: FilterMenuActiveItem) => {
    onSelect(value);
    handleClose();
  };

  const open = Boolean(anchorEl);

  const selected = useMemo(() => {
    const selectedItem = options.find((b) => b.value === value);
    return selectedItem || defaultOption;
  }, [defaultOption, options, value]);

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <Button
        variant="text"
        startIcon={<Avatar item={selected} bgColor="white" />}
        endIcon={<ArrowDownTinIcon />}
        sx={(theme) => ({
          padding: theme.spacing(1, 1.5),
          backgroundColor:
            theme.palette.mode === 'dark'
              ? undefined
              : theme.palette.primary.light,
          color: theme.palette.text.primary,
          minHeight: '48px',
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? undefined
                : theme.palette.primary.light,
          },
          '& .MuiButton-startIcon': {
            mr: 2,
          },
        })}
      >
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Typography color="text.primary">{selected.label}</Typography>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          position: 'inherit',
        }}
        PaperProps={{
          sx: (theme) => ({
            position: 'fixed',
            maxHeight: theme.spacing(45),
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }),
        }}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleSelect(option)}
            selected={selected.value === option.value}
            sx={{ minHeight: '48px' }}
          >
            {option.image && (
              <ListItemAvatar>
                <Avatar item={option} bgColor="primary" />
              </ListItemAvatar>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default FilterMenu;
