import React from 'react';
import { Stack } from '@mui/material';
import { TooltipChip, BrandChip, ShopInfo } from 'components';
import { DiscountTypes } from 'api';
import { getDiscountStatusColor } from 'helpers/getStatusColor';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';

interface Props {
  discount: DiscountTypes.ItemData;
}

const InfoRow = (props: Props): JSX.Element => {
  const { discount } = props;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      <BrandChip brand={discount.brand} country={discount.country_team} />
      <ShopInfo shopId={discount.shop_id.$oid} />
      <TooltipChip
        title="Status"
        label={discount.shopify_status}
        color={getDiscountStatusColor(discount.shopify_status)}
      />
      {discount.strategy && (
        <TooltipChip
          title="Channel"
          label={discount.strategy}
          color={getDiscountStatusColor(discount.shopify_status)}
        />
      )}
      {discount.channel && (
        <TooltipChip
          title="Sub channel"
          label={discount.channel}
          color={getDiscountStatusColor(discount.shopify_status)}
        />
      )}
      <TooltipChip
        title="Start date"
        label={`Start ${getFormattedDateFromNow(discount.start_date)}`}
        color="success"
      />
      {discount.end_date && (
        <TooltipChip
          title="End date"
          label={`End ${getFormattedDateFromNow(discount.end_date)}`}
          color="error"
        />
      )}
    </Stack>
  );
};

export default InfoRow;
