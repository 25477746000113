import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { DealTypes } from 'api';
import { BrandChip, StoriesSlider } from 'components';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';
import { formatCurrency, formatNumber } from 'helpers/formatters';
import { useFiltersSearchParams } from 'hooks';

interface Props {
  data: DealTypes.ItemDataWithAttachments;
}

const ContentCard = (props: Props): JSX.Element | null => {
  const { data } = props;
  const { updateFiltersSearchParams } = useFiltersSearchParams();

  return (
    <Card
      sx={{
        overflow: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CardHeader
        avatar={<Avatar src={data.influencer.profile_picture_url} />}
        onClick={() =>
          updateFiltersSearchParams({
            influencerId: data.influencer.influencer_id,
          })
        }
        sx={{ '&:hover': { cursor: 'pointer' } }}
        title={
          <Tooltip title={data.influencer_profile_name}>
            <Box display="flex" alignItems="center" columnGap={1}>
              <Typography color="text.primary" noWrap sx={{ maxWidth: '65%' }}>
                {data.influencer_profile_name || '-'}
              </Typography>
              <VerifiedIcon color="primary" fontSize="small" />
            </Box>
          </Tooltip>
        }
        subheader={getFormattedDateFromNow(data.start_date)}
      />
      <StoriesSlider attachments={data.attachments} inline />
      <CardContent>
        <Stack gap={1}>
          <Stack direction="row" columnGap={2} flexWrap="wrap">
            <Typography variant="body2" flexGrow={1}>
              Actual revenue
            </Typography>
            <Typography color="text.secondary">
              {formatCurrency(data.revenue_actual)}
            </Typography>
          </Stack>
          <Stack direction="row" columnGap={2} flexWrap="wrap">
            <Typography variant="body2" flexGrow={1}>
              Actual ROI
            </Typography>
            <Typography color="text.secondary">
              {formatNumber(data.roi_actual)}
            </Typography>
          </Stack>
          <BrandChip
            brand={data.brand}
            country={data.country_team}
            ChipProps={{ sx: { width: '100%' }, size: 'small' }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ContentCard;
