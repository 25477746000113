import React, { useMemo } from 'react';
import DEALS from 'constants/deals';
import { DataTable, DataTableProps } from 'components';
import { deals, DealTypes } from 'api';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import { getDealStatusColor } from 'helpers/getStatusColor';
import { appLinks } from 'routes/links';
import { getFormattedDate } from 'helpers/dayJsFormats';
import { useStoreContext } from 'store';
import INFLUENCERS from 'constants/influencers';

interface Props {
  influencerId: string;
}

const filters: DataTableProps.Filters<DealTypes.GetListParams['filters']> = [
  {
    type: 'checkbox',
    key: 'status',
    name: 'Status',
    options: DEALS.STATUS_OPTIONS,
  },
  {
    type: 'checkbox',
    key: 'strategy',
    name: 'Channel',
    options: DEALS.STRATEGY_OPTIONS,
  },
  {
    type: 'checkbox',
    key: 'channel',
    name: 'Sub channel',
    options: DEALS.SUB_CHANNEL_OPTIONS,
  },
  {
    type: 'date',
    key: 'start_date',
    name: 'Posting date',
    quickFilter: false,
    nameFormatter: (value) =>
      value.key === 'custom'
        ? `${getFormattedDate(value.value.$gte)} - ${getFormattedDate(
            value.value.$lte,
          )}`
        : value.label,
  },
  {
    type: 'checkbox',
    key: 'influencer.vertical_primary',
    quickFilter: false,
    name: 'Primary vertical',
    options: INFLUENCERS.VERTICALS_OPTIONS,
  },
  {
    type: 'checkbox',
    key: 'budget_type',
    quickFilter: false,
    name: 'Budget type',
    options: DEALS.BUDGET_TYPE_OPTIONS,
  },
];

const columns: DataTableProps.Columns<DealTypes.ItemData> = [
  {
    type: 'rowLink',
    label: '',
    key: 'deal_id',
    href: (item) => appLinks.deals.details.as(item.deal_id),
  },
  {
    type: 'text',
    label: 'Posting date',
    key: 'start_date',
    isSortable: true,
    children: (item) => getFormattedDate(item.start_date),
  },
  {
    type: 'text',
    label: 'Brand',
    key: 'brand',
    isSortable: true,
    children: (item) => item.brand,
  },
  {
    type: 'country',
    key: 'country_team',
    label: 'Country',
    isSortable: true,
    multiple: false,
  },
  {
    type: 'text',
    label: 'Channel',
    key: 'strategy',
    isSortable: true,
    children: (item) => item.strategy,
  },
  {
    type: 'chip',
    label: 'Status',
    key: 'status',
    isSortable: true,
    text: (item) => item.status,
    color: (item) => getDealStatusColor(item.status),
  },
  {
    type: 'text',
    label: 'Budget',
    key: 'budget_potential',
    isSortable: true,
    children: (item) => formatCurrency(item.budget_potential),
  },
  {
    type: 'text',
    label: 'Actual Budget',
    key: 'budget_actual',
    isSortable: true,
    children: (item) => formatCurrency(item.budget_actual),
  },
  {
    type: 'text',
    label: 'Actual Revenue',
    key: 'revenue_actual',
    isSortable: true,
    children: (item) => formatCurrency(item.revenue_actual),
  },
  {
    type: 'text',
    key: 'roi_actual',
    label: 'Actual ROI',
    isSortable: true,
    children: (item) => formatNumber(item.roi_actual),
    TypographyProps: (item) => ({
      color: item.roi_actual >= 1 ? 'success.main' : 'error',
    }),
  },
  {
    type: 'text',
    label: 'Avg basket',
    key: 'avg_basket_actual',
    isSortable: true,
    children: (item) => formatCurrency(item.avg_basket_actual),
  },
  {
    type: 'text',
    label: 'NCR',
    key: 'new_customers_ratio',
    isSortable: true,
    children: (item) => formatPercent(item.new_customers_ratio),
  },
  {
    type: 'text',
    key: 'discount_description',
    label: 'Discount description',
    isSortable: true,
    children: (item) => item.discount_description,
    noWrap: false,
    TypographyProps: {
      maxWidth: '300px',
      minWidth: '200px',
    },
  },
  {
    type: 'actions',
    key: 'actions',
    label: 'Actions',
    actions: [
      {
        name: `Bookmark deal`,
        BookmarkProps: {
          itemId: (item) => item.deal_id,
          bookmarkId: (item) => item.bookmark_id,
          type: 'deal_ids',
        },
      },
    ],
  },
];

const defaultParams: DataTableProps.DefaultParams<DealTypes.ItemData> = {
  sortBy: 'start_date',
  sortDesc: true,
};

const DealsTable = (props: Props): JSX.Element | null => {
  const { influencerId } = props;
  const {
    state: { activeCountry, activeBrand },
  } = useStoreContext();

  const extraFiltersValues: DealTypes.GetListParams['filters'] = useMemo(
    () => ({
      brand: activeBrand,
      country_team: activeCountry,
      influencer_id: influencerId,
    }),
    [activeBrand, activeCountry, influencerId],
  );

  return (
    <div id="deals-table">
      <DataTable
        inline
        title="Deals"
        createButton={{
          link: appLinks.deals.create.as(influencerId),
          name: 'Add',
        }}
        headers={appLinks.deals}
        columns={columns}
        filters={filters}
        getDataApi={deals.getList}
        extraFiltersValues={extraFiltersValues}
        defaultParams={defaultParams}
      />
    </div>
  );
};

export default DealsTable;
