import React from 'react';
import { Box, Grid } from '@mui/material';
import { MetricCard } from 'components';
import getRandomColor from 'helpers/getRandomColor';
import { formatCurrency } from 'helpers/formatters';
import { CampaignTypes } from 'api';

interface Props {
  inline?: boolean;
  performance: CampaignTypes.PerformanceData;
}

const config: {
  key: keyof CampaignTypes.PerformanceSummary;
  label: string;
  color: string;
}[] = [
  {
    key: 'budget_potential',
    label: 'Potential budget',
    color: getRandomColor(1),
  },
  {
    key: 'budget_actual',
    label: 'Actual budget',
    color: getRandomColor(11),
  },
  {
    key: 'revenue_potential',
    label: 'Potential revenue',
    color: getRandomColor(5),
  },
  {
    key: 'revenue_actual',
    label: 'Actual revenue',
    color: getRandomColor(6),
  },
  {
    key: 'roi_potential',
    label: 'Potential ROI',
    color: getRandomColor(9),
  },
  {
    key: 'roi_actual',
    label: 'Actual ROI',
    color: getRandomColor(10),
  },
];

const MetricsCards: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    performance: { summary },
    inline,
  } = props;

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        {config.map((c) => (
          <Grid item xs={6} md={inline ? 6 : 3} key={c.key}>
            <MetricCard
              title={
                (c.key.includes('roi')
                  ? summary[c.key]
                  : formatCurrency(summary[c.key], 0)) ?? '-'
              }
              subtitle={c.label}
              color={c.color}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MetricsCards;
