import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/Routes';
import { StoreContextProvider } from 'store';
import { SnackbarProvider } from 'notistack';
import ThemeConfig from './ThemeConfig';
import UserDataWrapper from './UserDataWrapper';
import QueryClient from './QueryClient';
import Localization from './Localization';
import Analytics from './Analytics';

const App = () => (
  <BrowserRouter>
    <StoreContextProvider>
      <ThemeConfig>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Analytics>
            <Localization>
              <QueryClient>
                <UserDataWrapper>
                  <Routes />
                </UserDataWrapper>
              </QueryClient>
            </Localization>
          </Analytics>
        </SnackbarProvider>
      </ThemeConfig>
    </StoreContextProvider>
  </BrowserRouter>
);

export default App;
