import { InfluencerTypes, TimelineTypes } from 'api';
import { Timeline } from 'components';

interface Props {
  data: InfluencerTypes.ItemData;
}

const ActivityLogTab = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Timeline id={data._id.$oid} type={TimelineTypes.ModelEnum.Influencer} />
  );
};

export default ActivityLogTab;
