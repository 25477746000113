import apiClient from '../apiClient';
import { AutocompleteSearchResponse } from '../types/common';
import { UserTypes } from './index';

const endpoints = {
  autocompleteSearch: 'users/autocomplete',
  getList: 'users/filter',
  getUser: 'user',
  signIn: 'auth/google_oauth2/callback',
  getById: (id: string) => `users/${id}`,
  create: 'users',
  update: (id: string) => `users/${id}`,
  remove: (id: string) => `users/${id}`,
};

const autocompleteSearch = (query: string): AutocompleteSearchResponse =>
  apiClient
    .post(endpoints.autocompleteSearch, {
      json: {
        name: query,
        limit: 10,
        show_unactive: false,
      },
    })
    .then((response) => response.json());

const getList = (params: UserTypes.GetListParams): UserTypes.GetListResponse =>
  apiClient
    .post(endpoints.getList, {
      json: params,
    })
    .then((response) => response.json());

const getItem = (params: UserTypes.GetItemParams): UserTypes.ItemResponse =>
  apiClient
    .post(endpoints.getUser, {
      json: params,
    })
    .then((response) => response.json());

const signIn = (params: UserTypes.SignInParams): UserTypes.SignInResponse =>
  apiClient
    .post(endpoints.signIn, { json: params })
    .then((response) => response.json());

const getById = (id: string): UserTypes.ItemResponse =>
  apiClient.get(endpoints.getById(id)).then((response) => response.json());

const create = (data: UserTypes.ItemCreateParams): UserTypes.ItemResponse =>
  apiClient
    .post(endpoints.create, { json: { user: data } })
    .then((response) => response.json());

const update = (
  id: string,
  data: UserTypes.ItemCreateParams,
): UserTypes.ItemResponse =>
  apiClient
    .put(endpoints.update(id), { json: { user: data } })
    .then((response) => response.json());

const remove = (id: string): UserTypes.ItemResponse =>
  apiClient
    .put(endpoints.remove(id), { json: { user: { is_deleted: true } } })
    .then((response) => response.json());

export default {
  endpoints,
  signIn,
  getItem,
  getList,
  autocompleteSearch,
  create,
  update,
  remove,
  getById,
};
