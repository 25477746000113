import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { HandleChangeValue } from 'hooks/useModel';

type Value = boolean | undefined;

interface Props {
  label: string;
  value: Value;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;
}

const SwitchFilter = (props: Props): JSX.Element | null => {
  const { onChange, value, label, inline } = props;

  const handleChange = () => {
    onChange((prev) => (prev === true ? undefined : true));
  };

  return (
    <FormControl
      component="fieldset"
      sx={{ my: inline ? 2 : 0, mx: inline ? 2 : undefined }}
      variant="standard"
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={value || false}
              onChange={handleChange}
            />
          }
          labelPlacement="end"
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export default SwitchFilter;
