import React from 'react';
import { Stack } from '@mui/material';
import { TooltipChip, BrandChip, CountryChip } from 'components';
import { CampaignTypes } from 'api';
import {
  getCampaignStatusColor,
  getCampaignTypeColor,
} from 'helpers/getStatusColor';
import {
  getFormattedDate,
  getFormattedDateFromNow,
} from 'helpers/dayJsFormats';

interface Props {
  campaign: CampaignTypes.ItemData;
}

const InfoRow = (props: Props): JSX.Element => {
  const { campaign } = props;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      {campaign.brands.map((brand) => (
        <BrandChip key={brand} brand={brand} />
      ))}
      {campaign.country_teams.map((country) => (
        <CountryChip key={country} country={country} />
      ))}
      <TooltipChip
        title="Status"
        label={campaign.status}
        color={getCampaignStatusColor(campaign.status)}
      />
      <TooltipChip
        title="Type"
        label={campaign.type}
        color={getCampaignTypeColor(campaign.type)}
      />
      <TooltipChip
        title={`Start date: ${getFormattedDate(campaign.start_date)}`}
        label={`Start ${getFormattedDateFromNow(campaign.start_date)}`}
        color="success"
      />
      {campaign.end_date && (
        <TooltipChip
          title={`End date: ${getFormattedDate(campaign.end_date)}`}
          label={`End ${getFormattedDateFromNow(campaign.end_date)}`}
          color="error"
        />
      )}
    </Stack>
  );
};

export default InfoRow;
