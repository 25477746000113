import React from 'react';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { Avatar, Card, Grid, Skeleton, Stack } from '@mui/material';
import { InfluencerName } from 'components';
import { DealTypes, influencers } from 'api';
import getSocialNetworkFromChannel from 'helpers/getSocialNetworkFromChannel';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  deal: DealTypes.ItemDataExtended;
  refetch: () => Promise<QueryObserverResult>;
  inline?: boolean;
}

const Header = (props: Props): JSX.Element => {
  const { deal, refetch, inline } = props;

  const { data: influencer } = useQuery(
    [influencers.endpoints.getById(deal.influencer_id.$oid)],
    () => influencers.getById(deal.influencer_id.$oid),
  );

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          {influencer ? (
            <Avatar
              src={influencer.profile_picture_url}
              sx={{ width: '100px', height: '100px' }}
            />
          ) : (
            <Skeleton
              variant="circular"
              sx={{ width: '100px', height: '100px' }}
            />
          )}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Stack rowGap={2}>
            {influencer ? (
              <InfluencerName
                link
                data={influencer}
                activeNetwork={getSocialNetworkFromChannel(deal.channel)}
                TypographyProps={{ variant: 'h4' }}
              />
            ) : (
              <Skeleton variant="text" />
            )}
            <InfoRow deal={deal} />
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          {influencer ? (
            <Avatar
              src={influencer.profile_picture_url}
              sx={{ width: '100px', height: '100px' }}
            />
          ) : (
            <Skeleton
              variant="circular"
              sx={{ width: '100px', height: '100px' }}
            />
          )}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="center" mb={3}>
            <Grid item sx={{ flexGrow: 1 }}>
              {influencer ? (
                <InfluencerName
                  link
                  data={influencer}
                  activeNetwork={getSocialNetworkFromChannel(deal.channel)}
                  TypographyProps={{ variant: 'h4' }}
                />
              ) : (
                <Skeleton variant="text" />
              )}
            </Grid>
            <Grid item>
              <Actions data={deal} refetch={refetch} />
            </Grid>
          </Grid>
          <InfoRow deal={deal} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
