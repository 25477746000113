import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
} from '../types/common';
import { CountriesEnum } from '../types/Countries';
import { BrandsEnum } from '../types/Brands';
import { DealTypes } from '../deals';

namespace SalesPlanningTypes {
  import ChannelEnum = DealTypes.ChannelEnum;
  import StrategiesEnum = DealTypes.StrategiesEnum;

  interface BudgetItem {
    budget_target: number;
    revenue_target: number;
    roi_target: number;
  }
  interface GroupBudgetItem extends BudgetItem {
    cm3_target: number;
  }

  export type GroupSubItems<T extends string | number | symbol> = {
    [key in T]: BudgetItem;
  };

  export interface GroupItem<C extends DealTypes.ChannelEnum>
    extends GroupBudgetItem {
    sub_items: GroupSubItems<C>;
  }

  export interface InfluencersBudgetSubItems extends BudgetItem {
    sub_items: {
      Instagram: BudgetItem;
      Youtube: BudgetItem;
      Tiktok: BudgetItem;
    };
  }
  export type InfluencersBudget = {
    sub_items: {
      [key in Exclude<
        DealTypes.TypeEnum,
        DealTypes.TypeEnum.NonInfluencer | DealTypes.TypeEnum.UGC
      >]: InfluencersBudgetSubItems;
    };
  } & GroupBudgetItem;

  export interface BudgetDist {
    [DealTypes.StrategiesEnum.influencers]: InfluencersBudget;
    [DealTypes.StrategiesEnum
      .performanceMarketing]: GroupItem<DealTypes.ChannelPerformanceMarketingEnum>;
    [DealTypes.StrategiesEnum
      .affiliate]: GroupItem<DealTypes.ChannelAffiliateEnum>;
    [DealTypes.StrategiesEnum
      .internal]: GroupItem<DealTypes.ChannelInternalEnum>;
    [DealTypes.StrategiesEnum.CRM]: GroupItem<DealTypes.ChannelCRMEnum>;
    [DealTypes.StrategiesEnum.website]: GroupItem<DealTypes.ChannelWebsiteEnum>;
    [DealTypes.StrategiesEnum.HR]: GroupItem<DealTypes.ChannelHrEnum>;
    [DealTypes.StrategiesEnum
      .sampling]: GroupItem<DealTypes.ChannelSamplingEnum>;
    [DealTypes.StrategiesEnum.organic]: GroupItem<DealTypes.ChannelOrganicEnum>;
    [DealTypes.StrategiesEnum
      .customerService]: GroupItem<DealTypes.ChannelCustomersServiceEnum>;
    [DealTypes.StrategiesEnum.B2B]: GroupItem<DealTypes.ChannelB2BEnum>;
    [DealTypes.StrategiesEnum.PR]: GroupItem<DealTypes.ChannelPrEnum>;
    [DealTypes.StrategiesEnum
      .localPromotion]: GroupItem<DealTypes.ChannelLocalPromoEnum>;
    [DealTypes.StrategiesEnum
      .marketplace]: GroupItem<DealTypes.ChannelMarketplaceEnum>;
    [DealTypes.StrategiesEnum
      .coCreation]: GroupItem<DealTypes.ChannelCoCreation>;
  }

  export type BrandDist = Partial<{
    [key in BrandsEnum]: number;
  }>;

  export enum StatusEnum {
    Pending = 'Pending',
    Ongoing = 'Ongoing',
    Finished = 'Finished',
  }

  export enum TypeEnum {
    Content = 'Content',
    Sales = 'Sales',
  }

  export interface ItemCreateParams {
    name: string;
    shop_id: string;
    brands: BrandsEnum[];
    country_team: CountriesEnum;
    status: StatusEnum;
    type: TypeEnum;
    budget_distribution: BudgetDist;
    brand_revenue_distribution?: BrandDist;
    budget_target: number;
    cm3_target: number;
    revenue_target: number;
    roi_target: number;
    start_date: string;
    end_date: string;
  }

  export interface ItemData extends Omit<ItemCreateParams, 'shop_id'> {
    _id: ID;
    shop_id: ID;
    budget_actual: number;
    budget_potential: number;
    budget_running_forecast: number;
    budget_traffic_light: number;
    deal_ids: string[];
    created_at: string;
    deals_nb: number;
    free_deals_nb: number;
    free_influencers_nb: number;
    influencers_nb: number;
    is_deleted: boolean | null;
    paid_deals_nb: number;
    paid_influencers_nb: number;
    reach_total: number;
    revenue_actual: number;
    revenue_potential: number;
    revenue_running_forecast: number;
    revenue_traffic_light: number;
    roi_actual: number;
    roi_potential: number;
    sales_planning_id: string;
    updated_at: string;
  }

  export type ItemResponse = Promise<ItemData>;

  export type ListData = ListPaginationResponse<ItemData>;

  export type ListResponse = Promise<ListData>;

  export interface GetListParams extends Partial<ListPaginationParams> {
    // global
    filters: {
      country_team?: string;
      brand?: string;
      // general
      status?: StatusEnum[];
      brands?: BrandsEnum[];
      type?: TypeEnum;
      start_date?: {
        $gte?: string;
        $lte: string;
      };
    };
  }

  export type AutocompleteSearchItem = Pick<ItemData, '_id' | 'name'>;

  export type AutocompleteSearchData = AutocompleteSearchItem[];

  export type AutocompleteSearchResponse = Promise<AutocompleteSearchData>;

  // ----------- start performance report ---------------

  export interface PerformanceDetailsItem {
    budget_actual: number;
    budget_potential: number;
    revenue_running_forecast: number;
    revenue_actual: number;
    revenue_potential: number;
    budget_running_forecast: number;
    orders_nb_actual: number;
    gross_profit_actual: number;
    key: string; // date
    status: DealTypes.StatusEnum;
    roi_actual?: number;
    roi_potential: number;
  }

  export interface PerformanceSummary {
    budget_target: number;
    budget_actual: number;
    pending_budget_planned: number;
    scheduled_budget_planned: number;
    budget_potential: number;
    budget_running_forecast: number;
    budget_traffic_light?: number;
    revenue_target: number;
    revenue_actual: number;
    pending_revenue_planned: number;
    scheduled_revenue_planned: number;
    revenue_potential: number;
    revenue_running_forecast: number;
    revenue_traffic_light?: number;
    orders_nb_actual: number;
    roi_actual?: number;
    roi_potential: number;
  }

  export interface PerformanceData {
    summary: PerformanceSummary;
    details: PerformanceDetailsItem[];
  }

  export type PerformanceResponse = Promise<PerformanceData>;

  export interface PerformanceParams {
    date_selector: 2;
    filters: {
      brand: BrandsEnum[];
      country_team: CountriesEnum;
      strategy?: DealTypes.StrategiesEnum;
      channel?: DealTypes.ChannelEnum;
      type?: Exclude<
        DealTypes.TypeEnum,
        DealTypes.TypeEnum.NonInfluencer | DealTypes.TypeEnum.UGC
      >;
    };
    period: {
      start_date: string;
      end_date: string;
    };
  }

  // ----------- end performance report ---------------

  export interface CM2ItemData {
    channel: StrategiesEnum | 'default';
    cm2_prct: number;
    month: string;
    shop_id: string;
    shop_schema: string;
    sub_channel_type: ChannelEnum | 'default';
    value_type: string;
  }

  export type CM2FlatListData = Array<CM2ItemData>;

  export type CM2FlatListResponse = Promise<CM2FlatListData>;

  export interface GetCM2Params {
    month: number;
    year: number;
  }
}

export default SalesPlanningTypes;
