import { SalesPlanningTypes } from 'api';
import { enumToOptions } from 'helpers/common';
import { CountriesEnum } from '../api/types/Countries';

const STATUS_OPTIONS = enumToOptions(SalesPlanningTypes.StatusEnum);

const TYPE_OPTIONS = enumToOptions(SalesPlanningTypes.TypeEnum);

const AMAZON_TAX_OPTIONS = {
  [CountriesEnum.DE]: { label: '19%', value: 0.19 },
  [CountriesEnum.FR]: { label: '20%', value: 0.2 },
  [CountriesEnum.IT]: { label: '22%', value: 0.22 },
  [CountriesEnum.IN]: { label: '0%', value: 0 },
  [CountriesEnum.PL]: { label: '0%', value: 0 },
};

const SALES_PLANNING = {
  STATUS_OPTIONS,
  TYPE_OPTIONS,
  AMAZON_TAX_OPTIONS,
};

export default SALES_PLANNING;
