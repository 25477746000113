import { useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';
import { ErrorBoundary } from 'components';
import { salesPlanning, SalesPlanningTypes } from 'api';
import { useMemo, useState } from 'react';
import TrafficLightProgress from './TrafficLightProgress';
import TrafficLightTable from './TrafficLightTable';
import PerformanceFilters from './PerformanceFilters';
import PerformanceLoader from './PerformanceLoader';
import TrafficLightMetrics from './TrafficLightMetrics';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const PerformanceTab = (props: Props): JSX.Element | null => {
  const { planning, inline } = props;

  const [brands, setBrands] = useState(planning.brands);
  const [strategy, setStrategy] =
    useState<SalesPlanningTypes.PerformanceParams['filters']['strategy']>();
  const [channel, setChannel] =
    useState<SalesPlanningTypes.PerformanceParams['filters']['channel']>();

  const filters: SalesPlanningTypes.PerformanceParams['filters'] = useMemo(
    () => ({
      country_team: planning.country_team,
      brand: brands,
      strategy,
      channel,
    }),
    [brands, channel, planning.country_team, strategy],
  );

  const { data, error } = useQuery(
    [salesPlanning.endpoints.performanceReport, filters],
    () =>
      salesPlanning.getPerformanceReport({
        date_selector: 2,
        period: {
          start_date: planning.start_date,
          end_date: planning.end_date,
        },
        filters,
      }),
  );

  if (error) {
    return (
      <ErrorBoundary
        height="60vh"
        variant="error"
        message="Failed to load performance data"
      />
    );
  }

  if (!data) {
    return <PerformanceLoader />;
  }

  return (
    <Stack rowGap={3}>
      <PerformanceFilters
        brands={brands}
        setBrands={setBrands}
        strategy={strategy}
        setStrategy={setStrategy}
        channel={channel}
        setChannel={setChannel}
      />
      <TrafficLightMetrics performance={data} inline={inline} />
      <TrafficLightProgress performance={data.summary} inline={inline} />
      <TrafficLightTable performance={data} />
    </Stack>
  );
};

export default PerformanceTab;
