import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  DeleteOutlined,
  LaunchOutlined,
  LinkOutlined,
} from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  ConfirmDialogControlled,
  CloseDetailsDrawer,
} from 'components';
import { appLinks } from 'routes/links';
import { campaigns, CampaignTypes } from 'api';
import { useApiRequest, useFiltersSearchParams, useClipboard } from 'hooks';

interface Props {
  inline?: boolean;
  campaign: CampaignTypes.ItemData;
}

const Actions = (props: Props): JSX.Element => {
  const { campaign, inline } = props;
  const navigate = useNavigate();
  const { handleFilterChange } = useFiltersSearchParams();
  const handleCopy = useClipboard();

  const { isLoading, requestFn } = useApiRequest(() =>
    campaigns.remove(campaign._id.$oid),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleDelete = useCallback(async () => {
    await requestFn({
      successMessage: 'Campaign deleted',
    });

    setIsDeleteDialogOpen(false);

    if (inline) {
      handleFilterChange('splitItemId', undefined);
    } else {
      navigate(-1);
    }
  }, [handleFilterChange, inline, navigate, requestFn]);

  const actions: ActionButtonProps<CampaignTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.campaigns.details.as(item.campaign_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin +
              appLinks.campaigns.details.as(item.campaign_id),
          ),
      },
      {
        name: 'Edit campaign',
        EditButtonProps: {
          inline,
          idKey: 'campaignId',
          id: (item) => item.campaign_id,
          link: appLinks.campaigns.edit.as,
        },
      },
      {
        name: 'Delete campaign',
        Icon: DeleteOutlined,
        onClick: handleOpenDialog,
      },
    ],
    [handleCopy, inline],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={inline ? 7 : 4}
          actions={actions}
          data={campaign}
        />
        <ConfirmDialogControlled
          isLoading={isLoading}
          name="campaign"
          isOpen={isDeleteDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDelete}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
