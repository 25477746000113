import React from 'react';
import { Divider, Stack } from '@mui/material';
import { BrandsStatus } from 'components';
import { formatCompactNumber, formatPercent } from 'helpers/formatters';
import { InfluencerTypes } from 'api';
import MetricBlock from '../MetricBlock';
import OtherPlatformsBlock from './OtherPlatformsBlock';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const InfoRow = (props: Props): JSX.Element => {
  const { data } = props;

  return (
    <Stack
      gap={2}
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <BrandsStatus brands={data.brand_infos} />
      <MetricBlock
        value={formatCompactNumber(data.instagram?.followers_nb)}
        percentage={data.instagram?.followers_growth}
        title="Followers"
      />
      <MetricBlock
        value={formatPercent(data.instagram_post?.engagement_rate)}
        percentage={0}
        title="Eng. Rate"
      />
      <OtherPlatformsBlock data={data} />
    </Stack>
  );
};

export default InfoRow;
