import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  Alert,
  Collapse,
  Grid,
  Link,
  ListSubheader,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  DiscountOutlined,
  EuroOutlined,
  LightbulbCircleOutlined,
  PercentOutlined,
} from '@mui/icons-material';
import { DiscountTypes } from 'api';
import DISCOUNTS from 'constants/discounts';
import {
  getProductCategories,
  getProductOptions,
  getProducts,
} from 'helpers/products';
import { FormCard, FormInput } from 'components';
import { CountriesEnum } from 'api/types/Countries';
import CouponCodeInput from './CouponCodeInput';
import { checkExternalDiscount } from '../fields';

const CONFLUENCE_LINK =
  'https://hashtagyou.atlassian.net/wiki/spaces/MG/pages/1016332289/Discount+Logics+prefixes+and+suffixes+across+markets';

interface Props {
  control: Control<DiscountTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DiscountTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DiscountTypes.ItemCreateParams>;
  initialData: DiscountTypes.ItemCreateParams;
}

const DiscountBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue, initialData } = props;

  const couponCode = watch('coupon_code');
  const brand = watch('brand');
  const discountType = watch('discount_type');
  const discountAmountType = watch('discount_amount_type');
  const discountDetails = watch('discount_details');
  const value = watch('value');
  const countryTeam = watch('country_team');
  const freeProductCategories = watch('free_gift_product_categories');
  const freeProducts = watch('free_gift_products');

  const isActiveDiscountGift = useMemo(
    () =>
      discountDetails &&
      (discountDetails.indexOf('gift') >= 0 ||
        discountDetails.indexOf('gift') >= 0),
    [discountDetails],
  );

  const handleDiscountAmountTypeChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      setValue('applies_once_per_order', false);

      onChange(event.target.value);
    },
    [setValue],
  );

  const handleDiscountDetailsChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const discType = event.target.value.includes('Value')
        ? DiscountTypes.DiscountTypeEnum.FixedAmount
        : DiscountTypes.DiscountTypeEnum.Percentage;

      setValue('free_gift_product_categories', []);
      setValue('free_gift_products', []);

      if (discType !== discountType) {
        setValue('discount_type', discType);
        setValue('value', 0);
      }

      if (
        event.target.value === DiscountTypes.DetailsEnum.TieredDiscounts ||
        event.target.value === DiscountTypes.DetailsEnum.SplitDiscounts
      ) {
        setValue('value', 0);
      }

      onChange(event.target.value);
    },
    [discountType, setValue],
  );

  const handleFreeProductCategoryChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      if (freeProducts && freeProducts.length > 0) {
        const availableProducts = getProducts(
          brand,
          (event.target.value || []) as unknown as never[],
        );

        const selectedProducts = freeProducts.filter((p) =>
          availableProducts.includes(p),
        );

        setValue('free_gift_products', selectedProducts);
      }

      onChange(event.target.value);
    },
    [brand, freeProducts, setValue],
  );

  const isDiscountValueDisabled = useMemo(
    () =>
      discountDetails === DiscountTypes.DetailsEnum.TieredDiscounts ||
      discountDetails === DiscountTypes.DetailsEnum.SplitDiscounts,
    [discountDetails],
  );

  const discountValueAdornment = useMemo(() => {
    if (isDiscountValueDisabled) {
      return (
        <Tooltip
          title={`Discount value is disabled when ${DiscountTypes.DetailsEnum.TieredDiscounts} or ${DiscountTypes.DetailsEnum.SplitDiscounts} selected`}
        >
          <LightbulbCircleOutlined />
        </Tooltip>
      );
    }

    return discountType === DiscountTypes.DiscountTypeEnum.Percentage ? (
      <PercentOutlined fontSize="small" />
    ) : (
      <EuroOutlined fontSize="small" />
    );
  }, [discountType, isDiscountValueDisabled]);

  return (
    <FormCard title="Discount details" avatar={<DiscountOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CouponCodeInput
            control={control}
            watch={watch}
            initialData={initialData}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="coupon_description"
            inputType="textarea"
            label="Coupon description"
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="discount_amount_type"
            inputType="radio"
            label="Discount type"
            options={DISCOUNTS.AMOUNT_TYPE_OPTIONS}
            onChange={handleDiscountAmountTypeChange}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="discount_details"
            inputType="select"
            label="Discount details"
            options={DISCOUNTS.DETAILS_OPTIONS}
            onChange={handleDiscountDetailsChange}
            rules={{ required: true }}
          >
            <ListSubheader>
              {DiscountTypes.DiscountTypeEnum.Percentage}
            </ListSubheader>
            {DISCOUNTS.DETAILS_OPTIONS.filter(
              (v) => !v.label.includes('Value'),
            ).map((o) => (
              <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
                {o.label}
              </MenuItem>
            ))}
            <ListSubheader>
              {DiscountTypes.DiscountTypeEnum.FixedAmount}
            </ListSubheader>
            {DISCOUNTS.DETAILS_OPTIONS.filter((v) =>
              v.label.includes('Value'),
            ).map((o) => (
              <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
                {o.label}
              </MenuItem>
            ))}
          </FormInput>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            control={control}
            name="value"
            inputType="number"
            label="Value"
            placeholder={`Enter ${
              discountType === DiscountTypes.DiscountTypeEnum.FixedAmount
                ? 'EUR'
                : 'percent'
            } value`}
            InputProps={{
              endAdornment: discountValueAdornment,
            }}
            errorText={
              discountType === DiscountTypes.DiscountTypeEnum.FixedAmount
                ? undefined
                : 'Percent value can not be more than 100%'
            }
            rules={{
              required: true,
              onBlur: (e) => setValue('value', +(+e.target.value).toFixed(2)),
              max:
                discountType === DiscountTypes.DiscountTypeEnum.Percentage
                  ? 100
                  : undefined,
            }}
            disabled={isDiscountValueDisabled}
          />
        </Grid>

        <Grid item xs={12}>
          <Collapse in={checkExternalDiscount(couponCode) && value > 0}>
            <Alert severity="info" variant="filled">
              The coupon code you entered contains prefix or suffix that has a
              percentage added via script. Please change the value here to 0
              before saving the code
              <Link href={CONFLUENCE_LINK} target="_blank" display="block">
                More info
              </Link>
            </Alert>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <Collapse
            in={
              (discountType === DiscountTypes.DiscountTypeEnum.Percentage &&
                value > 60) ||
              (discountType === DiscountTypes.DiscountTypeEnum.FixedAmount &&
                value > 100)
            }
          >
            <Alert severity="warning" variant="filled">
              Please double-check the discount before saving: values higher than
              60% or 100 euro are not recommended
            </Alert>
          </Collapse>
        </Grid>

        {discountType === DiscountTypes.DiscountTypeEnum.FixedAmount &&
          discountAmountType ===
            DiscountTypes.DiscountAmountTypeEnum.ProductDiscount && (
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="applies_once_per_order"
                inputType="switch"
                label="Apply discount once per order"
                helperText={
                  value
                    ? `If not selected, ${value} ${
                        countryTeam === CountriesEnum.PL ? 'PLN' : 'EUR'
                      } will be taken off each eligible item in an order`
                    : ''
                }
              />
            </Grid>
          )}
        {discountType === DiscountTypes.DiscountTypeEnum.Percentage && (
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="percentage_description"
              inputType="textarea"
              label="Percentage description"
            />
          </Grid>
        )}

        {isActiveDiscountGift && (
          <>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="free_gift_product_categories"
                inputType="select"
                label="Gift product categories"
                options={getProductCategories(brand)}
                onChange={handleFreeProductCategoryChange}
                SelectProps={{
                  multiple: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="free_gift_products"
                inputType="select"
                label="Gift products"
                options={getProductOptions(
                  brand,
                  freeProductCategories as never[],
                )}
                disabled={freeProductCategories?.length === 0}
                SelectProps={{
                  multiple: true,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormCard>
  );
};

export default DiscountBlock;
