import React, { useMemo } from 'react';
import { Avatar, Card, Grid, Stack, Typography } from '@mui/material';
import { UserTypes } from 'api';
import { getUserInitials } from 'helpers/common';
import Actions from './Actions';
import InfoRow from './InfoRow';

interface Props {
  inline?: boolean;
  user: UserTypes.ItemData;
}

const Header = (props: Props): JSX.Element => {
  const { user, inline } = props;

  const userName = useMemo(
    () =>
      user.full_name || (user.first_name && user.last_name)
        ? `${user.first_name} ${user.last_name}`
        : 'User',
    [user],
  );

  if (inline) {
    return (
      <Grid
        container
        columnGap={2}
        alignItems="center"
        wrap="nowrap"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Avatar sx={{ width: '100px', height: '100px' }}>
            {getUserInitials(userName)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Stack rowGap={2}>
            <Typography variant="h4">{userName}</Typography>
            <InfoRow user={user} />
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container columnGap={4} alignItems="center">
        <Grid item>
          <Avatar
            src={user.display_picture_url}
            sx={{ width: '100px', height: '100px' }}
          >
            {getUserInitials(userName)}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnGap={2} alignItems="center" mb={3}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h4">{userName}</Typography>
            </Grid>
            <Grid item>
              <Actions user={user} />
            </Grid>
          </Grid>
          <InfoRow user={user} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Header;
