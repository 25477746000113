import { Button, ButtonProps, CircularProgress } from '@mui/material';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

const LoadingButton = (props: LoadingButtonProps): JSX.Element | null => {
  const { loading, endIcon, children, ...rest } = props;

  return (
    <Button
      {...rest}
      disabled={loading}
      endIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : endIcon
      }
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
