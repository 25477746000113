import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Control, FieldValues } from 'react-hook-form';
import FormInput from '../FormInput';
import FormProps from '../FormProps';
import { ArrowDownTinIcon } from '../../icons';

interface Props<T extends FieldValues = any> {
  itemFields: FormProps.FormItemMulti<T>[];
  title: string;
  index: number;
  onRemove: () => void;
  control: Control<T> | undefined;
}

const BlockItem = (props: Props): JSX.Element | null => {
  const { itemFields, title, onRemove, index, control } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleIsCollapsed = () => setIsCollapsed((prev) => !prev);

  return (
    <Card variant="outlined">
      <CardHeader
        onClick={toggleIsCollapsed}
        sx={{ userSelect: 'none', '&:hover': { cursor: 'pointer' } }}
        avatar={
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: '16px',
            }}
          >
            {index + 1}
          </Avatar>
        }
        action={
          <IconButton>
            <ArrowDownTinIcon
              sx={{ transform: isCollapsed ? 'rotate(180deg)' : undefined }}
            />
          </IconButton>
        }
        title={
          <Typography variant="subtitle1">
            {`${title} #${index + 1}`}
          </Typography>
        }
      />
      <Collapse in={isCollapsed}>
        <CardContent>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {itemFields.map(({ GridProps, ...item }) => (
                  <Grid item xs={6} key={item.name(index)} {...GridProps}>
                    <FormInput
                      {...item}
                      control={control}
                      name={item.name(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Tooltip title={`Remove "${title} #${index + 1}"`}>
              <IconButton color="error" onClick={onRemove}>
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default BlockItem;
