import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { BookmarkRemoveOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { bookmarks } from 'api';
import { useDataTableContext } from '../context';
import Loader from '../../Loader';
import { selectAllRows } from '../context/actions';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
}

const BookmarkRemoveAction = (props: Props): JSX.Element | null => {
  const { refetch } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { requestFn: removeBookmarkFn, isLoading } = useApiRequest(
    bookmarks.bulkRemove,
  );

  const bookmarkedItems = selectedItems
    .filter((s) => !!s.bookmark_id)
    .map((s) => s.bookmark_id);

  const handleRemoveBookmarks = async (e: React.MouseEvent) => {
    e.stopPropagation();

    await removeBookmarkFn({
      args: {
        bookmark_ids: bookmarkedItems,
      },
      successMessage: `${bookmarkedItems.length} bookmarks removed`,
    });

    await refetch();
    dispatch(selectAllRows([]));
  };

  return (
    <Tooltip title={`Remove ${bookmarkedItems.length} bookmarks`}>
      <span>
        <Button
          onClick={handleRemoveBookmarks}
          disabled={isLoading || bookmarkedItems.length === 0}
        >
          {isLoading ? (
            <Loader
              variant="inline"
              circularProgressProps={{ size: 24, color: 'primary' }}
            />
          ) : (
            <BookmarkRemoveOutlined
              color={bookmarkedItems.length ? 'error' : undefined}
            />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default BookmarkRemoveAction;
