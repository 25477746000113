import { LinearProgress, linearProgressClasses, Stack } from '@mui/material';
import { isImageUrl } from 'helpers/common';
import { colors } from 'theme/constants';

interface Props {
  posts: string[];
  activePost: number;
  activeVideoDuration: number;
  timestamp: number;
}

const StoriesProgressBar = (props: Props): JSX.Element | null => {
  const { activeVideoDuration, activePost, posts, timestamp } = props;

  const isImage = isImageUrl(posts[activePost]);

  const getValue = (index: number) => {
    if (activePost > index) {
      return 100;
    }
    if (activePost < index) {
      return 0;
    }

    if (timestamp === 0 && activePost === 0) {
      return 0;
    }

    return isImage ? 0 : (timestamp / activeVideoDuration) * 100;
  };

  return (
    <Stack direction="row" spacing={0.5}>
      {posts.map((p, index) => (
        <LinearProgress
          key={p}
          variant="determinate"
          value={getValue(index)}
          sx={(theme) => ({
            width: `${100 / posts.length}%`,
            backgroundColor: colors.grey3,
            borderRadius: `${theme.shape.borderRadius}px`,
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: colors.grey2,
            },
          })}
        />
      ))}
    </Stack>
  );
};

export default StoriesProgressBar;
