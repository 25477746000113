import { Card, styled } from '@mui/material';

const ColorCard = styled(Card)(({ theme, color }) => ({
  position: 'relative',
  '&:after': {
    content: '" "',
    position: 'absolute',
    top: '0%',
    left: '0',
    width: '4px',
    height: '100%',
    backgroundColor: color || theme.palette.primary.main,
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    zIndex: 15,
  },
}));

export default ColorCard;
