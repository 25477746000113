import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { InfluencerTypes } from 'api';
import { formatCurrency, formatNumber } from 'helpers/formatters';
import { MetricCard } from 'components';
import { colors } from 'theme/constants';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const DealsPerformance = (props: Props): JSX.Element | null => {
  const { data } = props;

  const options = useMemo(
    () => [
      {
        label: 'Number of deals',
        value: formatNumber(data.deals_nb, 0),
        color: colors.accent,
      },
      {
        label: 'Marketing spend',
        value: formatCurrency(data.total_spent),
        color: colors.darkOrange,
      },
      {
        label: 'Revenue generated',
        value: formatCurrency(data.total_revenue),
        color: colors.darkGreen,
      },
    ],
    [data],
  );

  return (
    <Box>
      <Grid container spacing={2}>
        {options.map((o) => (
          <Grid item xs={12} sm={6} md={4} key={o.label}>
            <MetricCard title={o.value} subtitle={o.label} color={o.color} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DealsPerformance;
