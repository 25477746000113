import { InfluencerTypes } from '../influencers';

export enum BrandsEnum {
  avaAndMay = 'Ava & May',
  faynt = 'Faynt',
  clubNoe = 'Club Noe',
  millionFacets = 'Million Facets',
  ultravit = 'Ultravit',
  reflekt = 'Reflekt',
}

export enum BrandRelationsQualityEnum {
  Excellent = 'Excellent',
  Good = 'Good',
  Average = 'Average',
  Bad = 'Bad',
  Avoided = 'Avoided',
}

export enum BrandSalesPotentialEnum {
  Excellent = 'Excellent',
  Good = 'Good',
  Neutral = 'Neutral',
  Avoid = 'Avoid',
}

export enum BrandStatusEnum {
  'New' = 'New',
  'On radar' = 'On radar',
  'Approached' = 'Approached',
  'In negotiation' = 'In negotiation',
  'Collaboration test' = 'Collaboration test',
  'Collaboration long-term' = 'Collaboration long-term',
  'Collaboration content' = 'Collaboration content',
  'Product test' = 'Product test',
  'Not interested' = 'Not interested',
  'Parked' = 'Parked',
  'Re-approached' = 'Re-approached',
  'Blacklist' = 'Blacklist',
}

export enum BrandsTimeframeEnum {
  test = 'Test',
  shortTerm = 'Short term',
  longTerm = 'Long term',
}

export enum BrandBusinessFocusesEnum {
  content = 'Content',
  sales = 'Sales',
  brandEnhancement = 'Brand enhancement',
}

export type BrandInfo = InfluencerTypes.BrandInfo;
