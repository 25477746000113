import React, { useCallback, useMemo } from 'react';
import { Drawer } from '@mui/material';
import { useFiltersSearchParams } from 'hooks';
import DetailsRoutes from './DetailsRoutes';
import { DataTableProps } from '../DataTable';
import { DrawerMode } from '../../types/SearchParams';

const DetailsDrawerRoutes = (): JSX.Element | null => {
  const {
    filtersSearchParams: {
      influencerId,
      dealId,
      negotiationId,
      campaignId,
      discountId,
      salesPlanningId,
      userId,
      drawerMode,
    },
    updateFiltersSearchParams,
  } = useFiltersSearchParams();

  const activeItem = useMemo(() => {
    const obj = {
      influencerId,
      dealId,
      negotiationId,
      campaignId,
      discountId,
      salesPlanningId,
      userId,
    };

    return Object.entries(obj)
      .map(([key, value]) =>
        value !== undefined
          ? {
              type: key as DataTableProps.DetailsDrawerType,
              id: value as string,
            }
          : undefined,
      )
      .filter((v) => !!v)[0];
  }, [
    campaignId,
    dealId,
    discountId,
    influencerId,
    negotiationId,
    salesPlanningId,
    userId,
  ]);

  const handleClose = useCallback(() => {
    if (activeItem?.type) {
      updateFiltersSearchParams({
        [activeItem.type]: undefined,
        drawerMode: undefined,
      });
    }
  }, [activeItem, updateFiltersSearchParams]);

  return (
    <Drawer
      open={!!activeItem}
      variant="temporary"
      anchor="right"
      onClose={handleClose}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
          width: '50vw',
          minWidth: '640px',
          border: `1px solid ${theme.palette.divider}`,
          top: 0,
          height: '100vh',
        }),
      }}
    >
      {activeItem && (
        <DetailsRoutes
          type={activeItem.type}
          id={activeItem.id}
          drawerMode={drawerMode as DrawerMode}
        />
      )}
    </Drawer>
  );
};

export default DetailsDrawerRoutes;
