import { useCallback, useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { TimelineTypes } from 'api';
import { getUserInitials } from 'helpers/common';
import { getFormattedDateFromNow } from 'helpers/dayJsFormats';
import { useStoreContext } from 'store';
import CommentUpdateForm from './CommentUpdateForm';
import DeleteButton from './DeleteButton';

interface Props {
  id: string;
  type: TimelineTypes.ModelEnum;
  data: TimelineTypes.FlatListData;
  refetch: () => Promise<QueryObserverResult>;
}

const CommentsList = (props: Props): JSX.Element | null => {
  const { type, id, data, refetch } = props;
  const {
    state: { user },
  } = useStoreContext();

  const [activeEditComment, setActiveEditComment] = useState<
    string | undefined
  >(undefined);

  const handleCloseEditComment = useCallback(() => {
    setActiveEditComment(undefined);
    refetch();
  }, [refetch]);

  return (
    <List disablePadding sx={{ px: 0.75 }}>
      {data.map((item) => (
        <ListItem disablePadding key={item._id.$oid}>
          <Divider orientation="vertical" flexItem>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              {getUserInitials(item.author)}
            </Avatar>
          </Divider>
          <ListItemText sx={{ py: 2, ml: 2 }}>
            {activeEditComment === item._id.$oid ? (
              <CommentUpdateForm
                id={id}
                commentId={activeEditComment}
                type={type}
                value={item.comment_text || ''}
                onClose={handleCloseEditComment}
              />
            ) : (
              <>
                <Typography variant="body2" gutterBottom>
                  {item.message}
                </Typography>
                {item.comment_text && (
                  <Typography variant="body1" gutterBottom>
                    {item.comment_text}
                  </Typography>
                )}
                <Typography variant="caption" color="text.secondary">
                  {getFormattedDateFromNow(item.timestamp)}
                </Typography>
              </>
            )}
          </ListItemText>
          {user?._id.$oid === item.user_id.$oid &&
            activeEditComment !== item._id.$oid && (
              <ListItemSecondaryAction>
                <Stack direction="row" columnGap={1}>
                  <IconButton
                    onClick={() => setActiveEditComment(item._id.$oid)}
                  >
                    <EditOutlined />
                  </IconButton>
                  <DeleteButton
                    id={id}
                    commentId={item._id.$oid}
                    type={type}
                    refetch={refetch}
                  />
                </Stack>
              </ListItemSecondaryAction>
            )}
        </ListItem>
      ))}
    </List>
  );
};

export default CommentsList;
