import MarketingTypes from './types';
import { ListPaginationParams } from '../types/common';

const endpoints = {
  getList: 'marketing-plan/filter',
  getById: (id: string) => `marketing-plan/${id}`,
  create: 'marketing-plan',
  update: (id: string) => `marketing-plan/${id}`,
  remove: (id: string) => `marketing-plan/${id}`,
};

const getList = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: Pick<
    ListPaginationParams,
    Exclude<keyof ListPaginationParams, [string, string][number]>
  >,
): MarketingTypes.ListResponse =>
  new Promise((resolve) => {
    setTimeout(() => {
      const data = window.localStorage.getItem('marketing-plan');

      if (data) {
        const parsed = JSON.parse(data);
        resolve({
          total: parsed.length,
          data: parsed,
        });
      }

      resolve({
        total: 0,
        data: [],
      });
    }, 3000);
  });

const getById = (id: string): MarketingTypes.ItemResponse =>
  new Promise((resolve) => {
    const data = window.localStorage.getItem('marketing-plan')!;

    const parsed = data ? JSON.parse(data) : [];

    const item = parsed.find(
      (d: { story_insight_id: string }) => d.story_insight_id === id,
    );

    resolve(item);
  });

const create = (
  data: MarketingTypes.ItemCreateParams,
): MarketingTypes.ItemResponse =>
  new Promise((resolve) => {
    const insights = window.localStorage.getItem('marketing-plan')!;

    const parsed = insights ? JSON.parse(insights) : [];

    const newItem: MarketingTypes.ItemData = {
      ...data,
      marketing_id: new Date().toISOString(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      shop_id: {
        $oid: data.shop_id,
      },
      _id: {
        $oid: new Date().toISOString(),
      },
    };

    const newData = [...parsed, newItem];

    window.localStorage.setItem('marketing-plan', JSON.stringify(newData));

    resolve(newItem);
  });

const update = (
  id: string,
  data: Partial<MarketingTypes.ItemCreateParams | MarketingTypes.ItemData>,
): MarketingTypes.ItemResponse =>
  new Promise((resolve) => {
    const insights = window.localStorage.getItem('marketing-plan')!;
    const parsed = insights ? JSON.parse(insights) : [];
    const newData = parsed.map((p: { story_insight_id: string }) =>
      p.story_insight_id === id
        ? {
            ...p,
            ...data,
            updated_at: new Date().toISOString(),
          }
        : p,
    );

    window.localStorage.setItem('marketing-plan', JSON.stringify(newData));

    resolve(newData);
  });

const remove = (id: string): MarketingTypes.ItemResponse =>
  new Promise((resolve) => {
    const insights = window.localStorage.getItem('marketing-plan')!;
    const parsed = insights ? JSON.parse(insights) : [];
    const newData = parsed.filter(
      (p: { story_insight_id: string }) => p.story_insight_id === id,
    );

    window.localStorage.setItem('marketing-plan', JSON.stringify(newData));

    resolve(newData);
  });
export default {
  endpoints,
  getList,
  create,
  update,
  getById,
  remove,
};
