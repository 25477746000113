import React from 'react';
import { EventContentArg } from '@fullcalendar/core';
import { Stack } from '@mui/material';
import BrandChip from '../../Chips/BrandChip';

interface Props {
  eventInfo: EventContentArg;
}

const EventContent = (props: Props): JSX.Element | null => {
  const { eventInfo } = props;

  return (
    <Stack
      direction="row"
      columnGap={0.5}
      sx={{ '&:hover': { cursor: 'pointer' } }}
    >
      <BrandChip
        brand={eventInfo.event.extendedProps.brand}
        country={eventInfo.event.extendedProps.country_team}
        label={eventInfo.event.title}
        ChipProps={{ sx: { '&:hover': { cursor: 'pointer' }, width: '100%' } }}
      />
    </Stack>
  );
};

export default EventContent;
