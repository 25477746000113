import { CardContent, CardHeader } from '@mui/material';
import { DealTypes } from 'api';
import { ColorCard } from 'components';
import { colors } from 'theme/constants';
import Chart from './Chart';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const MetricsChart = (props: Props): JSX.Element | null => {
  const { deal } = props;

  return (
    <ColorCard color={colors.darkBlue}>
      <CardHeader title="Deal revenue" />
      <CardContent>
        <Chart data={deal.metrics} />
      </CardContent>
    </ColorCard>
  );
};

export default MetricsChart;
