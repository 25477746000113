import React from 'react';
import { Box, Grid } from '@mui/material';
import { MetricCard } from 'components';
import { SalesPlanningTypes } from 'api';
import getRandomColor from 'helpers/getRandomColor';
import { formatCurrency } from 'helpers/formatters';

interface Props {
  inline?: boolean;
  performance: SalesPlanningTypes.PerformanceData;
}

const config: {
  key: keyof SalesPlanningTypes.PerformanceSummary;
  label: string;
  tooltip: string;
  color: string;
}[] = [
  {
    key: 'budget_potential',
    label: 'Potential budget',
    tooltip: 'Sum of planned Budget for selected deals in this sales planning',
    color: getRandomColor(1),
  },
  {
    key: 'budget_actual',
    label: 'Actual budget',
    tooltip: 'Sum of actual budget for selected deals in this sales planning',
    color: getRandomColor(11),
  },
  {
    key: 'pending_budget_planned',
    label: 'Pending budget',
    tooltip: 'Sum pending budget for selected deals in this sales planning',
    color: getRandomColor(3),
  },
  {
    key: 'scheduled_budget_planned',
    label: 'Scheduled budget',
    tooltip: 'Sum scheduled budget for selected deals in this sales planning',
    color: getRandomColor(4),
  },

  {
    key: 'revenue_potential',
    label: 'Potential revenue',
    tooltip: 'Sum of planned revenue for selected deals in this sales planning',
    color: getRandomColor(5),
  },
  {
    key: 'revenue_actual',
    label: 'Actual revenue',
    tooltip: 'Sum of actual revenue for selected deals in this sales planning',
    color: getRandomColor(6),
  },
  {
    key: 'pending_revenue_planned',
    label: 'Pending revenue',
    tooltip: 'Sum pending revenue for selected deals in this sales planning',
    color: getRandomColor(12),
  },
  {
    key: 'scheduled_revenue_planned',
    label: 'Scheduled revenue',
    tooltip: 'Sum scheduled revenue for selected deals in this sales planning',
    color: getRandomColor(8),
  },

  {
    key: 'roi_potential',
    label: 'Potential roi',
    tooltip:
      'Potential ROI based on selected deals in this sales planning, Potential ROI = Potential revenue/ Potential Budget',
    color: getRandomColor(9),
  },
  {
    key: 'roi_actual',
    label: 'Actual roi',
    tooltip:
      'ROI from selected deals which were already executed in this sales planning, Actual ROI = Actual revenue/ Actual Budget',
    color: getRandomColor(10),
  },
];

const TrafficLightMetrics: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    performance: { summary },
    inline,
  } = props;

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        {config.map((c) => (
          <Grid item xs={inline ? 6 : 3} key={c.key}>
            <MetricCard
              title={
                (c.key.includes('roi')
                  ? summary[c.key]
                  : formatCurrency(summary[c.key], 0)) ?? '-'
              }
              subtitle={c.label}
              color={c.color}
              tooltip={c.tooltip}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TrafficLightMetrics;
