import { HandleChangeValue } from 'hooks';
import CheckboxFilter from './CheckboxFilter';
import SelectFilter from './SelectFilter';
import SwitchFilter from './SwitchFilter';
import UsersSearchFilter from './UsersSearchFilter';
import DataTableProps from '../DataTableProps';
import DateFilter from './DateFilter';
import RangeFilter from './RangeFilter';
import InputFilter from './InputFilter';
import { SelectOptions } from '../../../types/common';

interface Props {
  filter: DataTableProps.Filter<Record<string, unknown>>;
  inline?: boolean;
  value: any;
  onChange: (key: string, value: HandleChangeValue<unknown>) => void;
  filtersState: Record<string, any>;
  options?: SelectOptions;
}

const Filter = (props: Props): JSX.Element | null => {
  const { onChange, value, inline, filter, filtersState, options } = props;

  const handleChange = (v: HandleChangeValue<unknown>) => {
    onChange(filter.key, (prev: any) =>
      typeof v === 'function' ? v(prev) : v,
    );
  };

  return (
    <>
      {filter.type === 'checkbox' && (
        <CheckboxFilter
          inline={inline}
          label={filter.name}
          value={value}
          options={
            typeof filter.options === 'function'
              ? filter.options(filtersState)
              : filter.options
          }
          onChange={handleChange}
        />
      )}
      {filter.type === 'select' && (
        <SelectFilter
          inline={inline}
          multiple={filter.multiple}
          label={filter.name}
          options={
            typeof filter.options === 'function'
              ? filter.options(filtersState)
              : filter.options
          }
          value={filter.multiple ? value || [] : value || ''}
          onChange={handleChange}
        />
      )}
      {filter.type === 'switch' && (
        <SwitchFilter
          inline={inline}
          label={filter.name}
          value={value}
          onChange={handleChange}
        />
      )}
      {filter.type === 'search' && (
        <UsersSearchFilter
          inline={inline}
          label={filter.name}
          queryFn={filter.queryFn}
          queryKey={filter.queryKey}
          value={value}
          onChange={handleChange}
          options={options}
          defaultOption={filter.defaultOption}
        />
      )}
      {filter.type === 'date' && (
        <DateFilter
          inline={inline}
          label={filter.name}
          value={value}
          onChange={handleChange}
        />
      )}
      {filter.type === 'range' && (
        <RangeFilter
          inline={inline}
          label={filter.name}
          value={value}
          onChange={handleChange}
        />
      )}
      {filter.type === 'input' && (
        <InputFilter
          inline={inline}
          label={filter.name}
          value={value}
          onChange={handleChange}
          TextFieldProps={filter.TextFieldProps}
        />
      )}
    </>
  );
};

export default Filter;
