import {
  ID,
  ListPaginationParams,
  ListPaginationResponse,
} from '../types/common';
import { InfluencerTypes } from '../influencers';
import { CountriesEnum } from '../types/Countries';
import { BrandsEnum } from '../types/Brands';
import { DealTypes } from '../deals';

namespace NegotiationTypes {
  export enum StatusEnum {
    approved = 'Approved',
    pending = 'Pending',
    contacted = 'Contacted',
    parked = 'Parked',
    renegotiatePrice = 'Renegotiate Price',
    waitingForInfo = 'Wait for more info',
    declined = 'Declined',
  }

  export interface LogItem {
    total_budget: number;
    total_budget_lc: number;
    date: string;
    source: string;
    posts_nb: number;
    decision?: 'Approved' | 'Declined';
  }

  export interface ItemCreateParams {
    shop_id: string;
    influencer_id: string;
    lead: string;
    brand: BrandsEnum;
    country_team: CountriesEnum;
    strategy: DealTypes.StrategiesEnum;
    channel: DealTypes.ChannelEnum;
    status: StatusEnum;
  }

  export interface ItemData
    extends Omit<ItemCreateParams, 'influencer_id' | 'shop_id'> {
    _id: ID;
    shop_id: ID | null;
    bookmark_id?: string | null;
    channel_reach_at_creation: number;
    chat_messages?: unknown[];
    created_at: string;
    currency: string;
    influencer_id: ID;
    influencer_profile_name: string;
    is_deleted: null | boolean;
    logs: LogItem[];
    negotiation_id: string;
    updated_at: string;
    influencer?: Pick<
      InfluencerTypes.Item,
      'profile_name' | 'profile_picture_url'
    > &
      Pick<InfluencerTypes.PersonalInfo, 'gender'>;
  }

  export type ItemLogs = Pick<ItemData, 'logs'>;

  export type ItemResponse = Promise<ItemData>;

  export type ListData = ListPaginationResponse<ItemData>;

  export type ListResponse = Promise<ListData>;

  export interface GetListParams extends ListPaginationParams {
    filters: {
      // global
      country_team?: string;
      brand?: string;
      // general
      influencer_id?: string;
      'influencer.profile_name'?: string[];
      strategy?: string[];
      channel?: string[];
      lead?: string[];
      status?: StatusEnum[];
      show_bookmarks?: boolean;
      date?: {
        $gte: string;
        $lte: string;
      };
    };
  }
}

export default NegotiationTypes;
