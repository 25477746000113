import { DealTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import { getCurrentDate } from 'helpers/dayJsFormats';
import { MarketingTypes } from '../../../api/marketing';

const initialModel: MarketingTypes.ItemCreateParams = {
  shop_id: '',
  brand: BrandsEnum.avaAndMay,
  country_team: CountriesEnum.DE,
  start_date: getCurrentDate(),
  coupon_code: '',
  discount_id: '',
  strategy: DealTypes.StrategiesEnum.influencers,
  channel: DealTypes.ChannelInfluencerEnum.InstagramStories,
};

const dataToInitialModel = (
  data: MarketingTypes.ItemData,
): MarketingTypes.ItemCreateParams => ({
  ...initialModel,
  ...data,
  shop_id: data.shop_id.$oid,
});

export { initialModel, dataToInitialModel };
