import apiClient from '../apiClient';
import TimelineTypes from './types';

const path = {
  [TimelineTypes.ModelEnum.Deal]: 'deals',
  [TimelineTypes.ModelEnum.Discount]: 'discounts',
  [TimelineTypes.ModelEnum.Negotiation]: 'negotiations',
  [TimelineTypes.ModelEnum.Influencer]: 'influencers',
};

const endpoints = {
  getList: (type: TimelineTypes.ModelEnum, id: string) =>
    `${path[type]}/${id}/timeline_events`,
  create: (type: TimelineTypes.ModelEnum, id: string) =>
    `${path[type]}/${id}/timeline_comment`,
  update: (type: TimelineTypes.ModelEnum, id: string, commentId: string) =>
    `${path[type]}/${id}/timeline_comment/${commentId}`,
  remove: (type: TimelineTypes.ModelEnum, id: string, commentId: string) =>
    `${path[type]}/${id}/timeline_comment/${commentId}`,
};

const getList = (
  type: TimelineTypes.ModelEnum,
  id: string,
): TimelineTypes.FlatListResponse =>
  apiClient
    .get(endpoints.getList(type, id))
    .then((response) => response.json());

const create = (
  type: TimelineTypes.ModelEnum,
  id: string,
  data: TimelineTypes.ItemCreateParams,
): Promise<unknown> =>
  apiClient
    .post(endpoints.create(type, id), { json: data })
    .then((response) => response.json());

const update = (
  type: TimelineTypes.ModelEnum,
  id: string,
  commentId: string,
  data: TimelineTypes.ItemCreateParams,
): Promise<unknown> =>
  apiClient
    .put(endpoints.update(type, id, commentId), {
      json: data,
    })
    .then((response) => response.json());
const remove = (
  type: TimelineTypes.ModelEnum,
  id: string,
  commentId: string,
): Promise<unknown> =>
  apiClient
    .delete(endpoints.remove(type, id, commentId))
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  create,
  update,
  remove,
};
