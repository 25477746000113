import React, { useEffect, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { BookmarkBorderOutlined, BookmarkOutlined } from '@mui/icons-material';
import { useApiRequest } from 'hooks';
import { bookmarks, BookmarksTypes } from 'api';
import { QueryObserverResult } from '@tanstack/react-query';
import Loader from '../Loader';

interface Props {
  name: string;
  itemId: string;
  bookmarkId: string | null | undefined;
  type: keyof BookmarksTypes.ItemCreateParams;
  inMenu?: boolean;
  refetch: () => Promise<QueryObserverResult>;
  disabled?: boolean;
}

const BookmarkButton = (props: Props): JSX.Element | null => {
  const { bookmarkId, itemId, type, inMenu, name, refetch, disabled } = props;

  const [isActive, setIsActive] = useState(!!bookmarkId);

  const { requestFn: createFn, isLoading: isCreating } = useApiRequest(
    bookmarks.create,
  );

  const { requestFn: removeFn, isLoading: isRemoving } = useApiRequest(
    bookmarks.remove,
  );

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (bookmarkId) {
      await removeFn({
        args: bookmarkId,
        successMessage: 'Bookmark deleted',
      });
    } else {
      await createFn({
        args: {
          [type]: [itemId],
        },
        successMessage: 'Bookmark created',
      });
    }

    setIsActive((p) => !p);

    await refetch();
  };

  useEffect(() => {
    setIsActive(!!bookmarkId);
  }, [bookmarkId]);

  const isLoading = isCreating || isRemoving;

  if (inMenu) {
    return (
      <MenuItem onClick={handleClick} disabled={isLoading || disabled}>
        <ListItemIcon>
          {isLoading && (
            <Loader variant="inline" circularProgressProps={{ size: 16 }} />
          )}
          {!isLoading &&
            (isActive ? <BookmarkOutlined /> : <BookmarkBorderOutlined />)}
        </ListItemIcon>
        <ListItemText primary={name} />
      </MenuItem>
    );
  }

  return (
    <Tooltip title={name}>
      <span>
        <IconButton
          onClick={handleClick}
          size="small"
          disabled={isLoading || disabled}
        >
          {isLoading && (
            <Loader variant="inline" circularProgressProps={{ size: 16 }} />
          )}
          {!isLoading &&
            (isActive ? <BookmarkOutlined /> : <BookmarkBorderOutlined />)}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default BookmarkButton;
