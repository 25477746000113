import React from 'react';
import { Stack } from '@mui/material';
import { TooltipChip, BrandChip, ShopInfo } from 'components';
import { NegotiationTypes } from 'api';
import { getNegotiationStatusColor } from 'helpers/getStatusColor';

interface Props {
  negotiation: NegotiationTypes.ItemData;
}

const InfoRow = (props: Props): JSX.Element => {
  const { negotiation } = props;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap">
      <BrandChip brand={negotiation.brand} country={negotiation.country_team} />
      {negotiation.shop_id ? (
        <ShopInfo shopId={negotiation.shop_id.$oid} />
      ) : (
        <TooltipChip
          title="Shop is missing for current negotiation"
          label="Shop is missing for current negotiation"
          color="error"
        />
      )}
      <TooltipChip
        title="Channel"
        label={negotiation.strategy}
        color={getNegotiationStatusColor(negotiation.status)}
      />
      <TooltipChip
        title="Sub channel"
        label={negotiation.channel}
        color={getNegotiationStatusColor(negotiation.status)}
      />
      <TooltipChip title="Lead" label={negotiation.lead} color="warning" />
    </Stack>
  );
};

export default InfoRow;
