import React, { useMemo, useReducer } from 'react';
import { useFiltersSearchParams } from 'hooks';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import reducer from './context/reducer';
import DataTableProps from './DataTableProps';
import DataTableContainer from './DataTableContainer';
import {
  DataTableContext,
  DataTableState,
  initialDataTableState,
} from './context';

const DataTableProvider = (props: DataTableProps.Props): JSX.Element => {
  const { viewOptions, filters, defaultParams, defaultPerPage } = props;
  const { filtersSearchParams } = useFiltersSearchParams();

  const initialFiltersState = useMemo(
    () =>
      filters?.reduce(
        (prev, current) => ({
          ...prev,
          [current.key]:
            filtersSearchParams[current.key] ||
            (current.required ? current.initialValue : undefined),
        }),
        {},
      ) || {},
    [filters, filtersSearchParams],
  );

  const initialActiveFilters = useMemo(
    () =>
      Object.entries(initialFiltersState)
        .filter(([, value]) =>
          Array.isArray(value) ? value.length !== 0 : value !== undefined,
        )
        .map(([key]) => key),
    [initialFiltersState],
  );

  const emptyFiltersState = useMemo(
    () =>
      filters?.reduce(
        (prev, current) => ({
          ...prev,
          [current.key]: current.required ? current.initialValue : undefined,
        }),
        {},
      ) || {},
    [filters],
  );

  const defaultStateParams = useMemo(() => {
    const defaultSelectedView =
      defaultParams?.selectedViewKey ||
      (viewOptions && viewOptions.length > 0 ? viewOptions[0].key : '');

    const selectedViewKey =
      typeof filtersSearchParams.selectedViewKey === 'string'
        ? filtersSearchParams.selectedViewKey
        : defaultSelectedView;

    const defaultSortBy =
      typeof defaultParams?.sortBy === 'string' ? defaultParams?.sortBy : '';

    const sortBy =
      typeof filtersSearchParams.sortBy === 'string'
        ? filtersSearchParams.sortBy
        : defaultSortBy;

    const defaultSortDesc =
      typeof defaultParams?.sortDesc === 'boolean'
        ? defaultParams.sortDesc
        : initialDataTableState.sortDesc;

    const sortDesc =
      typeof filtersSearchParams.sortDesc === 'boolean'
        ? filtersSearchParams.sortDesc
        : defaultSortDesc;

    const defaultPageNumber =
      typeof defaultParams?.pageNumber === 'number'
        ? defaultParams?.pageNumber
        : 1;

    const pageNumber =
      typeof filtersSearchParams.page === 'number'
        ? filtersSearchParams.page
        : defaultPageNumber;

    const query = Array.isArray(filtersSearchParams.query)
      ? filtersSearchParams.query
      : undefined;

    return {
      selectedViewKey,
      sortBy,
      sortDesc,
      pageNumber,
      query,
    };
  }, [defaultParams, filtersSearchParams, viewOptions]);
  // todo - fix initial value for user search filter
  const initialState = useMemo(() => {
    const perPage = LocalStorage.get(LocalStorageEnum.TABLE_PER_PAGE);
    const isSplitViewEnabled =
      LocalStorage.get(LocalStorageEnum.IS_SPLIT_VIEW_ACTIVE) === 'true';

    const state: DataTableState = {
      ...initialDataTableState,
      ...defaultStateParams,
      initialFiltersState,
      emptyFiltersState,
      filtersState: initialFiltersState,
      activeFilters: initialActiveFilters,
      isSplitViewEnabled,
      perPage:
        defaultPerPage ||
        (perPage !== null ? +perPage : initialDataTableState.perPage),
    };

    return state;
  }, [
    defaultPerPage,
    defaultStateParams,
    emptyFiltersState,
    initialActiveFilters,
    initialFiltersState,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <DataTableContext.Provider value={value}>
      <DataTableContainer {...props} />
    </DataTableContext.Provider>
  );
};

export default DataTableProvider;
