import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  CardContent,
  CardHeader,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import { campaigns, CampaignTypes } from 'api';
import { colors } from 'theme/constants';
import { appLinks } from 'routes/links';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { TooltipChip, ColorCard } from 'components';

interface Props {
  campaign: CampaignTypes.ItemData;
}

type InfoItems = {
  key: keyof CampaignTypes.ItemData;
  label: string;
  prevValue: ReactNode;
  currentValue: ReactNode;
  trend: 'up' | 'down';
}[];

const CampaignComparisonCard = (props: Props): JSX.Element | null => {
  const { campaign } = props;

  const queryParams: CampaignTypes.GetListParams = useMemo(
    () => ({
      sort_by: 'start_date',
      sort_desc: true,
      limit: 2,
      page: 1,
      filters: {
        start_date: { $lte: campaign.start_date },
        brands: campaign.brands,
        country_teams: campaign.country_teams,
        type: campaign.type,
      },
    }),
    [
      campaign.brands,
      campaign.country_teams,
      campaign.start_date,
      campaign.type,
    ],
  );

  const { data, error } = useQuery(
    [campaigns.endpoints.getList, queryParams],
    () =>
      campaigns
        .getList(queryParams)
        .then((res) =>
          res.data.filter((r) => r._id.$oid !== campaign._id.$oid),
        ),
  );

  const prev = useMemo(() => (data ? data[0] : undefined), [data]);

  const getTrend = useCallback(
    (key: keyof CampaignTypes.ItemData) =>
      (campaign[key] || 0) > (prev ? prev[key] : 0) ? 'up' : 'down',
    [campaign, prev],
  );

  const tableFields: InfoItems = useMemo(
    () => [
      {
        key: 'budget_actual',
        label: 'Budget',
        prevValue: formatCurrency(prev?.budget_actual || 0),
        currentValue: formatCurrency(campaign.budget_actual || 0),
        trend: getTrend('budget_actual'),
      },
      {
        key: 'aov_actual',
        label: 'AOV',
        prevValue: formatCurrency(prev?.aov_actual || 0),
        currentValue: formatCurrency(campaign.aov_actual || 0),
        trend: getTrend('aov_actual'),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue',
        prevValue: formatCurrency(prev?.revenue_actual || 0),
        currentValue: formatCurrency(campaign.revenue_actual || 0),
        trend: getTrend('revenue_actual'),
      },
      {
        key: 'deals_nb',
        label: 'Deals',
        prevValue: prev?.deals_nb || 0,
        currentValue: campaign.deals_nb || 0,
        trend: getTrend('deals_nb'),
      },
      {
        key: 'influencers_nb',
        label: 'Influencers',
        prevValue: prev?.influencers_nb || 0,
        currentValue: campaign.influencers_nb || 0,
        trend: getTrend('influencers_nb'),
      },
      {
        key: 'new_customers_actual',
        label: 'New customers',
        prevValue: formatPercent(prev?.new_customers_actual || 0),
        currentValue: formatPercent(campaign.new_customers_actual || 0),
        trend: getTrend('new_customers_actual'),
      },
      {
        key: 'orders_nb_actual',
        label: 'Orders',
        prevValue: prev?.orders_nb_actual || 0,
        currentValue: campaign.orders_nb_actual || 0,
        trend: getTrend('orders_nb_actual'),
      },
      {
        key: 'reach_total',
        label: 'Reach',
        prevValue: prev?.reach_total || 0,
        currentValue: campaign.reach_total || 0,
        trend: getTrend('reach_total'),
      },
    ],
    [
      campaign.aov_actual,
      campaign.budget_actual,
      campaign.deals_nb,
      campaign.influencers_nb,
      campaign.new_customers_actual,
      campaign.orders_nb_actual,
      campaign.reach_total,
      campaign.revenue_actual,
      prev?.aov_actual,
      prev?.budget_actual,
      prev?.deals_nb,
      prev?.influencers_nb,
      prev?.new_customers_actual,
      prev?.orders_nb_actual,
      prev?.reach_total,
      prev?.revenue_actual,
      getTrend,
    ],
  );

  return (
    <ColorCard color={colors.orange}>
      <CardHeader
        title="Campaigns comparison"
        subheader={
          prev ? (
            <Typography variant="inherit">
              Comparison of actual metrics with{' '}
              <Link
                variant="inherit"
                underline="hover"
                href={appLinks.campaigns.details.as(prev?.campaign_id || '')}
                target="_blank"
              >
                {prev?.name}
              </Link>
              <Typography
                variant="inherit"
                color="text.secondary"
                component="span"
                sx={{ ml: 0.5 }}
              >
                (latest campaign)
              </Typography>
            </Typography>
          ) : undefined
        }
      />
      <CardContent>
        <>
          {data && data.length === 0 && (
            <Typography variant="subtitle2">No previous campaigns</Typography>
          )}
          {error && (
            <Typography variant="subtitle1">
              Failed to load latest campaign
            </Typography>
          )}
          {(!data || data.length > 0) && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">Current</TableCell>
                  <TableCell align="right">Previous</TableCell>
                  <TableCell align="right">Trend</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!data &&
                  tableFields.map((row) => (
                    <TableRow
                      key={row.key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton
                          variant="rectangular"
                          sx={(theme) => ({
                            borderRadius: `${theme.shape.borderRadius}px`,
                          })}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {data &&
                  data.length > 0 &&
                  tableFields.map((row) => (
                    <TableRow
                      key={row.key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">
                        <Typography color="text.secondary" variant="inherit">
                          {row.currentValue}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color="text.secondary" variant="inherit">
                          {row.prevValue}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <TooltipChip
                          title="Trend"
                          color={row.trend === 'up' ? 'success' : 'warning'}
                          sx={{
                            '& span': {
                              display: 'flex',
                              padding: 1,
                            },
                          }}
                          label={
                            row.trend === 'up' ? (
                              <TrendingUpRounded />
                            ) : (
                              <TrendingDownRounded />
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </>
      </CardContent>
    </ColorCard>
  );
};

export default CampaignComparisonCard;
