import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { influencers, InfluencerTypes } from 'api';
import { DealsChartOption, InfluencerChartOption } from 'types/influencers';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import { getChartOptions } from 'helpers/chartHelpers';
import ComparisonChart from '../ComparisonChart';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const DealsChart = (props: Props): JSX.Element | null => {
  const { data: influencerData } = props;

  const influencerId = useMemo(() => influencerData._id.$oid, [influencerData]);

  const [chartDataType, setChartDataType] =
    useState<DealsChartOption['type']>('roi');

  const { data, isLoading } = useQuery(
    [influencers.endpoints.getDealsChartById(influencerId), chartDataType],
    () => influencers.getDealsChartById(influencerId, chartDataType),
  );

  const chartData = useMemo(() => {
    if (data) {
      return getChartOptions({
        data: data[chartDataType],
        avgData: data.global_avg_details,
      });
    }

    return [];
  }, [chartDataType, data]);

  const tabs = useMemo(
    () =>
      [
        {
          type: 'roi',
          label: 'Return of investment (ROI)',
          value: formatNumber(influencerData.roi),
        },
        {
          type: 'budget',
          label: 'Average budget',
          value: formatCurrency(influencerData.avg_budget),
        },
        {
          type: 'revenue',
          label: 'Revenue per deal',
          value: formatCurrency(influencerData.avg_revenue),
        },
        {
          type: 'cpo',
          label: 'Cost per order (CPO)',
          value: formatCurrency(influencerData.cpo),
        },
        {
          type: 'cpm',
          label: 'Cost per 1000 views (CPM)',
          value: formatCurrency(influencerData.avg_cpm),
        },
        {
          type: 'cr',
          label: 'Conversion rate',
          value: formatPercent(influencerData.avg_cr),
        },
      ] as DealsChartOption[],
    [influencerData],
  );

  return (
    <ComparisonChart
      isLoading={isLoading}
      chartData={chartData}
      tabs={tabs}
      chartDataType={chartDataType}
      setChartDataType={
        setChartDataType as React.Dispatch<
          React.SetStateAction<InfluencerChartOption['type']>
        >
      }
    />
  );
};

export default DealsChart;
