import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { DiscountOutlined } from '@mui/icons-material';
import { FormCard, Loader } from 'components';
import { MarketingTypes } from 'api/marketing';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import CouponCodeInput from './CouponCodeInput';
import { discounts } from '../../../../api';
import DetailsTab from '../../../Discounts/Details/components/DetailsTab';

interface Props {
  control: Control<MarketingTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<MarketingTypes.ItemCreateParams>;
  setValue: UseFormSetValue<MarketingTypes.ItemCreateParams>;
}

const DiscountBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const discountId = watch('discount_id');

  const { data, error } = useQuery(
    [discounts.endpoints.getById(discountId!)],
    () => discounts.getById(discountId!),
    {
      enabled: !!discountId,
      cacheTime: 0,
    },
  );

  return (
    <FormCard title="Discount details" avatar={<DiscountOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CouponCodeInput
            control={control}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        {discountId && (
          <Grid item xs={12}>
            {error ? (
              <Typography sx={{ color: (t) => t.palette.error.main }} />
            ) : (
              <span>
                {!data ? (
                  <Loader variant="centered" height="200px" />
                ) : (
                  <DetailsTab discount={data} />
                )}
              </span>
            )}
          </Grid>
        )}
      </Grid>
    </FormCard>
  );
};

export default DiscountBlock;
