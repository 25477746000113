import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Legend,
  Brush,
} from 'recharts';
import { alpha, Box, useTheme } from '@mui/material';
import { formatShortNumber } from 'helpers/formatters';
import { getShortMonthYear } from 'helpers/dayJsFormats';

interface Props {
  variant: 'bar' | 'line';
  data: any[];
  height: number;
}

const Chart = (props: Props): JSX.Element | null => {
  const { data, variant, height } = props;
  const theme = useTheme();

  const { endIndex, startIndex } = useMemo(() => {
    if (data.length > 12) {
      const startIndex = data.length - 12 - (12 - new Date().getMonth());
      const endIndex = data.length - (12 - new Date().getMonth());

      return {
        startIndex,
        endIndex,
      };
    }

    return {
      startIndex: undefined,
      endIndex: undefined,
    };
  }, [data]);

  const renderQuarterTick = (tickProps: any): any => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;

    const item = data.find((d) => d.date === value);

    const isLast = item ? item.monthNumber === 0 : false;

    if (isLast) {
      const pathX = Math.floor(x + offset) + 0.5;

      return (
        <>
          <path
            d={`M${pathX},${y + 4}v${-42}`}
            stroke={theme.palette.error.main}
            strokeWidth={1.5}
          />
          <text x={pathX + 8} y={y + 4}>
            {item.year}
          </text>
        </>
      );
    }
    return null;
  };

  if (variant === 'bar') {
    return (
      <Box width="100%" height={height}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 32,
              right: 16,
              left: -16,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              height={30}
              dataKey="date"
              interval={0}
              tickFormatter={(tick) =>
                data.find((d) => d.date === tick)?.monthShortname || tick
              }
            />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderQuarterTick}
              height={20}
              xAxisId="year"
            />
            <YAxis
              tickFormatter={(tick) =>
                formatShortNumber(
                  data.find((d) => d.date === tick)?.value || tick,
                )
              }
            />
            <Tooltip />
            <Legend
              iconType="circle"
              verticalAlign="top"
              align="center"
              height={36}
            />
            <Brush
              dataKey="date"
              startIndex={startIndex}
              endIndex={endIndex}
              stroke={theme.palette.primary.main}
              fill={theme.palette.background.paper}
              alwaysShowText
              tickFormatter={getShortMonthYear}
            />
            <Bar
              name="Value"
              dataKey="value"
              stackId="1"
              fill={theme.palette.primary.main}
              radius={[
                +theme.shape.borderRadius,
                +theme.shape.borderRadius,
                0,
                0,
              ]}
            />
            <Bar
              name="Avg. value"
              dataKey="avgValue"
              stackId="2"
              fill={theme.palette.secondary.main}
              radius={[
                +theme.shape.borderRadius,
                +theme.shape.borderRadius,
                0,
                0,
              ]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }

  return (
    <Box width="100%" height={`${height}px`}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 32,
            right: 16,
            left: -16,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={30}
            dataKey="date"
            interval={0}
            tickFormatter={(tick) =>
              data.find((d) => d.date === tick)?.monthShortname || tick
            }
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={renderQuarterTick}
            height={20}
            xAxisId="year"
          />
          <YAxis
            tickFormatter={(tick) =>
              formatShortNumber(
                data.find((d) => d.date === tick)?.value || tick,
              )
            }
          />
          <Tooltip />
          <Legend
            iconType="circle"
            verticalAlign="top"
            align="center"
            height={36}
          />
          <Brush
            dataKey="date"
            startIndex={startIndex}
            endIndex={endIndex}
            stroke={theme.palette.primary.main}
            fill={theme.palette.background.paper}
            alwaysShowText
            tickFormatter={getShortMonthYear}
          />
          <Area
            name="Value"
            type="linear"
            dot={{
              stroke: theme.palette.primary.main,
              fill: theme.palette.common.white,
              fillOpacity: 1,
              strokeWidth: 2,
              r: 4,
            }}
            dataKey="value"
            stackId="1"
            strokeWidth={2}
            stroke={theme.palette.primary.main}
            fill={alpha(theme.palette.primary.main, 0.1)}
          />
          <Area
            name="Avg. value"
            type="linear"
            dot={{
              stroke: theme.palette.secondary.main,
              fill: theme.palette.common.white,
              fillOpacity: 1,
              strokeWidth: 2,
              r: 4,
            }}
            dataKey="avgValue"
            stackId="2"
            strokeWidth={2}
            stroke={theme.palette.secondary.main}
            fill={alpha(theme.palette.secondary.main, 0.1)}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Chart;
