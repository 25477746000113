import React, { Fragment, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { InfoCard } from 'components';
import { DealTypes, SalesPlanningTypes } from 'api';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import { getNegativeRatio } from 'helpers/calculations';
import { colors } from 'theme/constants';
import { BrandsEnum } from 'api/types/Brands';
import { ArrowDownTinIcon } from 'components/icons';
import {
  columns,
  budgetDistributionConfig,
  Field,
} from '../../../budgetConfig';

interface Props {
  planning: SalesPlanningTypes.ItemData;
  selectedBrand: BrandsEnum | undefined;
  selectedBrandPercent: number | undefined;
}

const BudgetTable: React.FC<Props> = (props: Props): JSX.Element => {
  const { planning, selectedBrandPercent, selectedBrand } = props;

  const { watch } = useForm<SalesPlanningTypes.ItemData>({
    defaultValues: planning,
  });

  const [openedStrategy, setOpenedStrategy] = useState<
    DealTypes.StrategiesEnum | string | undefined
  >();

  const handleToggleStrategy = (strategy: string) => {
    setOpenedStrategy((prev) => (prev === strategy ? undefined : strategy));
  };

  const getFieldKey = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (subKey && subSubKey) {
        return `budget_distribution.${key}.sub_items.${subKey}.sub_items.${subSubKey}.${field}`;
      }

      if (subKey) {
        return `budget_distribution.${key}.sub_items.${subKey}.${field}`;
      }

      return `budget_distribution.${key}.${field}`;
    },
    [],
  );

  const getFieldValue = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (key) {
        const fieldKey = getFieldKey({ field, key, subKey, subSubKey });
        return watch(
          fieldKey as keyof SalesPlanningTypes.ItemCreateParams,
        ) as number;
      }
      return watch(field);
    },
    [watch, getFieldKey],
  );

  const getCellValue = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (selectedBrand && selectedBrandPercent) {
        if (field === 'revenue_target') {
          const value =
            getFieldValue({
              field,
              key,
              subKey,
              subSubKey,
            }) * selectedBrandPercent;

          return formatCurrency(value);
        }

        return '-';
      }

      const value = getFieldValue({ field, key, subKey, subSubKey });

      if (!value && value !== 0) {
        return '-';
      }

      if (field === 'roi_target') {
        return formatNumber(value);
      }

      return formatCurrency(value);
    },
    [getFieldValue, selectedBrand, selectedBrandPercent],
  );

  const getRevenueRatio = useCallback(
    ({
      key,
      subKey,
      subSubKey,
    }: {
      key: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (subKey && subSubKey) {
        const subRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
        });
        const subSubRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
          subSubKey,
        });
        const ratio = getNegativeRatio({
          value: subSubRevenue,
          total: subRevenue,
        });

        return formatPercent(ratio);
      }

      if (subKey) {
        const revenue = getFieldValue({ field: 'revenue_target', key });
        const subRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
        });

        const ratio = getNegativeRatio({ value: subRevenue, total: revenue });

        return formatPercent(ratio);
      }

      const total = watch('revenue_target');
      const revenue = getFieldValue({ field: 'revenue_target', key });

      const ratio = getNegativeRatio({ value: revenue, total });

      return formatPercent(ratio);
    },
    [getFieldValue, watch],
  );

  const getCM3Ratio = useCallback(
    ({
      key,
      subKey,
      subSubKey,
    }: {
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (selectedBrand && selectedBrandPercent) {
        return '-';
      }

      const total = getFieldValue({
        field: 'revenue_target',
        key,
        subKey,
        subSubKey,
      });
      const cm3 = getFieldValue({
        field: 'cm3_target',
        key,
        subKey,
        subSubKey,
      });

      const ratio = getNegativeRatio({ value: cm3, total });

      return formatPercent(ratio);
    },
    [getFieldValue, selectedBrand, selectedBrandPercent],
  );

  const getIsAllCellsEmpty = useCallback(
    ({
      key,
      subKey,
      subSubKey,
    }: {
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      const emptySigns = [
        '-',
        '0',
        formatCurrency(0),
        formatPercent(0),
        formatNumber(0),
      ];

      return columns.every((field) =>
        emptySigns.includes(getCellValue({ field, key, subKey, subSubKey })),
      );
    },
    [getCellValue],
  );

  return (
    <InfoCard title="Budget distribution" color={colors.darkGreen}>
      <Card variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '4%' }}>
                <IconButton onClick={() => handleToggleStrategy('all')}>
                  <ArrowDownTinIcon
                    sx={{
                      transform:
                        openedStrategy === 'all' ? 'rotate(180deg)' : undefined,
                    }}
                  />
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: '18%' }} />
              <TableCell sx={{ width: '15%' }}>Target budget</TableCell>
              <TableCell sx={{ width: '15%' }}>Target ROI</TableCell>
              <TableCell sx={{ width: '15%' }}>Target revenue</TableCell>
              <TableCell sx={{ width: '15%' }}>Target CM3</TableCell>
              <TableCell sx={{ width: '9%' }}>Target CM3%</TableCell>
              <TableCell sx={{ width: '9%' }}>Revenue ratio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(budgetDistributionConfig).map(
              ([key, value]) =>
                !getIsAllCellsEmpty({ key }) && (
                  <Fragment key={key}>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                        backgroundColor: (theme) =>
                          openedStrategy === key || openedStrategy === 'all'
                            ? theme.palette.primary.light
                            : undefined,
                      }}
                    >
                      <TableCell component="th" sx={{ pr: 0 }}>
                        <IconButton
                          onClick={() => handleToggleStrategy(key)}
                          disabled={!('sub_items' in value && value.sub_items)}
                        >
                          <ArrowDownTinIcon
                            sx={{
                              transform:
                                openedStrategy === key ||
                                openedStrategy === 'all'
                                  ? 'rotate(180deg)'
                                  : undefined,
                            }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      {columns.map((field) => (
                        <TableCell key={field + key}>
                          {getCellValue({ field, key })}
                        </TableCell>
                      ))}
                      <TableCell>{getCM3Ratio({ key })}</TableCell>
                      <TableCell>{getRevenueRatio({ key })}</TableCell>
                    </TableRow>
                    <TableRow sx={{ p: 0 }}>
                      <TableCell colSpan={8} sx={{ p: 0, borderBottom: 0 }}>
                        <Collapse
                          in={
                            openedStrategy === key || openedStrategy === 'all'
                          }
                        >
                          <Table>
                            <TableHead
                              sx={{
                                visibility: 'collapse',
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    width: '4%',
                                    borderBottom: 'unset',
                                    minWidth: '60px',
                                  }}
                                />
                                <TableCell
                                  sx={{ width: '18%', borderBottom: 'unset' }}
                                />
                                <TableCell
                                  sx={{ width: '15%', borderBottom: 'unset' }}
                                >
                                  Target budget
                                </TableCell>
                                <TableCell
                                  sx={{ width: '15%', borderBottom: 'unset' }}
                                >
                                  Target ROI
                                </TableCell>
                                <TableCell
                                  sx={{ width: '15%', borderBottom: 'unset' }}
                                >
                                  Target revenue
                                </TableCell>
                                <TableCell
                                  sx={{ width: '15%', borderBottom: 'unset' }}
                                >
                                  Target CM3
                                </TableCell>
                                <TableCell
                                  sx={{ width: '9%', borderBottom: 'unset' }}
                                >
                                  Target CM3%
                                </TableCell>
                                <TableCell
                                  sx={{ width: '9%', borderBottom: 'unset' }}
                                >
                                  Revenue ratio
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {'sub_items' in value &&
                                value.sub_items &&
                                Object.entries(value.sub_items).map(
                                  ([subKey, subValue]) =>
                                    !getIsAllCellsEmpty({ key, subKey }) && (
                                      <Fragment key={key + subKey}>
                                        <TableRow
                                          sx={
                                            'sub_items' in subValue
                                              ? (theme) => ({
                                                  backgroundColor:
                                                    theme.palette.background
                                                      .default,
                                                })
                                              : undefined
                                          }
                                        >
                                          <TableCell />
                                          <TableCell component="th" scope="row">
                                            {subKey}
                                          </TableCell>
                                          {columns.map((field) => (
                                            <TableCell
                                              key={field + key + subKey}
                                            >
                                              {getCellValue({
                                                field,
                                                key,
                                                subKey,
                                              })}
                                            </TableCell>
                                          ))}
                                          <TableCell>
                                            {getCM3Ratio({ key, subKey })}
                                          </TableCell>
                                          <TableCell>
                                            {getRevenueRatio({ key, subKey })}
                                          </TableCell>
                                        </TableRow>
                                        {'sub_items' in subValue &&
                                          subValue.sub_items &&
                                          Object.entries(
                                            subValue.sub_items,
                                          ).map(
                                            ([subSubKey]) =>
                                              !getIsAllCellsEmpty({
                                                key,
                                                subKey,
                                                subSubKey,
                                              }) && (
                                                <TableRow
                                                  key={key + subKey + subSubKey}
                                                >
                                                  <TableCell />
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {subSubKey}
                                                  </TableCell>
                                                  {columns.map((field) => (
                                                    <TableCell
                                                      key={
                                                        field +
                                                        key +
                                                        subKey +
                                                        subSubKey
                                                      }
                                                    >
                                                      {getCellValue({
                                                        field,
                                                        key,
                                                        subKey,
                                                        subSubKey,
                                                      })}
                                                    </TableCell>
                                                  ))}
                                                  <TableCell>
                                                    {getCM3Ratio({
                                                      key,
                                                      subKey,
                                                      subSubKey,
                                                    })}
                                                  </TableCell>
                                                  <TableCell>
                                                    {getRevenueRatio({
                                                      key,
                                                      subKey,
                                                      subSubKey,
                                                    })}
                                                  </TableCell>
                                                </TableRow>
                                              ),
                                          )}
                                      </Fragment>
                                    ),
                                )}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ),
            )}
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell />
              <TableCell>Total</TableCell>
              <TableCell>{getCellValue({ field: 'budget_target' })}</TableCell>
              <TableCell>{getCellValue({ field: 'roi_target' })}</TableCell>
              <TableCell>{getCellValue({ field: 'revenue_target' })}</TableCell>
              <TableCell>{getCellValue({ field: 'cm3_target' })}</TableCell>
              <TableCell>{getCM3Ratio({})}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </InfoCard>
  );
};

export default BudgetTable;
