import { DealTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';

const initialModel: DealTypes.ItemCreateParams = {
  brand: BrandsEnum.avaAndMay,
  country_team: CountriesEnum.DE,
  budget_type: DealTypes.BudgetTypeEnum.fixedBudget,
  focus: DealTypes.FocusEnum.Sales,
  status: DealTypes.StatusEnum.Pending,
  stories_tag: DealTypes.StoriesTagEnum.AvaMayDE,
  type: DealTypes.TypeEnum.NewDeal,
  strategy: DealTypes.StrategiesEnum.influencers,
  channel: DealTypes.ChannelInfluencerEnum.InstagramStories,
  budget_potential_lc: '' as any, // should be "0" but removed to keep init value empty field
  campaign_ids: [],
  campaigns: [],
  coupon_code: '',
  coupon_description: '',
  currency: 'EUR',
  discount_description: '',
  discount_type: undefined,
  discount_value: '' as any, // should be "0" but removed to keep init value empty field
  end_date: '',
  lead: '',
  reminder_date: '',
  free_gift_product_categories: [],
  free_gift_products: [],
  influencer_id: '',
  product_categories: [],
  products: [],
  revenue_potential_lc: '' as any, // should be "0" but removed to keep init value empty field
  roi_potential: '' as any, // should be "0" but removed to keep init value empty field
  shop_id: '',
  short_url_utm: '',
  start_date: '',
  url_landing: '',
  url_auto_redemption: false,
};

const dataToInitialModel = (
  data: DealTypes.ItemData,
): DealTypes.ItemCreateParams => ({
  influencer_id: data.influencer_id.$oid,
  shop_id: data.shop_id.$oid,

  brand: data.brand,
  channel: data.channel,
  country_team: data.country_team,
  focus: data.focus,
  lead: data.lead,
  start_date: data.start_date,
  status: data.status,
  stories_tag: data.stories_tag,
  strategy: data.strategy,
  type: data.type,

  currency: data.currency,
  budget_potential_lc: data.budget_potential_lc,
  roi_potential: data.roi_potential,

  revenue_potential_lc: data.revenue_potential_lc,
  budget_fixed: data.budget_fixed,
  commission: data.commission,

  budget_type: data.budget_type,
  url_auto_redemption: data.url_auto_redemption,
  end_date: data.end_date,
  reminder_date: data.reminder_date,
  product_categories: data.product_categories || [],
  products: data.products || [],
  url_landing: data.url_landing,
  short_url_utm: data.short_url_utm,
  campaigns: data.campaigns || [],
  campaign_ids: data.campaign_ids?.map((c) => c.$oid) || [],

  coupon_code: data.coupon_code,
  coupon_description: data.coupon_description,
  discount_type: data.discount_type,
  discount_description: data.discount_description,
  discount_value: data.discount_value,
  free_gift_products: data.free_gift_products || [],
  free_gift_product_categories: data.free_gift_product_categories || [],

  actual_reach: data.actual_reach,
  avg_overall_reach: data.avg_overall_reach,
  clicks: data.clicks,
  impressions: data.impressions,
  post: data.post,
});

export { initialModel, dataToInitialModel };
