import { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  alpha,
} from '@mui/material';
import { useStoreContext } from 'store';
import { MenuLink, MenuNavLink } from 'routes/links';
import {
  activeGroupItemCollapsedMixin,
  activeGroupItemMixin,
  dotItemMixin,
} from './mixins';
import { ArrowDownTinIcon } from '../../icons';

interface Props extends Omit<MenuNavLink, 'children'> {
  links: MenuLink[];
}

const NavLinkGroup = (props: Props): JSX.Element => {
  const { link, links, name, Icon } = props;

  const {
    state: { isSideNavOpen },
  } = useStoreContext();
  const location = useLocation();

  const isActiveGroup = useMemo(() => {
    const pathnames = location.pathname.split('/').filter((x) => !!x);

    return pathnames.includes(link.replace('/', ''));
  }, [link, location.pathname]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    if (isActiveGroup) return;
    setIsOpen((prev) => !prev);
  };

  // close group if sidebar was collapsed
  useEffect(() => {
    if (!isSideNavOpen && isOpen) {
      setIsOpen(false);
    }
  }, [isSideNavOpen, isOpen]);

  // open group if sidebar was un collapsed and this group is active
  useEffect(() => {
    if (isSideNavOpen && !isOpen && isActiveGroup) {
      setIsOpen(true);
    }
  }, [isSideNavOpen, isOpen, isActiveGroup]);

  // close if this group is not active anymore
  useEffect(() => {
    if (!isActiveGroup) {
      setIsOpen(false);
    }
  }, [isActiveGroup]);

  return (
    <>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={(theme) => ({
            ...(isSideNavOpen
              ? activeGroupItemMixin(theme, isActiveGroup)
              : activeGroupItemCollapsedMixin(theme, isActiveGroup)),
          })}
          onClick={toggleIsOpen}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isSideNavOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={{ opacity: isSideNavOpen ? 1 : 0 }}
          />
          <ListItemIcon
            sx={{
              opacity: isSideNavOpen ? 1 : 0,
              minWidth: 0,
            }}
          >
            <ArrowDownTinIcon
              sx={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Collapse
        in={isOpen}
        timeout="auto"
        sx={(theme) => ({
          ...(isActiveGroup && {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }),
        })}
      >
        {links.map((link) => (
          <ListItem key={link.link} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              component={NavLink}
              to={link.link}
              sx={dotItemMixin}
            >
              <ListItemText primary={link.name} sx={{ ml: 6 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

export default NavLinkGroup;
