import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { influencers } from 'api';
import { Loader } from 'components';
import { BrandsEnum } from 'api/types/Brands';
import { convertToFixed, getRevenue } from 'helpers/calculations';

interface Props {
  influencerId: string;
  brand: BrandsEnum;
}

const SuggestedBudgetInfo = (props: Props): JSX.Element | null => {
  const { influencerId, brand } = props;

  const { data, error } = useQuery(
    [influencers.endpoints.getPotentialDetailsById(influencerId), influencerId],
    () => influencers.getPotentialDetailsById(influencerId),
    {
      enabled: !!influencerId,
      refetchOnWindowFocus: false,
    },
  );

  const potentialData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    let brandRoi: number;
    let brandBudget: number;

    switch (brand) {
      case BrandsEnum.avaAndMay: {
        brandRoi = data.am_potential_roi;
        brandBudget = data.am_based_avg_budget;
        break;
      }
      case BrandsEnum.faynt: {
        brandRoi = data.faynt_potential_roi;
        brandBudget = data.faynt_based_avg_budget;
        break;
      }
      default: {
        brandRoi = data.cn_potential_roi;
        brandBudget = data.cn_based_avg_budget;
      }
    }

    const roi = convertToFixed(brandRoi);
    const budget = convertToFixed(brandBudget);
    const revenue = getRevenue({ budget, roi });

    return {
      roi,
      budget,
      revenue,
    };
  }, [brand, data]);

  return (
    <>
      <Typography gutterBottom>Suggested values for {brand}</Typography>
      {!data && !error && data !== null && (
        <Loader variant="centered" height="100px" />
      )}
      {(error || data === null) && (
        <Typography color="error">No info</Typography>
      )}
      {potentialData && (
        <>
          <Typography color="text.secondary">
            Suggested ROI {potentialData.roi}
          </Typography>
          <Typography color="text.secondary">
            Suggested budget {potentialData.budget}
          </Typography>
          <Typography color="text.secondary">
            Suggested revenue {potentialData.revenue}
          </Typography>
        </>
      )}
    </>
  );
};

export default SuggestedBudgetInfo;
