interface DataItem {
  label: string;
  values: {
    key: string;
    value: number | null;
  }[];
}

interface ConvertedItem {
  key: string;
  potential: number;
  actual: number;
  forecast: number;
}

function convertDataToItems(data: DataItem[]): ConvertedItem[] {
  const itemsMap = new Map<string, ConvertedItem>();

  data.forEach((dataItem) => {
    const { label } = dataItem;
    dataItem.values.forEach((item) => {
      const dateKey = item.key.split(' ')[0];

      if (!itemsMap.has(dateKey)) {
        itemsMap.set(dateKey, {
          key: dateKey,
          potential: 0,
          actual: 0,
          forecast: 0,
        });
      }

      const existingItem = itemsMap.get(dateKey);

      if (existingItem) {
        if (label.includes('potential')) {
          existingItem.potential = item.value !== null ? item.value : 0;
        } else if (label.includes('actual')) {
          existingItem.actual = item.value !== null ? item.value : 0;
        } else if (label.includes('running_forecast')) {
          existingItem.forecast = item.value !== null ? item.value : 0;
        }
      }
    });
  });

  return Array.from(itemsMap.values());
}

export default convertDataToItems;
