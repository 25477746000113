import { CSSObject, Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';

const dot = {
  content: '" "',
  position: 'absolute',
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  marginLeft: '2px',
};

const activeDotItemMixin = (theme: Theme): CSSObject => ({
  '&:after': {
    ...dot,
    backgroundColor: theme.palette.primary.main,
  },
});

const dotItemMixin = (theme: Theme): CSSObject => ({
  marginLeft: 2,
  '&:after': {
    ...dot,
    backgroundColor: theme.palette.text.primary,
  },
  '&.active': {
    ...activeDotItemMixin(theme),
    color: theme.palette.primary.main,
  },
  '&:hover, &:focus': {
    ...activeDotItemMixin(theme),
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
});

const activeItemMixin = (theme: Theme): CSSObject => ({
  color: theme.palette.primary.main,
  backgroundColor: alpha(
    theme.palette.primary.main,
    theme.palette.mode === 'dark' ? 0.3 : 0.1,
  ),
  '&:after': {
    content: '" "',
    position: 'absolute',
    width: '4px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    marginLeft: '-16px',
  },
});

const activeLinkItemMixin = (theme: Theme): CSSObject => ({
  py: 0.25,
  '& .MuiListItemIcon-root': { color: 'inherit', padding: 1 },
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.active': {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.mode === 'dark' ? 0.3 : 0.1,
    ),
    '& .MuiListItemIcon-root': { color: 'inherit' },
    ...activeItemMixin(theme),
  },
});

const activeLinkItemCollapsedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: 'transparent',
  py: 0.25,
  justifyContent: 'center',
  '& .MuiListItemIcon-root': {
    color: 'inherit',
    padding: 1,
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '&.active': {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: 'inherit',
      background: alpha(
        theme.palette.primary.main,
        theme.palette.mode === 'dark' ? 0.3 : 0.1,
      ),
      padding: 1,
      borderRadius: `${theme.shape.borderRadius}px`,
    },
  },
  '&:hover, &:focus': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: 'inherit',
      background: alpha(
        theme.palette.primary.main,
        theme.palette.mode === 'dark' ? 0.3 : 0.1,
      ),
      padding: 1,
      borderRadius: `${theme.shape.borderRadius}px`,
    },
  },
});

const activeGroupItemMixin = (theme: Theme, isActive: boolean): CSSObject => ({
  py: 0.25,
  '& .MuiListItemIcon-root': { color: 'inherit', padding: 1 },
  ...(isActive && {
    ...activeItemMixin(theme),
  }),
  '&:hover, &:focus': {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': { color: 'inherit', padding: 1 },
    backgroundColor: isActive
      ? alpha(
          theme.palette.primary.main,
          theme.palette.mode === 'dark' ? 0.3 : 0.1,
        )
      : 'transparent',
  },
});

const activeGroupItemCollapsedMixin = (
  theme: Theme,
  isActive: boolean,
): CSSObject => ({
  backgroundColor: 'transparent',
  py: 0.25,
  justifyContent: 'center',
  '&.Mui-focusVisible': {
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
    padding: 1,
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  ...(isActive && {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: 'inherit',
      background: alpha(
        theme.palette.primary.main,
        theme.palette.mode === 'dark' ? 0.3 : 0.1,
      ),
      padding: 1,
      borderRadius: `${theme.shape.borderRadius}px`,
    },
  }),
  '&:hover, &:focus': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: 'inherit',
      background: alpha(
        theme.palette.primary.main,
        theme.palette.mode === 'dark' ? 0.3 : 0.1,
      ),
      padding: 1,
      borderRadius: `${theme.shape.borderRadius}px`,
    },
  },
});

export {
  dotItemMixin,
  activeItemMixin,
  activeLinkItemMixin,
  activeDotItemMixin,
  activeLinkItemCollapsedMixin,
  activeGroupItemMixin,
  activeGroupItemCollapsedMixin,
};
