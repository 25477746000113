import { Stack } from '@mui/material';
import { InfluencerTypes } from 'api';
import Mentions from './Mentions';
import Stats from './Stats';
import InsightsChart from './InsightsChart';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const InsightsTab = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Stack spacing={3}>
      <InsightsChart data={data} />
      <Stats data={data} />
      <Mentions data={data} />
    </Stack>
  );
};

export default InsightsTab;
