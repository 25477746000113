/* eslint-disable no-param-reassign */

import React, { useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export interface TextInputProps
  extends Omit<
    TextFieldProps,
    'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur' | 'defaultValue'
  > {
  onChange?: () => void;
}

type Props<T extends FieldValues = any> = TextInputProps &
  UseControllerProps<T>;

const TextInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    onChange,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value ? +event.target.value : 0;
      field.onChange(value);

      if (onChange) {
        onChange();
      }

      // trick to avoid issue that keeps input value & field value different ex. field === 0 and input === 000
      event.target.value = value as unknown as string;

      // trick to avoid issue that keeps moves cursor to start after deleting chars after comma
      // @ts-ignore
      event.target.type = 'text';
      event.target.setSelectionRange(-1, -1);
      // @ts-ignore
      event.target.type = 'number';
    },
    [field, onChange],
  );

  return (
    <TextField
      {...rest}
      placeholder={
        rest.placeholder ||
        (typeof rest.label === 'string'
          ? `Enter ${rest.label.toLowerCase()}`
          : undefined)
      }
      variant="outlined"
      type="number"
      inputRef={field.ref}
      name={field.name}
      value={field.value ?? ''}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={!!error}
      helperText={error && error.message}
      InputProps={{
        ...rest.InputProps,
        notched: false,
        inputProps: {
          min: rules?.min,
          ...rest.InputProps?.inputProps,
        },
      }}
    />
  );
};

export default TextInput;
