import React, { useMemo, useState } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import {
  AUDIENCE_COUNTRY_CODES,
  AUDIENCE_GENDER_KEYS,
  AUDIENCE_POPULATION_KEYS,
} from 'constants/common';
import { InfluencerTypes } from 'api';

const options = {
  gender: AUDIENCE_GENDER_KEYS,
  age_group: AUDIENCE_POPULATION_KEYS,
  country: AUDIENCE_COUNTRY_CODES,
};

interface Props {
  type: InfluencerTypes.AudienceInfoItem['type'];
  label: string;
  value?: InfluencerTypes.AudienceInfoCreateParams;
  onAdd: (value: InfluencerTypes.AudienceInfoCreateParams) => void;
  onRemove: (key: string) => void;
}

const KeyValueInput = (props: Props): JSX.Element | null => {
  const { type, value, label, onRemove, onAdd } = props;

  const [selectValue, setSelectValue] = useState('');
  const [count, setCount] = useState<string | number>('');

  const handleAdd = () => {
    const newValue: InfluencerTypes.AudienceInfoCreateParams = {
      [selectValue]: {
        value_percent: +count,
        type,
      },
    };

    onAdd(newValue);
    setSelectValue('');
    setCount('');
  };

  const values = useMemo(
    () =>
      !value
        ? []
        : Object.entries(value)
            .map(([key, el]) => ({ ...el, key }))
            .filter((el) => el.type === type),
    [type, value],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" columnGap={1}>
          <TextField
            select
            value={selectValue || 'empty'}
            onChange={(e) => setSelectValue(e.target.value)}
            SelectProps={{
              MenuProps: {
                sx: {
                  maxHeight: '50vh',
                },
              },
            }}
          >
            <MenuItem value="empty" disabled>
              Key
            </MenuItem>
            {options[type].map((o) => (
              <MenuItem
                key={o}
                value={o}
                disabled={value ? Object.keys(value).includes(o) : false}
              >
                {o}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="number"
            placeholder="Count"
            value={count}
            onChange={(e) => setCount(e.target.value ? +e.target.value : '')}
          />
          <IconButton onClick={handleAdd}>
            <Add />
          </IconButton>
        </Stack>
      </Grid>
      {values.length > 0 && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {values.map((item) => (
        <Grid item xs={12} key={item.key}>
          <Stack direction="row" columnGap={1}>
            <TextField disabled value={item.key} />
            <TextField disabled value={item.value_percent} />
            <IconButton color="error" onClick={() => onRemove(item.key)}>
              <Remove />
            </IconButton>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default KeyValueInput;
