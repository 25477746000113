import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';

const CommentsLoadingList = (): JSX.Element | null => (
  <List disablePadding sx={{ px: 0.75 }}>
    {Array.from(new Array(5))
      .map((_, i) => i)
      .map((item) => (
        <ListItem disablePadding key={item}>
          <Divider orientation="vertical" flexItem>
            <Skeleton
              variant="circular"
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </Divider>
          <ListItemText sx={{ py: 2, ml: 2 }}>
            <Typography variant="body2" gutterBottom>
              <Skeleton width="50%" />
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Skeleton width="70%" />
            </Typography>
            <Typography variant="caption" color="text.secondary">
              <Skeleton width="30%" />
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
  </List>
);

export default CommentsLoadingList;
