import { InfluencerTypes } from 'api';
import React, { useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandMore, SvgIconComponent } from '@mui/icons-material';
import { useStoreContext } from 'store';
import { formatNumber } from 'helpers/formatters';
import StatsBlock from './StatsBlock';
import { getStatsFormattedValue, StatGroupIcon, statsFields } from './fields';

interface Props {
  data: InfluencerTypes.ItemExtended;
}

const Stats = (props: Props): JSX.Element | null => {
  const { data } = props;
  const {
    state: { activeSocialPlatform },
  } = useStoreContext();

  const stats = useMemo(() => {
    const activePlatform =
      activeSocialPlatform || InfluencerTypes.PlatformEnum.Instagram;
    const platform =
      activePlatform.toLowerCase() as InfluencerTypes.PlatformType;
    const postKey: InfluencerTypes.PlatformPostType = `${platform}_post`;

    const keys: (
      | keyof Pick<
          InfluencerTypes.ItemExtended,
          'instagram_story' | 'instagram_reel' | 'instagram_tv'
        >
      | InfluencerTypes.PlatformPostType
    )[] = [];

    if (platform === 'instagram') {
      keys.push(
        'instagram_post',
        'instagram_story',
        'instagram_reel',
        'instagram_tv',
      );
    } else {
      keys.push(postKey);
    }

    return keys.map((key) => {
      const post = data[key];

      const dailyKPI = [
        {
          key: 'mentions_nb',
          label: 'Mentions',
          value: formatNumber(post?.daily_kpis_7d_avg?.mentions_nb),
        },
        {
          key: 'sequences_nb',
          label: 'Sequences',
          value: formatNumber(post?.daily_kpis_7d_avg?.sequences_nb),
        },
        {
          key: 'sequences_with_video',
          label: 'With video',
          value: formatNumber(post?.daily_kpis_7d_avg?.sequences_with_video),
        },
        {
          key: 'sequences_with_sound',
          label: 'With sound',
          value: formatNumber(post?.daily_kpis_7d_avg?.sequences_with_sound),
        },
      ];

      const fields = !post
        ? []
        : statsFields.map((field) => ({
            ...field,
            value: getStatsFormattedValue(post[field.key], field.type),
          }));

      return {
        key,
        label: key.replaceAll('_', ' '),
        GroupIcon: StatGroupIcon[key] as SvgIconComponent,
        dailyKPI,
        fields,
      };
    });
  }, [activeSocialPlatform, data]);

  const [expanded, setExpanded] = React.useState<string | false>(
    stats.length > 0 ? stats[0].key : false,
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (stats.length > 0) {
      setExpanded(stats[0].key);
    }
  }, [stats]);

  return (
    <Box>
      {stats.map(({ GroupIcon, ...stat }) => (
        <Accordion
          defaultExpanded
          elevation={0}
          key={stat.key}
          expanded={expanded === stat.key}
          onChange={handleChange(stat.key)}
          sx={(theme) => ({
            borderRadius: `${theme.shape.borderRadius}px`,
            border: `1px solid ${theme.palette.divider}`,
            '&:before': {
              content: 'unset',
            },
            '&:not(:last-child)': {
              mb: 2,
            },
          })}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`${stat.key}-content`}
            id={`${stat.key}-header`}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box
                sx={(theme) => ({
                  display: 'inline-flex',
                  p: 1,
                  backgroundColor: alpha(theme.palette.warning.main, 0.1),
                  color: theme.palette.warning.main,
                  borderRadius: `${theme.shape.borderRadius}px`,
                })}
              >
                <GroupIcon />
              </Box>
              <Typography variant="h6" textTransform="capitalize">
                {stat.label}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} mb={2}>
              {stat.dailyKPI.map((kpi) => (
                <Grid item xs={3} key={kpi.key}>
                  <StatsBlock
                    variant="square"
                    title={kpi.label}
                    value={kpi.value}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              {stat.fields.map((field) => (
                <Grid item xs={4} key={field.key}>
                  <StatsBlock title={field.label} value={field.value} />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Stats;
