import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { InsightsOutlined } from '@mui/icons-material';
import { FormCard, FormInput } from 'components';
import { DealTypes } from 'api';

interface Props {
  control: Control<DealTypes.ItemCreateParams> | undefined;
}

const InfluencerInsightsBlock = (props: Props): JSX.Element | null => {
  const { control } = props;

  return (
    <FormCard title="Influencer insights" avatar={<InsightsOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="actual_reach"
            inputType="number"
            label="Actual reach"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="avg_overall_reach"
            inputType="number"
            label="Avg overall reach"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="impressions"
            inputType="number"
            label="Impressions"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="clicks"
            inputType="number"
            label="Clicks"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default InfluencerInsightsBlock;
