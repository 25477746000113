import {
  Backdrop,
  CircularProgress,
  CircularProgressProps,
  BackdropProps,
  Box,
} from '@mui/material';

interface FullScreenProps {
  variant?: 'fullScreen';
  circularProgressProps?: CircularProgressProps;
  backdropProps?: BackdropProps;
  height?: string;
}

interface CenteredProps {
  variant: 'centered';
  circularProgressProps?: CircularProgressProps;
  backdropProps?: never;
  height?: string;
}

interface InlineProps {
  variant?: 'inline';
  circularProgressProps?: CircularProgressProps;
  backdropProps?: never;
  height?: never;
}

type Props = CenteredProps | FullScreenProps | InlineProps;

const Loader = (props: Props): JSX.Element | null => {
  const {
    circularProgressProps,
    backdropProps,
    variant = 'inline',
    height = 'auto',
  } = props;

  if (variant === 'fullScreen') {
    return (
      <Backdrop
        open
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
        {...backdropProps}
      >
        <CircularProgress {...circularProgressProps} />
      </Backdrop>
    );
  }

  if (variant === 'centered') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height,
        }}
      >
        <CircularProgress {...circularProgressProps} />
      </Box>
    );
  }

  return <CircularProgress {...circularProgressProps} />;
};

export default Loader;
