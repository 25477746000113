import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import { FormCard, FormInput } from 'components';
import INFLUENCERS from 'constants/influencers';
import COUNTRIES from 'constants/countries';

interface Props {
  control: Control<InfluencerTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<InfluencerTypes.ItemCreateParams>;
}

const ProfileBlock = (props: Props): JSX.Element | null => {
  const { control, watch } = props;
  const verticalPrimary = watch('vertical_primary');

  return (
    <FormCard title="Personal information" avatar={<PersonOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="vertical_primary"
            label="Primary vertical"
            options={INFLUENCERS.VERTICALS_OPTIONS}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="verticals_secondary"
            label="Secondary vertical"
            options={INFLUENCERS.VERTICALS_OPTIONS.filter(
              (o) => o.value !== verticalPrimary,
            )}
            disabled={!verticalPrimary}
            SelectProps={{ multiple: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="select"
            name="target_countries"
            label="Target countries"
            options={COUNTRIES.OPTIONS}
            rules={{ required: true }}
            SelectProps={{ multiple: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="autocompleteCreatable"
            name="tags"
            label="Tags"
            multiple
            options={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            inputType="textarea"
            name="notes"
            label="Notes"
            rows={4}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ProfileBlock;
