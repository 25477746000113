import React, { useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { UserTypes } from 'api';
import DetailsTab from './DetailsTab';

const TABS = {
  details: 'Details',
};

interface Props {
  inline?: boolean;
  data: UserTypes.ItemData;
}

const PageTabs = (props: Props): JSX.Element => {
  const { data, inline } = props;

  const [activeTab, setActiveTab] = useState(TABS.details);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <Tab label={TABS.details} value={TABS.details} />
      </Tabs>

      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.details && (
          <DetailsTab user={data} inline={inline} />
        )}
      </Stack>
    </>
  );
};

export default PageTabs;
