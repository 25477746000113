import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStoreContext } from 'store';
import { appLinks } from './links';

interface Props {
  children: JSX.Element;
}

const PrivateRoute = (props: Props): JSX.Element => {
  const { children } = props;
  const {
    state: { user },
  } = useStoreContext();
  const { pathname, search } = useLocation();

  const isAuth = useMemo(() => !!user, [user]);

  if (isAuth) {
    return children;
  }

  return (
    <Navigate
      replace
      to={`${appLinks.signIn.link}?redirectTo=${encodeURI(pathname + search)}`}
    />
  );
};

export default PrivateRoute;
