import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { CommonItem } from 'api/types/common';
import { QueryObserverResult } from '@tanstack/react-query';
import { DataTableProps } from '../DataTable';
import BookmarkButton from './BookmarkButton';
import EditButton from './EditButton';

export type ActionButtonProps<T> = DataTableProps.Action<T>;

interface Props {
  action: DataTableProps.Action<CommonItem>;
  data: CommonItem;
  inMenu?: boolean;
  refetch?: () => Promise<QueryObserverResult>;
  onClose: () => void;
}

const ActionButton = (props: Props) => {
  const {
    data,
    action: {
      href,
      onClick,
      to,
      Icon,
      name,
      disabled,
      BookmarkProps,
      hidden,
      iconColor,
      EditButtonProps,
    },
    inMenu,
    refetch,
    onClose,
  } = props;

  if (hidden) {
    return null;
  }

  if (EditButtonProps) {
    return (
      <EditButton
        inMenu={inMenu}
        name={typeof name === 'string' ? name : name(data)}
        id={
          typeof EditButtonProps.id === 'string'
            ? EditButtonProps.id
            : EditButtonProps.id(data)
        }
        idKey={EditButtonProps.idKey}
        link={EditButtonProps.link}
        inline={EditButtonProps.inline}
      />
    );
  }

  if (BookmarkProps) {
    return (
      <BookmarkButton
        inMenu={inMenu}
        type={BookmarkProps.type}
        name={typeof name === 'string' ? name : name(data)}
        itemId={BookmarkProps.itemId(data)}
        bookmarkId={BookmarkProps.bookmarkId(data)}
        refetch={refetch!}
        disabled={disabled}
      />
    );
  }

  if (inMenu) {
    return (
      <MenuItem
        onClick={(e) => {
          onClose();
          if (onClick) onClick(data, e);
        }}
        {...{
          href: href ? href(data) : undefined,
          // eslint-disable-next-line no-nested-ternary
          component: to ? NavLink : href ? 'a' : undefined,
          to: to ? to(data) : undefined,
          disabled: disabled || (!onClick && !href && !to),
        }}
      >
        <ListItemIcon>
          {Icon && (
            <Icon
              sx={
                iconColor
                  ? (theme) => ({
                      color:
                        iconColor === 'success'
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    })
                  : undefined
              }
            />
          )}
        </ListItemIcon>
        <ListItemText primary={typeof name === 'string' ? name : name(data)} />
      </MenuItem>
    );
  }

  return (
    <Tooltip title={typeof name === 'string' ? name : name(data)}>
      <span>
        <IconButton
          size="small"
          onClick={onClick ? (e) => onClick(data, e) : undefined}
          {...{
            href: href ? href(data) : undefined,
            component: to ? NavLink : undefined,
            to: to ? to(data) : undefined,
            disabled: disabled || (!onClick && !href && !to),
          }}
        >
          {Icon && (
            <Icon
              sx={
                iconColor
                  ? (theme) => ({
                      color:
                        iconColor === 'success'
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    })
                  : undefined
              }
            />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ActionButton;
