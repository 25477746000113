import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormCard, FormInput } from 'components';
import { SalesPlanningTypes } from 'api';
import { AttachMoneyOutlined, PercentOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { getSum } from 'helpers/calculations';

interface Props {
  control: Control<SalesPlanningTypes.ItemCreateParams>;
  watch: UseFormWatch<SalesPlanningTypes.ItemCreateParams>;

  setValue: UseFormSetValue<SalesPlanningTypes.ItemCreateParams>;
  isError: boolean;
}

const BrandBudgetDistribution: React.FC<Props> = (
  props: Props,
): JSX.Element => {
  const { setValue, control, watch, isError } = props;

  const selectedBrands = watch('brands');

  const isValidTotal = () => {
    const dist = watch('brand_revenue_distribution');

    const sum = getSum(Object.values(dist || {}).map((v) => v || 0));

    return sum === 100;
  };

  const showError = isError && !isValidTotal();

  return (
    <FormCard
      title="Revenue distribution"
      avatar={<AttachMoneyOutlined />}
      {...(showError && {
        CardHeaderProps: {
          subheader: 'Sum for all brands should be equal 100%',
          titleTypographyProps: { color: 'error' },
          subheaderTypographyProps: {
            color: 'error',
          },
        },
        CardProps: {
          id: 'brand_revenue_distribution',
          sx: { borderColor: (theme) => theme.palette.error.main },
        },
      })}
    >
      <Grid container spacing={2}>
        {selectedBrands.length === 0 && (
          <Grid item xs={12}>
            <Typography>Select at least one brand</Typography>
          </Grid>
        )}
        {selectedBrands.map((brand) => (
          <Grid item xs={4} key={brand}>
            <FormInput
              control={control}
              name={`brand_revenue_distribution.${brand}`}
              inputType="number"
              label={brand}
              rules={{
                required: true,
                max: 100,
                min: 0,
                onBlur: (e) =>
                  setValue(
                    `brand_revenue_distribution.${brand}` as keyof SalesPlanningTypes.ItemCreateParams,
                    +(+e.target.value).toFixed(2),
                  ),
              }}
              InputProps={{
                endAdornment: <PercentOutlined fontSize="small" />,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormCard>
  );
};

export default BrandBudgetDistribution;
