import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { useFiltersSearchParams } from 'hooks';
import { EditOutlined } from '@mui/icons-material';
import { DrawerType } from '../types/SearchParams';

interface Props {
  id: string;
  idKey: DrawerType;
  link: (id: string) => string;
  inline?: boolean;
  variant?: 'button' | 'icon';
  disabled?: boolean;
}

const EditButton = (props: Props): JSX.Element | null => {
  const { link, id, inline, idKey, variant, disabled } = props;

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const componentProps = useMemo(
    () =>
      inline
        ? {
            onClick: () => {
              updateFiltersSearchParams({
                [idKey]: id,
                drawerMode: 'edit',
              });
            },
          }
        : {
            component: NavLink,
            to: link(id),
          },
    [id, idKey, inline, link, updateFiltersSearchParams],
  );

  return variant === 'button' ? (
    <Button color="primary" {...componentProps} disabled={disabled}>
      Edit
    </Button>
  ) : (
    <IconButton {...componentProps} disabled={disabled}>
      <EditOutlined />
    </IconButton>
  );
};

export default EditButton;
