import { ID } from '../types/common';
import { CountriesEnum } from '../types/Countries';
import { BrandsEnum } from '../types/Brands';

namespace ShopTypes {
  export enum StatusEnum {
    Active = 'active',
    Inactive = 'inactive',
  }

  export interface ItemData {
    _id: ID;
    brand: BrandsEnum;
    country_team: CountriesEnum;
    name: string;
    shop_id: string;
    status: StatusEnum;
  }

  export type FlatListData = ItemData[];

  export type FlatListResponse = Promise<FlatListData>;
}

export default ShopTypes;
