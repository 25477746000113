import apiClient from '../apiClient';
import NotificationsTypes from './types';

const endpoints = {
  getList: 'notifications',
  update: (id: string) => `notifications/${id}`,
};

const getList = (): NotificationsTypes.GetListResponse =>
  apiClient.get(endpoints.getList).then((response) => response.json());

const update = (
  id: string,
  markAsRead: boolean,
): NotificationsTypes.ItemResponse =>
  apiClient
    .patch(endpoints.update(id), { json: { mark_as_read: markAsRead } })
    .then((response) => response.json());

export default {
  endpoints,
  getList,
  update,
};
