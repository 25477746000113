import React from 'react';
import {
  TextField,
  MenuItem,
  ListItemIcon,
  Box,
  ListItemText,
} from '@mui/material';
import { useFiltersSearchParams } from 'hooks';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';
import { setDataTableState } from '../context/actions';

interface Props {
  inline: boolean;
  viewOptions: DataTableProps.ViewOptions;
}

const ViewSelectFilter = (props: Props) => {
  const { viewOptions, inline } = props;
  const {
    state: { selectedViewKey },
    dispatch,
  } = useDataTableContext();
  const { handleFilterChange } = useFiltersSearchParams();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDataTableState({ selectedViewKey: event.target.value }));

    if (!inline) {
      handleFilterChange('selectedViewKey', event.target.value);
    }
  };

  return (
    <TextField
      size="small"
      select
      value={selectedViewKey}
      onChange={handleChange}
      SelectProps={{
        renderValue: (value1) => {
          const option = viewOptions.find((v) => v.key === value1);
          if (!option) return '';
          const { Icon, name } = option;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={name} sx={{ m: 0 }} />
            </Box>
          );
        },
      }}
      // SelectProps={{
      //   renderValue: (value: any) => (
      //     <span>{viewOptions.find((v) => v.key === value)?.name}</span>
      //   ),
      // }}
    >
      {viewOptions.map(({ Icon, key, name }) => (
        <MenuItem key={key} value={key} sx={{ display: 'flex' }}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ViewSelectFilter;
