import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Skeleton,
} from '@mui/material';
import { dummyArray } from 'helpers/common';

const LogsTableLoader = () => {
  const items = dummyArray(10);

  return (
    <TableContainer component={Card}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Modifier</TableCell>
            <TableCell>Changes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item}>
              <TableCell>
                <Skeleton variant="text" width="60%" height="32px" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="70%" height="32px" />
              </TableCell>
              <TableCell width="60%">
                <Skeleton variant="rounded" height="48px" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogsTableLoader;
