import React, { useMemo, useState } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import { SelectOption } from 'types/common';
import FormInputProps from './FormInputProps';
import { ArrowDownTinIcon } from '../../icons';

const filter = createFilterOptions<SelectOption>();

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.AutocompleteCreatableInput,
  'inputType'
> &
  UseControllerProps<T>;

const AutocompleteCreatableInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    options,
    label,
    placeholder,
    multiple,
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const initialValue = useMemo(() => (multiple ? [] : null), [multiple]);

  const [value, setValue] = useState<SelectOption | SelectOption[] | null>(
    field.value,
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string | SelectOption | null | (string | SelectOption)[],
  ) => {
    if (typeof newValue === 'string') return;

    if (!newValue) {
      field.onChange(newValue);
      setValue(newValue);
      return;
    }

    if (Array.isArray(newValue)) {
      const data = newValue.map((v) => {
        if (typeof v === 'string') {
          return {
            label: v,
            value: v,
          };
        }
        return v.label.indexOf('Add') > -1
          ? {
              value: v.value,
              label: v.value,
            }
          : v;
      });
      setValue(data);
      field.onChange(data.map((v) => v.value));
      return;
    }

    const data =
      newValue.label.indexOf('Add') > -1
        ? {
            value: newValue.value,
            label: newValue.value,
          }
        : newValue;

    setValue(data);
    field.onChange(data.value);
  };

  const handleFilterOptions = (
    options: SelectOption[],
    params: FilterOptionsState<SelectOption>,
  ) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((opt) => inputValue === opt.value);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        label: `Add "${inputValue}"`,
      });
    }

    if (value) {
      if (Array.isArray(value)) {
        const selected = (value || []).map((v: { value: any }) => v.value);
        return filtered.filter((f) => !selected.includes(f.value));
      }

      return filtered.filter((f) => f.value !== value.value);
    }

    return filtered;
  };

  return (
    <Autocomplete
      popupIcon={<ArrowDownTinIcon />}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      disableCloseOnSelect={multiple}
      multiple={multiple}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      options={options}
      value={value || initialValue}
      onBlur={field.onBlur}
      filterOptions={handleFilterOptions}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={field.ref}
          name={field.name}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error && error.message}
          InputLabelProps={{
            shrink: true,
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            notched: false,
          }}
        />
      )}
      ChipProps={{
        color: 'primary',
      }}
    />
  );
};

export default AutocompleteCreatableInput;
