import InfluencersDrawer from 'pages/Influencers/Drawer';
import CampaignsDrawer from 'pages/Campaigns/Drawer';
import NegotiationsDrawer from 'pages/Negotiations/Drawer';
import DiscountsDrawer from 'pages/Discounts/Drawer';
import DealsDrawer from 'pages/Deals/Drawer';
import SalesPlanningDrawer from 'pages/SalesPlanning/Drawer';
import UsersDrawer from 'pages/Users/Drawer';
import MarketingPlanForm from 'pages/MarketingPlan/Form';
import { DrawerMode } from 'types/SearchParams';
import { DataTableProps } from '../index';

interface Props {
  type: DataTableProps.DetailsDrawerType;
  id: string;
  drawerMode: DrawerMode;
}

const DetailsRoutes = (props: Props): JSX.Element | null => {
  const { type, id, drawerMode } = props;

  if (type === 'influencerId') {
    return <InfluencersDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'dealId') {
    return <DealsDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'negotiationId') {
    return <NegotiationsDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'campaignId') {
    return <CampaignsDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'discountId') {
    return <DiscountsDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'salesPlanningId') {
    return <SalesPlanningDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'userId') {
    return <UsersDrawer id={id} mode={drawerMode} />;
  }
  if (type === 'marketingPlanId') {
    return <MarketingPlanForm editModalId={id} />;
  }

  return null;
};

export default DetailsRoutes;
