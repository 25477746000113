import React, { ReactNode } from 'react';
import { CardContent, CardHeader, Stack, Typography } from '@mui/material';
import ColorCard from 'components/Cards/ColorCard';

export interface InfoCardItem<T = any> {
  key: keyof T | string;
  label: string;
  value: ReactNode;
}

interface Props {
  title: ReactNode;
  subheader?: ReactNode;
  children?: ReactNode;
  data?: InfoCardItem[];
  color?: string;
}

const InfoCard = (props: Props): JSX.Element | null => {
  const { data, title, subheader, color, children } = props;

  return (
    <ColorCard color={color}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        {data && (
          <Stack rowGap={1}>
            {data.map((item) => (
              <Stack
                direction="row"
                columnGap={2}
                key={item.label}
                flexWrap="wrap"
              >
                <Typography variant="body2" flexGrow={1}>
                  {item.label}
                </Typography>
                <Typography color="text.secondary">
                  {item.value || '-'}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {children}
      </CardContent>
    </ColorCard>
  );
};

export default InfoCard;
