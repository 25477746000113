import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { DealTypes } from 'api';
import DEALS from 'constants/deals';
import BRANDS from 'constants/brands';
import { colors } from 'theme/constants';
import { formatCurrency } from 'helpers/formatters';
import PieChartCard from './PieChartCard';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

const BrandEconomics = (props: Props): JSX.Element | null => {
  const { deal } = props;

  const chartsData = useMemo(() => {
    const getData = (
      itemKey:
        | 'brand_product_cm3'
        | 'brand_product_quantity'
        | 'brand_product_revenue',
    ) =>
      deal[itemKey].map((p) => {
        const [key, value] = Object.entries(p)[0];
        const brand =
          DEALS.BRAND_PRODUCT_MAPPING[
            key as unknown as DealTypes.BrandProductEnum
          ];

        return {
          name: brand,
          value: value < 0 ? value * -1 : value,
          isNegative: value < 0,
          actualValue: value,
          formattedValue:
            itemKey === 'brand_product_quantity'
              ? value
              : formatCurrency(value),
          color: BRANDS.LIST[brand].color,
        };
      });

    return {
      cm3: getData('brand_product_cm3'),
      quantity: getData('brand_product_quantity'),
      revenue: getData('brand_product_revenue'),
    };
  }, [deal]);

  return (
    <Stack direction="row" gap={3}>
      <PieChartCard
        title="Revenue"
        color={colors.blue}
        data={chartsData.revenue}
      />
      <PieChartCard
        title="Quantity"
        color={colors.darkOrange}
        data={chartsData.quantity}
      />
      <PieChartCard title="CM3" color={colors.green2} data={chartsData.cm3} />
    </Stack>
  );
};

export default BrandEconomics;
