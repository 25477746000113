import { SnackbarNotificationData } from 'types/common';
import { UserTypes } from 'api';
import { StoreActionEnums, StoreActions, StoreStateData } from './types';

const toggleIsSideNavOpen = (): StoreActions => ({
  type: StoreActionEnums.TOGGLE_IS_SIDENAV_OPEN,
});

const setUserData = (data: UserTypes.ItemData | undefined): StoreActions => ({
  type: StoreActionEnums.SET_USER_DATA,
  payload: data,
});

const setSnackbarNotification = (
  data: SnackbarNotificationData | undefined,
): StoreActions => ({
  type: StoreActionEnums.SET_SNACKBAR_NOTIFICATION,
  payload: data,
});

const setStoreState = (data: Partial<StoreStateData>): StoreActions => ({
  type: StoreActionEnums.SET_STORE_STATE,
  payload: data,
});

export {
  toggleIsSideNavOpen,
  setUserData,
  setSnackbarNotification,
  setStoreState,
};
