import { DealTypes } from 'api';
import StrategiesEnum = DealTypes.StrategiesEnum;
import ChannelPerformanceMarketingEnum = DealTypes.ChannelPerformanceMarketingEnum;
import ChannelSamplingEnum = DealTypes.ChannelSamplingEnum;

type Fields = 'budget_target' | 'roi_target' | 'revenue_target' | 'cm3_target';

type SubItems<E extends string = string> = {
  [key in E]: {
    disabled: Fields[];
    sub_items?: {
      [key: string]: {
        disabled: Fields[];
      };
    };
  };
};

type Config<E extends string = string> = {
  [key in DealTypes.StrategiesEnum]: {
    disabled: Fields[];
    sub_items?: SubItems<E>;
  };
};

const INFLUENCERS_DIST: Config<
  Exclude<
    DealTypes.TypeEnum,
    DealTypes.TypeEnum.NonInfluencer | DealTypes.TypeEnum.UGC
  >
>[DealTypes.StrategiesEnum.influencers] = {
  disabled: ['budget_target', 'roi_target', 'revenue_target'],
  sub_items: {
    [DealTypes.TypeEnum.NewDeal]: {
      disabled: ['budget_target', 'roi_target', 'revenue_target', 'cm3_target'],
      sub_items: {
        Instagram: { disabled: ['revenue_target', 'cm3_target'] },
        Youtube: { disabled: ['revenue_target', 'cm3_target'] },
        Tiktok: { disabled: ['revenue_target', 'cm3_target'] },
      },
    },
    [DealTypes.TypeEnum.LongTerm]: {
      disabled: ['budget_target', 'roi_target', 'revenue_target', 'cm3_target'],
      sub_items: {
        Instagram: { disabled: ['revenue_target', 'cm3_target'] },
        Youtube: { disabled: ['revenue_target', 'cm3_target'] },
        Tiktok: { disabled: ['revenue_target', 'cm3_target'] },
      },
    },
    [DealTypes.TypeEnum.ReTest]: {
      disabled: ['budget_target', 'roi_target', 'revenue_target', 'cm3_target'],
      sub_items: {
        Instagram: { disabled: ['revenue_target', 'cm3_target'] },
        Youtube: { disabled: ['revenue_target', 'cm3_target'] },
        Tiktok: { disabled: ['revenue_target', 'cm3_target'] },
      },
    },
  },
};

const PERFORMANCE_MARKETING_DIST: Config<ChannelPerformanceMarketingEnum>[DealTypes.StrategiesEnum.performanceMarketing] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [ChannelPerformanceMarketingEnum.FacebookAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.GoogleAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.MicrosoftAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.YoutubeAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.TikTokAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.PinterestAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelPerformanceMarketingEnum.SnapchatAds]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
    },
  };

const CRM_DIST: Config<DealTypes.ChannelCRMEnum>[DealTypes.StrategiesEnum.CRM] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [DealTypes.ChannelCRMEnum.Newsletter]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.LifecycleAutomation]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.Reactivation]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.LoyaltyProgram]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.Community]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.Flyer]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.ScentCards]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.OfflineMailing]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelCRMEnum.WebPushNotifications]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
    },
  };

const WEBSITE_DIST: Config<DealTypes.ChannelWebsiteEnum>[DealTypes.StrategiesEnum.website] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    // @ts-ignore
    sub_items: {
      [DealTypes.ChannelWebsiteEnum.AnnouncementBar]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      // [DealTypes.ChannelWebsiteEnum.NewsletterSubscription]: {
      //   disabled: ['budget_target', 'roi_target', 'cm3_target'],
      // },
      // [DealTypes.ChannelWebsiteEnum.NewProductSubscription]: {
      //   disabled: ['budget_target', 'roi_target', 'cm3_target'],
      // },
      // [DealTypes.ChannelWebsiteEnum.BackInStockSubscription]: {
      //   disabled: ['budget_target', 'roi_target', 'cm3_target'],
      // },
      // [DealTypes.ChannelWebsiteEnum.WishlistSubscriptions]: {
      //   disabled: ['budget_target', 'roi_target', 'cm3_target'],
      // },
    },
  };

// todo - remove subchannel
const AFFILIATE_DIST: Config<DealTypes.ChannelAffiliateEnum>[DealTypes.StrategiesEnum.affiliate] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    // sub_items: {
    //   [DealTypes.ChannelAffiliateEnum.Affiliate]: {
    //     disabled: ['revenue_target', 'cm3_target'],
    //   },
    // },
  };

const INTERNAL_DIST: Config<DealTypes.ChannelInternalEnum>[DealTypes.StrategiesEnum.internal] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    // sub_items: {
    //   [ChannelInternalEnum.InstagramBio]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.InstagramStories]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.InstagramReels]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.InstagramPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.TiktokPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.PinterestPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.FacebookStories]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.SnapchatPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.YoutubePost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.InstagramTVLive]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.FacebookPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.Bambuser]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.InstagramGuide]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    //   [ChannelInternalEnum.TelegramPost]: {
    //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
    //   },
    // },
  };

const HR_DIST: Config<DealTypes.ChannelHrEnum>[DealTypes.StrategiesEnum.HR] = {
  disabled: ['budget_target', 'roi_target', 'revenue_target'],
  // sub_items: {
  //   [ChannelHrEnum.Employees]: {
  //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
  //   },
  //   [ChannelHrEnum.FamilyFriends]: {
  //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
  //   },
  //   [ChannelHrEnum.Investors]: {
  //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
  //   },
  //   [ChannelHrEnum.Partners]: {
  //     disabled: ['budget_target', 'roi_target', 'cm3_target'],
  //   },
  // },
};

const SAMPLING_DIST: Config<DealTypes.ChannelSamplingEnum>[DealTypes.StrategiesEnum.sampling] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [ChannelSamplingEnum.Sachets]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [ChannelSamplingEnum.TeaBags]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [ChannelSamplingEnum.VialCards]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [ChannelSamplingEnum.ScratchCards]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
    },
  };

const ORGANIC_DIST: Config<DealTypes.ChannelOrganicEnum>[DealTypes.StrategiesEnum.organic] =
  {
    disabled: ['budget_target', 'roi_target'],
  };

const CUSTOMERS_SERVICE_DIST: Config<DealTypes.ChannelCustomersServiceEnum>[DealTypes.StrategiesEnum.customerService] =
  {
    disabled: ['budget_target', 'roi_target'],
  };

const B2B_DIST: Config<DealTypes.ChannelB2BEnum>[DealTypes.StrategiesEnum.B2B] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [DealTypes.ChannelB2BEnum.Retailers]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.CorporateGifting]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.RetailCampaign]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.BenefitsPrograms]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.FlyerPlacements]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.MarketingActivation]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.BrandCollaboration]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.Dropshipping]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelB2BEnum.Others]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
    },
  };

const PR_DIST: Config<DealTypes.ChannelPrEnum>[DealTypes.StrategiesEnum.PR] = {
  disabled: ['budget_target', 'roi_target', 'revenue_target'],
  // sub_items: {
  //   [DealTypes.ChannelPrEnum.MagazineOffline]: {
  //     disabled: ['revenue_target', 'cm3_target'],
  //   },
  //   [DealTypes.ChannelPrEnum.MagazineOnline]: {
  //     disabled: ['revenue_target', 'cm3_target'],
  //   },
  // },
};

const LOCAL_PROMOTIONS_DIST: Config<DealTypes.ChannelLocalPromoEnum>[DealTypes.StrategiesEnum.localPromotion] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [DealTypes.ChannelLocalPromoEnum.OutOfHome]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelLocalPromoEnum.PopUpStores]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
    },
  };

const MARKETPLACE_DIST: Config<DealTypes.ChannelMarketplaceEnum>[DealTypes.StrategiesEnum.marketplace] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [DealTypes.ChannelMarketplaceEnum.Amazon]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
    },
  };

const CO_CREATION_DIST: Config<DealTypes.ChannelCoCreation>[DealTypes.StrategiesEnum.coCreation] =
  {
    disabled: ['budget_target', 'roi_target', 'revenue_target'],
    sub_items: {
      [DealTypes.ChannelCoCreation.UsageRights]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.InternalExpenses]: {
        disabled: ['revenue_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.ProductCommission]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.InstagramStories]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.InstagramBio]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.InstagramPost]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.InstagramTVLive]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.YoutubePost]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
      [DealTypes.ChannelCoCreation.TiktokPost]: {
        disabled: ['budget_target', 'roi_target', 'cm3_target'],
      },
    },
  };

const budgetDistributionConfig: Config = {
  [StrategiesEnum.influencers]: INFLUENCERS_DIST,
  [StrategiesEnum.performanceMarketing]: PERFORMANCE_MARKETING_DIST,
  [StrategiesEnum.affiliate]: AFFILIATE_DIST,
  [StrategiesEnum.CRM]: CRM_DIST,
  [StrategiesEnum.internal]: INTERNAL_DIST,
  [StrategiesEnum.website]: WEBSITE_DIST,
  [StrategiesEnum.organic]: ORGANIC_DIST,
  [StrategiesEnum.B2B]: B2B_DIST,
  [StrategiesEnum.customerService]: CUSTOMERS_SERVICE_DIST,
  [StrategiesEnum.HR]: HR_DIST,
  [StrategiesEnum.PR]: PR_DIST,
  [StrategiesEnum.sampling]: SAMPLING_DIST,
  [StrategiesEnum.localPromotion]: LOCAL_PROMOTIONS_DIST,
  [StrategiesEnum.marketplace]: MARKETPLACE_DIST,
  [StrategiesEnum.coCreation]: CO_CREATION_DIST,
};

const labels = {
  budget_target: 'Target budget',
  roi_target: 'Target ROI',
  revenue_target: 'Target revenue',
  cm3_target: 'Target CM3',
};

export type Field =
  | 'budget_target'
  | 'roi_target'
  | 'revenue_target'
  | 'cm3_target';

const columns: Field[] = [
  'budget_target',
  'roi_target',
  'revenue_target',
  'cm3_target',
];

export { budgetDistributionConfig, columns, labels };
