import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { SalesPlanningTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { formatPercent } from 'helpers/formatters';
import { getBrandColor } from 'helpers/getBrandColor';
import BRANDS from 'constants/brands';
import { TooltipChip, InfoCard } from 'components';
import { colors } from 'theme/constants';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
  activeBrand: BrandsEnum | undefined;
  setActiveBrand: React.Dispatch<React.SetStateAction<BrandsEnum | undefined>>;
}

const BrandRevenueBlock = (props: Props): JSX.Element | null => {
  const { planning, setActiveBrand, activeBrand, inline } = props;

  const getBrandPercent = (brand: BrandsEnum) =>
    formatPercent(
      planning.brand_revenue_distribution
        ? planning.brand_revenue_distribution[brand]
        : 0,
    );

  return (
    <InfoCard title="Brand revenue distribution" color={colors.orange}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={inline ? 8 : 4}>
          <TextField
            select
            disabled={
              planning.brands.length <= 1 &&
              Object.keys(planning.brand_revenue_distribution || {}).length <= 1
            }
            value={activeBrand || 'empty'}
            onChange={(e) =>
              setActiveBrand(
                (e.target.value !== 'empty'
                  ? e.target.value
                  : undefined) as typeof activeBrand,
              )
            }
            SelectProps={{
              renderValue: activeBrand ? () => activeBrand : undefined,
              endAdornment: !activeBrand ? undefined : (
                <InputAdornment position="end" sx={{ mr: 2 }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setActiveBrand(undefined);
                    }}
                  >
                    <CloseOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="empty">All brands</MenuItem>
            {planning.brands.map((brand) => (
              <MenuItem key={brand} value={brand}>
                <ListItemText>{brand}</ListItemText>
                <ListItemIcon>
                  <Typography color="text.secondary">
                    {formatPercent(
                      planning.brand_revenue_distribution
                        ? planning.brand_revenue_distribution[brand]
                        : 0,
                    )}
                  </Typography>
                </ListItemIcon>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            {Object.values(BRANDS.ACTIVE_LIST).map((brand) => (
              <Grid item key={brand.name}>
                <TooltipChip
                  title={`${brand.name} ${getBrandPercent(brand.name)}`}
                  variant="outlined"
                  color={getBrandColor(brand.name)}
                  label={
                    <Stack direction="row" columnGap={1}>
                      <Box
                        component="img"
                        sx={(theme) => ({
                          width: theme.spacing(1.5),
                          height: 'auto',
                        })}
                        src={brand.image}
                        alt={brand.name}
                      />
                      <Typography fontSize="inherit" color="inherit">
                        {brand.name}
                      </Typography>
                      <Typography
                        fontSize="inherit"
                        color="inherit"
                        fontWeight={500}
                      >
                        {getBrandPercent(brand.name)}
                      </Typography>
                    </Stack>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

export default BrandRevenueBlock;
