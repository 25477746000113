import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Stack } from '@mui/material';

const SkeletonCard = (): JSX.Element | null => (
  <Card>
    <CardHeader
      avatar={
        <Skeleton variant="circular" sx={{ width: '40px', height: '40px' }} />
      }
      title={<Skeleton variant="text" width="70%" />}
      subheader={<Skeleton variant="text" width="30%" />}
    />
    <Skeleton variant="rectangular" width="100%" height="350px" />
    <CardContent>
      <Stack>
        <Skeleton variant="text" height="32px" width="100%" />
        <Skeleton variant="text" height="32px" width="100%" />
        <Skeleton variant="text" height="32px" width="100%" />
        <Stack gap={1} direction="row">
          {Array.from(new Array(3))
            .map((_, i) => i)
            .map((tag) => (
              <Skeleton variant="text" height="30px" width="36px" key={tag} />
            ))}
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default SkeletonCard;
