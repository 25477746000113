import {
  QueryClientProvider,
  QueryClient as ReactQueryClient,
} from '@tanstack/react-query';

const queryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount) => failureCount < 3,
    },
  },
});

interface Props {
  children: JSX.Element;
}

const QueryClient = (props: Props): JSX.Element | null => {
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClient;
