import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CampaignOutlined, GppBadOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { campaigns, deals, DealTypes } from 'api';
import { AutocompleteSearchItem } from 'api/types/common';
import { useDataTableContext } from '../../context';
import { selectAllRows } from '../../context/actions';
import { ConfirmDialog } from '../../../ConfirmDialogs';
import { FormInput } from '../../../Form';

interface Props {
  type?: 'assign' | 'remove';
  refetch: () => Promise<QueryObserverResult>;
}

const CampaignAssign = (props: Props): JSX.Element | null => {
  const { refetch, type = 'assign' } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { control, handleSubmit } = useForm<DealTypes.BulkCampaignAssignParams>(
    {
      defaultValues: {
        deal_ids: [],
        campaign_ids: [],
      },
    },
  );

  const { requestFn, isLoading } = useApiRequest(deals.bulkCampaignAssign);

  const handleChangeStatus = async (
    data: DealTypes.BulkCampaignAssignParams,
  ) => {
    const dealIds = selectedItems.map((s) => s.deal_id);

    await requestFn({
      args: {
        deal_ids: dealIds,
        campaign_ids: data.campaign_ids,
        remove_campaigns: type !== 'assign',
      },
      successMessage: `${dealIds.length} deals campaigns ${
        type === 'assign' ? 'added' : 'removed'
      }`,
    });

    await refetch();
    dispatch(selectAllRows([]));
  };

  const handleChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && Array.isArray(value)) {
        onChange(value.map((v) => v._id.$oid));
      }
    },
    [],
  );

  return (
    <ConfirmDialog
      name={`${selectedItems.length} deals`}
      buttonType="buttonGroup"
      onSubmit={handleSubmit(handleChangeStatus)}
      isLoading={isLoading}
      disabled={selectedItems.length === 0}
      icon={type === 'assign' ? <CampaignOutlined /> : <GppBadOutlined />}
      title={`${type === 'assign' ? 'Add' : 'Remove'} campaigns`}
      submitButtonText={`${type === 'assign' ? 'Add' : 'Remove'} campaigns`}
      buttonColor={type === 'assign' ? 'primary' : 'error'}
    >
      <Typography paragraph variant="subtitle1">{`Are You sure You want to ${
        type === 'assign' ? 'add' : 'remove'
      } ${selectedItems.length} deals campaigns?`}</Typography>
      <Box minWidth="calc(600px - 48px)">
        <FormInput
          rules={{ required: true }}
          control={control}
          name="campaign_ids"
          inputType="autocompleteSearch"
          placeholder="Campaigns"
          multiple
          queryFn={(q) => campaigns.autocompleteSearch({ name: q })}
          queryKey={campaigns.endpoints.autocompleteSearch}
          onChange={handleChange}
        />
      </Box>
    </ConfirmDialog>
  );
};

export default CampaignAssign;
