import React from 'react';
import {
  Box,
  Button,
  Badge,
  List,
  Popover,
  ListItem,
  ListItemText,
} from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { notifications } from 'api';
import NotificationItem from './NotificationItem';
import { useStoreContext } from '../../../../store';

const Notifications: React.FC = () => {
  const {
    state: { user },
  } = useStoreContext();
  const { data, refetch } = useQuery(
    [notifications.endpoints.getList],
    notifications.getList,
    { enabled: !!user },
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <Button
        variant="text"
        sx={(theme) => ({
          padding: theme.spacing(1, 1.5),
          backgroundColor:
            theme.palette.mode === 'dark'
              ? undefined
              : theme.palette.primary.light,
          color: theme.palette.text.primary,
          minHeight: '48px',
          minWidth: 'unset',
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? undefined
                : theme.palette.primary.light,
          },
        })}
      >
        <Badge badgeContent={data?.unread_count} color="primary">
          <NotificationsNoneOutlined />
        </Badge>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          position: 'inherit',
        }}
        PaperProps={{
          sx: (theme) => ({
            position: 'fixed',
            maxHeight: theme.spacing(45),
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }),
        }}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {!data || data.notifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="No notifications available" />
            </ListItem>
          ) : (
            data.notifications.map((n) => (
              <NotificationItem
                item={n}
                key={n.notification_id}
                refetch={refetch}
              />
            ))
          )}
        </List>
      </Popover>
    </Box>
  );
};

export default Notifications;
