import React, { useMemo } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import getSocialNetworkLink from 'helpers/getSocialNetworkLink';
import INFLUENCERS from 'constants/influencers';
import { InfluencerTypes } from 'api';

interface Props {
  data: InfluencerTypes.ItemData;
}

const OtherPlatformsBlock = (props: Props): JSX.Element | null => {
  const { data } = props;

  const platforms = useMemo(
    () =>
      data.platform_types?.map((code) => ({
        code,
        link: getSocialNetworkLink(code, data),
        Icon: INFLUENCERS.PLATFORM_ICONS_COLOR[code],
      })),
    [data],
  );

  if (!platforms || platforms.length === 0) {
    return null;
  }

  return (
    <Stack justifyContent="center" alignItems="center" rowGap={0.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        {platforms.map(({ link, Icon, code }) =>
          link ? (
            <Link
              key={code}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex',
              }}
            >
              <Icon fontSize="small" />
            </Link>
          ) : (
            <Icon key={code} fontSize="small" color="disabled" />
          ),
        )}
      </Stack>
      <Typography variant="body2" color="text.secondary">
        Profiles
      </Typography>
    </Stack>
  );
};

export default OtherPlatformsBlock;
