import { Avatar, avatarClasses, AvatarGroup } from '@mui/material';
import { CountriesEnum } from 'api/types/Countries';
import COUNTRIES from 'constants/countries';
import { LanguageRounded } from '@mui/icons-material';
import DataTableProps from '../../DataTableProps';

interface Props {
  cell: DataTableProps.CountryCell &
    DataTableProps.DefaultCell<DataTableProps.Item>;
  data: DataTableProps.Item;
}

const CountryCell = (props: Props): JSX.Element => {
  const { data, cell } = props;

  if (cell.multiple) {
    return data[cell.key]?.length === 0 ? (
      <Avatar
        sx={(theme) => ({
          width: 24,
          height: 20,
          borderRadius: theme.spacing(0.5),
        })}
      >
        <LanguageRounded fontSize="small" />
      </Avatar>
    ) : (
      <AvatarGroup
        sx={{
          display: 'inline-flex',
          [`& .${avatarClasses.root}`]: {
            border: 'unset',
            marginLeft: '4px',
          },
        }}
      >
        {data[cell.key]?.map((code: CountriesEnum) => (
          <Avatar
            key={code}
            src={COUNTRIES.LIST[code]?.image}
            sx={(theme) => ({
              width: 24,
              height: 20,
              borderRadius: theme.spacing(0.5),
            })}
          />
        ))}
      </AvatarGroup>
    );
  }

  return (
    <Avatar
      src={COUNTRIES.LIST[data[cell.key] as CountriesEnum]?.image}
      sx={(theme) => ({
        width: 24,
        height: 20,
        borderRadius: theme.spacing(0.5),
      })}
    >
      <LanguageRounded fontSize="small" />
    </Avatar>
  );
};

export default CountryCell;
