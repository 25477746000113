import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

const useClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    async (text: string) => {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar({
        variant: 'success',
        message: 'Copied to clipboard',
      });
    },
    [enqueueSnackbar],
  );
};

export default useClipboard;
