import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  CardContent,
  CardHeader,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import { deals, DealTypes } from 'api';
import { useStoreContext } from 'store';
import { colors } from 'theme/constants';
import { appLinks } from 'routes/links';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { TooltipChip, ColorCard } from 'components';

interface Props {
  deal: DealTypes.ItemDataExtended;
}

type InfoItems = {
  key: keyof DealTypes.ItemData;
  label: string;
  prevValue: ReactNode;
  currentValue: ReactNode;
  trend: 'up' | 'down';
}[];

const DealComparisonCard = (props: Props): JSX.Element | null => {
  const { deal } = props;
  const {
    state: { activeCountry, activeBrand },
  } = useStoreContext();

  const filtersValues: DealTypes.GetListParams['filters'] = useMemo(
    () => ({
      brand: activeBrand,
      country_team: activeCountry,
      influencer_id: deal.influencer_id.$oid,
      status: [DealTypes.StatusEnum.Posted, DealTypes.StatusEnum.Finished],
    }),
    [activeBrand, activeCountry, deal.influencer_id.$oid],
  );

  const { data, error } = useQuery(
    [deals.endpoints.getList, filtersValues],
    () =>
      deals
        .getList({ filters: filtersValues, limit: 2, page: 1 })
        .then((res) => res.data[1]),
  );

  const getTrend = useCallback(
    (key: keyof DealTypes.ItemData) =>
      (deal[key] || 0) > (data ? data[key] : 0) ? 'up' : 'down',
    [deal, data],
  );

  const tableFields: InfoItems = useMemo(
    () => [
      {
        key: 'budget_actual',
        label: 'Budget',
        prevValue: formatCurrency(data?.budget_actual || 0),
        currentValue: formatCurrency(deal.budget_actual || 0),
        trend: getTrend('budget_actual'),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue',
        prevValue: formatCurrency(data?.revenue_actual || 0),
        currentValue: formatCurrency(deal.revenue_actual || 0),
        trend: getTrend('revenue_actual'),
      },
      {
        key: 'roi_actual',
        label: 'ROI',
        prevValue: data?.roi_actual || 0,
        currentValue: deal.roi_actual || 0,
        trend: getTrend('roi_actual'),
      },
      {
        key: 'cm3_actual',
        label: 'CM3',
        prevValue: formatCurrency(data?.cm3_actual || 0),
        currentValue: formatCurrency(deal.cm3_actual || 0),
        trend: getTrend('cm3_actual'),
      },
      {
        key: 'cm3_actual_pc',
        label: 'CM3%',
        prevValue: formatPercent(data?.cm3_actual_pc || 0),
        currentValue: formatPercent(deal.cm3_actual_pc || 0),
        trend: getTrend('cm3_actual_pc'),
      },
      {
        key: 'cpm_actual',
        label: 'CPM',
        prevValue: data?.cpm_actual || 0,
        currentValue: deal.cpm_actual || 0,
        trend: getTrend('cpm_actual'),
      },
      {
        key: 'cpo_actual',
        label: 'CPO',
        prevValue: data?.cpo_actual || 0,
        currentValue: deal.cpo_actual || 0,
        trend: getTrend('cpo_actual'),
      },
      {
        key: 'gross_profit_actual',
        label: 'Gross profit',
        prevValue: formatCurrency(data?.gross_profit_actual || 0),
        currentValue: formatCurrency(deal.gross_profit_actual || 0),
        trend: getTrend('gross_profit_actual'),
      },
      {
        key: 'avg_basket_actual',
        label: 'Avg basket',
        prevValue: formatCurrency(data?.avg_basket_actual || 0),
        currentValue: formatCurrency(deal.avg_basket_actual || 0),
        trend: getTrend('avg_basket_actual'),
      },
      {
        key: 'new_customers_ratio',
        label: 'NCR',
        prevValue: formatPercent(data?.new_customers_ratio || 0),
        currentValue: formatPercent(deal.new_customers_ratio || 0),
        trend: getTrend('new_customers_ratio'),
      },
      {
        key: 'orders_nb_actual',
        label: 'Orders number',
        prevValue: data?.orders_nb_actual || 0,
        currentValue: deal.orders_nb_actual || 0,
        trend: getTrend('orders_nb_actual'),
      },
    ],
    [
      data?.avg_basket_actual,
      data?.budget_actual,
      data?.cm3_actual,
      data?.cm3_actual_pc,
      data?.cpm_actual,
      data?.cpo_actual,
      data?.gross_profit_actual,
      data?.new_customers_ratio,
      data?.orders_nb_actual,
      data?.revenue_actual,
      data?.roi_actual,
      deal.avg_basket_actual,
      deal.budget_actual,
      deal.cm3_actual,
      deal.cm3_actual_pc,
      deal.cpm_actual,
      deal.cpo_actual,
      deal.gross_profit_actual,
      deal.new_customers_ratio,
      deal.orders_nb_actual,
      deal.revenue_actual,
      deal.roi_actual,
      getTrend,
    ],
  );

  return (
    <ColorCard color={colors.orange}>
      <CardHeader
        title="Deals comparison"
        subheader={
          <Typography variant="inherit">
            Comparison of actual metrics with{' '}
            <Link
              variant="inherit"
              underline="hover"
              href={appLinks.deals.details.as(data?.deal_id || '')}
              target="_blank"
            >
              latest deal
            </Link>
          </Typography>
        }
      />
      <CardContent>
        {error ? (
          <Typography variant="subtitle1">
            Failed to load latest deal
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="right">Current</TableCell>
                <TableCell align="right">Previous</TableCell>
                <TableCell align="right">Trend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data &&
                tableFields.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        variant="rectangular"
                        sx={(theme) => ({
                          borderRadius: `${theme.shape.borderRadius}px`,
                        })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {data &&
                tableFields.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.currentValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.prevValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <TooltipChip
                        title="Trend"
                        color={row.trend === 'up' ? 'success' : 'warning'}
                        sx={{
                          '& span': {
                            display: 'flex',
                            padding: 1,
                          },
                        }}
                        label={
                          row.trend === 'up' ? (
                            <TrendingUpRounded />
                          ) : (
                            <TrendingDownRounded />
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </ColorCard>
  );
};

export default DealComparisonCard;
