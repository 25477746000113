import React, { useCallback, useMemo } from 'react';
import {
  Box,
  BoxProps,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { getInfluencerName } from 'helpers/common';
import { InfluencerTypes } from 'api';
import { useStoreContext } from 'store';
import INFLUENCERS from 'constants/influencers';
import { setStoreState } from 'store/actions';
import { appLinks } from 'routes/links';

interface Props {
  data: InfluencerTypes.ItemData;
  withSocialNetworkSelect?: boolean;
  TypographyProps?: TypographyProps;
  BoxProps?: BoxProps;
  activeNetwork?: InfluencerTypes.PlatformEnum;
  link?: boolean;
}

const InfluencerName = (props: Props): JSX.Element | null => {
  const {
    data,
    BoxProps,
    TypographyProps,
    withSocialNetworkSelect,
    activeNetwork,
    link,
  } = props;
  const {
    state: { activeSocialPlatform },
    dispatch,
  } = useStoreContext();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setStoreState({
          activeSocialPlatform: event.target
            .value as InfluencerTypes.PlatformEnum,
        }),
      );
    },
    [dispatch],
  );
  const {
    data: activeSocialNetworkData,
    Icon,
    ColorIcon,
    selectOptions,
  } = useMemo(() => {
    let platform =
      activeNetwork ||
      activeSocialPlatform ||
      InfluencerTypes.PlatformEnum.Instagram;
    let platformLowercase =
      platform.toLowerCase() as InfluencerTypes.PlatformType;

    if (!data[platformLowercase]) {
      platform = InfluencerTypes.PlatformEnum.Instagram;
      platformLowercase = 'instagram';
    }

    const Icon = INFLUENCERS.PLATFORM_ICONS[platform];
    const ColorIcon = INFLUENCERS.PLATFORM_ICONS_COLOR[platform];

    const selectOptions = data.platform_types?.map((platform) => ({
      label:
        data[platform.toLowerCase() as InfluencerTypes.PlatformType]
          ?.username || 'unknown',
      value: platform,
      Icon: INFLUENCERS.PLATFORM_ICONS_COLOR[platform],
    }));

    return { data: data[platformLowercase], Icon, ColorIcon, selectOptions };
  }, [activeNetwork, activeSocialPlatform, data]);

  return (
    <Box {...BoxProps}>
      {data.is_verified ? (
        <Box display="flex" alignItems="center" columnGap={1}>
          <Typography
            color="text.primary"
            {...TypographyProps}
            {...(link && {
              component: 'a',
              href: appLinks.influencers.details.as(data.influencer_id),
              target: '_blank',
              sx: { textDecoration: 'none' },
            })}
          >
            {getInfluencerName(data) || '-'}
          </Typography>
          <VerifiedIcon color="primary" fontSize="small" />
        </Box>
      ) : (
        <Typography
          color="text.primary"
          {...TypographyProps}
          {...(link && {
            component: 'a',
            href: appLinks.influencers.details.as(data.influencer_id),
            target: '_blank',
            sx: { textDecoration: 'none' },
          })}
        >
          {getInfluencerName(data) || '-'}
        </Typography>
      )}
      {withSocialNetworkSelect && (
        <TextField
          select
          fullWidth={false}
          id="social-networks"
          size="small"
          variant="standard"
          value={activeSocialPlatform || InfluencerTypes.PlatformEnum.Instagram}
          onChange={handleChange}
          inputProps={{
            sx: {
              '&:focus': {
                backgroundColor: 'unset',
              },
            },
          }}
          SelectProps={{
            notched: undefined,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <ColorIcon sx={{ fontSize: '20px' }} />
              </InputAdornment>
            ),
            renderValue: () => (
              <Typography
                variant="caption"
                color="text.secondary"
                fontSize="14px"
                sx={{ ml: -1, mr: 1 }}
              >
                {activeSocialNetworkData?.username || 'unknown'}
              </Typography>
            ),
          }}
        >
          {selectOptions?.map(({ Icon, ...option }) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ display: 'flex' }}
            >
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </TextField>
      )}

      {!withSocialNetworkSelect && activeSocialNetworkData?.username && (
        <Box display="flex" alignItems="center" columnGap={1}>
          <Icon sx={{ fontSize: '20px', color: 'text.secondary' }} />
          <Typography variant="caption" color="text.secondary" fontSize="14px">
            {activeSocialNetworkData?.username}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default InfluencerName;
