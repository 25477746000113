import {
  alpha,
  Box,
  lighten,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  data: {
    label: string;
    value: number;
    count: number;
  }[];
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 44,
  width: '100%',
  borderRadius: `${theme.shape.borderRadius}px`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.paper, 0.05)
        : theme.palette.grey['100'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.dark
        : alpha(theme.palette.secondary.main, 0.3),
  },
}));

const MentionsBlock = (props: Props): JSX.Element | null => {
  const { data } = props;

  return (
    <Box py={2}>
      {data.length === 0 ? (
        <Box py={4}>
          <Typography textAlign="center" variant="subtitle1">
            No data found
          </Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          {data.map((v) => (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
              key={v.label}
            >
              <Box
                sx={{
                  position: 'absolute',
                  p: 2,
                  zIndex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Typography>#{v.label}</Typography>
                <Typography>{v.count}</Typography>
              </Box>
              <BorderLinearProgress variant="determinate" value={v.value} />
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default MentionsBlock;
