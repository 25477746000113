import { HandleChangeValue } from 'hooks';
import { MenuOptions } from 'types/common';
import MultipleFilter from './MultipleFilter';
import SingleFilter from './SingleFilter';

interface Props {
  label: string;
  value: any;
  onChange: (value: HandleChangeValue<unknown>) => void;
  inline?: boolean;
  multiple?: boolean;
  options: MenuOptions;
}

const SelectFilter = (props: Props): JSX.Element | null => {
  const { multiple, ...rest } = props;

  return multiple ? <MultipleFilter {...rest} /> : <SingleFilter {...rest} />;
};

export default SelectFilter;
