import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import { FormCard, FormInput } from 'components';
import INFLUENCERS from 'constants/influencers';
import COUNTRIES from 'constants/countries';

interface Props {
  control: Control<InfluencerTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<InfluencerTypes.ItemCreateParams>;
}

const PersonalBlock = (props: Props): JSX.Element | null => {
  const { control, watch } = props;
  const enableBrandMentions = watch('enable_brand_mentions');

  return (
    <FormCard title="Personal information" avatar={<PersonOutlined />}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="enable_brand_mentions"
            inputType="switch"
            label="Enable brand mention"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="country_for_brand_mentions"
            inputType="select"
            label="Brand mentions country"
            options={COUNTRIES.OPTIONS}
            required={!!enableBrandMentions}
            rules={{ required: !!enableBrandMentions }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.first_name"
            inputType="input"
            label="First name"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.middle_name"
            inputType="input"
            label="Middle name"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.last_name"
            inputType="input"
            label="Last name"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.gender"
            inputType="select"
            label="Gender"
            options={INFLUENCERS.GENDER_OPTIONS}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.age_group"
            inputType="select"
            label="Age group"
            options={INFLUENCERS.AGE_OPTIONS}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.birth_date"
            inputType="datepicker"
            label="Birth date"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.email"
            inputType="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.phone"
            inputType="tel"
            label="Phone"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.private_address"
            inputType="input"
            label="Private address"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="personal_info.shipping_address"
            inputType="input"
            label="Shipping address"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default PersonalBlock;
