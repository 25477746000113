import React, { ReactNode } from 'react';
import { Chip, ChipProps, Tooltip } from '@mui/material';

interface Props extends Omit<ChipProps, 'title'> {
  title?: ReactNode;
}

const TooltipChip = (props: Props): JSX.Element | null => {
  const { title, ...rest } = props;

  if (title) {
    return (
      <Tooltip title={title}>
        <Chip variant="outlined" {...rest} />
      </Tooltip>
    );
  }

  return <Chip variant="outlined" {...rest} />;
};

export default TooltipChip;
