import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type HandleChangeValue<T> = T | ((prev: T) => T);

type HandleChangeFn<T> = <Key extends keyof T>(
  key: Key,
  value: HandleChangeValue<T[Key]>,
) => void;

const useModel = <T>(
  initialModel: T,
): [T, HandleChangeFn<T>, Dispatch<SetStateAction<T>>] => {
  const [model, setModel] = useState<T>(initialModel);

  const handleChange = useCallback(
    <Key extends keyof T>(key: Key, value: HandleChangeValue<T[Key]>) => {
      setModel((prevState) => ({
        ...prevState,
        [key]:
          typeof value === 'function' && value instanceof Function
            ? value(prevState[key])
            : value,
      }));
    },
    [],
  );

  return [model, handleChange, setModel];
};

export type { HandleChangeFn, HandleChangeValue };

export default useModel;
