import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import {
  CheckCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from '@mui/icons-material';
import { ConfirmDialogControlled } from 'components';
import { discounts, DiscountTypes } from 'api';
import { useApiRequest } from 'hooks';

interface Props {
  discount: DiscountTypes.ItemData;
  refetch: () => Promise<QueryObserverResult>;
}

const ChangeStatusButton = (props: Props): JSX.Element => {
  const { discount, refetch } = props;

  const { isLoading, requestFn } = useApiRequest((data) =>
    discounts.update(discount._id.$oid, data),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleDelete = useCallback(async () => {
    const date = dayjs()
      .set('minutes', dayjs().get('minutes') - 5)
      .toISOString();

    let updateParams: Partial<DiscountTypes.ItemCreateParams>;

    if (discount.shopify_status === 'SCHEDULED') {
      updateParams = {
        start_date: date,
        end_date: date,
      };
    } else if (discount.shopify_status === 'EXPIRED') {
      updateParams = {
        end_date: null,
      };
    } else {
      updateParams = {
        end_date: date,
      };
    }

    await requestFn({
      args: updateParams,
      successMessage: `Discount ${
        discount.shopify_status === 'EXPIRED' ? 'Activated' : 'Deactivated'
      }`,
    });
    await refetch();
    setIsDeleteDialogOpen(false);
  }, [discount.shopify_status, refetch, requestFn]);

  return (
    <>
      <Button
        color="primary"
        onClick={handleOpenDialog}
        startIcon={
          discount.shopify_status === 'EXPIRED' ? (
            <CheckCircleOutlineOutlined />
          ) : (
            <RemoveCircleOutlineOutlined />
          )
        }
      >
        {discount.shopify_status === 'EXPIRED' ? 'Activate' : 'Deactivate'}
      </Button>
      <ConfirmDialogControlled
        isLoading={isLoading}
        name="discount"
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleDelete}
        submitButtonText={
          discount.shopify_status === 'EXPIRED' ? 'Activate' : 'Deactivate'
        }
        message={`Are You sure You want to ${
          discount.shopify_status === 'EXPIRED' ? 'activate' : 'deactivate'
        } discount?`}
      />
    </>
  );
};

export default ChangeStatusButton;
