import {
  Card,
  CardContent,
  CardProps,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { InfoOutlined } from '@mui/icons-material';

const LineCard = styled(Card)(({ theme, color }) => ({
  position: 'relative',
  '&:after': {
    content: '" "',
    position: 'absolute',
    top: 0,
    left: '0%',
    width: '5px',
    height: '100%',
    backgroundColor: color || theme.palette.primary.main,
  },
}));

export interface MetricCardProps {
  title: React.ReactNode;
  subtitle: string;
  tooltip?: string;
  color?: string;
  CardProps?: CardProps;
}
const MetricCard = (props: MetricCardProps): JSX.Element | null => {
  const { subtitle, title, color, CardProps, tooltip } = props;

  return (
    <LineCard color={color} {...CardProps}>
      <CardContent sx={{ position: 'relative' }}>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {subtitle}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoOutlined
              sx={(theme) => ({
                position: 'absolute',
                right: '8px',
                top: '8px',
                fontSize: '1rem',
                color: theme.palette.text.secondary,
              })}
            />
          </Tooltip>
        )}
      </CardContent>
    </LineCard>
  );
};

export default MetricCard;
