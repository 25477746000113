import React from 'react';
import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { InfluencerTypes } from 'api';
import { FormCard, FormInput } from 'components';

interface Props {
  control: Control<InfluencerTypes.ItemCreateParams> | undefined;
}

const AgencyBlock = (props: Props): JSX.Element | null => {
  const { control } = props;

  return (
    <FormCard title="Personal information" avatar={<PersonOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="input"
            name="agency_contact_info.agency_name"
            label="Agency name"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="input"
            name="agency_contact_info.contact_name"
            label="Agency contact name"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="email"
            name="agency_contact_info.email"
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="tel"
            name="agency_contact_info.phone"
            label="Phone"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default AgencyBlock;
