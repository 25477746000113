import { SvgIcon, SvgIconProps } from '@mui/material';

const TikTokColorIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.67371 10.2945C6.40005 10.2945 5.37108 11.3235 5.3899 12.5846C5.40245 13.394 5.84792 14.0967 6.50357 14.4888C6.28084 14.1469 6.14908 13.7422 6.14281 13.3061C6.12398 12.045 7.15296 11.016 8.42662 11.016C8.67759 11.016 8.91914 11.0568 9.14502 11.129V8.60361C8.90973 8.5691 8.66818 8.55028 8.42662 8.55028C8.41407 8.55028 8.40466 8.55028 8.39211 8.55028V10.4074C8.16624 10.3353 7.92468 10.2945 7.67371 10.2945Z"
      fill="#F00044"
    />
    <path
      d="M13.1792 2.72153H13.1603H12.5078C12.696 3.6658 13.2262 4.48772 13.9666 5.04613C13.4803 4.40302 13.1854 3.59992 13.1792 2.72153Z"
      fill="#F00044"
    />
    <path
      d="M17.0189 6.56448C16.7711 6.56448 16.5327 6.53939 16.2974 6.49547V8.30558C15.4441 8.30558 14.6159 8.13931 13.8379 7.80678C13.336 7.59345 12.8685 7.32053 12.4388 6.99113L12.4513 12.572C12.445 13.8269 11.9494 15.0033 11.0522 15.8911C10.3212 16.6126 9.39577 17.0738 8.3919 17.2212C8.15661 17.2557 7.91506 17.2746 7.6735 17.2746C6.60061 17.2746 5.57791 16.9263 4.74658 16.2832C4.8407 16.3962 4.94108 16.506 5.05088 16.6126C5.95437 17.5036 7.15274 17.9961 8.42954 17.9961C8.6711 17.9961 8.91266 17.9773 9.14794 17.9428C10.1518 17.7953 11.0773 17.3342 11.8082 16.6126C12.7054 15.7248 13.2011 14.5484 13.2074 13.2936L13.1603 7.71266C13.5869 8.04206 14.0544 8.31813 14.5594 8.52831C15.3406 8.85771 16.1688 9.02711 17.0189 9.02711"
      fill="#F00044"
    />
    <path
      d="M5.0413 9.94001C5.93538 9.05221 7.12434 8.55968 8.39173 8.55027V7.88207C8.15645 7.84756 7.91489 7.82874 7.67333 7.82874C6.39339 7.82874 5.19188 8.32126 4.2884 9.21847C3.4006 10.1 2.89239 11.3046 2.89552 12.5564C2.89552 13.8175 3.39432 15.0002 4.29467 15.8942C4.43898 16.0354 4.58642 16.1672 4.74328 16.2864C4.03429 15.4425 3.64843 14.3884 3.64529 13.2779C3.64843 12.0262 4.1535 10.8215 5.0413 9.94001Z"
      fill="#08FFF9"
    />
    <path
      d="M16.2975 6.49547V5.82413H16.2912C15.4191 5.82413 14.616 5.53552 13.9697 5.04613C14.5313 5.78648 15.3532 6.31352 16.2975 6.49547Z"
      fill="#08FFF9"
    />
    <path
      d="M8.29784 15.5272C8.59587 15.5429 8.88134 15.5021 9.14486 15.4174C10.0546 15.1194 10.7103 14.2724 10.7103 13.2748L10.7134 9.5416V2.72153H12.5078C12.4608 2.48625 12.4357 2.24783 12.4325 2H9.96051V8.81693L9.95737 12.5501C9.95737 13.5477 9.30171 14.3947 8.39195 14.6927C8.12844 14.7806 7.84296 14.8213 7.54494 14.8025C7.16535 14.7806 6.81085 14.6676 6.50342 14.4857C6.88928 15.0817 7.54807 15.4864 8.29784 15.5272Z"
      fill="#08FFF9"
    />
    <path
      d="M8.39175 17.2212C9.39563 17.0738 10.3211 16.6126 11.052 15.8911C11.9492 15.0033 12.4449 13.8269 12.4512 12.572L12.4386 6.99113C12.8653 7.32053 13.3327 7.59659 13.8378 7.80678C14.6189 8.13618 15.4471 8.30558 16.2973 8.30558V6.49547C15.353 6.31352 14.5311 5.78649 13.9695 5.04613C13.2292 4.48772 12.6959 3.6658 12.5108 2.72153H10.7132V9.53846L10.7101 13.2716C10.7101 14.2692 10.0544 15.1162 9.14466 15.4143C8.88114 15.5021 8.59566 15.5429 8.29764 15.5241C7.54473 15.4833 6.88908 15.0786 6.50322 14.4857C5.84756 14.0967 5.40209 13.3908 5.38954 12.5815C5.37072 11.3203 6.39969 10.2914 7.67336 10.2914C7.92432 10.2914 8.16588 10.3321 8.39175 10.4043V8.54714C7.12436 8.55655 5.9354 9.04907 5.04133 9.93687C4.15353 10.8184 3.64531 12.023 3.64845 13.2716C3.64845 14.3822 4.03432 15.4362 4.74644 16.2801C5.58091 16.9232 6.60047 17.2714 7.67336 17.2714C7.91491 17.2746 8.15647 17.2557 8.39175 17.2212Z"
      fill="black"
    />
  </SvgIcon>
);

export default TikTokColorIcon;
