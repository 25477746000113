import React, { Fragment, useCallback, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  darken,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FormCard, Loader } from 'components';
import { DealTypes, salesPlanning, SalesPlanningTypes } from 'api';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { getNegativeRatio } from 'helpers/calculations';
import { ArrowDownTinIcon } from 'components/icons';
import { CalculateOutlined } from '@mui/icons-material';
import InputCell from './InputCell';
import { columns, budgetDistributionConfig } from '../../budgetConfig';
import AutocalculationsRow from './AutocalculationsRow';

interface Props {
  control: Control<SalesPlanningTypes.ItemCreateParams>;
  watch: UseFormWatch<SalesPlanningTypes.ItemCreateParams>;
  setValue: UseFormSetValue<SalesPlanningTypes.ItemCreateParams>;
  shopId: string;
}

type Field = 'budget_target' | 'roi_target' | 'revenue_target' | 'cm3_target';

const BudgetDistribution: React.FC<Props> = (props: Props): JSX.Element => {
  const { watch, setValue, control, shopId } = props;

  const startDate = watch('start_date');

  const [openedStrategy, setOpenedStrategy] = useState<
    DealTypes.StrategiesEnum | string | undefined
  >();

  const handleToggleStrategy = (strategy: string) => {
    setOpenedStrategy((prev) => (prev === strategy ? undefined : strategy));
  };

  const { data } = useQuery(
    [salesPlanning.endpoints.getCM2, startDate],
    () =>
      salesPlanning.getCM2({
        month: dayjs(startDate).month() + 1,
        year: dayjs(startDate).year(),
      }),
    {
      enabled: !!startDate,
      refetchOnWindowFocus: false,
    },
  );

  const getFieldKey = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (subKey && subSubKey) {
        return `budget_distribution.${key}.sub_items.${subKey}.sub_items.${subSubKey}.${field}`;
      }

      if (subKey) {
        return `budget_distribution.${key}.sub_items.${subKey}.${field}`;
      }

      return `budget_distribution.${key}.${field}`;
    },
    [],
  );

  const getFieldValue = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (key) {
        const fieldKey = getFieldKey({ field, key, subKey, subSubKey });
        return watch(
          fieldKey as keyof SalesPlanningTypes.ItemCreateParams,
        ) as number;
      }
      return watch(field);
    },
    [getFieldKey, watch],
  );

  const getCellValue = useCallback(
    ({
      field,
      key,
      subKey,
      subSubKey,
    }: {
      field: Field;
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      const value = getFieldValue({ field, key, subKey, subSubKey });

      if (!value && value !== 0) {
        return '-';
      }

      if (field === 'roi_target') {
        return value;
      }

      return formatCurrency(value);
    },
    [getFieldValue],
  );

  const getRevenueRatio = useCallback(
    ({
      key,
      subKey,
      subSubKey,
    }: {
      key: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      if (subKey && subSubKey) {
        const subRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
        });
        const subSubRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
          subSubKey,
        });

        const ratio = getNegativeRatio({
          value: subSubRevenue,
          total: subRevenue,
        });
        return formatPercent(ratio);
      }

      if (subKey) {
        const revenue = getFieldValue({ field: 'revenue_target', key });
        const subRevenue = getFieldValue({
          field: 'revenue_target',
          key,
          subKey,
        });

        const ratio = getNegativeRatio({ value: subRevenue, total: revenue });
        return formatPercent(ratio);
      }
      const total = watch('revenue_target');
      const revenue = getFieldValue({ field: 'revenue_target', key });

      const ratio = getNegativeRatio({ value: revenue, total });
      return formatPercent(ratio);
    },
    [getFieldValue, watch],
  );

  const getCM3Ratio = useCallback(
    ({
      key,
      subKey,
      subSubKey,
    }: {
      key?: string;
      subKey?: string;
      subSubKey?: string;
    }) => {
      const total = getFieldValue({
        field: 'revenue_target',
        key,
        subKey,
        subSubKey,
      });
      const cm3 = getFieldValue({
        field: 'cm3_target',
        key,
        subKey,
        subSubKey,
      });

      const ratio = getNegativeRatio({ value: cm3, total });

      return formatPercent(ratio);
    },
    [getFieldValue],
  );

  const cm2Data = shopId ? data?.filter((p) => p.shop_id === shopId) : [];

  return (
    <div>
      {!cm2Data ? (
        <Loader variant="centered" height="300px" />
      ) : (
        <FormCard
          title="Sales planning"
          avatar={<CalculateOutlined />}
          CardProps={{ sx: { overflow: 'unset' } }}
          CardContentProps={{
            sx: {
              px: 0,
              '&:last-child': {
                paddingBottom: '12px',
              },
            },
          }}
        >
          <Table>
            <TableHead
              sx={{
                borderRadius: 0,
                position: 'sticky',
                top: 64,
                zIndex: 100,
                borderTopLeftRadius: (t) => `${t.shape.borderRadius}px`,
                borderTopRightRadius: (t) => `${t.shape.borderRadius}px`,
                background: (theme) =>
                  theme.palette.mode === 'dark'
                    ? darken(theme.palette.primary.main, 0.6)
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell sx={{ width: '4%' }}>
                  <IconButton onClick={() => handleToggleStrategy('all')}>
                    <ArrowDownTinIcon
                      sx={{
                        transform:
                          openedStrategy === 'all'
                            ? 'rotate(180deg)'
                            : undefined,
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '18%' }} />
                <TableCell sx={{ width: '15%' }}>Target budget</TableCell>
                <TableCell sx={{ width: '15%' }}>Target ROI</TableCell>
                <TableCell sx={{ width: '15%' }}>Target revenue</TableCell>
                <TableCell sx={{ width: '15%' }}>Target CM3</TableCell>
                <TableCell sx={{ width: '9%' }}>Target CM3%</TableCell>
                <TableCell sx={{ width: '9%' }}>Revenue ratio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(budgetDistributionConfig).map(([key, value]) => (
                <Fragment key={key}>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { borderBottom: 0 },
                      backgroundColor: (theme) =>
                        openedStrategy === key || openedStrategy === 'all'
                          ? theme.palette.primary.light
                          : undefined,
                    }}
                  >
                    <TableCell component="th" sx={{ pr: 0 }}>
                      <IconButton
                        onClick={() => handleToggleStrategy(key)}
                        disabled={!('sub_items' in value && value.sub_items)}
                      >
                        <ArrowDownTinIcon
                          sx={{
                            transform:
                              openedStrategy === key || openedStrategy === 'all'
                                ? 'rotate(180deg)'
                                : undefined,
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    {columns.map((field) => (
                      <InputCell
                        key={field + key}
                        disabledFields={[]}
                        // disabledFields={value.disabled}
                        field={field}
                        itemKey={key as DealTypes.StrategiesEnum}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        cm2Data={cm2Data}
                        hasSubChannels={!!value.sub_items}
                      />
                    ))}
                    <TableCell>{getCM3Ratio({ key })}</TableCell>
                    <TableCell>{getRevenueRatio({ key })}</TableCell>
                  </TableRow>
                  {value.sub_items ? (
                    <AutocalculationsRow watch={watch} itemKey={key as any} />
                  ) : (
                    <TableRow
                      sx={{
                        backgroundColor: (t) =>
                          t.palette.mode === 'dark'
                            ? t.palette.grey['900']
                            : t.palette.grey.A200,
                      }}
                    >
                      <TableCell />
                      <TableCell colSpan={7}>
                        {`${key} doesn't have sub channels`}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow sx={{ p: 0 }}>
                    <TableCell colSpan={8} sx={{ p: 0, borderBottom: 0 }}>
                      <Collapse
                        in={openedStrategy === key || openedStrategy === 'all'}
                      >
                        <Table>
                          <TableHead sx={{ visibility: 'collapse' }}>
                            <TableRow>
                              <TableCell
                                sx={{ width: '4%', minWidth: '60px' }}
                              />
                              <TableCell sx={{ width: '18%' }} />
                              <TableCell sx={{ width: '15%' }}>
                                Target budget
                              </TableCell>
                              <TableCell sx={{ width: '15%' }}>
                                Target ROI
                              </TableCell>
                              <TableCell sx={{ width: '15%' }}>
                                Target revenue
                              </TableCell>
                              <TableCell sx={{ width: '15%' }}>
                                Target CM3
                              </TableCell>
                              <TableCell sx={{ width: '9%' }}>
                                Target CM3%
                              </TableCell>
                              <TableCell sx={{ width: '9%' }}>
                                Revenue ratio
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {'sub_items' in value &&
                              value.sub_items &&
                              Object.entries(value.sub_items).map(
                                ([subKey, subValue]) => (
                                  <Fragment key={key + subKey}>
                                    <TableRow
                                      sx={
                                        'sub_items' in subValue
                                          ? (theme) => ({
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                            })
                                          : undefined
                                      }
                                    >
                                      <TableCell />
                                      <TableCell component="th" scope="row">
                                        {subKey}
                                      </TableCell>
                                      {columns.map((field) => (
                                        <InputCell
                                          key={field + key + subKey}
                                          disabledFields={subValue.disabled}
                                          field={field}
                                          itemKey={
                                            key as DealTypes.StrategiesEnum
                                          }
                                          itemSubKey={subKey}
                                          watch={watch}
                                          setValue={setValue}
                                          control={control}
                                          cm2Data={cm2Data}
                                          hasSubChannels={!!subValue.sub_items}
                                        />
                                      ))}
                                      <TableCell>
                                        {getCM3Ratio({ key, subKey })}
                                      </TableCell>
                                      <TableCell>
                                        {getRevenueRatio({ key, subKey })}
                                      </TableCell>
                                    </TableRow>
                                    {'sub_items' in subValue &&
                                      subValue.sub_items &&
                                      Object.entries(subValue.sub_items).map(
                                        ([subSubKey, subSubValue]) => (
                                          <TableRow
                                            key={key + subKey + subSubKey}
                                            sx={{
                                              '&:last-child td, &:last-child th':
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell />

                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {subSubKey}
                                            </TableCell>
                                            {columns.map((field) => (
                                              <InputCell
                                                key={
                                                  field +
                                                  key +
                                                  subKey +
                                                  subSubKey
                                                }
                                                disabledFields={
                                                  subSubValue.disabled
                                                }
                                                field={field}
                                                itemKey={
                                                  key as DealTypes.StrategiesEnum
                                                }
                                                itemSubKey={subKey}
                                                itemSubSubKey={subSubKey}
                                                watch={watch}
                                                setValue={setValue}
                                                control={control}
                                                cm2Data={cm2Data}
                                                hasSubChannels={false}
                                              />
                                            ))}
                                            <TableCell>
                                              {getCM3Ratio({
                                                key,
                                                subKey,
                                                subSubKey,
                                              })}
                                            </TableCell>
                                            <TableCell>
                                              {getRevenueRatio({
                                                key,
                                                subKey,
                                                subSubKey,
                                              })}
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                  </Fragment>
                                ),
                              )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell />
                <TableCell>Total</TableCell>
                <TableCell>
                  {getCellValue({ field: 'budget_target' })}
                </TableCell>
                <TableCell>{getCellValue({ field: 'roi_target' })}</TableCell>
                <TableCell>
                  {getCellValue({ field: 'revenue_target' })}
                </TableCell>
                <TableCell>{getCellValue({ field: 'cm3_target' })}</TableCell>
                <TableCell>{getCM3Ratio({})}</TableCell>
                <TableCell />
              </TableRow>
              <AutocalculationsRow watch={watch} />
            </TableBody>
          </Table>
        </FormCard>
      )}
    </div>
  );
};

export default BudgetDistribution;
