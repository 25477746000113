import { useCallback, useMemo } from 'react';
import { omitBy, isUndefined } from 'lodash';
import { useSearchParams } from 'react-router-dom';

type Value = unknown | undefined;
type FiltersSearchParams = Record<string, Value>;

const stringifyFilters = (value: FiltersSearchParams) => {
  const cleanParams = omitBy(value, isUndefined);

  return Object.entries(cleanParams).reduce(
    (previousValue, [key, value]) => ({
      ...previousValue,
      [key]: JSON.stringify(value),
    }),
    {},
  );
};

const useFiltersSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersSearchParams = useMemo(() => {
    const parsed = Object.fromEntries(searchParams);

    return Object.entries(parsed).reduce(
      (previousValue, [key, value]) => {
        try {
          return {
            ...previousValue,
            [key]: JSON.parse(value),
          };
        } catch (e) {
          return previousValue;
        }
      },
      {} as Record<string, unknown>,
    );
  }, [searchParams]);

  const setFiltersSearchParams = useCallback(
    (value: FiltersSearchParams) => {
      const stringified = stringifyFilters(value);

      setSearchParams(stringified, { replace: true });
    },
    [setSearchParams],
  );

  const updateFiltersSearchParams = useCallback(
    (value: FiltersSearchParams) => {
      const newState = { ...filtersSearchParams, ...value };
      const stringified = stringifyFilters(newState);

      setSearchParams(stringified, { replace: true });
    },
    [filtersSearchParams, setSearchParams],
  );

  const handleFilterChange = useCallback(
    (key: string, value: Value) => {
      setFiltersSearchParams({
        ...filtersSearchParams,
        [key]: value,
      });
    },
    [filtersSearchParams, setFiltersSearchParams],
  );

  return {
    filtersSearchParams,
    handleFilterChange,
    updateFiltersSearchParams,
    setFiltersSearchParams,
  };
};

export default useFiltersSearchParams;
