import React, { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
  Toolbar,
  Typography,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Stack,
  Box,
  Chip,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'theme/constants';
import { appLinks, appLinksFlat } from 'routes/links';
import { legacyLinksFlat } from 'routes/legacyLinks';
import { useStoreContext } from 'store';
import { ChevronLeftOutlined } from '@mui/icons-material';
import UserMenu from './UserMenu';
import CountriesFilter from './CountriesFilter';
import BrandsFilter from './BrandsFilter';
import AppearanceMenu from './AppearanceMenu';
import Notifications from './Notifications';

const links = [...legacyLinksFlat, ...appLinksFlat];

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: `calc(100% - ${theme.spacing(8)} - 1px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (): JSX.Element | null => {
  const {
    state: { isSideNavOpen, tableItemsCount },
  } = useStoreContext();
  const location = useLocation();
  const navigate = useNavigate();

  const pageTitle = useMemo(
    () => links.find((l) => matchPath(l.link, location.pathname))?.name,
    [location.pathname],
  );

  const topLevelLink = useMemo(
    () =>
      Object.values(appLinks).find((l) => matchPath(l.link, location.pathname)),
    [location.pathname],
  );

  const handleBackClick = () => {
    // @ts-ignore
    if (location?.state?.from === 'edit') {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  const activeLink = useMemo(
    () => links.find((l) => matchPath(l.link, location.pathname)),
    [location.pathname],
  );

  useEffect(() => {
    if (activeLink) {
      document.title = `${activeLink.name} - IMS`;
    }
  }, [activeLink]);

  return (
    <AppBar position="fixed" open={isSideNavOpen} elevation={0}>
      <Toolbar
        disableGutters
        sx={(theme) => ({
          py: 1.5,
          height: HEADER_HEIGHT,
          maxHeight: HEADER_HEIGHT,
          mx: theme.spacing(3),
        })}
      >
        <Stack direction="row" alignItems="center" columnGap={2} width="100%">
          <Box flexGrow={1}>
            <Stack direction="row" alignItems="center" columnGap={1}>
              {!topLevelLink && location.key !== 'default' && (
                <IconButton
                  onClick={handleBackClick}
                  sx={(theme) => ({
                    color: theme.palette.text.primary,
                    ml: -2,
                  })}
                >
                  <ChevronLeftOutlined fontSize="large" />
                </IconButton>
              )}
              <Box flexGrow={1}>
                <Typography
                  variant="h6"
                  color="text.primary"
                  noWrap
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {pageTitle}
                  {!!tableItemsCount && (
                    <Tooltip
                      placement="right"
                      title={`${tableItemsCount} items found`}
                    >
                      <Chip
                        color="default"
                        size="small"
                        label={tableItemsCount}
                        sx={{ ml: 1 }}
                      />
                    </Tooltip>
                  )}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <CountriesFilter />
            <BrandsFilter />
            <AppearanceMenu />
            <Notifications />
            <UserMenu />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
