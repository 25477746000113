import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import {
  DeleteOutlined,
  LaunchOutlined,
  LinkOutlined,
} from '@mui/icons-material';
import {
  ActionButtonsGroup,
  ActionButtonProps,
  ConfirmDialogControlled,
  CloseDetailsDrawer,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { appLinks } from 'routes/links';
import { salesPlanning, SalesPlanningTypes } from 'api';
import { useApiRequest, useClipboard, useFiltersSearchParams } from 'hooks';

interface Props {
  inline?: boolean;
  planning: SalesPlanningTypes.ItemData;
}

const Actions = (props: Props): JSX.Element => {
  const { planning, inline } = props;
  const navigate = useNavigate();
  const handleCopy = useClipboard();
  const { handleFilterChange } = useFiltersSearchParams();

  const { isLoading, requestFn } = useApiRequest(() =>
    salesPlanning.remove(planning._id.$oid),
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDeleteDialogOpen(true);
  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleDelete = useCallback(async () => {
    await requestFn({
      successMessage: 'Sales planning deleted',
    });

    setIsDeleteDialogOpen(false);

    if (inline) {
      handleFilterChange('splitItemId', undefined);
    } else {
      navigate(-1);
    }
  }, [handleFilterChange, inline, navigate, requestFn]);

  const actions: ActionButtonProps<SalesPlanningTypes.ItemData>[] = useMemo(
    () => [
      {
        name: `Open fullscreen`,
        Icon: LaunchOutlined,
        to: (item) => appLinks.sales.details.as(item.sales_planning_id),
        hidden: !inline,
      },
      {
        name: `Copy link`,
        Icon: LinkOutlined,
        onClick: (item) =>
          handleCopy(
            window.location.origin +
              appLinks.sales.details.as(item.sales_planning_id),
          ),
      },
      {
        name: 'Edit sales planning',
        EditButtonProps: {
          inline,
          idKey: 'salesPlanningId',
          id: (item) => item.sales_planning_id,
          link: appLinks.sales.edit.as,
        },
      },
      {
        name: 'Delete sales planning',
        Icon: DeleteOutlined,
        onClick: handleOpenDialog,
      },
    ],
    [handleCopy, inline],
  );

  return (
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      {inline && (
        <Grid item flexGrow={1}>
          <CloseDetailsDrawer />
        </Grid>
      )}
      <Grid item>
        <ActionButtonsGroup
          maxActions={inline ? 7 : 4}
          actions={actions}
          data={planning}
        />
        <ConfirmDialogControlled
          isLoading={isLoading}
          name="sales planning"
          isOpen={isDeleteDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDelete}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
