import { NegotiationTypes, TimelineTypes } from 'api';
import { Timeline } from 'components';

interface Props {
  negotiation: NegotiationTypes.ItemData;
}

const ActivityLogTab = (props: Props): JSX.Element | null => {
  const { negotiation } = props;

  return (
    <Timeline
      id={negotiation._id.$oid}
      type={TimelineTypes.ModelEnum.Negotiation}
    />
  );
};

export default ActivityLogTab;
