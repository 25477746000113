import React, { Fragment, Key, MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, darken, TableCell, TableRow } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import TableCellContent from './TableCellContent';
import DataTableProps from '../DataTableProps';
import { useDataTableContext } from '../context';
import { selectRow } from '../context/actions';
import { useFiltersSearchParams } from '../../../hooks';

interface Props {
  inline: boolean;
  tableBodyItems: DataTableProps.Columns;
  withBulkActions: boolean | undefined;
  refetch: () => Promise<QueryObserverResult>;
  idKey?: string;
  scrollPosition: number;
  item: DataTableProps.Item;
  withSplitView: boolean;
}

const TableBodyItem: React.FC<Props> = (props: Props) => {
  const {
    tableBodyItems,
    withBulkActions,
    inline,
    refetch,
    idKey,
    scrollPosition,
    item,
    withSplitView,
  } = props;
  const navigate = useNavigate();
  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const {
    state: { selectedItems, isSplitViewEnabled },
    dispatch,
  } = useDataTableContext();

  const handleSelectItem = useCallback(() => {
    dispatch(selectRow(item));
  }, [dispatch, item]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      const link = tableBodyItems.find((cell) => cell.type === 'rowLink');

      if (link?.type === 'rowLink') {
        if (withSplitView && isSplitViewEnabled) {
          if (link.id && link.idKey) {
            updateFiltersSearchParams({
              splitItemId: link.id(item),
            });
          }
        } else {
          if (link.href) {
            window.open(link.href(item), '_blank');
          }
          if (link.to) {
            navigate(link.to(item));
          }
          if (link.id && link.idKey) {
            updateFiltersSearchParams({
              [typeof link.idKey === 'string' ? link.idKey : link.idKey(item)]:
                link.id(item),
            });
          }
        }
      }
    },
    [
      isSplitViewEnabled,
      item,
      navigate,
      tableBodyItems,
      updateFiltersSearchParams,
      withSplitView,
    ],
  );

  const isChecked = useMemo(() => {
    const id = idKey ? item[idKey] : item._id.$oid;
    return (
      selectedItems.map((s) => (idKey ? s[idKey] : s._id.$oid)).indexOf(id) !==
      -1
    );
  }, [idKey, item, selectedItems]);

  return (
    <TableRow
      aria-checked={isChecked}
      selected={isChecked}
      sx={(theme) => ({
        '&:last-child td, &:last-child th': { borderBottom: 0 },
        '&:hover': {
          cursor: 'pointer',
          'th, td': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? darken(theme.palette.primary.main, 0.6)
                : darken(theme.palette.background.paper, 0.05),
          },
        },
        '&.Mui-selected': {
          background:
            theme.palette.mode === 'dark'
              ? darken(theme.palette.primary.main, 0.6)
              : theme.palette.primary.light,
        },
      })}
    >
      {tableBodyItems
        .filter((t) => t.type !== 'rowLink')
        .map((cell, index) => (
          <Fragment key={cell.key as Key}>
            {index === 0 && withBulkActions && (
              <TableCell padding="checkbox" size={inline ? 'small' : 'medium'}>
                <Checkbox
                  color="primary"
                  checked={isChecked}
                  onClick={handleSelectItem}
                />
              </TableCell>
            )}
            <TableCell
              onClick={
                cell.type !== 'actions' && cell.type !== 'platforms'
                  ? handleClick
                  : undefined
              }
              size={inline ? 'small' : 'medium'}
              align={cell.align || 'left'}
              scope={index === 0 ? 'row' : undefined}
              component={index === 0 ? 'th' : undefined}
              sx={(theme) => ({
                position:
                  index === 0 || cell.type === 'actions' ? 'sticky' : undefined,
                left: index === 0 ? 0 : undefined,
                right: cell.type === 'actions' ? 0 : undefined,
                zIndex: index === 0 || cell.type === 'actions' ? 1 : undefined,
                background:
                  // eslint-disable-next-line no-nested-ternary
                  index === 0
                    ? // eslint-disable-next-line no-nested-ternary
                      isChecked
                      ? theme.palette.mode === 'dark'
                        ? darken(theme.palette.primary.main, 0.6)
                        : theme.palette.primary.light
                      : theme.palette.mode === 'dark' && !inline
                      ? theme.palette.background.default
                      : theme.palette.background.paper
                    : undefined,
                backgroundColor:
                  // eslint-disable-next-line no-nested-ternary
                  cell.type === 'actions'
                    ? theme.palette.mode === 'dark' && !inline
                      ? theme.palette.background.default
                      : theme.palette.background.paper
                    : undefined,
                borderRight:
                  index === 0
                    ? `1px solid ${
                        scrollPosition > 1
                          ? theme.palette.divider
                          : 'transparent'
                      }`
                    : undefined,
                borderLeft:
                  cell.type === 'actions'
                    ? `1px solid ${theme.palette.divider}`
                    : undefined,
                width:
                  cell.type === 'actions'
                    ? `${
                        (cell.actions.length > 4 ? 4 : cell.actions.length) * 42
                      }px`
                    : undefined,
                maxWidth: 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '& p':
                  cell.noWrap === false
                    ? undefined
                    : {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
              })}
            >
              <TableCellContent cell={cell} data={item} refetch={refetch} />
            </TableCell>
          </Fragment>
        ))}
    </TableRow>
  );
};

export default TableBodyItem;
