import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

export type FetcherFn<Data, Args> = (
  ...args: Array<Args>
) => Data | Promise<Data>;

export interface RequestFnParams<Args = any> {
  args?: Args;
  successMessage?: string;
  error?: {
    getError?: (error: any) => string;
    message?: string;
    code?: number;
  };
}

export type ResponseInterface<Data, Error, Args> = {
  requestFn(params?: RequestFnParams<Args>): Promise<Data | undefined>;
  isLoading?: boolean;
  error?: Error;
};

const useApiRequest = <Data = any, Error = any, Args = any>(
  fetcher: FetcherFn<Data, Args>,
): ResponseInterface<Data, Error, Args> => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const requestFn = useCallback(
    async (params?: RequestFnParams) => {
      try {
        setIsLoading(true);
        setError(undefined);

        const response = await fetcher(params?.args);

        if (params?.successMessage) {
          enqueueSnackbar({
            variant: 'success',
            message: params.successMessage,
          });
        }

        setIsLoading(false);

        return response;
      } catch (e: any) {
        setError(e);
        setIsLoading(false);

        if (params?.error?.getError) {
          enqueueSnackbar({
            variant: 'error',
            message: params.error.getError(e),
          });
        } else if (
          params?.error &&
          params.error.message &&
          params.error.code === e.response?.status
        ) {
          enqueueSnackbar({
            variant: 'error',
            message: params.error.message,
          });
        } else {
          enqueueSnackbar({
            variant: 'error',
            message: e?.response?.data?.message || e?.message,
          });
        }

        // throw e;
        return undefined;
      }
    },
    [enqueueSnackbar, fetcher],
  );

  return {
    error,
    isLoading,
    requestFn,
  };
};

export default useApiRequest;
