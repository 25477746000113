import { DrawerMode } from 'types/SearchParams';
import Form from '../Form';
import Details from '../Details';

interface Props {
  id: string;
  mode: DrawerMode;
}

const InfluencersDrawer = (props: Props) => {
  const { id, mode } = props;

  return mode === 'edit' ? <Form itemId={id} /> : <Details itemId={id} />;
};

export default InfluencersDrawer;
