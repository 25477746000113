import React, { useState } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { CampaignTypes } from 'api';
import PrevCampaignsTab from './PrevCampaignsTab';
import DealsTab from './DealsTab';
import DetailsTab from './DetailsTab';
import BrandMentionsTab from './BrandMentionsTab';
import PerformanceTab from './PerformanceTab';

const TABS = {
  details: 'Details',
  deals: 'Deals',
  prevCampaigns: 'Prev campaigns',
  brandMentions: 'Mentions',
  performance: 'Performance',
};

interface Props {
  inline?: boolean;
  data: CampaignTypes.ItemData;
}

const Details = (props: Props): JSX.Element => {
  const { data, inline } = props;

  const [activeTab, setActiveTab] = useState(TABS.performance);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <Tab label={TABS.performance} value={TABS.performance} />
        <Tab label={TABS.details} value={TABS.details} />
        <Tab label={TABS.brandMentions} value={TABS.brandMentions} />
        <Tab label={TABS.deals} value={TABS.deals} />
        {inline && (
          <Tab label={TABS.prevCampaigns} value={TABS.prevCampaigns} />
        )}
      </Tabs>

      <Stack rowGap={3} sx={{ mt: 3 }}>
        {activeTab === TABS.details && (
          <DetailsTab campaign={data} inline={inline} />
        )}
        {activeTab === TABS.deals && <DealsTab campaign={data} />}
        {activeTab === TABS.brandMentions && (
          <BrandMentionsTab campaignId={data._id.$oid} inline={inline} />
        )}
        {activeTab === TABS.performance && (
          <PerformanceTab campaign={data} inline={inline} />
        )}
        {activeTab === TABS.prevCampaigns && (
          <PrevCampaignsTab campaign={data} inline={inline} />
        )}
      </Stack>
    </>
  );
};

export default Details;
