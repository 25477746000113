import ky, { BeforeRequestHook } from 'ky';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import isJwtExpired from 'helpers/isJwtExprired';

export const authBeforeRequestHook: BeforeRequestHook = (request) => {
  const token = LocalStorage.get(LocalStorageEnum.TOKEN);

  if (token) {
    if (isJwtExpired(token)) {
      LocalStorage.remove(LocalStorageEnum.TOKEN);
      LocalStorage.remove(LocalStorageEnum.USER_EMAIL);

      document.location.pathname = '/';
    } else {
      request.headers.set('Authorization', token);
    }
  }
};

const apiClient = ky.create({
  // prefixUrl: 'https://hashtag-ims-api.herokuapp.com',
  // prefixUrl: 'https://hashtag-ims-api-staging.herokuapp.com',
  // prefixUrl: 'https://world.ava-may.com',
  prefixUrl: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 1 * 60 * 1000,
  hooks: {
    beforeRequest: [authBeforeRequestHook],
  },
});

export default apiClient;
