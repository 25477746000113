import React, { useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { DealTypes } from 'api';
import { colors } from 'theme/constants';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { DealInfoItems } from '../DetailsTab';
import DealComparisonCard from './DealComparisonCard';
import InfoCard from '../InfoCard';
import BudgetComparisonCard from './BudgetComparisonCard';
import BrandEconomics from './BrandEconomics';
import MetricsChart from './MetricsChart';
import PrimaryMetrics from './PrimaryMetrics';
import ProductsTable from './ProductsTable';

interface Props {
  inline?: boolean;
  deal: DealTypes.ItemDataExtended;
}

const PerformanceTab = (props: Props): JSX.Element | null => {
  const { deal, inline } = props;

  const actualBudgetInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'budget_type',
        label: 'Budget type',
        value: deal.budget_type,
      },
      {
        key: 'budget_actual_lc',
        label: 'Budget',
        value:
          deal.budget_actual_lc && deal.budget_actual_lc >= 0
            ? formatCurrency(deal.budget_actual_lc)
            : '-',
      },
      {
        key: 'budget_fixed',
        label: 'Budget fixed',
        value: deal.budget_fixed,
      },
      {
        key: 'roi_actual',
        label: 'ROI',
        value: deal.roi_actual ?? '-',
      },
      {
        key: 'revenue_actual',
        label: 'Revenue',
        value:
          deal.revenue_actual && deal.revenue_actual >= 0
            ? formatCurrency(deal.revenue_actual)
            : '-',
      },
      {
        key: 'commission',
        label: 'Commission',
        value: deal.commission ?? '-',
      },
    ],
    [
      deal.budget_actual_lc,
      deal.budget_fixed,
      deal.budget_type,
      deal.commission,
      deal.revenue_actual,
      deal.roi_actual,
    ],
  );

  const basketInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'avg_basket_actual',
        label: 'Average basket',
        value: formatCurrency(deal.avg_basket_actual),
      },
      {
        key: 'orders_nb_actual',
        label: 'Orders count',
        value: deal.orders_nb_actual,
      },
      {
        key: 'customers.all',
        label: 'Total customers',
        value: deal.customers.all,
      },
      {
        key: 'customers.new',
        label: 'New customers',
        value: deal.customers.new,
      },
      {
        key: 'new_customers_ratio',
        label: 'NCR',
        value: formatPercent(deal.new_customers_ratio),
      },
    ],
    [
      deal.avg_basket_actual,
      deal.customers.all,
      deal.customers.new,
      deal.new_customers_ratio,
      deal.orders_nb_actual,
    ],
  );

  const advancedMetricsInfo: DealInfoItems = useMemo(
    () => [
      {
        key: 'cm3_actual',
        label: 'CM3',
        value: formatCurrency(deal.cm3_actual),
      },
      {
        key: 'cm3_actual_pc',
        label: 'CM3%',
        value: formatPercent(deal.cm3_actual_pc),
      },
      {
        key: 'cpm_actual',
        label: 'CPM',
        value: formatCurrency(deal.cpm_actual),
      },
      {
        key: 'cpo_actual',
        label: 'CPO',
        value: formatCurrency(deal.cpo_actual),
      },
      {
        key: 'gross_profit_actual',
        label: 'Gross profit',
        value: formatCurrency(deal.gross_profit_actual),
      },
    ],
    [
      deal.cm3_actual,
      deal.cm3_actual_pc,
      deal.cpm_actual,
      deal.cpo_actual,
      deal.gross_profit_actual,
    ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PrimaryMetrics deal={deal} inline={inline} />
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <InfoCard
            title="Actual budget info"
            data={actualBudgetInfo}
            color={colors.darkGreen}
          />
          <InfoCard title="Basket info" data={basketInfo} color={colors.blue} />
          <InfoCard
            title="Advanced metrics"
            data={advancedMetricsInfo}
            color={colors.darkRed}
          />
          <MetricsChart deal={deal} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={inline ? 12 : 6}>
        <Stack rowGap={3}>
          <BudgetComparisonCard deal={deal} />
          <DealComparisonCard deal={deal} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <BrandEconomics deal={deal} />
      </Grid>
      <Grid item xs={12}>
        <ProductsTable deal={deal} />
      </Grid>
    </Grid>
  );
};

export default PerformanceTab;
