import React, { useCallback, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { alpha, Box, Chip, Stack } from '@mui/material';
import { CalendarOptions } from '@fullcalendar/core';
import { HEADER_HEIGHT } from 'theme/constants';
import { Loader } from 'components';
import { deals, DealTypes } from 'api';
import DEALS from 'constants/deals';
import { getDealStatusColor } from 'helpers/getStatusColor';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { QueryObserverResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import DayCellContent from './DayCellContent';
import EventContent from './EventContent';
import DataTable from '../DataTableProps';

interface Props {
  data: DealTypes.ListData['data'];
  isLoading: boolean;
  setDateRange: (value: DataTable.DateFilterOption['value']) => void;
  refetch: () => Promise<QueryObserverResult>;
}

const FullCalendarPage = (props: Props) => {
  const { isLoading, data, setDateRange, refetch } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const { requestFn, isLoading: isChangingDate } = useApiRequest((args) =>
    deals.update(args.deal_id, args.data),
  );

  const updateDealStartDate = useCallback(
    async (payload: any) => {
      const dealId = payload.event._def.extendedProps.deal_id;
      const dealStatus = payload.event._def.extendedProps.status;
      const newDate = payload.event.startStr;

      if (dealStatus === 'Posted' || dealStatus === 'Finished') {
        payload.revert();
        enqueueSnackbar({
          variant: 'warning',
          message: `Deals with ${dealStatus} can not be moved`,
        });
      } else {
        await requestFn({
          args: {
            deal_id: dealId,
            data: { start_date: newDate },
          },
          successMessage: 'Deal date changed',
        });

        await refetch();
      }
    },
    [refetch, requestFn, enqueueSnackbar],
  );

  const config: CalendarOptions = useMemo(
    () => ({
      buttonText: {
        month: 'Month',
        today: 'Today',
        week: 'Week',
      },
      datesSet: (args) => {
        setDateRange({
          $gte: args.startStr,
          $lte: args.endStr,
        });
      },
      eventChange: updateDealStartDate,

      // eslint-disable-next-line react/no-unstable-nested-components
      dayCellContent: (d) => <DayCellContent arg={d} />,
      // eslint-disable-next-line react/no-unstable-nested-components
      eventContent: (e) => <EventContent eventInfo={e} />,
      eventClick: (eventInfo) =>
        updateFiltersSearchParams({
          dealId: eventInfo.event.extendedProps.deal_id,
        }),
      dayHeaderFormat: { weekday: 'long' },
      editable: true,
      events: (data || []).map((d) => ({
        date: d.start_date,
        title: d.influencer_profile_name,
        brand: d.brand,
        status: d.status,
        deal_id: d._id.$oid,
      })),
      firstDay: 1,
      fixedWeekCount: false,
      headerToolbar: {
        left: 'prev next today',
        center: 'title',
        right: 'dayGridWeek dayGridMonth',
      },
      height: `calc(100vh - 34px - ${HEADER_HEIGHT}px - 160px)`,
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      selectable: true,
      selectMirror: true,
      views: {
        dayGridMonth: {
          dayMaxEvents: 3,
        },
        dayGridWeek: {},
      },
      weekends: true,
    }),
    [data, setDateRange, updateDealStartDate, updateFiltersSearchParams],
  );

  return (
    <Box
      sx={(theme) => ({
        p: 2,
        '& .fc-toolbar-title': {
          fontWeight: 400,
        },
        '& .fc .fc-daygrid-day.fc-day-today': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.15),
        },
        '& .fc-button.fc-button-primary': {
          borderRadius: `${theme.shape.borderRadius}px`,
          backgroundColor: theme.palette.primary.main,
          border: 0,
          '&:disabled': {
            backgroundColor: theme.palette.primary.light,
          },
          '&:hover, &:not(:disabled).fc-button-active': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        '& .fc-h-event': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
        '& .fc-theme-standard .fc-popover-header': {
          backgroundColor: 'unset',
        },
        '& .fc-popover': {
          borderRadius: `${theme.shape.borderRadius}px`,
          padding: 1,
          overflow: 'hidden',
          bottom: 0,
          top: 'unset !important',
          // transform: 'translate(-250px, -200px)',
          '&-header': {
            fontSize: '16px',
            fontWeight: 500,
          },
          '&-body': {
            maxHeight: 350,
            overflowY: 'auto',
          },
        },
      })}
    >
      {(isLoading || isChangingDate) && <Loader variant="fullScreen" />}
      <FullCalendar {...config} />
      <Stack direction="row" columnGap={1} sx={{ mt: 1.5 }}>
        {DEALS.STATUS_OPTIONS.map((status) => (
          <Chip
            key={status.value}
            size="small"
            variant="outlined"
            component="span"
            label={status.label}
            color={getDealStatusColor(status.value as any)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default FullCalendarPage;
