import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { LocalStorage, LocalStorageEnum } from 'helpers/localStorage';
import { PaletteMode } from '@mui/material';
import reducer from './reducer';
import { StoreContext as IStoreContext, StoreStateData } from './types';

const isSideNavOpen =
  LocalStorage.get(LocalStorageEnum.IS_SIDE_NAV_OPEN) === 'true';

const appearance = (LocalStorage.get(LocalStorageEnum.APPEARANCE) ||
  undefined) as PaletteMode | undefined;

const initialState: StoreStateData = {
  isSideNavOpen,
  user: undefined,
  snackbarNotification: undefined,
  hideSiteHeader: undefined,
  activeSocialPlatform: undefined,
  activeBrand: undefined,
  activeCountry: undefined,
  tableItemsCount: undefined,
  refetchTableData: undefined,
  appearance,
};

const StoreContext = createContext<IStoreContext>({
  state: initialState,
  dispatch: () => null,
});

export const useStoreContext = (): IStoreContext => useContext(StoreContext);

interface Props {
  children: ReactNode;
}

export const StoreContextProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
