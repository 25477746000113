import _, { isEqual, omitBy } from 'lodash';

const getModifiedFields = (
  dirtyFields: object | boolean | Array<unknown>,
  allValues: object,
): object => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      // @ts-ignore
      getModifiedFields(dirtyFields[key], allValues[key]),
    ]),
  );
};

const getDiffFields = (
  initialData: Record<string, any>,
  currentData: Record<string, any>,
): Record<string, any> =>
  omitBy(currentData, (value, key) => isEqual(value, initialData[key]));

const getFieldsWithValues = (obj: object): object =>
  _(obj)
    .pickBy(_.isPlainObject) // get only objects
    .mapValues(getFieldsWithValues) // call only for values as objects
    .assign(_.omitBy(obj, _.isPlainObject)) // save back result that is not object
    .omitBy(_.isUndefined) // remove null and undefined from object
    .omitBy((v) => typeof v === 'string' && v.trim().length === 0) // remove null and undefined from object
    .value(); // get value

export { getModifiedFields, getFieldsWithValues, getDiffFields };
