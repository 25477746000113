import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import FormInputProps from './FormInputProps';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.SwitchInput,
  'inputType'
> &
  UseControllerProps<T>;

const SwitchInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    onChange,
    FormControlLabelProps,
    FormControlProps,
    label,
    helperText,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl {...FormControlProps} error={!!error}>
      <FormControlLabel
        {...FormControlLabelProps}
        label={label}
        control={
          <Switch
            inputRef={field.ref}
            name={field.name}
            checked={field.value || false}
            onChange={
              onChange ? (e) => onChange(e, field.onChange) : field.onChange
            }
            {...rest}
          />
        }
      />
      {error ? (
        <FormHelperText>{error.message}</FormHelperText>
      ) : (
        !!helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SwitchInput;
