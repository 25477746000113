enum LocalStorageEnum {
  TOKEN = 'hashtag-token',
  USER_EMAIL = 'hashtag-user-email',
  TABLE_PER_PAGE = 'hashtag-table-per-page',
  IS_SIDE_NAV_OPEN = 'hashtag-is-side-nav-open',
  IS_DETAILS_SIDE_PANEL_OPEN = 'hashtag-is-details-side-panel-open',
  SEARCH_HISTORY = 'hashtag-search-history',
  SP_VIEW_TYPE = 'hashtag-sp-view-type',
  IS_SPLIT_VIEW_ACTIVE = 'hashtag-split-view-active',
  APPEARANCE = 'hashtag-appearance',
}

const LocalStorage = {
  get: (key: LocalStorageEnum): string | null =>
    window.localStorage.getItem(key),
  set: (key: LocalStorageEnum, value: string) => {
    window.localStorage.setItem(key, value);
  },
  remove: (key: LocalStorageEnum) => {
    window.localStorage.removeItem(key);
  },
};

export { LocalStorage, LocalStorageEnum };
