import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as DomRoutes } from 'react-router-dom';
import { Loader, Layout, ErrorBoundary } from 'components';
import { appLinks } from './links';
import { legacyLinks } from './legacyLinks';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const Home = lazy(() => import('pages/Home'));
const SignIn = lazy(() => import('pages/SignIn'));
const InfluencersTable = lazy(() => import('pages/Influencers/Table'));
const InfluencersDetails = lazy(() => import('pages/Influencers/Details'));
const InfluencersForm = lazy(() => import('pages/Influencers/Form'));
const DealsTable = lazy(() => import('pages/Deals/Table'));
const DealsForm = lazy(() => import('pages/Deals/Form'));
const DealsDetails = lazy(() => import('pages/Deals/Details'));
const NegotiationsTable = lazy(() => import('pages/Negotiations/Table'));
const NegotiationsForm = lazy(() => import('pages/Negotiations/Form'));
const NegotiationsDetails = lazy(() => import('pages/Negotiations/Details'));
const CampaignsTable = lazy(() => import('pages/Campaigns/Table'));
const CampaignsForm = lazy(() => import('pages/Campaigns/Form'));
const CampaignsDetails = lazy(() => import('pages/Campaigns/Details'));
const DiscountsTable = lazy(() => import('pages/Discounts/Table'));
const DiscountsForm = lazy(() => import('pages/Discounts/Form'));
const DiscountsDetails = lazy(() => import('pages/Discounts/Details'));
const SalesPlanningTable = lazy(() => import('pages/SalesPlanning/Table'));
const SalesPlanningForm = lazy(() => import('pages/SalesPlanning/Form'));
const SalesPlanningDetails = lazy(() => import('pages/SalesPlanning/Details'));
const UsersTable = lazy(() => import('pages/Users/Table'));
const UsersForm = lazy(() => import('pages/Users/Form'));
const UsersDetails = lazy(() => import('pages/Users/Details'));
const Calendar = lazy(() => import('pages/Calendar/Table'));
const PipelinePreparation = lazy(
  () => import('pages/PipelinePreparation/Table'),
);
const BrandMentions = lazy(() => import('pages/BrandMentions/Table'));
const Bookmarks = lazy(() => import('pages/Bookmarks/Table'));
const Dashboard = lazy(() => import('pages/Dashboard'));

const MarketingPlanCalendar = lazy(() => import('pages/MarketingPlan/Table'));
const MarketingPlanForm = lazy(() => import('pages/MarketingPlan/Form'));

const privateRoutes = [
  { paths: [appLinks.index.link], Component: Home },
  { paths: [appLinks.calendar.link], Component: Calendar },
  { paths: [appLinks.dashboard.link], Component: Dashboard },

  { paths: [appLinks.markingPlan.link], Component: MarketingPlanCalendar },
  { paths: [appLinks.markingPlan.create.link], Component: MarketingPlanForm },
  { paths: [appLinks.markingPlan.edit.link], Component: MarketingPlanForm },
  { paths: [appLinks.markingPlan.details.link], Component: MarketingPlanForm },

  { paths: [appLinks.team.link], Component: UsersTable },
  { paths: [appLinks.team.create.link], Component: UsersForm },
  { paths: [appLinks.team.edit.link], Component: UsersForm },
  { paths: [appLinks.team.details.link], Component: UsersDetails },
  {
    paths: [appLinks.influencers.link, legacyLinks.influencers.link],
    Component: InfluencersTable,
  },
  {
    paths: [
      appLinks.influencers.details.link,
      legacyLinks.influencers.details.link,
    ],
    Component: InfluencersDetails,
  },
  {
    paths: [
      appLinks.influencers.create.link,
      legacyLinks.influencers.create.link,
    ],
    Component: InfluencersForm,
  },
  {
    paths: [appLinks.influencers.edit.link, legacyLinks.influencers.edit.link],
    Component: InfluencersForm,
  },

  {
    paths: [appLinks.deals.link, legacyLinks.deals.link],
    Component: DealsTable,
  },
  {
    paths: [appLinks.deals.create.link, legacyLinks.deals.create.link],
    Component: DealsForm,
  },
  {
    paths: [appLinks.deals.edit.link, legacyLinks.deals.edit.link],
    Component: DealsForm,
  },
  {
    paths: [appLinks.deals.details.link, legacyLinks.deals.details.link],
    Component: DealsDetails,
  },

  {
    paths: [appLinks.negotiations.link, legacyLinks.negotiations.link],
    Component: NegotiationsTable,
  },
  {
    paths: [
      appLinks.negotiations.create.link,
      legacyLinks.negotiations.create.link,
    ],
    Component: NegotiationsForm,
  },
  {
    paths: [
      appLinks.negotiations.edit.link,
      legacyLinks.negotiations.edit.link,
    ],
    Component: NegotiationsForm,
  },
  {
    paths: [
      appLinks.negotiations.details.link,
      legacyLinks.negotiations.details.link,
    ],
    Component: NegotiationsDetails,
  },

  {
    paths: [appLinks.campaigns.link, legacyLinks.campaigns.link],
    Component: CampaignsTable,
  },
  {
    paths: [appLinks.campaigns.create.link, legacyLinks.campaigns.create.link],
    Component: CampaignsForm,
  },
  {
    paths: [appLinks.campaigns.edit.link, legacyLinks.campaigns.edit.link],
    Component: CampaignsForm,
  },
  {
    paths: [
      appLinks.campaigns.details.link,
      legacyLinks.campaigns.details.link,
    ],
    Component: CampaignsDetails,
  },

  {
    paths: [appLinks.discounts.link, legacyLinks.discounts.link],
    Component: DiscountsTable,
  },
  {
    paths: [appLinks.discounts.create.link, legacyLinks.discounts.create.link],
    Component: DiscountsForm,
  },
  {
    paths: [appLinks.discounts.edit.link, legacyLinks.discounts.edit.link],
    Component: DiscountsForm,
  },
  {
    paths: [
      appLinks.discounts.details.link,
      legacyLinks.discounts.details.link,
    ],
    Component: DiscountsDetails,
  },

  {
    paths: [appLinks.sales.link, legacyLinks.sales.link],
    Component: SalesPlanningTable,
  },
  {
    paths: [appLinks.sales.create.link, legacyLinks.sales.create.link],
    Component: SalesPlanningForm,
  },
  {
    paths: [appLinks.sales.edit.link, legacyLinks.sales.edit.link],
    Component: SalesPlanningForm,
  },
  {
    paths: [appLinks.sales.details.link, legacyLinks.sales.details.link],
    Component: SalesPlanningDetails,
  },

  {
    paths: [
      appLinks.pipelinePreparation.link,
      legacyLinks.pipelinePreparation.link,
    ],
    Component: PipelinePreparation,
  },
  {
    paths: [appLinks.mentions.link, legacyLinks.mentions.link],
    Component: BrandMentions,
  },
  {
    paths: [appLinks.bookmarks.link, legacyLinks.bookmarks.link],
    Component: Bookmarks,
  },
];

const redirectRoutes = [
  { from: legacyLinks.sourcing.link, to: appLinks.influencers.link },
  { from: legacyLinks.planning.link, to: appLinks.deals.link },
  { from: legacyLinks.analytics.link, to: appLinks.budgetDistribution.link },
  { from: appLinks.index.link, to: appLinks.influencers.link },
];

const wipRoutes = [appLinks.budgetDistribution.link];

const Routes = (): JSX.Element | null => (
  <DomRoutes>
    <Route
      path={appLinks.signIn.link}
      element={
        <PublicRoute>
          <Suspense fallback={<Loader variant="fullScreen" />}>
            <SignIn />
          </Suspense>
        </PublicRoute>
      }
    />
    {redirectRoutes.map((route) => (
      <Route
        key={route.from}
        path={route.from}
        element={<Navigate replace to={route.to} />}
      />
    ))}
    <Route element={<Layout />}>
      {privateRoutes.map(({ Component, paths }) =>
        paths.map((link) => (
          <Route
            key={link}
            path={link}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader variant="fullScreen" />}>
                  <Component />
                </Suspense>
              </PrivateRoute>
            }
          />
        )),
      )}
      {wipRoutes.map((route) => (
        <Route
          key={route}
          path={route}
          element={
            <PrivateRoute>
              <ErrorBoundary variant="wip" height="90vh" />
            </PrivateRoute>
          }
        />
      ))}
      <Route
        path="*"
        element={
          <PrivateRoute>
            <ErrorBoundary variant="notFound" height="90vh" />
          </PrivateRoute>
        }
      />
    </Route>
  </DomRoutes>
);

export default Routes;
